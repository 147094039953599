import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { ProjectSettingsContext } from "../context/ProjectSettingsContext";

const Head = ({ ...props }) => {
	const { projectContextData } = useContext(ProjectSettingsContext);
	const [projectData, setProjectData] = projectContextData;

	return (
		<Helmet>
			<title>
				{props.title ? props.title + " | " : null} {projectData.title ?? ""}
			</title>
			<link rel="shortcut icon" href={projectData.favicon} />
		</Helmet>
	);
};
export default Head;
