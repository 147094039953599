import React, {useEffect, useState} from 'react'
import {Button, Col, Form, FormGroup, Row, TabPane, UncontrolledAlert, Spinner} from 'reactstrap'
import {useTranslation} from "react-i18next";
import {Block, BlockHeadContent, Icon, RSelect, TooltipComponent} from '../Component';
import {platformcmsServices, videoServices} from '../../services';
import {useParams} from 'react-router';
import {components} from "react-select";
import countries from './CountryCodes'

export const Monetization = (props) => {

	const {t} = useTranslation("common");
	const {videoId} = useParams();
	const [policies, setPolicies] = useState();
	const [assetDetails, setAssetDetails] = useState();
	const [monetizeDisable, setMonetizeDisable] = useState(true);
	const [monetizeLoading, setMonetizeLoading] = useState(false);
	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});
	const [formData, setFormData] = useState({
		monetize: "disable",
		owner_type: "general",
		asset_type: "web",
		block_outside: true,
		content_id_status: false,
		territories: []
	});

	const setMonetization = (value) => {
		if (value === "disable") {
			setFormData({monetize: value});
		} else {
			setFormData(current => ({...current, monetize: value}))
		}
	}

	const getPolicies = async () => {
		try {
			const res = await platformcmsServices.getCmsPolicies(props.cmsId);
			if (res.payload?.length > 0)
				setPolicies(res.payload.map((val) => ({id: val.id, label: val.name, value: val.id})));
			setAlertData({
				status: true,
				type: "success",
				icon: "check",
				message: res?.message
			});
		} catch (err) {
			const {response} = err;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? err.message,
			});
		}
	};

	const getAssetDetails = async () => {
		try {
			const res = await videoServices.getMonetizeAssetDetails(videoId);
			setAssetDetails(res.payload);
		} catch (err) {
			console.error(err);
		}
	}

	const handleSubmit = async () => {
		const {monetize, ...data} = formData;
		setMonetizeLoading(true);
		if (data.owner_type !== "general" && data.territories.length < 1) {
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: t('videos.publish.monetization.territories_error'),
			});
			return;
		}
		if (monetize && data.owner_type !== "spesific_territories") {
			try {
				const res = await videoServices.setMonetizationPolicies(videoId, data);
				setAlertData({
					status: true,
					type: "success",
					icon: "check",
					message: res?.message
				});
				setMonetizeDisable(true)
			} catch (err) {
				const {response} = err;
				setAlertData({
					status: true,
					type: "danger",
					icon: "cross-circle",
					message: response?.data?.message ?? err.message,
				});
			}
			setMonetizeLoading(false);
		}
	}

	useEffect(() => {
		if (props.activeTab === "2") {
			addCountryLabels();
			getPolicies();
			getAssetDetails();
		}
	}, [props.activeTab])

	useEffect(() => {
		console.log("monetizeDisable:",monetizeDisable)
		console.log("monetizeLoading:",monetizeLoading)
		console.log("formData:",formData)
		if (formData.policy_id && formData.monetize === "active") {
			setMonetizeDisable(false)
		} else {
			setMonetizeDisable(true)
		}
	}, [formData])

	const monetizeRawData = [
		{
			id: 1,
			label: t('videos.publish.monetization.monetize.disable'),
			value: "disable",
			icon: "https://lh3.googleusercontent.com/7usZF0O26NJ2EbDBFe29rNKmTkcN7O3r6poW7zKf6IxTDG3xPmkVyr7xKAjqUtob6Z8=w60"
		},
		{
			id: 2,
			label: t('videos.publish.monetization.monetize.active'),
			value: "active",
			icon: "https://www.gstatic.com/images/icons/material/system/2x/attach_money_googgreen_48dp.png"
		},
		{
			id: 3,
			label: t('videos.publish.monetization.monetize.limit'),
			value: "limit",
			icon: "https://www.gstatic.com/images/icons/material/system/2x/attach_money_googyellow_48dp.png"
		},
		{
			id: 4,
			label: t('videos.publish.monetization.monetize.block'),
			value: "block",
			icon: "https://www.gstatic.com/images/icons/material/system/2x/attach_money_googgreen_48dp.png"
		},
		{
			id: 5,
			label: t('videos.publish.monetization.monetize.share'),
			value: "share",
			icon: "https://www.gstatic.com/images/icons/material/system/2x/attach_money_googgreen_48dp.png"
		},
	];

	const {Option} = components;
	const IconOption = props => (
		<Option {...props}>
			<img
				src={props.data.icon}
				style={{width: 20}}
				alt={props.data.label}
			/>
			{props.data.label}
		</Option>
	);

	const addCountryLabels = () => {
		for (var country of countries) {
			country.label = t(`countries:${country.value}`)
		}
	}

	const setOwnerType = (value) => {
		if (value === "general")
			setFormData(current => ({...current, owner_type: value, territories: []}))
		else
			setFormData(current => ({...current, owner_type: value}));
	}

	const SingleValue = (props) => {
		return (
			<div>
				<img
					src={props.data.icon}
					style={{width: 20}}
					alt={props.data.label}
				/>
				{props.children}
			</div>
		);
	};

	{/*<<<<<<< HEAD*/}
		return (
        <TabPane key={assetDetails} tabId="2">
	        {alertData.status && (
		        <Block>
			        <BlockHeadContent>
				        <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
					        <Icon name={alertData.icon ?? "cross-circle"}/>
					        {alertData.message ?? t("error.unknown")}
				        </UncontrolledAlert>
			        </BlockHeadContent>
		        </Block>
	        )}
	        <Row className='mt-2 mb-3'>
		        <Col md="6">
			        <FormGroup>
				        <div>
					        <label className='form-label'
					               htmlFor={"monetization"}>{t('videos.publish.tab-monetization')}</label>
					        <RSelect
						        isDisabled={!props.tokenStatus || props.monetize === "active"}
						        defaultValue={monetizeRawData.find((data) => data.value === props.monetize)}
						        onChange={(e) => setMonetization(e.value)}
						        id="monetization"
						        options={[monetizeRawData[0], monetizeRawData[1]]}
						        components={{Option: IconOption, SingleValue}}
					        />
				        </div>
			        </FormGroup>
		        </Col>
		        <Col md="6">
			        <FormGroup>
				        <div>
					        <label className='form-label'
					               htmlFor={"upload_policies"}>{t('videos.publish.monetization.upload_policies')}</label>
					        <RSelect
						        defaultValue={{label: assetDetails?.claim?.policy?.name}}
						        isDisabled={formData.monetize === "disable"}
						        onChange={(e) => setFormData(current => ({...current, policy_id: e.id}))}
						        id="upload_policies" options={policies}/>
				        </div>
			        </FormGroup>
		        </Col>
	        </Row>
	        <span className="preview-title overline-title">{t('videos.publish.monetization.content_id_title')}</span>
	        <Row className='mb-4'>
		        <Col md="12">
			        <FormGroup>
				        <div className='custom-control custom-checkbox'>
					        <input
						        name={"content_id_status"}
						        id={"content_id_status"}
						        type="checkbox"
						        className={"custom-control-input form-control"}
						        onChange={(e) => {
							        setFormData(current => ({
								        ...current,
								        content_id_status: !current.content_id_status
							        }));
						        }}
						        defaultChecked={assetDetails?.asset?.matchPolicy?.policyId ? true : false}
						        disabled={formData.monetize === "disable"}/>
					        <label className='custom-control-label' htmlFor={"content_id_status"}>
						        <span className='mr-1'>{t('videos.publish.monetization.content_id_cb')}</span>
						        <TooltipComponent icon="help-fill" iconClass="card-hint" direction="left" id="tooltip-2"
						                          text={t('videos.publish.monetization.content_id_hint')}/></label>
				        </div>
			        </FormGroup>
		        </Col>
	        </Row>
	        {formData?.content_id_status &&
		        <Row className='mb-4'>
			        <Col md="6">
				        <FormGroup key={assetDetails}>
					        <div>
						        <label className='form-label'
						               htmlFor={"match_policy"}>{t('videos.publish.monetization.match_policy')}</label>
						        <RSelect
							        isDisabled={formData.monetize === "disable"}
							        onChange={(e) => setFormData(current => ({...current, match_policy: e.id}))}
							        id="match_policy"
							        options={policies}
						        />
					        </div>
				        </FormGroup>
			        </Col>
		        </Row>
	        }
	        <span className="preview-title overline-title">{t('videos.publish.monetization.ownership')}</span>
	        <Row className='mb-4'>
		        <Col md="6">
			        <FormGroup>
				        <div>
					        <label className='form-label'
					               htmlFor={"owner_type"}>{t('videos.publish.monetization.ownership')}</label>
					        <RSelect
						        isDisabled={formData.monetize === "disable"}
						        defaultValue={assetDetails?.asset?.ownership?.general?.[0].territories ? {
							        id: 2,
							        label: t('videos.publish.monetization.spesific_territories'),
							        value: "spesific_territories"
						        } : {
							        id: 1,
							        label: t('videos.publish.monetization.general'),
							        value: "general"
						        }}
						        onChange={(e) => setOwnerType(e.value)} id="owner_type"
						        options={[{
							        id: 1,
							        label: t('videos.publish.monetization.general'),
							        value: "general"
						        }, {
							        id: 2,
							        label: t('videos.publish.monetization.spesific_territories'),
							        value: "spesific_territories"
						        }]}/>
				        </div>
			        </FormGroup>
		        </Col>
	        </Row>
	        {(formData.owner_type !== "general") &&
		        <>
			        <Row className='mb-4'>
				        <Col md="6">
					        <FormGroup>
						        <div className={"custom-control custom-radio"}>
							        <input disabled={formData.monetize === "disable"}
							               checked={assetDetails?.asset?.ownership?.general?.[0]?.type === "include" || formData.owner_type === "include"}
							               onChange={(e) => setFormData(current => ({
								               ...current,
								               owner_type: "include"
							               }))} id={"include"} type="radio"
							               className={"custom-control-input form-control"} value={"include"}/>
							        <label className="custom-control-label"
							               htmlFor={"include"}>{t('videos.publish.monetization.selected_regions')}</label>
						        </div>
					        </FormGroup>
				        </Col>
				        <Col md="6">
					        <FormGroup>
						        <div className={"custom-control custom-radio"}>
							        <input disabled={formData.monetize === "disable"}
							               checked={assetDetails?.asset?.ownership?.general?.[0]?.type === "exclude" || formData.owner_type === "exclude"}
							               onChange={(e) => setFormData(current => ({
								               ...current,
								               owner_type: "exclude"
							               }))} id={"exclude"} type="radio"
							               className={"custom-control-input form-control"} value={"exclude"}/>
							        <label className="custom-control-label"
							               htmlFor={"exclude"}>{t('videos.publish.monetization.except_selected_regions')}</label>
						        </div>
					        </FormGroup>
				        </Col>
			        </Row>
			        <Row className='mb-4'>
				        <Col md="12">
					        <FormGroup>
						        <div>
							        <label className='form-label'
							               htmlFor={"territories"}>{t('videos.publish.monetization.territories')}</label>
							        <RSelect
								        isDisabled={formData.monetize === "disable"}
								        isMulti
								        onChange={(result) => {
									        setFormData(current => ({
										        ...current,
										        territories: result.map((res) => res.value)
									        }))
								        }}
								        id="territories"
								        options={countries}
								        value={assetDetails?.asset?.ownership?.general?.[0]?.territories.map((item) => countries.find((cn) => cn.value === item))}/>
						        </div>
					        </FormGroup>
				        </Col>
			        </Row>
			        <Row className='mb-4'>
				        <Col md="12">
					        <FormGroup>
						        <div className='custom-control custom-checkbox'>
							        <input
								        name={"block_outside"}
								        id={"block_outside"}
								        type="checkbox"
								        className={"custom-control-input form-control"}
								        defaultChecked={assetDetails?.claim?.blockOutsideOwnership}
								        disabled={formData.monetize === "disable"}
								        onChange={(e) => {
									        setFormData(current => ({
										        ...current,
										        block_outside: !current.block_outside
									        }));
								        }}/>
							        <label className='custom-control-label'
							               htmlFor={"block_outside"}>{t('videos.publish.monetization.block_outside')}</label>
						        </div>
					        </FormGroup>
				        </Col>
			        </Row>
		        </>
	        }
	        <Row className='mb-2'>
		        <Col md="6">
			        <FormGroup>
				        <div>
					        <label className='form-label'
					               htmlFor={"asset_type"}>{t('videos.publish.monetization.asset_type')}</label>
					        <RSelect
						        isDisabled={formData.monetize === "disable"}
						        defaultValue={assetDetails?.asset?.type === "episode" ? {
							        id: 2,
							        label: "Episode",
							        value: "episode"
						        } : {id: 1, label: "Web", value: "web"}}
						        onChange={(e) => setFormData(current => ({...current, asset_type: e.value}))}
						        id="asset_type" options={[{id: 1, label: "Web", value: "web"}, {
						        id: 2,
						        label: "Episode",
						        value: "episode"
					        }]}/>
				        </div>
			        </FormGroup>
		        </Col>
	        </Row>
	        <Row className='mb-2'>
		        <Col md="6">
			        <FormGroup>
				        <div>
					        <label className='form-label'
					               htmlFor={"asset_title"}>{t('videos.publish.monetization.asset_title')}</label>
					        <input
						        className='form-control'
						        type="text"
						        onChange={(e) => setFormData(current => ({...current, title: e.target.value}))}
						        id="asset_title"
						        disabled={formData.monetize === "disable"}
						        defaultValue={assetDetails?.asset?.metadata?.title}/>
				        </div>
			        </FormGroup>
		        </Col>
		        {(formData.asset_type === "episode" || assetDetails?.asset?.type === "episode") &&
			        <Col md="6">
				        <FormGroup>
					        <div>
						        <label className='form-label'
						               htmlFor={"custom_id"}>{t('videos.publish.monetization.custom_id')}</label>
						        <input
							        className='form-control'
							        type="text"
							        onChange={(e) => setFormData(current => ({...current, custom_id: e.target.value}))}
							        id="custom_id"
							        disabled={formData.monetize === "disable"}
							        defaultValue={assetDetails?.asset?.metadata?.customId}/>
					        </div>
				        </FormGroup>
			        </Col>
		        }
	        </Row>
	        {(formData.asset_type === "episode" || assetDetails?.asset?.type === "episode") &&
		        <Row>
			        <Col md="6">
				        <FormGroup>
					        <div>
						        <label className='form-label'
						               htmlFor={"season_number"}>{t('videos.publish.monetization.season_number')}</label>
						        <input
							        className='form-control'
							        type="number"
							        onChange={(e) => setFormData(current => ({
								        ...current,
								        season_number: e.target.value
							        }))}
							        id="season_number"
							        disabled={formData.monetize === "disable"}
							        defaultValue={assetDetails?.asset?.metadata?.seasonNumber}/>
					        </div>
				        </FormGroup>
			        </Col>
			        <Col md="6">
				        <FormGroup>
					        <div>
						        <label className='form-label'
						               htmlFor={"episode_number"}>{t('videos.publish.monetization.episode_number')}</label>
						        <input
							        className='form-control'
							        type="number"
							        onChange={(e) => setFormData(current => ({
								        ...current,
								        episode_number: e.target.value
							        }))}
							        id="episode_number"
							        disabled={formData.monetize === "disable"}
							        defaultValue={assetDetails?.asset?.metadata?.episodeNumber}/>
					        </div>
				        </FormGroup>
			        </Col>
		        </Row>
	        }
			<FormGroup className={"mt-5"}>
				<Button color="primary" size="md" onClick={handleSubmit} disabled={monetizeDisable || monetizeLoading}>
					{monetizeLoading ? <Spinner size={"sm"} color={"white"}/> : null}
					<div className={"ml-2 mr-2"}>
						{t('operations.save')}
					</div>
				</Button>
			</FormGroup>
        </TabPane>
)

	{/*
=======

	return (
			<TabPane tabId="2">
				<Row className='mt-2 mb-3'>
					<Col md="6">
						<FormGroup>
							<div>
								<label className='form-label'
								       htmlFor={"monetization"}>{t('videos.publish.tab-monetization')}</label>
								<RSelect
									isDisabled={!props.tokenStatus || props.monetize === "active"}
									defaultValue={monetizeRawData.find((data) => data.value === props.monetize)}
									onChange={(e) => setMonetization(e.value)}
									id="monetization"
									options={[monetizeRawData[0], monetizeRawData[1]]}
									components={{Option: IconOption, SingleValue}}
								/>
							</div>
						</FormGroup>
					</Col>
					<Col md="6">
						<FormGroup key={currentPolicy}>
							<div>
								<label className='form-label'
								       htmlFor={"upload_policies"}>{t('videos.publish.monetization.upload_policies')}</label>
								<RSelect
									defaultValue={{label: currentPolicy?.policy?.name, value: currentPolicy?.policy?.id}}
									isDisabled={formData.monetize === "disable"}
									onChange={(e) => setFormData(current => ({...current, policy_id: e.id}))}
									id="upload_policies" options={policies}/>
							</div>
						</FormGroup>
					</Col>
				</Row>
				{formData.monetize === "active" &&
					<>
					<span
						className="preview-title overline-title">{t('videos.publish.monetization.content_id_title')}</span>
						<Row className='mb-4'>
							<Col md="12">
								<FormGroup>
									<div className='custom-control custom-checkbox'>
										<input name={"content_id_status"} id={"content_id_status"} type="checkbox"
										       className={"custom-control-input form-control"} onChange={(e) => {
											setFormData(current => ({
												...current,
												content_id_status: !current.content_id_status
											}));
										}}/>
										<label className='custom-control-label' htmlFor={"content_id_status"}>
											<span className='mr-1'>{t('videos.publish.monetization.content_id_cb')}</span>
											<TooltipComponent icon="help-fill" iconClass="card-hint" direction="left"
											                  id="tooltip-2"
											                  text={t('videos.publish.monetization.content_id_hint')}/></label>
									</div>
								</FormGroup>
							</Col>
						</Row>
						{formData?.content_id_status &&
							<Row className='mb-4'>
								<Col md="6">
									<FormGroup key={currentPolicy}>
										<div>
											<label className='form-label'
											       htmlFor={"match_policy"}>{t('videos.publish.monetization.match_policy')}</label>
											<RSelect isDisabled={formData.monetize === "disable"}
											         onChange={(e) => setFormData(current => ({
												         ...current,
												         match_policy: e.id
											         }))} id="match_policy" options={policies}/>
										</div>
									</FormGroup>
								</Col>
							</Row>
						}
						<span className="preview-title overline-title">{t('videos.publish.monetization.ownership')}</span>
						<Row className='mb-4'>
							<Col md="6">
								<FormGroup>
									<div>
										<label className='form-label'
										       htmlFor={"owner_type"}>{t('videos.publish.monetization.ownership')}</label>
										<RSelect defaultValue={{
											id: 1,
											label: t('videos.publish.monetization.general'),
											value: "general"
										}} onChange={(e) => setOwnerType(e.value)} id="owner_type" options={[{
											id: 1,
											label: t('videos.publish.monetization.general'),
											value: "general"
										}, {
											id: 1,
											label: t('videos.publish.monetization.spesific_territories'),
											value: "spesific_territories"
										}]}/>
									</div>
								</FormGroup>
							</Col>
						</Row>
						{formData.owner_type !== "general" &&
							<>
								<Row className='mb-4'>
									<Col md="6">
										<FormGroup>
											<div className={"custom-control custom-radio"}>
												<input checked={formData.owner_type === 'include'}
												       onChange={(e) => setFormData(current => ({
													       ...current,
													       owner_type: "include"
												       }))} id={"include"} type="radio"
												       className={"custom-control-input form-control"} value={"include"}/>
												<label className="custom-control-label"
												       htmlFor={"include"}>{t('videos.publish.monetization.selected_regions')}</label>
											</div>
										</FormGroup>
									</Col>
									<Col md="6">
										<FormGroup>
											<div className={"custom-control custom-radio"}>
												<input checked={formData.owner_type === 'exclude'}
												       onChange={(e) => setFormData(current => ({
													       ...current,
													       owner_type: "exclude"
												       }))} id={"exclude"} type="radio"
												       className={"custom-control-input form-control"} value={"exclude"}/>
												<label className="custom-control-label"
												       htmlFor={"exclude"}>{t('videos.publish.monetization.except_selected_regions')}</label>
											</div>
										</FormGroup>
									</Col>
								</Row>
								<Row className='mb-4'>
									<Col md="12">
										<FormGroup>
											<div>
												<label className='form-label'
												       htmlFor={"territories"}>{t('videos.publish.monetization.territories')}</label>
												<RSelect isMulti onChange={(result) => {
													setFormData(current => ({
														...current,
														territories: result.map((res) => res.value)
													}))
												}} id="territories" options={countries}/>
											</div>
										</FormGroup>
									</Col>
								</Row>
								<Row className='mb-4'>
									<Col md="12">
										<FormGroup>
											<div className='custom-control custom-checkbox'>
												<input name={"block_outside"} id={"block_outside"} type="checkbox"
												       className={"custom-control-input form-control"}
												       defaultChecked={formData?.block_outside} onChange={(e) => {
													setFormData(current => ({
														...current,
														block_outside: !current.block_outside
													}));
												}}/>
												<label className='custom-control-label'
												       htmlFor={"block_outside"}>{t('videos.publish.monetization.block_outside')}</label>
											</div>
										</FormGroup>
									</Col>
								</Row>
							</>
						}
						<Row className='mb-2'>
							<Col md="6">
								<FormGroup>
									<div>
										<label className='form-label'
										       htmlFor={"asset_type"}>{t('videos.publish.monetization.asset_type')}</label>
										<RSelect defaultValue={{id: 1, label: "Web", value: "web"}}
										         onChange={(e) => setFormData(current => ({
											         ...current,
											         asset_type: e.value
										         }))} id="asset_type" options={[{id: 1, label: "Web", value: "web"}, {
											id: 1,
											label: "Episode",
											value: "episode"
										}]}/>
									</div>
								</FormGroup>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col md="6">
								<FormGroup>
									<div>
										<label className='form-label'
										       htmlFor={"asset_title"}>{t('videos.publish.monetization.asset_title')}</label>
										<input className='form-control' type="text"
										       onChange={(e) => setFormData(current => ({
											       ...current,
											       title: e.target.value
										       }))} id="asset_title"/>
									</div>
								</FormGroup>
							</Col>
							{formData.asset_type === "episode" &&
								<Col md="6">
									<FormGroup>
										<div>
											<label className='form-label'
											       htmlFor={"custom_id"}>{t('videos.publish.monetization.custom_id')}</label>
											<input className='form-control' type="text"
											       onChange={(e) => setFormData(current => ({
												       ...current,
												       custom_id: e.target.value
											       }))} id="custom_id"/>
										</div>
									</FormGroup>
								</Col>
							}
						</Row>
					</>}
				{(formData.asset_type === "episode") &&
					<Row>
						<Col md="6">
							<FormGroup>
								<div>
									<label className='form-label'
									       htmlFor={"season_number"}>{t('videos.publish.monetization.season_number')}</label>
									<input className='form-control' type="number" onChange={(e) => setFormData(current => ({
										...current,
										season_number: e.target.value
									}))} id="season_number"/>
								</div>
							</FormGroup>
						</Col>
						<Col md="6">
							<FormGroup>
								<div>
									<label className='form-label'
									       htmlFor={"episode_number"}>{t('videos.publish.monetization.episode_number')}</label>
									<input className='form-control' type="number" onChange={(e) => setFormData(current => ({
										...current,
										episode_number: e.target.value
									}))} id="episode_number"/>
								</div>
							</FormGroup>
						</Col>
					</Row>
				}
			</TabPane>
		)
		>>>>>>> feature/mcms550
		*/}
}
