import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * POST: '/api/auth/login'
 */

export async function login(data) {
	try {
		if (data) {
			const endpoint = generateApiEndpoint("auth/login");
			const response = await restApiClient.post(
				endpoint,
				{
					...data,
				},
				{
					headers: {
						"Content-Type": "application/json",
						"Content-Language": "tr",
					},
				}
			);
			console.log("response", response?.data);
			return response;
		} else {
			return {
				status: "error",
				message: "Please enter your username and password",
			};
		}
	} catch (error) {
		console.error("error", error);
		return error;
	}
}
