import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, UncontrolledDropdown, UncontrolledAlert, Badge } from "reactstrap";
import {
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Button,
	Row,
	Col,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableItem,
	DataTableRow,
	Icon,
	PaginationComponent,
	RSelect,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { productServices, companyService, directorServices, parametersServices, productGenresServices, licensorsServices } from "../../services";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useHistory, useLocation } from "react-router";
import { FilterComponent } from "../../components/FilterComponent";

const Product = () => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const location = useLocation();
 	let { search } = useLocation();
 	const [searchParams, setSearchParams] = useState("");

	 useEffect(() => {
		location.search = searchParams;
		history.replace({ pathname: location.pathname, search: location.search});
		loadData();
	},[searchParams]); 

	useEffect(() => {
		const query = new URLSearchParams(search);
		const hasPage = query.has('page');
		const hasLimit = query.has('limit'); 

		if(hasPage)
		setCurrentPage(Number(query.get('page')));
		else 
		query.append("page", currentPage);
		if(hasLimit)
		setItemPerPage(Number(query.get('limit')));
		else 
		query.append("limit", itemPerPage);
		
		setSearchParams(decodeURI(query.toString())) 
		history.replace({ pathname: location.pathname, search: decodeURI(query.toString()) })
		loadData();
 	},[]); 

	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});

	const [productData, setProductData] = useState([]);

	const [productMeta, setProductMeta] = useState({
		total: null,
	});

	const [companyRList, setCompanyRList] = useState([]);
	const [directorRList, setDirectorRList] = useState([]);
	const [licensorRList, setLicensorRList] = useState([]);
	const [productTypeRList, setProductTypeRList] = useState([]);
	const [productGenreRList, setProductGenreRList] = useState([]);
	const [allFilterData, setAllFilterData] = useState([]);
	const [parameters] = useState(["company.id", "director.id", "licensor_id", "product_type", "genres.id"]);

	const [releaseDate, setReleaseDate] = useState(null);
	const [rangeStart, setRangeStart] = useState(null);
	const [rangeEnd, setRangeEnd] = useState(null);

	const [sm, updateSm] = useState(false);
	const [modal, setModal] = useState({
		edit: false,
		add: false,
	});
	const [editId, setEditedId] = useState();
	const [formData, setFormData] = useState({
		name: "",
		company: "",
		director: "",
		licensor: "",
		license_date_start: "",
		license_date_end: "",
		license_price: "",
		release_date: "",
		product_type: "",
		product_genres: [],
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);

	const loadData = async () => {
		try {
			const product = await productServices.getProductsByPageAndLimit({ search: decodeURIComponent(location.search) });
			setProductMeta({ total: product.meta.total });
			setProductData([...product.payload]);
		} catch (error) {
			console.debug(error);
		}
	};

	useEffect(() => {
		getCompanyList();
		getLicensorList();
		getProductTypeList();
		getProductGenreList();
		if (modal.add || modal.edit) {
			getDirectorList();
		}
	}, [modal.add, modal.edit]);

	const getCompanyList = async () => {
		const companyList = await companyService.getCompanies();
		if (companyList?.data?.payload?.length > 0) {
			const createRList = companyList.data.payload.map((company, key) => {
				return { value: company.id, label: company.name };
			});
			setCompanyRList(createRList);
			setAllFilterData(current => ({...current, 'company.id' : createRList }));
		}
	};

	const getDirectorList = async () => {
		const directorList = await directorServices.getDirectors();
		if (directorList?.payload?.length > 0) {
			const createRList = directorList.payload.map((director, key) => {
				return { value: director.id, label: director.name };
			});
			setDirectorRList(createRList);
			setAllFilterData(current => ({...current, 'director.id' : createRList }));
		}
	};

	const getLicensorList = async () => {
		const licensorList = await licensorsServices.getLicensors();
		if (licensorList?.payload?.length > 0) {
			const createRList = licensorList.payload.map((licensor, key) => {
				return { value: licensor.id, label: licensor.name };
			});
			setLicensorRList(createRList);
			setAllFilterData(current => ({...current, 'licensor_id' : createRList }));
		}
	};

	const getProductTypeList = async () => {
		const productTypeList = await parametersServices.getProductTypes();
		if (productTypeList?.payload?.length > 0) {
			const createRList = productTypeList.payload.map((productType, key) => {
				return { value: productType.key, label: productType.value };
			});
			setProductTypeRList(createRList);
			setAllFilterData(current => ({...current, 'product_type' : createRList }));
		}
	};

	const getProductGenreList = async () => {
		const productGenreList = await productGenresServices.getProductGenres();
		if (productGenreList?.payload?.length > 0) {
			const createRList = productGenreList.payload.map((productGenre, key) => {
				return { value: productGenre.id, label: productGenre.name };
			});
			setProductGenreRList(createRList);
			setAllFilterData(current => ({...current, 'genres.id' : createRList }));
		}
	};

	// function to reset the form
	const resetForm = () => {
		setFormData({
			name: "",
			company: "",
			director: "",
			licensor: "",
			license_date_start: "",
			license_date_end: "",
			license_price: "",
			release_date: "",
			product_type: "",
			product_genres: [],
		});
		setReleaseDate(null);
		setRangeStart(null);
		setRangeEnd(null);
		setAlertData({
			status: false,
			type: "info",
			icon: "alert-circle",
			message: t("error.unknown"),
		});
	};

	const handleChange = (result, e) => {
		setFormData({ ...formData, [e.name]: result.value });
	};
	const handleMultiChange = (result, e) => {
		setFormData({ ...formData, [e.name]: result.map((res) => res.value) });
	};

	// function to close the form modal
	const onFormCancel = () => {
		setModal({ edit: false, add: false });
		resetForm();
	};

	// submit function to add a new item
	const onFormSubmit = async (submitData) => {
		const { name, license_price } = submitData;
		let submittedData = {
			name: name,
			company_id: formData.company,
			director_id: formData.director,
			licensor_id: formData.licensor,
			license_date_start: rangeStart ? moment(rangeStart).format("YYYY-MM-DD") : null,
			license_date_end: rangeEnd ? moment(rangeEnd).format("YYYY-MM-DD") : null,
			license_price: license_price,
			publish_date: releaseDate ? moment(releaseDate).format("YYYY") : null,
			product_type: formData.product_type,
			product_genre_id: formData.product_genres,
		};
		try {
			const response = await productServices.addProduct({
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// submit function to update a new item
	const onEditSubmit = async (submitData) => {
		const { name, license_price } = submitData;
		let submittedData = {
			name: name,
			company_id: formData.company,
			director_id: formData.director,
			licensor_id: formData.licensor,
			license_date_start: rangeStart ? moment(rangeStart).format("DD.MM.YYYY") : null,
			license_date_end: rangeEnd ? moment(rangeEnd).format("DD.MM.YYYY") : null,
			license_price: license_price,
			publish_date: releaseDate ? moment(releaseDate).format("YYYY") : null,
			product_type: formData.product_type,
			product_genre_id: formData.product_genres
		};
		try {
			const response = await productServices.updateProduct(editId, {
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// function that loads the want to editted data
	const onEditClick = (id) => {
		resetForm();
		productData.forEach((item) => {
			if (item.id === id) {
				setFormData({
					name: item.name,
					company: item.company_id,
					companyValue: { value: item.company?.id, label: item.company?.name },
					director: item.director_id,
					directorValue: { value: item.director?.id, label: item.director?.name },
					licensor: item.licensor_id,
					licensorValue: { value: item.licensor?.id, label: item.licensor?.name },
					license_date_start: item.license_date_start ? new Date(item.license_date_start) : null,
					license_date_end: item?.license_date_end ? new Date(item?.license_date_end) : null,
					license_price: item?.license_price,
					publish_date: item?.publish_date ? new Date(item?.publish_date) : null,
					product_type: item.product_type,
					productTypeValue: { value: item?.product_type ?? undefined, label: item?.product_type },
					product_genres: item.genres.map((genre) => (genre.id)),
					productGenreValue:  item.genres.map((genre) => ({value: genre.id, label: genre.name})),
				});
				setReleaseDate(item?.publish_date ? new Date(item?.publish_date) : null);
				setRangeStart(item?.license_date_start ? new Date(item?.license_date_start) : null);
				setRangeEnd(item?.license_date_end ? new Date(item?.license_date_end) : null);
				setEditedId(id);
				setModal({ edit: true }, { add: false });
			}
		});
	};

	// function to change to suspend property for an item
	const deleteProduct = async (id) => {
		try {
			await productServices.deleteProduct(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	const { errors, register, handleSubmit } = useForm();

	// Get current list, pagination
	// const indexOfLastItem = currentPage * itemPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemPerPage;
	// const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
	const currentItems = productData;

	// Change Page
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		const query = new URLSearchParams(search);
		query.set("page", pageNumber)
		setSearchParams(decodeURI(query.toString()));
	}

	return (
		<React.Fragment>
			<Head title={t("content.product.list")}></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle tag="h3" page>
								{t("content.product.list")}
							</BlockTitle>
							<BlockDes className="text-soft">
								<p>
									{t("general.total")} {productMeta.total} {t("general.product")}.
								</p>
							</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
									<Icon name="menu-alt-r"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3">
										<li className="nk-block-tools-opt">
											<Button
												color="primary"
												className="btn-icon"
												onClick={() => {
													resetForm();
													setModal({ add: true });
												}}
											>
												<Icon name="plus"></Icon>
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>

				{alertData.status && !modal.add && !modal.edit ? (
					<Block>
						<BlockHeadContent>
							<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
								<Icon name={alertData.icon ?? "cross-circle"} />
								{alertData.message ?? t("error.unknown")}
							</UncontrolledAlert>
						</BlockHeadContent>
					</Block>
				) : null}

				<Block>
					<DataTable className="card-stretch">
					<FilterComponent
						type="name"
						history={history} 
						data={allFilterData} 
						parameters={parameters} 
						search={search} 
						setSearch={setSearchParams} 
						currentPage={currentPage} 
						setCurrentPage={setCurrentPage} 
						itemPerPage={itemPerPage}
						setItemPerPage={setItemPerPage} />
						<DataTableBody compact>
							<DataTableHead>
								<DataTableRow className="nk-tb-col-check">
									<span className="sub-text">#</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.product.name")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("content.product.company")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t('product_genre.default')}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.product.licensor")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.product.license_date")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.product.product_type")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("content.product.status")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text float-right">{t("content.product.action")}</span>
								</DataTableRow>
							</DataTableHead>
							{/*Head*/}
							{currentItems.length > 0
								? currentItems.map((item, key) => {
										return (
											<DataTableItem key={key}>
												<DataTableRow className="nk-tb-col-check">{item.id}</DataTableRow>
												<DataTableRow>
													<span className="tb-lead">{item?.name}</span>
												</DataTableRow>
												<DataTableRow>
													<span className="tb-lead">{item?.company?.name}</span>
												</DataTableRow>
												<DataTableRow>
													<span className="tb-lead">{item?.genres?.map((genre) => genre.name).join(', ')}</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>{item?.licensor?.name}</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>
														{moment(item?.license_date_start).format("DD.MM.YYYY")} / {moment(item?.license_date_end).format("DD.MM.YYYY")}
													</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>{item?.product_type}</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>
														<Badge className="badge-dot" color={item.status.toLowerCase() == "active" ? "success" : "danger"}>
															{item?.status}
														</Badge>
													</span>
												</DataTableRow>
												<DataTableRow className="nk-tb-col-tools">
													<ul className="nk-tb-actions gx-1">
														<li>
															<UncontrolledDropdown>
																<DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
																	<Icon name="more-h"></Icon>
																</DropdownToggle>
																<DropdownMenu right>
																	<ul className="link-list-opt no-bdr">
																		<li onClick={() => onEditClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#edit"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="edit"></Icon>
																				<span>{t("operations.edit")}</span>
																			</DropdownItem>
																		</li>
																		<li
																			onClick={() => {
																				if (window.confirm(t("content.channel.delete-confirm"))) deleteProduct(item.id);
																			}}
																		>
																			<DropdownItem
																				tag="a"
																				href="#delete"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="trash-fill"></Icon>
																				<span>{t("operations.delete")}</span>
																			</DropdownItem>
																		</li>
																	</ul>
																</DropdownMenu>
															</UncontrolledDropdown>
														</li>
													</ul>
												</DataTableRow>
											</DataTableItem>
										);
								  })
								: null}
						</DataTableBody>
						<div className="card-inner">
							{currentItems.length > 0 ? (
								<PaginationComponent itemPerPage={itemPerPage} totalItems={productMeta.total} paginate={paginate} currentPage={currentPage} />
							) : (
								<div className="text-center">
									<span className="text-silent">No data found</span>
								</div>
							)}
						</div>
					</DataTable>
				</Block>

				<Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("content.product.add")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.name")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.company")}</label>
												<RSelect name="company" options={companyRList} required onChange={handleChange} />
												{errors.company && <span className="invalid">{errors.company.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.director")}</label>
												<RSelect name="director" options={directorRList} required onChange={handleChange} />
												{errors.director && <span className="invalid">{errors.director.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.licensor")}</label>
												<RSelect name="licensor" options={licensorRList} required onChange={handleChange} />
												{errors.licensor && <span className="invalid">{errors.licensor.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("content.product.license_date")}</label>
												<div className="form-control-wrap">
													<div className="input-daterange date-picker-range input-group">
														<DatePicker selected={rangeStart} onChange={setRangeStart} selectsStart startDate={rangeStart} endDate={rangeEnd} wrapperClassName="start-m" className="form-control" />
														<div className="input-group-addon" style={{ width: "10%" }}>
															<span className={"w-100 text-center"}>TO</span>
														</div>
														<DatePicker selected={rangeEnd} onChange={setRangeEnd} startDate={rangeStart} endDate={rangeEnd} selectsEnd minDate={rangeStart} wrapperClassName="end-m" className="form-control" />
													</div>
												</div>
												{errors.license_date && <span className="invalid">{errors.license_date.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label htmlFor="default-1" className="form-label">
													{t("content.product.license_price")}
												</label>
												<div className="form-control-wrap">
													<div className="form-text-hint">
														<span className="overline-title">Usd</span>
													</div>
													<input className="form-control" type="number" name="license_price" defaultValue={formData.license_price} ref={register()} />
													{errors.license_price && <span className="invalid">{errors.license_price.message}</span>}
												</div>
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.release_date")}</label>
												<DatePicker selected={releaseDate} onChange={(date) => setReleaseDate(date)} dateFormat="yyyy" showYearPicker yearItemNumber={15} className="form-control date-picker" />
												{errors.release_date && <span className="invalid">{errors.release_date.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.product_type")}</label>
												<RSelect name="product_type" options={productTypeRList} required onChange={handleChange} />
												{errors.product_type && <span className="invalid">{errors.product_type.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.product_genre")}</label>
												<RSelect isMulti name="product_genres" options={productGenreRList} required onChange={handleMultiChange} />
												{errors.product_genre && <span className="invalid">{errors.product_genre.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("content.channel.edit")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onEditSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.name")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.company")}</label>
												<RSelect name="company" options={companyRList} required onChange={handleChange} defaultValue={formData.companyValue} />
												{errors.company && <span className="invalid">{errors.company.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.director")}</label>
												<RSelect name="director" options={directorRList} required onChange={handleChange} defaultValue={formData.directorValue} />
												{errors.director && <span className="invalid">{errors.director.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.licensor")}</label>
												<RSelect name="licensor" options={licensorRList} required onChange={handleChange} defaultValue={formData.licensorValue} />
												{errors.licensor && <span className="invalid">{errors.licensor.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("content.product.license_date")}</label>
												<div className="form-control-wrap">
													<div className="input-daterange date-picker-range input-group">
														<DatePicker selected={rangeStart} onChange={setRangeStart} selectsStart startDate={rangeStart} endDate={rangeEnd} wrapperClassName="start-m" className="form-control" />
														<div className="input-group-addon" style={{ width: "10%" }}>
															<span className={"w-100 text-center"}>TO</span>
														</div>
														<DatePicker selected={rangeEnd} onChange={setRangeEnd} selectsEnd startDate={rangeStart} endDate={rangeEnd} minDate={rangeStart} wrapperClassName="end-m" className="form-control" />
													</div>
												</div>
												{errors.license_date && <span className="invalid">{errors.license_date.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label htmlFor="default-1" className="form-label">
													{t("content.product.license_price")}
												</label>
												<div className="form-control-wrap">
													<div className="form-text-hint">
														<span className="overline-title">Usd</span>
													</div>
													<input className="form-control" type="number" name="license_price" defaultValue={formData.license_price} ref={register()} />
													{errors.license_price && <span className="invalid">{errors.license_price.message}</span>}
												</div>
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.release_date")}</label>
												<DatePicker selected={releaseDate} onChange={(date) => setReleaseDate(date)} dateFormat="yyyy" showYearPicker yearItemNumber={15} className="form-control date-picker" />
												{errors.release_date && <span className="invalid">{errors.release_date.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.product_type")}</label>
												<RSelect name="product_type" options={productTypeRList} required onChange={handleChange} defaultValue={formData.productTypeValue} />
												{errors.product_type && <span className="invalid">{errors.product_type.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.product.product_genre")}</label>
												<RSelect isMulti name="product_genres" defaultValue={formData.productGenreValue} options={productGenreRList} required onChange={handleMultiChange} />
												{errors.product_genre && <span className="invalid">{errors.product_genre.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</Content>
		</React.Fragment>
	);
};
export default Product;
