import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

export const RevenueChart = ({ revenue }) => {

    const [data,setData] = useState();

    useEffect(() => {
        var backgroundColors = [];
        for (var i = 0; i < revenue?.labels?.length - 1; i++) backgroundColors.push("rgba(133, 79, 255, 0.2)");
        backgroundColors.push("rgba(133, 79, 255, 1)")
        if(revenue) {
            setData({
                labels: revenue.labels,
                dataUnit: "USD",
                stacked: true,
                datasets: [
                    {
                        label: "Sales Revenue",
                        width: "30",
                        barPercentage: 0.7,
                        lineTension: 0,
                        categoryPercentage: 0.7,
                        backgroundColor: backgroundColors,
                        data: revenue.series?.revenue,
                    },
                ],
            });
        } 
    },[revenue])

	return (
        <React.Fragment>
            {data && 
		<Bar
			className="sales-bar-chart chartjs-render-monitor"
			data={data}
			options={{
				legend: {
					display: false,
				},
				scales: {
					yAxes: [
						{
							display: false,
						},
					],
					xAxes: [
						{
							display: false,
						},
					],
				},
				maintainAspectRatio: false,
				tooltips: {
					backgroundColor: "#eff6ff",
					titleFontSize: 11,
					titleFontColor: "#6783b8",
					titleMarginBottom: 4,
					bodyFontColor: "#9eaecf",
					bodyFontSize: 10,
					bodySpacing: 3,
					yPadding: 8,
					xPadding: 8,
					footerMarginTop: 0,
					displayColors: false,
				},
			}}
		/>
    }
    </React.Fragment>
	);
};