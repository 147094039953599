exports = module.exports = {
	roles: {},
	clientId: "merzigo-app",
	localStorageKeys: {
		language: "lang",
		accessToken: "merzigo_at",
		refreshToken: "merzigo_rt",
		rememberMe: "merzigo_rm",
	},
};
