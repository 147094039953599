import React from "react";
import AsyncSelect from "react-select/async";

const RAsyncSelect = ({ ...props }) => {
	return (
		<div className="form-control-select">
			<AsyncSelect className={`react-select-container ${props.className ? props.className : ""}`} classNamePrefix="react-select" {...props} />
		</div>
	);
};

export default RAsyncSelect;
