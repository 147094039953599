import React, { useState } from "react";
import { CardTitle } from "reactstrap";
import { reportServices } from "../../../../services";
import { Icon, TooltipComponent } from "../../../Component";
import { RevenueChart } from "../../dashboard/RevenueChart";
import { useTranslation } from "react-i18next";


const SaleRevenue = () => {

	const [data, setData] = useState();
	const [diff, setDiff] = useState();
	const { t, i18n } = useTranslation("common");

	useState(async () => {
		const res = await reportServices.getDashboardRevenuesData();
		setData(res?.payload);
		const diffs = {
			values: [
				{
					range : "last_7day",
					arrow: res.payload.weeklyDiff.toString().charAt(0) === "-" ? "-" : res.payload.weeklyDiff !== 0 ? "+" : "0",
					diff: res.payload.weeklyDiff,
					revenue: res.payload.weeklyRevenue,

				},
				{
					range : "last_28day",
					arrow: res.payload.monthlyDiff.toString().charAt(0) === "-" ? "-" : res.payload.monthlyDiff !== 0 ? "+" : "0",
					diff: res.payload.monthlyDiff,
					revenue: res.payload.monthlyRevenue,
				},
				{
					range : "last_90day",
					arrow: res.payload.monthly3Diff.toString().charAt(0) === "-" ? "-" : res.payload.monthly3Diff !== 0 ? "+" : "0" ,
					diff: res.payload.monthly3Diff,
					revenue: res.payload.monthly3Revenue
				}
			]
		};
		setDiff(diffs);
	}, [])

	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	return (
		<React.Fragment>
			<div className="card-title-group align-start mb-2">
				<CardTitle>
					<h6 className="title">{t("statistic.total_revenue")}</h6>
				</CardTitle>
				<div className="card-tools">
					<TooltipComponent icon="help-fill" iconClass="card-hint" direction="left" id="tooltip-1" text={t("statistic.total_revenue_subtext")} />
				</div>
			</div>
			<div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
				<div className="nk-sale-data-group flex-md-nowrap g-4">
					{diff && diff.values.map((diff, key) =>
						<div key={key} className="nk-sale-data">
							<span className="amount">
								{formatter.format(diff.revenue)}{" "}
								<span className={diff.arrow === "-" ? "change down" : diff.arrow === "+" ? "change up" : "change"}>
									<Icon name={diff.arrow === "-" ? "arrow-long-down" :  diff.arrow === "+"  ? "arrow-long-up"  : null } />
									{diff.arrow == 0 ? "-" : diff.diff}
								</span>
							</span>
							<span className="sub-title">{t("statistic." + diff.range)}</span>
						</div>
					)}
				</div>
				<div className="nk-sales-ck sales-revenue">
					{data?.yearlyRevenue ? <RevenueChart revenue={data?.yearlyRevenue} /> : <h4 className="text-center">No Data</h4>}
				</div>
			</div>
		</React.Fragment>
	);
};
export default SaleRevenue;
