import { parametersServices } from "../../../services";

export async function Languages() {
	const languages = await parametersServices.getLanguages({ limit: 10, page: 1 });
	const columns = [
		{
			key: "id",
			name: "#",
			sort: 0,
			type: "id",
		},
		{
			key: "name",
			name: "static-parameters.title",
			sort: 1,
			type: "title",
		},
		{
			key: "iso",
			name: "static-parameters.isocode",
			sort: 2,
			type: "title",
		},
		{
			key: "status",
			name: "static-parameters.status",
			sort: 3,
			type: "title",
		},
	];
	const rows = languages.payload;
	const tableData = {
		columns: columns,
		rows: rows,
	};
	return tableData;
}
