import restApiClient from "./client";
import {generateApiEndpoint, generateReApiEndpoint} from "../utils";

/**
 * GET: '/api/videos'
 */
export async function getVideos() {
	const endpoint = generateApiEndpoint("videos?limit=all");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/videos'
 */
export async function getMonetizeAssetDetails(videoId) {
	const endpoint = generateApiEndpoint(`videos/${videoId}/monetize`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/videos'
 * Params: { page, limit, filter = { 'upload_status' : ['client_finished','engine_finished']} }
 */
export async function getVideosByPageAndLimit({ search /* page, limit, filter = null, sort = null */}) {
/* 	let filterString = "";
	if (filter) {
		for (const [key, value] of Object.entries(filter)) {
			filterString += `&filter[${key}]=${value}`;
		}
	}
	let sortString = "";
	if (sort === "asc" || sort === "dsc") {
		let direction = sort === "dsc" ? "-" : "";
		sortString = `&sort=${direction}id`;
	} */
	const endpoint = generateApiEndpoint(`videos?${search ?? ""}&include=uploader,publisher,company,channel,inventory`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/videos/{videoId}/{platform}'
 * Params: {  }
 */
export async function getVideoByPlatform(videoId, platform) {
	const endpoint = generateApiEndpoint(`video/${videoId}/${platform}`);
	const response = await restApiClient.get(endpoint);
	return response.data;
}

/**
 * PUT: '/api/videos/{videoId}/{platform}'
 * Params: {  }
 */
export async function updateVideoByPlatform(videoId, platform, data = null) {
	const endpoint = generateApiEndpoint(`video/${videoId}/${platform}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * GET: '/api/videos/{videoId}'
 * Params: {  }
 */
export async function getVideo(videoId, include = null) {
	const endpoint = generateApiEndpoint(`videos/${videoId}?include=${include}`);
	const response = await restApiClient.get(endpoint);
	return response.data;
}

/**
 * POST: '/api/videos'
 * Params: { data: {
 * 		title, description, company_id, platform, video_uid, channel_id, inventory_id, metadata, video_kind, upload_status
 * }}
 */
export async function addVideo(data) {
	const endpoint = generateApiEndpoint("videos");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	return response.data;
}

/**
 * PUT: '/api/videos/{videoId}'
 * Params: { data: {
 *     title, description, metadata, video_kind
 * 	}}
 */
export async function updateVideo(videoId, data = null) {
	const endpoint = generateApiEndpoint(`videos/${videoId}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * GET: '/api/videos/{videoId}/reupload'
 */
export async function reuploadVideo(videoId) {
	const endpoint = generateReApiEndpoint(`videos/${videoId}/reupload`);
	const response = await restApiClient.get(endpoint);
	return response.data;
}

/**
 * DELETE: '/api/videos/{videoId}'
 */
export async function deleteVideo(videoId,returnResponse) {
	const endpoint = generateApiEndpoint(`videos/${videoId}`);
	const response = await restApiClient.delete(endpoint);
	if(returnResponse === true){
		return response
	}else{
		return response.data;
	}
}

/**
 * GET: '/api/videos'
 * Params: { page, limit, sortBy }
 */
 export async function getFilteredVideos({ page, limit, filterQuery, searchText }) {
	if(filterQuery || searchText){
		const endpoint = generateApiEndpoint(`videos?limit=${limit}&page=${page}&${filterQuery && filterQuery}&${searchText && searchText}&include=uploader,publisher,company,channel,inventory`);
		const response = await restApiClient.get(endpoint);
		return response.data;
	}
}

/**
* POST: '/api/videos({videoId}/monetize'
* Params: { data: {
* 		title, asset_type, custom_id, season_number, episode_number, policy_id
* }}
*/
export async function setMonetizationPolicies(videoId, data) {
   const endpoint = generateApiEndpoint(`videos/${videoId}/monetize`);
   const response = await restApiClient.post(endpoint, {
	   ...data,
   });

   return response.data;
}

/**
* GET: '/api/videos({videoId}/claims'
*/
export async function getCurrentMonetizationPolicies(videoId) {
   const endpoint = generateApiEndpoint(`videos/${videoId}/claims`);
   const response = await restApiClient.get(endpoint,);

   return response.data;
}

/**
* POST: '/api/videos({videoId}/thumbnail'
*/
export async function uploadVideoThumbnail(videoId, data) {
	const endpoint = generateApiEndpoint(`videos/${videoId}/thumbnail`);
	const response = await restApiClient.post(endpoint, data, {
		headers: {
			"Content-Type": "multipart/form-data" 
		}
	});

   return response.data;
}