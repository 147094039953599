import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ProjectSettingsContext, projectBulkData } from "../context/ProjectSettingsContext";

const Logo = () => {
	const { projectContextData } = useContext(ProjectSettingsContext);
	const [projectData, setProjectData] = projectContextData;

	return (
		<Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
			<span>
				<img className="logo-small logo-img logo-img-small d-block d-xl-none" src={projectData.favicon ?? projectBulkData.favicon} alt="logo" />
			</span>
			<span>
				<img className="logo-light logo-img d-none d-lg-none d-xl-block" src={projectData.logo ?? projectBulkData.logo} alt="logo" />
			</span>
			<span>
				<img className="logo-dark logo-img d-none d-lg-none d-xl-block" src={projectData.logo ?? projectBulkData.logo} alt="logo" />
			</span>
		</Link>
	);
};

export default Logo;
