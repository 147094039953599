import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/notifications'
 */
export async function getNotifications(search) {
	const endpoint = generateApiEndpoint(`notifications?${search ?? ""}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * PUT: '/api/notifications/{notificationId}'
 */
export async function setNotificationAsRead(notificationId) {
	const endpoint = generateApiEndpoint(`notifications/${notificationId}`);
	const response = await restApiClient.put(endpoint, {read: true});

	return response.data;
}