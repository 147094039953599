import React, { useState, useEffect } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import UserAvatar from "../../../../components/user/UserAvatar";
import { findUpper } from "../../../../utils/Utils";

const User = () => {
	const [open, setOpen] = useState(false);
	const toggle = () => setOpen((prevState) => !prevState);
	const [fullname, setFullname] = useState("");
	const [title, setTitle] = useState("");
	const [email, setEmail] = useState("");

	useEffect(() => {
		const authUser = JSON.parse(localStorage.getItem("authUser"));
		if (authUser?.fullname) setFullname(authUser.fullname);
		if (authUser?.title) setTitle(authUser.title);
		if (authUser?.email) setEmail(authUser.email);
	}, []);

	const handleSignout = () => {
		localStorage.removeItem("accessToken");
	};

	return (
		<Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
			<DropdownToggle
				tag="a"
				href="#toggle"
				className="dropdown-toggle"
				onClick={(ev) => {
					ev.preventDefault();
				}}
			>
				<div className="user-toggle">
					<UserAvatar icon="user-alt" className="sm" />
					<div className="user-info d-none d-md-block">
						<div className="user-status">{title}</div>
						<div className="user-name dropdown-indicator">{fullname}</div>
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
				<div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
					<div className="user-card sm">
						<div className="user-avatar">
							<span>{findUpper(fullname ?? null)}</span>
						</div>
						<div className="user-info">
							<span className="lead-text">{fullname}</span>
							<span className="sub-text">{email}</span>
						</div>
					</div>
				</div>
				<div className="dropdown-inner">
					<LinkList>
						<LinkItem link="/profile" icon="user-alt" onClick={toggle}>
							Profili Görüntüle
						</LinkItem>
					</LinkList>
				</div>
				<div className="dropdown-inner">
					<LinkList>
						<LinkItem link="/project/settings" icon="setting" onClick={toggle}>
							Proje Ayarları
						</LinkItem>
					</LinkList>
				</div>
				<div className="dropdown-inner">
					<LinkList>
						<a href={`${process.env.PUBLIC_URL}/auth-login`} onClick={handleSignout}>
							<Icon name="signout"></Icon>
							<span>Oturumu Kapat</span>
						</a>
					</LinkList>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
};

export default User;
