import React, {useEffect, useState} from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Form,
	FormGroup,
	Modal,
	ModalBody,
	UncontrolledDropdown,
	UncontrolledAlert,
	Badge,
	Tooltip
} from "reactstrap";
import {
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Button,
	Row,
	Col,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableItem,
	DataTableRow,
	Icon,
	PaginationComponent,
	RSelect,
	RAsyncSelect,
	TooltipComponent,
} from "../../components/Component";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {
	companyService,
	productServices,
	parametersServices,
	inventoriesServices,
	licensorsServices
} from "../../services";
import {useHistory, useLocation} from "react-router";
import {FilterComponent} from "../../components/FilterComponent";
import {TagsInput} from "react-tag-input-component";
import debounce from "lodash.debounce";

const Inventory = () => {
	const {t} = useTranslation("common");
	const history = useHistory();
	const location = useLocation();
	let {search} = useLocation();
	const [searchParams, setSearchParams] = useState("");

	useEffect(() => {
		location.search = searchParams;
		history.replace({pathname: location.pathname, search: location.search});
		if (searchParams?.includes('filter'))
			loadData();
		else
			setInventoryData([]);
	}, [searchParams]);

	useEffect(() => {
		const query = new URLSearchParams(search);
		const hasPage = query.has('page');
		const hasLimit = query.has('limit');

		if (hasPage)
			setCurrentPage(Number(query.get('page')));
		else
			query.append("page", currentPage);
		if (hasLimit)
			setItemPerPage(Number(query.get('limit')));
		else
			query.append("limit", itemPerPage);

		setSearchParams(decodeURI(query.toString()))
		history.replace({pathname: location.pathname, search: decodeURI(query.toString())})

		if (searchParams?.includes('filter'))
			loadData();
	}, []);

	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});

	const [inventoryData, setInventoryData] = useState([]);

	const [inventoryMeta, setInventoryMeta] = useState({
		total: null,
	});

	const [companyRList, setCompanyRList] = useState([]);
	const [productRList, setProductRList] = useState([]);
	const [languageRList, setLanguageRList] = useState([]);
	const [languageTypeRList, setLanguageTypeRList] = useState([]);
	const [allFilterData, setAllFilterData] = useState([]);
	const [tooltipOpen, setOpen] = useState(false);
	const [parameters] = useState(["company.id", "language.id", "language_type", "product.id", "product.licensor.id", "labels"]);
	const [sm, updateSm] = useState(false);
	const [modal, setModal] = useState({
		edit: false,
		add: false,
	});
	const [editId, setEditedId] = useState();
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		company: "",
		product: "",
		language: "",
		status: "",
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [selected, setSelected] = useState([]);


	const loadData = async () => {
		try {
			const inventory = await inventoriesServices.getInventoriesByPageAndLimit({search: decodeURIComponent(location.search)});
			setInventoryMeta({total: inventory.meta.total});
			setInventoryData([...inventory.payload]);
		} catch (error) {
			console.debug(error);
		}
	};

	useEffect(() => {
		getCompanyList();
		getProductList();
		getLanguageList();
		getLanguageTypeList();
		getInventoryLabelTypes();
		getLicensorList();
	}, [modal.add, modal.edit]);

	const getCompanyList = async () => {
		const companyList = await companyService.getCompanies();
		if (companyList?.data?.payload?.length > 0) {
			const createRList = companyList.data.payload.map((company, key) => {
				return {value: company.id, label: company.name};
			});
			setCompanyRList(createRList);
			setAllFilterData(current => ({...current, 'company.id': createRList}));
		}
	};

	const productLoadOptions = async (
		inputValue: string,
		callback: (options: productRList[]) => void
	) => {
		if (inputValue.length > 2) {
			const productList = await productServices.getProductsWithSearch(inputValue);
			if (productList?.payload?.length > 0) {
				const createProductRList = productList.payload.map((product, key) => {
					return {value: product.id, label: product.name};
				});
				callback(createProductRList)
			}
		} else {
			callback(productRList)
		}
	};

	const getProductList = async () => {
		const productList = await productServices.getProducts();
		if (productList?.payload?.length > 0) {
			const createRList = productList.payload.map((product, key) => {
				return {value: product.id, label: product.name};
			});
			setProductRList(createRList);
			setAllFilterData(current => ({...current, 'product.id': createRList}));
		}
	};

	const getLanguageList = async () => {
		const languageList = await parametersServices.getLanguages();
		if (languageList?.payload?.length > 0) {
			const createRList = languageList.payload.map((language, key) => {
				return {value: language.id, label: language.name};
			});
			setLanguageRList(createRList);
			setAllFilterData(current => ({...current, 'language.id': createRList}));
		}
	};

	const getLanguageTypeList = async () => {
		const languageTypeList = await parametersServices.getLanguageTypes();
		if (languageTypeList?.payload?.length > 0) {
			const createRList = languageTypeList.payload.map((languageType, key) => {
				return {value: languageType.key, label: languageType.value};
			});
			setLanguageTypeRList(createRList);
			setAllFilterData(current => ({...current, 'language_type': createRList}));
		}
	};

	const getLicensorList = async () => {
		const licensorList = await licensorsServices.getLicensors();
		if (licensorList?.payload?.length > 0) {
			const createRList = licensorList.payload.map((licensor, key) => {
				return {value: licensor.id, label: licensor.name};
			});
			setAllFilterData(current => ({...current, 'product.licensor.id': createRList}));
		}
	};

	const getInventoryLabelTypes = async () => {
		const labelTypeList = await inventoriesServices.getInventoryLabels();
		if (labelTypeList?.payload?.labels?.length > 0) {
			const createRList = labelTypeList.payload?.labels?.map((labelType, key) => {
				return {value: labelType, label: labelType};
			});
			setAllFilterData(current => ({...current, 'labels': createRList}));
		}
	};

	// function to reset the form
	const resetForm = () => {
		setFormData({
			name: "",
			description: "",
			company: "",
			product: "",
			language: "",
			status: "",
		});
		setAlertData({
			status: false,
			type: "info",
			icon: "alert-circle",
			message: t("error.unknown"),
		});
	};

	const handleChange = (result, e) => {
		setFormData({...formData, [e.name]: result.value});
	};

	// function to close the form modal
	const onFormCancel = () => {
		setModal({edit: false, add: false});
		setSelected([]);
		resetForm();
	};

	// submit function to add a new item
	const onFormSubmit = async (submitData) => {
		const {name, description} = submitData;
		let submittedData = {
			name: name,
			description: description,
			company_id: formData.company,
			product_id: formData.product,
			language_id: formData.language,
			language_type: formData.language_type,
			labels: selected ?? []
		};
		try {
			const response = await inventoriesServices.addInventory({
				...submittedData,
			});
			loadData();
			setModal({edit: false}, {add: false});
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const {response} = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// submit function to update a new item
	const onEditSubmit = async (submitData) => {
		const {name, description} = submitData;
		let submittedData = {
			name: name,
			description: description,
			company_id: formData.company,
			product_id: formData.product,
			language_id: formData.language,
			language_type: formData.language_type,
			labels: selected ?? []
		};
		try {
			const response = await inventoriesServices.updateInventory(editId, {
				...submittedData,
			});
			loadData();
			setModal({edit: false}, {add: false});
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const {response} = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// function that loads the want to editted data
	const onEditClick = (id) => {
		resetForm();
		inventoryData.forEach((item) => {
			if (item.id === id) {
				//console.log(item);
				setFormData({
					name: item.name,
					description: item.description,
					company: item.company_id,
					companyValue: {value: item.company?.id, label: item.company?.name},
					product: item.product_id,
					productValue: {value: item.product?.id, label: item.product?.name},
					language: item.language_id,
					languageValue: {value: item.language?.id, label: item.language?.name},
					language_type: item.language_type,
					languageTypeValue: {value: item.language_type, label: item.language_type},
					status: item.status,
				});
				if (item.labels)
					setSelected(item.labels);
				setEditedId(id);
				setModal({edit: true}, {add: false});
			}
		});
	};

	// function to change to suspend property for an item
	const deleteInventory = async (id) => {
		try {
			await inventoriesServices.deleteInventory(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	const {errors, register, handleSubmit} = useForm();

	// Get current list, pagination
	// const indexOfLastItem = currentPage * itemPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemPerPage;
	// const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
	const currentItems = inventoryData;

	// Change Page
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		const query = new URLSearchParams(search);
		query.set("page", pageNumber)
		setSearchParams(decodeURI(query.toString()));
	}

	useEffect(() => {
		setTimeout(() => {
			setOpen(false);
		}, 1000);
	}, [tooltipOpen])

	const unsecuredCopyToClipboard = (text) => {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			document.execCommand('copy')
		} catch (err) {
			console.error('Unable to copy to clipboard', err)
		}
		document.body.removeChild(textArea)
	};

	/**
	 * Copies the text passed as param to the system clipboard
	 * Check if using HTTPS and navigator.clipboard is available
	 * Then uses standard clipboard API, otherwise uses fallback
	 */
	const copyToClipboard = (content) => {
		if (window.isSecureContext && navigator.clipboard) {
			navigator.clipboard.writeText(content);
		} else {
			unsecuredCopyToClipboard(content);
		}
	};

	const fetchOptions = async (inputValue) => {
		if (inputValue.length > 0) {
			const response = await productServices.getProductsWithQuery(`filter[name]=${inputValue}`);
			const createRList = response.payload.map((product) => {
				return {value: product.id, label: product.name};
			});
			setProductRList(createRList);
		} else {
			getProductList();
		}
	};

	const handleInputChange = debounce((inputValue) => {
		fetchOptions(inputValue);
	}, 500);

	//Filter Component - Define here for other Input Types
	const [inputTypes] = useState({
		"product.id": {
			type: "RAsyncSelect",
			method: productLoadOptions,
		}
	});

	return (
		<React.Fragment>
			<Head title={t("content.inventory.list")}></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle tag="h3" page>
								{t("content.inventory.list")}
							</BlockTitle>
							<BlockDes className="text-soft">
								<p>
									{t("general.total")} {inventoryMeta.total ?? '0'} {t("general.inventory")}.
								</p>
							</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
								        onClick={() => updateSm(!sm)}>
									<Icon name="menu-alt-r"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{display: sm ? "block" : "none"}}>
									<ul className="nk-block-tools g-3">
										<li className="nk-block-tools-opt">
											<Button
												color="primary"
												className="btn-icon"
												onClick={() => {
													resetForm();
													setModal({add: true});
												}}
											>
												<Icon name="plus"></Icon>
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>

				{alertData.status && !modal.add && !modal.edit ? (
					<Block>
						<BlockHeadContent>
							<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
								<Icon name={alertData.icon ?? "cross-circle"}/>
								{alertData.message ?? t("error.unknown")}
							</UncontrolledAlert>
						</BlockHeadContent>
					</Block>
				) : null}

				<Block>
					<DataTable className="card-stretch">
						<FilterComponent
							type="name"
							history={history}
							data={allFilterData}
							inputType={inputTypes}
							parameters={parameters}
							search={search}
							setSearch={setSearchParams}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							itemPerPage={itemPerPage}
							setItemPerPage={setItemPerPage}/>
						<DataTableBody compact>
							<DataTableHead>
								<DataTableRow className="nk-tb-col-check">
									<span className="sub-text">#</span>
								</DataTableRow>
								<DataTableRow>
									<span>{t("content.inventory.uid")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("content.inventory.name")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.inventory.company")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("content.inventory.product")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.inventory.language")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.inventory.language_type")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.inventory.labels")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.inventory.status")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text float-right">{t("content.inventory.action")}</span>
								</DataTableRow>
							</DataTableHead>
							{/*Head*/}
							{currentItems.length > 0
								? currentItems.map((item, key) => {
									return (
										<DataTableItem key={key}>
											<DataTableRow className="nk-tb-col-check">{item.id}</DataTableRow>
											<DataTableRow>
												<Tooltip placement="top" isOpen={`uuid${key}` === tooltipOpen}
												         target={`uuid${key}`}>
													{t("filter.id_copied")}
												</Tooltip>
												<span style={{cursor: 'pointer'}} onClick={(e) => {
													copyToClipboard(e.target.textContent);
													setOpen(`uuid${key}`)
												}} id={`uuid${key}`}>{item?.uid}</span>
											</DataTableRow>
											<DataTableRow>
												<span title={item?.description} className="tb-lead">
													{item?.name}
												</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{item?.company?.name}</span>
											</DataTableRow>
											<DataTableRow>
												<span>{item?.product?.name}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{item?.language?.name}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{item?.language_type}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{item.labels ? item.labels.slice(0, 2).map((label, key) => <Badge
													className="mr-2" key={key}>{label}</Badge>) : "-"}</span>
												{(item.labels && item.labels?.length > 2) &&
													<TooltipComponent id={"tooltip" + item?.id} icon="more-h-alt"
													                  direction="left" text={item.labels.join(", ")}/>}
											</DataTableRow>
											<DataTableRow size="sm">
												<span>
													<Badge className="badge-dot"
													       color={item.status.toLowerCase() === "active" ? "success" : "danger"}>
														{item?.status}
													</Badge>
												</span>
											</DataTableRow>
											<DataTableRow className="nk-tb-col-tools">
												<ul className="nk-tb-actions gx-1">
													<li>
														<UncontrolledDropdown>
															<DropdownToggle tag="a"
															                className="dropdown-toggle btn btn-icon btn-trigger">
																<Icon name="more-h"></Icon>
															</DropdownToggle>
															<DropdownMenu right>
																<ul className="link-list-opt no-bdr">
																	<li onClick={() => onEditClick(item.id)}>
																		<DropdownItem
																			tag="a"
																			href="#edit"
																			onClick={(ev) => {
																				ev.preventDefault();
																			}}
																		>
																			<Icon name="edit"></Icon>
																			<span>{t("operations.edit")}</span>
																		</DropdownItem>
																	</li>
																	<li
																		onClick={() => {
																			if (window.confirm(t("content.inventory.delete-confirm"))) deleteInventory(item.id);
																		}}
																	>
																		<DropdownItem
																			tag="a"
																			href="#delete"
																			onClick={(ev) => {
																				ev.preventDefault();
																			}}
																		>
																			<Icon name="trash-fill"></Icon>
																			<span>{t("operations.delete")}</span>
																		</DropdownItem>
																	</li>
																</ul>
															</DropdownMenu>
														</UncontrolledDropdown>
													</li>
												</ul>
											</DataTableRow>
										</DataTableItem>
									);
								})
								: null}
						</DataTableBody>
						<div className="card-inner">
							{currentItems.length > 0 ? (
								<PaginationComponent itemPerPage={itemPerPage} totalItems={inventoryMeta.total}
								                     paginate={paginate} currentPage={currentPage}/>
							) : (
								<div className="text-center">
									<span className="text-silent">No data found</span>
								</div>
							)}
						</div>
						{!search.includes("filter") ?
							<div className="p-2">{t('content.inventory.add_filter')}</div> : null
						}
					</DataTable>
				</Block>
				<Modal isOpen={modal.add} toggle={() => setModal({add: false})} className="modal-dialog-centered"
				       size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("content.inventory.add")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"}/>
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.inventory.name")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label
													className="form-label">{t("content.inventory.description")}</label>
												<input
													className="form-control"
													type="text"
													name="description"
													defaultValue={formData.description}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.description &&
													<span className="invalid">{errors.description.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.inventory.company")}</label>
												<RSelect name="company" options={companyRList} required
												         onChange={handleChange}/>
												{errors.company &&
													<span className="invalid">{errors.company.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.inventory.product")}</label>
												<RAsyncSelect name="product" cacheOptions
												              loadOptions={productLoadOptions} onChange={handleChange}
												              defaultOptions={productRList} required/>
												{errors.product &&
													<span className="invalid">{errors.product.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.inventory.language")}</label>
												<RSelect name="language" options={languageRList} required
												         onChange={handleChange}/>
												{errors.language &&
													<span className="invalid">{errors.language.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label
													className="form-label">{t("content.inventory.language_type")}</label>
												<RSelect name="language_type" options={languageTypeRList} required
												         onChange={handleChange}/>
												{errors.languageType &&
													<span className="invalid">{errors.languageType.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("content.inventory.labels")}</label>
												<TagsInput
													value={selected}
													onChange={setSelected}
													name="fruits"
													placeHolder={t('content.inventory.labels_ph')}
													disabled={selected.length === 5}
												/>
												{errors.languageType &&
													<span className="invalid">{errors.languageType.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.edit} toggle={() => setModal({edit: false})} className="modal-dialog-centered"
				       size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("content.inventory.edit")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"}/>
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onEditSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.inventory.name")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label
													className="form-label">{t("content.inventory.description")}</label>
												<input
													className="form-control"
													type="text"
													name="description"
													defaultValue={formData.description}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.description &&
													<span className="invalid">{errors.description.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.inventory.company")}</label>
												<RSelect name="company" options={companyRList} required
												         onChange={handleChange} defaultValue={formData.companyValue}/>
												{errors.company &&
													<span className="invalid">{errors.company.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.inventory.product")}</label>
												<RAsyncSelect
													name="product"
													cacheOptions
													loadOptions={productLoadOptions} onChange={handleChange}
													defaultOptions={productRList} required
													defaultValue={formData.productValue}
												/>
												{errors.product &&
													<span className="invalid">{errors.product.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.inventory.language")}</label>
												<RSelect name="language" options={languageRList} required
												         onChange={handleChange} defaultValue={formData.languageValue}/>
												{errors.language &&
													<span className="invalid">{errors.language.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label
													className="form-label">{t("content.inventory.language_type")}</label>
												<RSelect name="language_type" options={languageTypeRList} required
												         onChange={handleChange}
												         defaultValue={formData.languageTypeValue}/>
												{errors.languageType &&
													<span className="invalid">{errors.languageType.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("content.inventory.labels")}</label>
												<TagsInput
													value={selected}
													onChange={setSelected}
													name="fruits"
													placeHolder={t('content.inventory.labels_ph')}
													disabled={selected.length === 5}
												/>
												{errors.languageType &&
													<span className="invalid">{errors.languageType.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</Content>
		</React.Fragment>
	);
};
export default Inventory;
