import React, { useEffect } from "react";
import { Icon, DataTableHead, DataTableRow, DataTableItem, RSelect } from "../../Component";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const DynamicList = (props) => {

	const { t } = useTranslation("common");

	const colors = ['#ca5c3d', '#fd44f9', '#30187e', '#2fe2ab', '#68416e', '#1da952', '#9e3e35', '#ee1a4a'];
	const [columns, setColumns] = useState([]);
	const [allColumns, setAllColumns] = useState();
	const [filteredData, setFilteredData] = useState();

	useEffect(() => { }, [props.sortData])
	useEffect(() => {
		if (props.total && columns.length < 1) {
			let processedColumns = [];
			let columnValues = Object.keys(props.total);
			for (var i = 0; i < Object.keys(props.total).length; i++) {
				processedColumns.push({ value: columnValues[i], id: i, label: t(`statistic.${columnValues[i]}`) });
			};
			setAllColumns(processedColumns);
			setColumns(processedColumns);
			props.setGraphColumns(processedColumns);
		};
	}, [props.total])

	const changeColumns = (result) => {
		setColumns(result);
	};

	const handleCheckboxClick = (id) => {
		var isSelected = props.selectedItems.find((item) => item === id);
		var selectedGraphData = props.graphData?.find((item) => item?.id === id);
		var selectedData = props.data.find((item) => item.id === id);
		if (isSelected && props.graphData) {
			var result = [...props.selectedItems].filter((item) => item !== id);
			props.setSelectedItems(result);
			props.setSelectedData(current => current.filter(x => x.id !== selectedGraphData.id));
			props.setSelectedListData(current => current.filter(x => x.id !== id))
		} else if (props.graphData) {
			props.setSelectedItems(current => ([...current, id]));
			props.setSelectedData(current => ([...current, selectedGraphData]))
			props.setSelectedListData(current => ([...current, selectedData]))
		}
	}

	useEffect(() => {
		if (props?.data) {
			if (props.selectedItems.length > 0) {
				const listDataResult = props.selectedListData.map(item => {
					if (props.data.find((data) => data.id === item.id)) {
						return props.data.find((data) => data.id === item.id);
					} else {
						return item;
					}
				});
				const dataResult = [...props.data].filter((item) => !props.selectedItems.includes(item.id))
				props.setSelectedListData(listDataResult);
				setFilteredData([...listDataResult, ...dataResult])

			} else {
				setFilteredData(props.data)
			}
		}
	}, [props.data, props.selectedItems])

	const formatter = new Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 2 });

	return (
		<React.Fragment>
			{columns &&
			<form style={{ width: columns.length < 3 ? '25%' : '50%' }}>
				<label className="form-label" htmlFor="">{t('statistic.columns_to_show')}</label>
					<RSelect isMulti name="columns" value={columns} options={allColumns} required onChange={changeColumns} />
				<br />
			</form>
			}
			{props.children}
			<div className="nk-tb-list is-loose traffic-channel-table">
				{filteredData && <DataTableHead>
					<DataTableRow className="nk-tb-col-check" />
					<DataTableRow sort={props.sortBy} isSortable className="nk-tb-channel">
						<span name={props.type} onClick={(e) => props.sortData(e.target.attributes.name.textContent)} style={{ cursor: 'pointer' }}>{t("general.name")}</span>
					</DataTableRow>
					{columns && columns.map((column) =>
						<DataTableRow key={Math.random().toString(16).slice(2)} sort={props.sortBy} isSortable className="nk-tb-trend tb-col-sm">
							<span name={column.value} onClick={() => props.sortData(column.value)} style={{ cursor: 'pointer' }}>{t(`statistic.${column.value}`)}</span>
						</DataTableRow>
					)}
					{(props.rowTotal === 1) &&
						<DataTableRow key={Math.random().toString(16).slice(2)} sort={props.sortBy} isSortable className="nk-tb-trend tb-col-sm">
							<span name={"rowTotal"} onClick={() => props.sortData("rowTotal")} style={{ cursor: 'pointer' }}>{t(`statistic.amount`)}</span>
						</DataTableRow>
					}
				</DataTableHead>}
				{filteredData ? filteredData?.map((item) => {
					return (
						<DataTableItem className="nk-tb-item " key={Math.random().toString(16).slice(2)}>

							<DataTableRow className="nk-tb-channel">
								{!props.hideCheckbox &&
									<input type="checkbox" checked={props.selectedItems.find((sl) => sl === item.id) ? true : false} onChange={() => handleCheckboxClick(item.id)} style={{ cursor: 'pointer' }} />
								}
							</DataTableRow>

							<DataTableRow className="nk-tb-channel">
								<span className="tb-lead">{props.selectedItems.find((sl) => sl === item.id) && <Icon name="square-fill" className="mr-1" style={{ color: colors[props.selectedItems.indexOf(item.id)] }} />}{item[props.type]}</span>
							</DataTableRow>
							{columns && columns.map((column) =>
								<>
									<DataTableRow key={Math.random().toString(16).slice(2)} className="nk-tb-sessions" size="sm">
										<span className="tb-sub tb-amount">
											<span>{
												Object.keys(item.data).findIndex((key) => key === column.value) !== -1 &&
													Object.values(item.data)[Object.keys(item.data).findIndex((key) => key === column.value)] !== null ?
													!props.text ?
														column.value === "avd_sum" ?
															formatter.format(Object.values(item.data)[Object.keys(item.data).findIndex((key) => key === column.value)] / 3600)
															: column.value === "emw_sum" ?
																formatter.format(Object.values(item.data)[Object.keys(item.data).findIndex((key) => key === column.value)] / 60) :
																formatter.format(Object.values(item.data)[Object.keys(item.data).findIndex((key) => key === column.value)]) :
														Object.values(item.data)[Object.keys(item.data).findIndex((key) => key === column.value)] :
													"-"
											}</span>
										</span>
									</DataTableRow>
								</>
							)}
							{(props.rowTotal === 1) && (
								<DataTableRow key={Math.random().toString(16).slice(2)} className="nk-tb-sessions" size="sm">
									<span className="tb-sub tb-amount">
										{Object.values(item.data).length > 0 ? Object.values(item.data).reduce((acc, val) => acc + val) : "-"}
									</span>
								</DataTableRow>
							)}
						</DataTableItem>
					);
				}) : <h4 className="text-center">{t("general.no_data")}</h4>}
				{!props.text &&
					<DataTableItem className="nk-tb-item ">
						<DataTableRow className="nk-tb-channel" size="sm">
							<span className="tb-lead"> <b>{t("general.total")}</b> </span>
						</DataTableRow>
						<DataTableRow className="nk-tb-sessions">
						</DataTableRow>
						{filteredData && columns.map((column) =>
							<>
								{column.value !== "name" && column.value !== "fullname" && column.value !== "title" &&
									<DataTableRow key={Math.random().toString(16).slice(2)} className="nk-tb-sessions" size="sm">
										<div className="tb-sub tb-amount">
											<b>{Object.keys(props.total).findIndex((key) => key === column.value) !== -1 && Object.values(props.total)[Object.keys(props.total).findIndex((key) => key === column.value)] !== null ? Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(Object.values(props.total)[Object.keys(props.total).findIndex((key) => key === column.value)]) : "-"}</b>
										</div>
									</DataTableRow>

								}
							</>
						)}
						<DataTableRow key={Math.random().toString(16).slice(2)} className="nk-tb-sessions" size="sm">
							<div className="tb-sub tb-amount">
								<b>{props.total && Object.keys(props?.total)?.length > 0 ? Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(Object.values(props?.total).reduce((acc, val) => acc + val)) : "0"}</b>
							</div>
						</DataTableRow>
					</DataTableItem>
				}
			</div>
		</React.Fragment>
	);
};
export default DynamicList;
