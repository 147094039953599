import { parametersServices } from "../../../services";

export async function Countries() {
	const countries = await parametersServices.getCountries({ limit: 10, page: 1 });
	const columns = [
		{
			key: "id",
			name: "#",
			sort: 0,
			type: "id",
		},
		{
			key: "name",
			name: "static-parameters.title",
			sort: 1,
			type: "title",
		},
		{
			key: "code",
			name: "static-parameters.isocode",
			sort: 2,
			type: "title",
		},
	];
	const rows = countries.payload;
	const tableData = {
		columns: columns,
		rows: rows,
	};
	return tableData;
}
