import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import common_tr from "./locales/tr/common.json";
import common_en from "./locales/en/common.json";
import languages_tr from "./locales/tr/languages.json";
import languages_en from "./locales/en/languages.json";
import youtube_tr from "./locales/tr/youtube.json";
import youtube_en from "./locales/en/youtube.json";
import settings_tr from "./locales/tr/settings.json";
import settings_en from "./locales/en/settings.json";
import countries_tr from "./locales/tr/countries.json";
import countries_en from "./locales/en/countries.json";
import labels_tr from "./locales/tr/labels.json";
import labels_en from "./locales/en/labels.json";

import Backend from "i18next-http-backend";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	// want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	// .use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		fallbackLng: "en",
		lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				common: common_en, // 'common' is our custom namespace
				languages: languages_en,
				youtube: youtube_en,
				settings: settings_en,
				countries: countries_en,
				labels: labels_en,
			},
			tr: {
				common: common_tr,
				languages: languages_tr,
				youtube: youtube_tr,
				settings: settings_tr,
				countries: countries_tr,
				labels: labels_tr,
			},
		},
	});

export default i18n;
