import React, { createContext, useState, useEffect } from "react";
import { projectServices } from "../../services";

import LogoLight2x from "../../images/logo2x.png";
import LogoDark2x from "../../images/logo-dark2x.png";
import LogoSmall from "../../images/logo-small.png";

export const ProjectSettingsContext = createContext();

export const projectBulkData = {
	title: "Merzigo CMS",
	logo: LogoLight2x,
	favicon: LogoSmall,
};

export const ProjectSettingsContextProvider = (props) => {
	const [projectData, setProjectData] = useState({ ...projectBulkData });

	useEffect(async () => {
		try {
			const projectReponse = await projectServices.getProject();
			if (projectReponse.status == 200) {
				setProjectData(projectReponse?.data?.payload);
			}
		} catch (error) {
			console.error(error);
		}
	}, []);

	return <ProjectSettingsContext.Provider value={{ projectContextData: [projectData, setProjectData] }}>{props.children}</ProjectSettingsContext.Provider>;
};
