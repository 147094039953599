import React, { useEffect, useState } from "react";
import Pages from "../route/Index";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import classNames from "classnames";
import { LanguageContextProvider } from "./context/LanguageContext";
import { ProjectSettingsContextProvider } from "./context/ProjectSettingsContext";
import { UploadsContextProvider } from "./context/UploadContext";

const Layout = () => {
	//Sidebar
	const [mobileView, setMobileView] = useState();
	const [visibility, setVisibility] = useState(false);
	const [themeState] = useState({
		main: "default",
		sidebar: "white",
		header: "white",
		skin: "light",
	});

	useEffect(() => {
		document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${themeState.skin === "dark" ? "dark-mode" : ""}`;
	}, [themeState.skin]);

	useEffect(() => {
		viewChange();
	}, []);

	// function to toggle sidebar
	const toggleSidebar = (e) => {
		e.preventDefault();
		if (visibility === false) {
			setVisibility(true);
		} else {
			setVisibility(false);
		}
	};

	// function to change the design view under 1200 px
	const viewChange = () => {
		if (window.innerWidth < 1200) {
			setMobileView(true);
		} else {
			setMobileView(false);
			setVisibility(false);
		}
	};
	window.addEventListener("load", viewChange);
	window.addEventListener("resize", viewChange);

	const sidebarClass = classNames({
		"nk-sidebar-mobile": mobileView,
		"nk-sidebar-active": visibility && mobileView,
	});

	return (
		<LanguageContextProvider>
			<ProjectSettingsContextProvider>
				<React.Fragment>
					<Head title="Loading" />
					<div className="nk-app-root">
						<div className="nk-main">
							<Sidebar sidebarToggle={toggleSidebar} fixed mobileView={mobileView} theme={themeState.sidebar} className={sidebarClass} />
							{visibility && mobileView && <div className="nk-sidebar-overlay" onClick={toggleSidebar}></div>}
							<div className="nk-wrap">
								<Header sidebarToggle={toggleSidebar} fixed setVisibility={setVisibility} theme={themeState.header} />
								<UploadsContextProvider>
									<Pages />
								</UploadsContextProvider>
								{/*<Footer />*/}
							</div>
						</div>
					</div>
				</React.Fragment>
			</ProjectSettingsContextProvider>
		</LanguageContextProvider>
	);
};
export default Layout;
