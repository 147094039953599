import React, {useState, useEffect} from "react";
import {
	Block,
	BlockHeadContent,
	Icon,
	Row,
	Col,
	Button,
	DataTableHead,
	DataTableBody,
	DataTableRow,
	DataTableItem,
	PaginationComponent,
	TooltipComponent
} from "../../../components/Component";
import {
	Card,
	DropdownItem,
	UncontrolledDropdown,
	DropdownMenu,
	DropdownToggle,
	Badge,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	ListGroupItemText
} from "reactstrap";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import makeAnimated from "react-select/animated";
import {Form, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledAlert} from "reactstrap";
import {RSelect, UserAvatar} from "../../../components/Component";
import {VideoDetailsModal} from "../../../components/videos/VideoDetailsModal";
import {findUpper, sliceString, statusColor} from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {videoServices, parametersServices, companyService, channelServices} from "../../../services";
import moment from "moment";
import {FilterComponent} from "../../../components/FilterComponent";

const VideoList = (props) => {
	const {modal, setModal} = props;
	const {t} = useTranslation("common");
	const history = useHistory();
	const location = useLocation();
	const privacyStatusesRawData = [
		{value: "private", label: t(`youtube:status.private`)},
		{value: "unlisted", label: t("youtube:status.unlisted")},
		{value: "public", label: t("youtube:status.public")},
		{value: "scheduled", label: t("youtube:status.scheduled")},
	];
	const uploadStatusesRawData = [
		{value: "client_pending", label: t(`settings:upload-status.client_pending`)},
		{value: "client_uploading", label: t(`settings:upload-status.client_uploading`)},
		{value: "client_finished", label: t(`settings:upload-status.client_finished`)},
		{value: "engine_processing", label: t(`settings:upload-status.engine_processing`)},
		{value: "engine_uploading", label: t(`settings:upload-status.engine_uploading`)},
		{value: "engine_finished", label: t(`settings:upload-status.engine_finished`)},
		{value: "engine_failed", label: t(`settings:upload-status.engine_failed`)},
		{value: "client_failed", label: t(`settings:upload-status.client_failed`)},
	];
	let {search} = useLocation();
	const [allFilterData, setAllFilterData] = useState({privacy_status:privacyStatusesRawData, upload_status: uploadStatusesRawData});
	const [parameters] = useState(["company.id", "platform", "channel_id", "privacy_status", "upload_status"]);
	const [searchParams, setSearchParams] = useState("");
	const now = new Date().getTime();

	useEffect(() => {
		if(searchParams.length > 0){
			location.search = searchParams;
			history.replace({pathname: location.pathname, search: location.search});
			loadData();
		}
	}, [searchParams]);

	useEffect(() => {
		const query = new URLSearchParams(search);
		const hasPage = query.has('page');
		const hasLimit = query.has('limit');

		if (hasPage)
			setCurrentPage(Number(query.get('page')));
		else
			query.append("page", currentPage);
		if (hasLimit)
			setItemPerPage(Number(query.get('limit')));
		else
			query.append("limit", itemPerPage);

		setSearchParams(decodeURI(query.toString()))
		history.replace({pathname: location.pathname, search: decodeURI(query.toString())})
	}, []);

	const formRawData = {
		title: "",
		videoKind: "",
	};
	const alertRawData = {
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	};

	const [videosData, setVideosData] = useState([]);
	const [videosMeta, setVideosMeta] = useState({total: null});
	const [alertData, setAlertData] = useState(alertRawData);
	const [formData, setFormData] = useState(formRawData);
	const [videoKindRList, setVideoKindRList] = useState([]);
	const [editId, setEditedId] = useState();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [intervalId, setIntervalId] = useState()

	const loadData = async () => {
		try {
			const videos = await videoServices.getVideosByPageAndLimit({search: decodeURIComponent(location.search)});
			setVideosMeta({total: videos.meta.total});
			setVideosData([...videos.payload]);
		} catch (error) {
			console.debug(error);
		}
	};

	const getCompanyList = async () => {
		const companyList = await companyService.getCompanies();
		if (companyList?.data?.payload?.length > 0) {
			const createRList = companyList.data.payload.map((company, key) => {
				return {value: company.id, label: company.name};
			});
			setAllFilterData(current => ({...current, 'company.id': createRList}));
		}
	};

	const getPlatformList = async () => {
		const platformList = await parametersServices.getPlatforms();
		if (platformList?.payload?.length > 0) {
			const createRList = platformList.payload.map((platform, key) => {
				return {value: platform.key, label: platform.value};
			});
			setAllFilterData(current => ({...current, 'platform': createRList}));
		}
	};

	const getChannelList = async () => {
		const channelList = await channelServices.getChannels();
		if (channelList?.payload?.length > 0) {
			const createRList = channelList.payload.map((channel, key) => {
				return {value: channel.id, label: channel.title};
			});
			setAllFilterData(current => ({...current, 'channel_id': createRList}));
		}
	};

	const getVideoKindList = async () => {
		const videoKindList = await parametersServices.getVideoKinds();
		if (videoKindList?.payload?.length > 0) {
			const createRList = videoKindList.payload.map((videoKind, key) => {
				return {value: videoKind.key, label: videoKind.value};
			});
			setVideoKindRList(createRList);
		}
	};

	useState(async () => {
		await getCompanyList();
		await getPlatformList();
		await getVideoKindList();
		await getChannelList();
	}, [])

	// function to close the form modal
	const onFormCancel = () => {
		setModal({create: false, edit: false, processlist: false});
		resetForm();
	};

	const resetForm = () => {
		setFormData({
			title: "",
			videoKind: "",
		});
		setAlertData({
			status: false,
			type: "info",
			icon: "alert-circle",
			message: t("error.unknown"),
		});
	};

	// submit function to update a new item
	const onEditSubmit = async (submitData) => {
		const {title} = submitData;
		let submittedData = {
			title: title,
			video_kind: formData.videoKind,
		};
		try {
			const response = await videoServices.updateVideo(editId, {
				...submittedData,
			});
			loadData();
			resetForm();
			setModal({edit: false});
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const {response} = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// function that loads the want to editted videosData
	const onEditClick = (id) => {
		resetForm();
		videosData.forEach((item) => {
			if (item.id === id) {
				setFormData({
					title: item.title,
					video_kind: item.video_kind,
					videoKindValue: {value: item.video_kind, label: item.video_kind},
				});
			}
		});
		setEditedId(id);
		setModal({create: false, edit: true, processlist: false});
	};

	const onReuploadClick = async (id) => {
		const reuploadConfirm = window.confirm(t("videos.upload.re-upload-confirm-message"));
		if (reuploadConfirm) {
			const response = await videoServices.reuploadVideo(id);
			if (response.status) {
				loadData()
			}
		}

	}

	// function to delete a video
	const deleteVideo = async (id) => {
		try {
			await videoServices.deleteVideo(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	const handleChange = (result, e) => {
		setFormData({...formData, [e.name]: result.value});
	};

	// toggle function to view product details
	const toggle = (type) => {
		setModal({
			edit: type === "edit" ? true : false,
			add: type === "add" ? true : false,
			details: type === "details" ? true : false,
		});
	};

	// Get current list, pagination
	const currentItems = videosData;

	// Change Page
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		const query = new URLSearchParams(search);
		query.set("page", pageNumber);
		query.set('limit', itemPerPage);
		setSearchParams(decodeURI(query.toString()));
	}

	const animatedComponents = makeAnimated();
	const {register, handleSubmit} = useForm();

	const [detailsModal, setDetailsModal] = useState({
		status: false,
		item: {}
	})

	const toggleDetailsModal = (item) => {
		setDetailsModal(current => ({
			status: !current.status,
			item: item ? item : {}
		}));
	};

	useEffect(() => {
		if (modal.processlist) {
			loadData();
			setIntervalId(setInterval(() => {
				loadData();
			}, 5000))
		} else {
			clearInterval(intervalId)
		}
	}, [modal.processlist])

	return (
		<>
			<VideoDetailsModal status={detailsModal?.status} toggle={toggleDetailsModal} item={detailsModal.item}
			                   t={t}/>
			{alertData.status && !modal.create && !modal.edit ? (
				<Block>
					<BlockHeadContent>
						<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
							<Icon name={alertData.icon ?? "cross-circle"}/>
							{alertData.message ?? t("error.unknown")}
						</UncontrolledAlert>
					</BlockHeadContent>
				</Block>
			) : null}

			<br/>

			<Card className="card-bordered">
				<FilterComponent
					type="title"
					history={history}
					data={allFilterData}
					parameters={parameters}
					search={search}
					setSearch={setSearchParams}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					itemPerPage={itemPerPage}
					setItemPerPage={setItemPerPage}/>
				<div className="card-inner-group">
					<div className="card-inner p-0">
						<DataTableBody key={currentItems}>
							<DataTableHead className="nk-tb-stiky-65">
								<DataTableRow className="nk-tb-col-check">
									<div className="custom-control custom-control-sm custom-checkbox notext">
										<input type="checkbox" className="custom-control-input form-control"
										       id="uid_1"/>
										<label className="custom-control-label" htmlFor="uid_1"></label>
									</div>
								</DataTableRow>
								<DataTableRow size="sm">
									<span>{t("videos.upload.video")}</span>
								</DataTableRow>
								<DataTableRow className="text-center">
									<span>{t("videos.upload.status")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span>{t("video_details_modal.create")}</span>
								</DataTableRow>
								<DataTableRow>
									<span>{t("modules.company")}</span>
								</DataTableRow>
								<DataTableRow>
									<span>{t("modules.content")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span>{t("modules.channel")}</span>
								</DataTableRow>
								<DataTableRow>
									<span>{t("general.action")}</span>
								</DataTableRow>
							</DataTableHead>
							{currentItems.length > 0
								? currentItems.map((item) => {
									let status = item?.upload_status.split("_")[1];
									return (
										<DataTableItem key={item.id}>
											<DataTableRow className="nk-tb-col-check">
												<div
													className="custom-control custom-control-sm custom-checkbox notext">
													<input type="checkbox" className="custom-control-input form-control"
													       defaultChecked={item.check} id={item.id + "uid1"}
													       key={Math.random()}/>
													<label className="custom-control-label"
													       htmlFor={item.id + "uid1"}></label>
												</div>
											</DataTableRow>
											<DataTableRow size="sm">
													<span className="tb-video">
														<div className="thumb-group">
															<img
																src={item.thumbnail ?? "https://img.youtube.com/vi/default.jpg"}
																alt="product" className="thumb"/>
															<Badge
																className="badge-thumb"> {moment.utc(Number(item?.detail?.duration ?? "0") * 1000).format('HH:mm:ss').replace(/^(?:00:)?0?/, '')}</Badge>
														</div>
														<div>
															<h6 className="title" title={item.title}>
																{sliceString(item?.title, 40)}
															</h6>
															<span className="desc" title={item?.description}>
																{" "}
																{sliceString(item.description, 50)}
															</span>
															<p>
															<Badge className="badge-fill mr-1" color="primary">
															{item.video_kind}
															</Badge>
															<span className="tb-sub text-center">
															{item?.platform === "youtube" && (
																<Badge className="badge-dim" color="danger">
																	<Icon name="youtube"></Icon> Youtube
																</Badge>
															)}
																{item?.platform === "facebook" && (
																	<Badge className="badge-dim" color="danger">
																		<Icon name="facebook"></Icon> Facebook
																	</Badge>
																)}
													</span>
															</p>
														</div>
													</span>
											</DataTableRow>
											<DataTableRow className="text-center">
												<div>
													{item.upload_status == "engine_finished" &&
														(new Date(item.published_at).getTime() > now ?
															(<>
																<span className="mr-1">
																	{t(`youtube:status.scheduled`)}
																</span>
																<TooltipComponent key={item.id} icon="help-fill"
																                  iconClass="card-hint" direction="left"
																                  id={"tooltip" + item.id}
																                  text={moment(item?.published_at).format("YYYY-MM-DD hh:mm:ss") !== 'Invalid date' ? moment.utc(item?.published_at).local().format('DD.MM.YYYY HH:mm ') : "-"}/>
															</>)
														: t(`youtube:status.${item.privacy_status}`))
													}
												</div>
												<div className={"mt-1"}>
													<Badge className="badge-dim" color={statusColor[status ?? "info"]}>
														{t(`settings:upload-status.${item.upload_status}`)}
													</Badge>
												</div>
											</DataTableRow>
											<DataTableRow size="sm">
												<span className="tb-sub">{item?.uploader?.fullname}
													<br/> {moment(new Date(item?.uploaded_at)).format("DD.MM.YYYY hh:mm") !== 'Invalid date' ? moment.utc(item?.created_at).local().format('DD.MM.YYYY HH:mm ') : "-"}</span>
											</DataTableRow>
											<DataTableRow>
												<span className="tb-sub">{item?.company?.name}</span>
											</DataTableRow>
											<DataTableRow>
												<span
													className="tb-sub">{item?.inventory?.name + " - " + item?.inventory?.language_id}</span>
											</DataTableRow>
											<DataTableRow size="md">
												<Link className="channel-card"
												      to={"//youtube.com/channel/" + item?.channel?.platform_id}
												      target="_blank">
													<div className="channel-card">
														<div className="channel-avatar sm bg-primary">
															{item?.channel?.cover
																?
																<UserAvatar className="sm" theme="primary"
																            image={item?.channel?.cover}/>
																:
																<UserAvatar className="sm" theme="primary"
																            text={findUpper(item?.channel?.title)}/>
															}
														</div>
														<div className="channel-name">
															<span className="tb-lead">{item?.channel?.title}</span>
														</div>
													</div>
												</Link>
											</DataTableRow>
											<DataTableRow className="nk-tb-col-tools">
												<ul className="nk-tb-actions gx-1 my-n1">
													<li className="mr-n1">
														<UncontrolledDropdown>
															<DropdownToggle tag="a" href="#more"
															                onClick={(ev) => ev.preventDefault()}
															                className="dropdown-toggle btn btn-icon btn-trigger">
																<Icon name="more-h"></Icon>
															</DropdownToggle>
															<DropdownMenu right>
																<ul className="link-list-opt no-bdr">
																	{item.upload_status == "client_finished" || item.upload_status == "engine_failed" &&
																		<li>
																			<DropdownItem
																				tag="a"
																				href="#reupload"
																				onClick={(ev) => {
																					ev.preventDefault();
																					onReuploadClick(item.id);
																				}}
																			>
																				<Icon name="upload"></Icon>
																				<span>{t("operations.re-upload")}</span>
																			</DropdownItem>
																		</li>
																	}
																	<li>
																		<DropdownItem
																			tag="a"
																			href="#details"
																			onClick={(ev) => {
																				ev.preventDefault();
																				toggleDetailsModal(item);
																			}}
																		>
																			<Icon name="eye"></Icon>
																			<span>{t("videos.detail.page-title")}</span>
																		</DropdownItem>
																	</li>
																	<li>
																		<DropdownItem
																			tag="a"
																			href="#edit"
																			onClick={(ev) => {
																				ev.preventDefault();
																				onEditClick(item.id);
																				toggle("edit");
																			}}
																		>
																			<Icon name="edit"></Icon>
																			<span>{t("operations.edit")}</span>
																		</DropdownItem>
																	</li>
																	<li>
																		<DropdownItem
																			tag="a"
																			href="#remove"
																			onClick={(ev) => {
																				ev.preventDefault();
																				if (window.confirm(t("videos.upload.delete-confirm"))) deleteVideo(item.id);
																			}}
																		>
																			<Icon name="trash"></Icon>
																			<span>{t("operations.delete")}</span>
																		</DropdownItem>
																	</li>
																</ul>
															</DropdownMenu>
														</UncontrolledDropdown>
													</li>
												</ul>
											</DataTableRow>
										</DataTableItem>
									);
								})
								: null}
						</DataTableBody>
						<div className="card-inner">
							{currentItems.length > 0 ? (
								<PaginationComponent itemPerPage={itemPerPage} totalItems={videosMeta.total}
								                     paginate={paginate} currentPage={currentPage}/>
							) : (
								<div className="text-center">
									<span className="text-silent">No videos found</span>
								</div>
							)}
						</div>
					</div>
				</div>
			</Card>

			<Modal isOpen={modal.edit} toggle={() => setModal({edit: false})} className="modal-dialog-centered"
			       size="lg">
				<ModalBody>
					<a
						href="#cancel"
						onClick={(ev) => {
							ev.preventDefault();
							onFormCancel();
						}}
						className="close"
					>
						<Icon name="cross-sm"></Icon>
					</a>
					<div className="p-2">
						<h5 className="title">{t("videos.upload.edit")}</h5>
						{alertData.status ? (
							<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
								<Icon name={alertData.icon ?? "cross-circle"}/>
								{alertData.message ?? t("error.unknown")}
							</UncontrolledAlert>
						) : null}
						<div className="mt-4">
							<Form className="gy-4" onSubmit={handleSubmit(onEditSubmit)}>
								<Row>
									<Col md="12">
										<FormGroup>
											<label className="form-label">{t("videos.upload.title")}</label>
											<input
												className="form-control"
												name="title"
												type="text"
												required={true}
												defaultValue={formData.title}
												ref={register({
													required: t("operations.required"),
												})}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md="12">
										<FormGroup>
											<label className="form-label">{t("modules.video-kind")}</label>
											<RSelect
												name="videoKind"
												components={animatedComponents}
												options={videoKindRList}
												onChange={handleChange}
												defaultValue={formData.videoKindValue}
												noOptionsMessage={() => t("videos.upload.video_kind")}
												required={true}
											/>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col size="12">
										<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
											<li>
												<Button color="primary" size="md" type="submit">
													{t("operations.save")}
												</Button>
											</li>
											<li>
												<a
													href="#cancel"
													onClick={(ev) => {
														ev.preventDefault();
														onFormCancel();
													}}
													className="link link-light"
												>
													{t("operations.cancel")}
												</a>
											</li>
										</ul>
									</Col>
								</Row>
							</Form>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default VideoList;
