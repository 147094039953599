import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/me'
 */
export async function getMe() {
	const endpoint = generateApiEndpoint("me");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/me/updatePassword'
 * Params: { data: {
 *		name, surname, email, password, validity_start_date, validity_end_date
 * }}
 */
export async function updatePassword(data = null) {
	const endpoint = generateApiEndpoint("me/updatePassword");
	const response = await restApiClient.post(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * POST: '/api/me/updateLanguage'
 * Params: { data: {
 *		language_code
 * }}
 */
export async function updateLanguage(langCode = "tr") {
	const endpoint = generateApiEndpoint("me/updateLanguage");
	const response = await restApiClient.post(endpoint, {
		language_code: langCode,
	});
	return response.data;
}


/**
 * GET: '/api/me/team'
 */
export async function getMyTeam() {
	const endpoint = generateApiEndpoint("me/team");
	const response = await restApiClient.get(endpoint);

	return response.data;
}