import React from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";

import Icon from "../../../../components/icon/Icon";
import { notificationServices } from "../../../../services";
import { useEffect } from "react";
import { useState } from "react";
import moment from 'moment';
import 'moment/locale/tr'
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotificationItem = (props) => {
	const { readed_at, text, time, id, markAsRead } = props;
	return (
		<div className="nk-notification-item justify-content-between" key={id} id={id}>
			<div className="nk-notification-content">
				<div className="nk-notification-text">{text}</div>
				<div className="nk-notification-time">{time}</div>
			</div>
			{!readed_at && <Icon onClick={() => markAsRead(id)} className={"cursor-pointer icon-status-info"} style={{ fontSize: '24px', color: '#08ac9b' }} name="dot" />}
		</div>
	);
};

const Notification = () => {

	const { t } = useTranslation("common");
	const [data, setData] = useState();
	const location = useLocation();

	const loadData = async () => {
		try {
			moment.locale(localStorage["lang"]);
			const res = await notificationServices.getNotifications("limit=5&sort=-id");
			setData(res.payload);
		} catch (err) {
			console.error(err);
		}
	};

	const markAsRead = async (notificationId) => {
		try {
			await notificationServices.setNotificationAsRead(notificationId);
			loadData();
		} catch (err) {
			console.error(err);
		}
	}

	useEffect(() => {
		loadData();
	}, [location])

	return (
		<UncontrolledDropdown className="user-dropdown">
			<DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
				<div className={data?.filter((item) => !item.readed_at).length > 0 ? "icon-status icon-status-info" : ""}>
					<Icon name="bell" />
				</div>
			</DropdownToggle>
			<DropdownMenu right className="dropdown-menu-xl dropdown-menu-s1">
				{data?.length > 0 ?
					<>
						<div className="dropdown-head">
							<span className="sub-title nk-dropdown-title">{data?.title}</span>
						</div>
						<div className="dropdown-body">
							<div className="nk-notification">
								{data?.map((item) => {
									return <NotificationItem markAsRead={markAsRead} key={item.id + Math.random() * 10000} id={item.id} text={item.title} time={moment.utc(item.created_at).calendar()} readed_at={item.readed_at} />;
								})}
							</div>
						</div>

						<div className="dropdown-foot center">
							<Link to="/notifications">
								{t('notifications.show_all')}
							</Link>
						</div>
					</>
					: <div className="text-center my-4">{t('notifications.no_notification')}</div>
				}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

export default Notification;
