import React, {useState, useEffect} from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
	Block,
	BlockHead,
	BlockTitle,
	BlockBetween,
	BlockHeadContent,
	BlockDes,
	Icon,
	Button,
	DataTable,
	DataTableHead,
	DataTableBody,
	DataTableRow,
	DataTableItem,
	PaginationComponent,
	TooltipComponent
} from "../../../components/Component";
import {Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge} from "reactstrap";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {UncontrolledAlert} from "reactstrap";
import {UserAvatar} from "../../../components/Component";
import {findUpper, sliceString} from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import {videoServices, parametersServices, companyService, channelServices} from "../../../services";
import moment from 'moment';
import {VideoDetailsModal} from "../../../components/videos/VideoDetailsModal";
import {AddToPlaylistModal} from "../../../components/videos/AddToPlaylistModal";
import {FilterComponent} from "../../../components/FilterComponent";

const VideoPublishMaster = () => {
	const {t} = useTranslation("common");
	const history = useHistory();
	const location = useLocation();
	let {search} = useLocation();
	const formRawData = {};
	const alertRawData = {
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	};
	const uploadStatusRawData = [
		{label: t('settings:upload-status.client_pending'), value: 'client_pending', name: "upload_status"},
		{label: t('settings:upload-status.client_uploading'), value: 'client_uploading', name: "upload_status"},
		{label: t('settings:upload-status.client_finished'), value: 'client_finished', name: "upload_status"},
		{label: t('settings:upload-status.engine_processing'), value: 'engine_processing', name: "upload_status"},
		{label: t('settings:upload-status.engine_uploading'), value: 'engine_uploading', name: "upload_status"},
		{label: t('settings:upload-status.engine_finished'), value: 'engine_finished', name: "upload_status"},
		{label: t('settings:upload-status.engine_failed'), value: 'engine_failed', name: "upload_status"},
		{label: t('settings:upload-status.client_failed'), value: 'client_failed', name: "upload_status"},
	];
	const privacyStatusesRawData = [
		{value: "private", label: t(`youtube:status.private`)},
		{value: "unlisted", label: t("youtube:status.unlisted")},
		{value: "public", label: t("youtube:status.public")},
		{value: "scheduled", label: t("youtube:status.scheduled")},
	];

	const [videosData, setVideosData] = useState([]);
	const [videosMeta, setVideosMeta] = useState({total: null});
	const [alertData, setAlertData] = useState(alertRawData);
	const [formData, setFormData] = useState(formRawData);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [allFilterData, setAllFilterData] = useState({
		upload_status: uploadStatusRawData,
		privacy_status: privacyStatusesRawData
	});
	const [parameters] = useState(["company.id", "platform", "channel_id", "upload_status", "video_kind", "privacy_status"]);
	const [searchParams, setSearchParams] = useState("");

	useEffect(() => {
		if (searchParams.length > 0) {
			location.search = searchParams;
			history.replace({pathname: location.pathname, search: location.search});
			loadData();
		}
	}, [searchParams]);

	useEffect(() => {
		const query = new URLSearchParams(search);
		const hasPage = query.has('page');
		const hasLimit = query.has('limit');

		if (hasPage) {
			setCurrentPage(Number(query.get('page')));
		} else {
			query.append("page", currentPage);
		}

		if (hasLimit) {
			setItemPerPage(Number(query.get('limit')));
		} else {
			query.append("limit", itemPerPage);
		}

		if (!query.has('filter[upload_status]')) {
			query.append("filter[upload_status]", "engine_finished")
		}

		setSearchParams(decodeURI(query.toString()))
		history.replace({pathname: location.pathname, search: decodeURI(query.toString())})
	}, []);

	const loadData = async () => {
		try {
			const videos = await videoServices.getVideosByPageAndLimit({search: decodeURIComponent(location.search)});
			setVideosMeta({total: videos.meta.total});
			setVideosData([...videos.payload]);
		} catch (error) {
			console.debug(error);
		}
	};

	const getCompanyList = async () => {
		const companyList = await companyService.getCompanies();
		if (companyList?.data?.payload?.length > 0) {
			const createRList = companyList.data.payload.map((company, key) => {
				return {value: company.id, label: company.name};
			});
			setAllFilterData(current => ({...current, 'company.id': createRList}));
		}
	};

	const getPlatformList = async () => {
		const platformList = await parametersServices.getPlatforms();
		if (platformList?.payload?.length > 0) {
			const createRList = platformList.payload.map((platform, key) => {
				return {value: platform.key, label: platform.value};
			});
			setAllFilterData(current => ({...current, 'platform': createRList}));
		}
	};

	const getChannelList = async () => {
		const channelList = await channelServices.getChannels();
		if (channelList?.payload?.length > 0) {
			const createRList = channelList.payload.map((channel, key) => {
				return {value: channel.id, label: channel.title};
			});
			setAllFilterData(current => ({...current, 'channel_id': createRList}));
		}
	};

	const getVideoKindList = async () => {
		const videoKindList = await parametersServices.getVideoKinds();
		if (videoKindList?.payload?.length > 0) {
			const createRList = videoKindList.payload.map((videoKind, key) => {
				return {value: videoKind.key, label: videoKind.value};
			});
			setAllFilterData(current => ({
				...current,
				'video_kind': [...createRList].map((item) => ({...item, label: t(`statistic.${item.label}`)}))
			}));
		}
	};

	useState(async () => {
		await getCompanyList();
		await getPlatformList();
		await getVideoKindList();
		await getChannelList();
	}, [])

	/* 	// function to close the form modal
		const onFormCancel = () => {
			setModal({create: false, edit: false, processlist: false});
			resetForm();
		}; */

	/* 	const resetForm = () => {
			setFormData({
				title: "",
				videoKind: "",
			});
			setAlertData({
				status: false,
				type: "info",
				icon: "alert-circle",
				message: t("error.unknown"),
			});
		}; */

	// function to delete a video
	const deleteVideo = async (id) => {
		try {
			await videoServices.deleteVideo(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	/* 	// function to toggle the search option
		const toggle = () => setonSearch(!onSearch); */
	/*
		// function which fires on applying selected action
		const onActionClick = (e) => {
			if (actionText === "suspend") {
				let newData = data.map((item) => {
					if (item.checked === true) item.status = "Suspend";
					return item;
				});
				setData([...newData]);
			} else if (actionText === "delete") {
				let newData;
				newData = data.filter((item) => item.checked !== true);
				setData([...newData]);
			}
		};

		const handleChange = (result, e) => {
			setFormData({...formData, [e.name]: result.value});
		}; */

	// Get current list, pagination
	const currentItems = videosData;

	// Change Page
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		const query = new URLSearchParams(search);
		query.set("page", pageNumber);
		query.set('limit', itemPerPage);
		setSearchParams(decodeURI(query.toString()));
	}

	const {handleSubmit} = useForm();

	const [detailsModal, setDetailsModal] = useState({
		status: false,
		item: {}
	})

	const [addToPlaylistModal, setAddToPlaylistModal] = useState({
		status: false,
		videoId: ""
	})

	const toggleDetailsModal = (item) => {
		setDetailsModal(current => ({
			status: !current.status,
			item: item ? item : {}
		}));
	};

	const toggleAddToPlaylistModal = (item) => {
		//console.log(item)
		setAddToPlaylistModal(current => ({
			status: !current.status,
			id: item?.id,
			channelId: item?.channelId
		}));
	};

	const now = new Date().getTime();

	return (
		<React.Fragment>
			{detailsModal.item &&
				<VideoDetailsModal status={detailsModal?.status ?? false} toggle={toggleDetailsModal}
				                   item={detailsModal.item} t={t}/>
			}
			{
				<AddToPlaylistModal status={addToPlaylistModal?.status ?? false} toggle={toggleAddToPlaylistModal}
				                    ids={{id: addToPlaylistModal.id, channelId: addToPlaylistModal.channelId}}/>
			}
			<Head title="Video Upload"></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle>{t('videos.publish.page-title')}</BlockTitle>
							<BlockDes></BlockDes>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>
				<Block>
					{alertData.status /* && !modal.create && !modal.edit  */ ? (
						<Block>
							<BlockHeadContent>
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"}/>
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							</BlockHeadContent>
						</Block>
					) : null}
				</Block>
				<Block>
					<Card className="card-bordered">
						<div className="card-inner-group">
							<div className="card-inner p-0">
								<DataTable>
									<FilterComponent
										type="title"
										history={history}
										data={allFilterData}
										parameters={parameters}
										search={search}
										setSearch={setSearchParams}
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										itemPerPage={itemPerPage}
										setItemPerPage={setItemPerPage}/>
									<DataTableBody>
										<DataTableHead className="nk-tb-stiky-65">
											<DataTableRow className="nk-tb-col-check" size="sm">
												<div
													className="custom-control custom-control-sm custom-checkbox notext">
													<input type="checkbox" className="custom-control-input form-control"
													       id="uid_1"/>
													<label className="custom-control-label" htmlFor="uid_1"></label>
												</div>
											</DataTableRow>
											<DataTableRow>
												<span>{t("videos.publish.video")}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{t("content.channel.playlists.privacy_status")}</span>
											</DataTableRow>
											{/*
											<DataTableRow size="lg">
											<span>{t("videos.publish.status")}</span>
											</DataTableRow>
											*/}
											<DataTableRow size="sm">
												<span>{t("videos.publish.created_at")}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{t("videos.publish.published_by")}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{t("videos.publish.uploaded_at")}</span>
											</DataTableRow>
											<DataTableRow size="lg">
												<span>{t("modules.content")}</span>
											</DataTableRow>
											<DataTableRow>
												<span>{t("general.action")}</span>
											</DataTableRow>
										</DataTableHead>
										{currentItems.length > 0 ? currentItems.map((item) => {
											return (
												<DataTableItem key={item.id}>
													<DataTableRow className="nk-tb-col-check" size="sm">
														<div
															className="custom-control custom-control-sm custom-checkbox notext">
															<input type="checkbox"
															       className="custom-control-input form-control"
															       defaultChecked={item.check} id={item.id + "uid1"}
															       key={Math.random()}/>
															<label className="custom-control-label"
															       htmlFor={item.id + "uid1"}></label>
														</div>
													</DataTableRow>
													<DataTableRow>
														<div className="tb-video">
															<div className="thumb-group left-section">
																<img
																	src={item.thumbnail ?? "https://img.youtube.com/vi/default.jpg"}
																	alt="product" className={"thumb"}/>
																<Badge
																	className="badge-thumb"> {moment.utc(Number(item?.detail?.duration ?? "0") * 1000).format('HH:mm:ss').replace(/^(?:00:)?0?/, '')}</Badge>
															</div>
															<div className="right-section">
																<h6 className="title" title={item.title}>
																	{sliceString(item?.title, 40)}
																</h6>
																<div className="metadata">
																	<div>
																		<span className="desc"
																		      title={item?.description}>
																			{sliceString(item.description, 50)}
																		</span>
																	</div>
																	<Badge className="badge-fill mr-1" color="primary">
																		{item.video_kind}
																	</Badge>
																	{item?.platform === "youtube" && (
																		<Badge className="badge-dim" color="danger">
																			<Icon name="youtube"></Icon> Youtube
																		</Badge>
																	)}
																	{item?.platform === "facebook" && (
																		<Badge className="badge-dim"
																		       style={{color: "#4267B2"}}>
																			<Icon name="facebook-f"></Icon> Facebook
																		</Badge>
																	)}
																</div>
																<div className="video-action">
																	<Link to={`/content/videos/${item.id}/edit`}>
																		<Button className="btn-icon btn-trigger">
																			<Icon name="pen"/>
																		</Button>
																	</Link>
																</div>
															</div>
														</div>
													</DataTableRow>
													{/*
													<DataTableRow size="lg">
														{item?.platform == "youtube" && (
															<select name="select" id="default-4" className="form-control" defaultValue={item?.publish_status}>
																<option value="public">{t('youtube:status.public')}</option>
																<option value="unlisted">{t('youtube:status.unlisted')}</option>
																<option value="private">{t('youtube:status.private')}</option>
															</select>
														)}
													</DataTableRow>
													*/}
													<DataTableRow size="lg">
														{new Date(item.published_at).getTime() > now ? (
															<>
																<span
																	className="mr-1">{t(`youtube:status.scheduled`)}</span>
																<TooltipComponent key={item.id} icon="help-fill"
																                  iconClass="card-hint" direction="left"
																                  id={"tooltip" + item.id}
																                  text={moment(item?.published_at).format("YYYY-MM-DD hh:mm:ss") !== 'Invalid date' ? moment.utc(item?.published_at).local().format('DD.MM.YYYY HH:mm ') : "-"}/>
															</>)
														: t(`youtube:status.${item.privacy_status}`)}
													</DataTableRow>
													<DataTableRow size="lg">
														{moment.utc(item?.created_at).local().format('DD.MM.YYYY HH:mm ')}
													</DataTableRow>
													<DataTableRow size="lg">
														{item?.published_by ?? " - "}
													</DataTableRow>
													<DataTableRow size="lg">
														{moment(item?.uploaded_at).format("YYYY-MM-DD hh:mm:ss") !== 'Invalid date' ? moment.utc(item?.uploaded_at).local().format('DD.MM.YYYY HH:mm ') : "-"}
													</DataTableRow>
													<DataTableRow size="lg">
														<span className="tb-sub">{item?.company?.name}</span>
														<Link className="channel-card"
														      to={"//youtube.com/channel/" + item?.channel?.platform_id}
														      target="_blank">
															<div className="channel-card">
																<div className="channel-avatar sm bg-primary">
																	<div className="channel-avatar sm bg-primary">
																		{item?.channel?.cover
																			?
																			<UserAvatar className="sm" theme="primary"
																			            image={item?.channel?.cover}/>
																			:
																			<UserAvatar className="sm" theme="primary"
																			            text={findUpper(item?.channel?.title)}/>
																		}
																	</div>
																</div>
																<div className="channel-name">
																	<span
																		className="tb-lead">{item?.channel?.title}</span>
																</div>
															</div>
														</Link>
														<span
															className="tb-sub">{item?.inventory?.name + " - " + item?.inventory?.language_id}</span>
													</DataTableRow>
													<DataTableRow className="nk-tb-col-tools">
														<ul className="nk-tb-actions gx-1 my-n1">
															<li className="mr-n1">
																<UncontrolledDropdown>
																	<DropdownToggle tag="a" href="#more"
																	                onClick={(ev) => ev.preventDefault()}
																	                className="dropdown-toggle btn btn-icon btn-trigger">
																		<Icon name="more-h"></Icon>
																	</DropdownToggle>
																	<DropdownMenu right>
																		<ul className="link-list-opt no-bdr">
																			<li>
																				<DropdownItem
																					tag="a"
																					href="#details"
																					onClick={(ev) => {
																						ev.preventDefault();
																						toggleDetailsModal(item);
																					}}
																				>
																					<Icon name="eye"></Icon>
																					<span>{t("videos.detail.page-title")}</span>
																				</DropdownItem>
																			</li>
																			<li>
																				<DropdownItem
																					tag="a"
																					href="#remove"
																					onClick={(ev) => {
																						ev.preventDefault();
																						if (window.confirm(t("videos.upload.delete-confirm"))) deleteVideo(item.id);
																					}}
																				>
																					<Icon name="trash"></Icon>
																					<span>{t("operations.delete")}</span>
																				</DropdownItem>
																			</li>
																			<li>
																				<DropdownItem
																					tag="a"
																					href="#addtoplaylist"
																					onClick={(ev) => {
																						ev.preventDefault();
																						toggleAddToPlaylistModal({
																							id: item.video_uid,
																							channelId: item.channel_id
																						});
																					}}>
																					<Icon name="view-list-wd"></Icon>
																					<span>{t("content.channel.playlists.add_to_playlist")}</span>
																				</DropdownItem>
																			</li>
																		</ul>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</li>
														</ul>
													</DataTableRow>
												</DataTableItem>
											);
										}) : null}
									</DataTableBody>
									<div className="card-inner">
										{currentItems.length > 0 ? (
											<PaginationComponent itemPerPage={itemPerPage} totalItems={videosMeta.total}
											                     paginate={paginate} currentPage={currentPage}/>
										) : (
											<div className="text-center">
												<span className="text-silent">No videos found</span>
											</div>
										)}
									</div>

								</DataTable>
							</div>
						</div>
					</Card>
				</Block>
			</Content>
		</React.Fragment>
	);
};

export default VideoPublishMaster;
