import React, { useMemo, useState } from 'react'
import {
    DataTable,
    DataTableBody,
    DataTableHead,
    DataTableRow,
    Icon,
    BlockBetween,
    BlockDes,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    Block
} from '../../components/Component';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router';
import { useEffect } from 'react';
import { channelServices } from '../../services';
import { Button, UncontrolledAlert } from 'reactstrap';

export const Playlist = () => {

    const { t } = useTranslation("common");
    const { playlistId, channelId } = useParams();
    const [playlist, setPlaylist] = useState();
    let { search } = useLocation();
    const history = useHistory();
    const [data, setData] = useState();
    const [meta, setMeta] = useState();
    const [alertData, setAlertData] = useState({
        status: false,
        type: "info",
        icon: "alert-circle",
        message: t("error.unknown"),
    });

    const getPlaylistItems = async () => {
        const query = new URLSearchParams(search);
        try {
            const res = await channelServices.getPlaylistItems(channelId, playlistId, decodeURIComponent(query));
            if (data) {
                setMeta(res.meta);
                setData(current => ([...current, ...res.payload]));
            } else {
                setData(res.payload);
                setMeta(res.meta);
            }
            if (res.payload?.length < 1 || !res.payload) {
                setAlertData({
                    status: true,
                    type: "warning",
                    icon: "info",
                    message: t('content.channel.playlists.not_found'),
                });
            }
        } catch (err) {
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
        }
    }

    const getPlaylist = async () => {
        try {
            const res = await channelServices.getPlaylist(channelId, playlistId);
            setPlaylist(res.payload);
            if (res.payload?.length < 1 || !res.payload) {
                setAlertData({
                    status: true,
                    type: "warning",
                    icon: "info",
                    message: t('content.channel.playlists.not_found'),
                });
            }
        } catch (err) {
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
        }
    }

    useEffect(() => {
        getPlaylistItems();
        getPlaylist();
    }, [])

    useEffect(() => {
        getPlaylistItems();
    }, [search]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            data,
            result.source.index,
            result.destination.index
        );

        await onReorder(data[result.source.index], data[result.destination.index].snippet.position);

        setData(items);
    }

    const loadMore = async () => {
        const query = new URLSearchParams(search);
        query.set("page", meta?.next_page);
        history.replace({ pathname: search.pathname, search: decodeURIComponent(query) });
    };

    const onDeleteSubmit = async (videoId) => {
        try {
            await channelServices.deletePlaylistItem(channelId, playlistId, videoId);
            setData(current => current.filter((cr) => cr.id !== videoId));
        } catch (err) {
            const { response } = err;
            setAlertData({
                status: true,
                type: "success",
                icon: "check",
                message: response?.data?.message ?? err.message,
            });
        }
    };

    const onReorder = async (item, destination) => {
        try {
            await channelServices.playlistItemReorder(channelId, playlistId, item.id, { video_id: item.snippet.resourceId.videoId, position: destination });
            setAlertData({
                status: true,
                type: "success",
                icon: "check",
                message: t('error.success'),
            });
        } catch (err) {
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
        }
    }

    return (
        <React.Fragment>
            <Head title={t("content.channel.playlists.playlist")}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                {playlist?.snippet?.title}
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>
                                    {t('content.channel.playlists.total_video_count', { count: meta?.total })}
                                </p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                        <Button
							color="light"
							outline
							className="bg-white d-none d-sm-inline-flex"
							onClick={() => history.push(`/content/channel/${channelId}/settings?activeTab=4`)}>
							<Icon name="arrow-left"></Icon>
							<span>{t('operations.back')}</span>
						</Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                <Block>
                {alertData.status ? (
						<Block className={"mb-2"}>
							<BlockHeadContent>
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"}/>
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							</BlockHeadContent>
						</Block>
					) : null}
                </Block>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {meta && <InfiniteScroll
                                    dataLength={data?.length}
                                    next={loadMore}
                                    style={{ overflow: 'hidden' }}
                                    hasMore={meta.next_page ? true : false}
                                    loader={<h4>Loading...</h4>}>
                                    <DataTable className="card-stretch">
                                        <DataTableBody compact>
                                            <DataTableHead>
                                                <DataTableRow className="nk-tb-col-check">
                                                    <span className="sub-text"></span>
                                                </DataTableRow>
                                                <DataTableRow>
                                                    <span className="sub-text">{t("videos.upload.video")}</span>
                                                </DataTableRow>
                                                <DataTableRow size="sm">
                                                    <span className="sub-text">{t("content.channel.playlists.privacy_status")}</span>
                                                </DataTableRow>
                                                <DataTableRow size="sm">
                                                    <span className="sub-text">{t("content.channel.playlists.published_at")}</span>
                                                </DataTableRow>
                                                <DataTableRow size="sm">
                                                    <span className="sub-text">{t("content.channel.action")}</span>
                                                </DataTableRow>
                                            </DataTableHead>
                                            {data && data.map((item, index) => (
                                                <Draggable key={item.id + Math.random()} draggableId={index.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className={`nk-tb-item`}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            key={index}>
                                                            <DataTableRow className="nk-tb-col-check">
                                                                <span {...provided.dragHandleProps}><Icon name="view-row-wd" size="md" /></span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <div className="tb-video">
                                                                    <div className='thumb-group left section'>
                                                                        <img
                                                                            src={item.snippet?.thumbnails?.["default"]?.url ?? "https://img.youtube.com/vi/default.jpg"}
                                                                            alt="product" className={"thumb"} />
                                                                    </div>
                                                                    <div className='thumb-group right section'>
                                                                        <div className="tb-lead">{item.snippet.title}</div>
                                                                        <div>{item.snippet.description.length > 100 ? item.snippet?.description.substring(0, 100) + "..." : item.snippet?.description}</div>
                                                                    </div>
                                                                </div>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span>{t(`youtube:status.${item.status?.privacyStatus}`)}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <span>{moment(item?.snippet?.publishedAt).format("YYYY-MM-DD hh:mm:ss") !== 'Invalid date' ? moment(item?.snippet?.publishedAt).format("YYYY-MM-DD hh:mm:ss") : "-"}</span>
                                                            </DataTableRow>
                                                            <DataTableRow>
                                                                <a
                                                                    href="#delete"
                                                                    onClick={(ev) => {
                                                                        ev.preventDefault();
                                                                        onDeleteSubmit(item.id);
                                                                    }}>
                                                                    <Icon className="text-danger h6" name="trash"></Icon>
                                                                </a>
                                                            </DataTableRow>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </DataTableBody>
                                    </DataTable>
                                </InfiniteScroll>}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Content>
        </React.Fragment>
    )
}
