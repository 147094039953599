import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/directors'
 */
export async function getDirectors() {
	const endpoint = generateApiEndpoint(`directors?limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/directors'
 * Params: { page, limit }
 */
export async function getDirectorsByPageAndLimit({ page, limit }) {
	const endpoint = generateApiEndpoint(`directors?limit=${limit}&page=${page}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/directors'
 */
export async function addDirectors(data) {
	const endpoint = generateApiEndpoint("directors");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	// TODO Change response payload on UI
	return response;
}

/**
 * PUT: '/api/directors/{id}'
 */
export async function updateDirectors(id, data) {
	const endpoint = generateApiEndpoint(`directors/${id}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});

	// TODO Change response payload on UI
	return response;
}

/**
 * DELETE: '/api/directors/{id}'
 */
export async function deleteDirectors(id) {
	const endpoint = generateApiEndpoint(`directors/${id}`);
	const response = await restApiClient.delete(endpoint);

	// TODO Change response payload on UI
	return response;
}
