import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/parameters/company-types'
 */
export async function getCompanyTypes() {
	const endpoint = generateApiEndpoint("parameters/company-types");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/language-types'
 */
export async function getLanguageTypes() {
	const endpoint = generateApiEndpoint("parameters/language-types");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/product-types'
 */
export async function getProductTypes() {
	const endpoint = generateApiEndpoint("parameters/product-types");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/channel-types'
 */
export async function getChannelTypes() {
	const endpoint = generateApiEndpoint("parameters/channel-types");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/platforms'
 */
export async function getPlatforms() {
	const endpoint = generateApiEndpoint("parameters/platforms");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/video-kinds'
 */
export async function getVideoKinds() {
	const endpoint = generateApiEndpoint("parameters/video-kinds");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/languages'
 */
export async function getLanguagesByPageAndLimit({ limit, page }) {
	const endpoint = generateApiEndpoint(`parameters/languages?limit=${limit}&page=${page}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/languages'
 */
export async function getLanguages() {
	const endpoint = generateApiEndpoint(`parameters/languages?limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/countries'
 */
export async function getCountriesByPageAndLimit({ limit, page }) {
	const endpoint = generateApiEndpoint(`parameters/countries?limit=${limit}&page=${page}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/parameters/countries'
 */
export async function getCountries() {
	const endpoint = generateApiEndpoint(`parameters/countries?limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}
