import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, UncontrolledDropdown } from "reactstrap";
import {
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Button,
	Col,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableItem,
	DataTableRow,
	Icon,
	PaginationComponent,
	RSelect,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { LanguageContext } from "../../layout/context/LanguageContext";

// Services
import { companyService, parametersServices } from "../../services";

import { useTranslation } from "react-i18next";

const Company = () => {
	const [refreshKey, setRefreshKey] = useState(0);
	const { t } = useTranslation("common");
	const { contextData } = useContext(LanguageContext);
	const [data, setData] = contextData;
	const [langData, setLangData] = contextData;

	const [Companies, setCompanies] = useState([]);
	const [CompaniesMeta, setCompaniesMeta] = useState({
		total: null,
	});

	const [companyTypeRList, setCompanyTypeRList] = useState([]);

	const [sm, updateSm] = useState(false);
	const [onSearch, setonSearch] = useState(true);
	const [modal, setModal] = useState({
		edit: false,
		add: false,
	});
	const [formData, setFormData] = useState({
		id: "",
		name: "",
		ype: "",
		status: "",
	});
	const [actionText, setActionText] = useState("");
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);

	// Sorting data
	const sortFunc = (params) => {
		let defaultData = data;
		if (params === "asc") {
			let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
			setData([...sortedData]);
		} else if (params === "dsc") {
			let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
			setData([...sortedData]);
		}
	};

	// function to close the form modal
	const onFormCancel = () => {
		setModal({ edit: false, add: false });
		resetForm();
	};
	const resetForm = () => {
		setFormData({
			name: "",
			type: "",
			status: "Active",
		});
	};

	const getCompanyTypeList = async () => {
		const companyTypeList = await parametersServices.getCompanyTypes();
		if (companyTypeList?.payload?.length > 0) {
			const createRList = companyTypeList.payload.map((companyType, key) => {
				return { value: companyType.key, label: companyType.value };
			});
			setCompanyTypeRList(createRList);
		}
	};

	const handleChange = (result, e) => {
		setFormData({ ...formData, [e.name]: result.value });
	};

	// function that loads the want to editted data
	const onEditClick = (id) => {
		Companies.forEach((item) => {
			if (item.id === id) {
				setFormData({
					id: item.id,
					name: item.name,
					type: item.type,
					status: item.status,
					companyTypeValue: { value: item.type, label: item.type },
				});
				setModal({ edit: true }, { add: false });
			}
		});
	};

	const { errors, register, setValue, handleSubmit } = useForm();

	const currentCompanies = Companies;

	// Change Page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// const deleteLanguage = async (id) => {
	//     try {
	//         await companyService.deleteCompanies(id)
	//         setRefreshKey(oldKey => oldKey + 1)
	//     } catch (e) {
	//         console.log(e)
	//     }
	// };

	useEffect(() => {
		const loadData = async () => {
			try {
				const response = await companyService.getCompaniesByPageAndLimit({ page: currentPage, limit: itemPerPage });
				setCompanies([...response.payload]);
				setCompaniesMeta({ total: response.meta.total });
			} catch (error) {
				console.debug(error);
			}
		};
		loadData();
		getCompanyTypeList();
	}, [currentPage, langData.selectedLangId, refreshKey]); // eslint-disable-line react-hooks/exhaustive-deps

	// submit function to update a new item
	const onEditSubmit = async (submitData) => {
		// TODO Change here with submitData !!!
		const { id } = formData;
		const { name, type, status } = submitData;
		try {
			const response = await companyService.updateCompanies(id, { name: name, type: type, status: status });

			if (response.status === 200) {
				setRefreshKey((oldKey) => oldKey + 1);
			} else {
				console.log(response);
			}
		} catch (e) {
			console.log(e);
		}
		// resetForm();
		setModal({ edit: false });
	};
	const deleteCompany = async (id) => {
		try {
			const response = await companyService.deleteCompany(id);
			if (response.status === 200) {
				setRefreshKey((oldKey) => oldKey + 1);
			} else {
				console.log(response);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onFormSubmit = async (submitData) => {
		const { id, name, type, status } = submitData;
		const submittedData = {
			name: name,
			type: formData.type,
			status: status,
		};
		try {
			const response = await companyService.addCompany(submittedData);
			if (response.status === 200) {
				setRefreshKey((oldKey) => oldKey + 1);
			} else {
				console.log(response);
			}
		} catch (e) {
			console.log(e);
		}
		resetForm();
		setModal({ edit: false }, { add: false });
	};

	return (
		<React.Fragment>
			<Head title={t("company.list")}></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle tag="h3" page>
								{t("company.list")}
							</BlockTitle>
							<BlockDes className="text-soft">
								<p>
									{t("general.total")} {CompaniesMeta.total} {t("company.default")}.
								</p>
							</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
									<Icon name="menu-alt-r"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3">
										{/* <li>
                  <a
                    href="#export"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="btn btn-white btn-outline-light"
                  >
                    <Icon name="download-cloud"></Icon>
                    <span>Export</span>
                  </a>
                </li> */}
										<li className="nk-block-tools-opt">
											<Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
												<Icon name="plus"></Icon>
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>

				<Block>
					<DataTable className="card-stretch">
						{/* <div className="card-inner position-relative card-tools-toggle">
          <div className="card-title-group">
            <div className="card-tools">
              <div className="form-inline flex-nowrap gx-3">
                <div className="form-wrap">
                  <RSelect
                    options={bulkActionOptions}
                    className="w-130px"
                    placeholder="Bulk Action"
                    onChange={(e) => onActionText(e)}
                  />
                </div>
                <div className="btn-wrap">
                  <span className="d-none d-md-block">
                    <Button
                      disabled={actionText !== "" ? false : true}
                      color="light"
                      outline
                      className="btn-dim"
                      onClick={(e) => onActionClick(e)}
                    >
                      Apply
                    </Button>
                  </span>
                  <span className="d-md-none">
                    <Button
                      color="light"
                      outline
                      disabled={actionText !== "" ? false : true}
                      className="btn-dim  btn-icon"
                      onClick={(e) => onActionClick(e)}
                    >
                      <Icon name="arrow-right"></Icon>
                    </Button>
                  </span>
                </div>
              </div>
            </div>
            <div className="card-tools mr-n1">
              <ul className="btn-toolbar gx-1">
                <li>
                  <a
                    href="#search"
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle();
                    }}
                    className="btn btn-icon search-toggle toggle-search"
                  >
                    <Icon name="search"></Icon>
                  </a>
                </li>
                <li className="btn-toolbar-sep"></li>
                <li>
                  <div className="toggle-wrap">
                    <Button
                      className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                      onClick={() => updateTableSm(true)}
                    >
                      <Icon name="menu-right"></Icon>
                    </Button>
                    <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                      <ul className="btn-toolbar gx-1">
                        <li className="toggle-close">
                          <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                            <Icon name="arrow-left"></Icon>
                          </Button>
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                              <div className="dot dot-primary"></div>
                              <Icon name="filter-alt"></Icon>
                            </DropdownToggle>
                            <DropdownMenu
                              right
                              className="filter-wg dropdown-menu-xl"
                              style={{ overflow: "visible" }}
                            >
                              <div className="dropdown-head">
                                <span className="sub-title dropdown-title">Filter Users</span>
                                <div className="dropdown">
                                  <DropdownItem
                                    href="#more"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                    className="btn btn-sm btn-icon"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownItem>
                                </div>
                              </div>
                              <div className="dropdown-body dropdown-body-rg">
                                <Row className="gx-6 gy-3">
                                  <Col size="6">
                                    <div className="custom-control custom-control-sm custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-control"
                                        id="hasBalance"
                                      />
                                      <label className="custom-control-label" htmlFor="hasBalance">
                                        {" "}
                                        Have Balance
                                      </label>
                                    </div>
                                  </Col>
                                  <Col size="6">
                                    <div className="custom-control custom-control-sm custom-checkbox">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input form-control"
                                        id="hasKYC"
                                      />
                                      <label className="custom-control-label" htmlFor="hasKYC">
                                        {" "}
                                        KYC Verified
                                      </label>
                                    </div>
                                  </Col>
                                   <Col size="6">
                                    <FormGroup>
                                      <label className="overline-title overline-title-alt">Role</label>
                                      <RSelect options={filterRole} placeholder="Any Role" />
                                    </FormGroup>
                                  </Col> 
                                  <Col size="6">
                                    <FormGroup>
                                      <label className="overline-title overline-title-alt">Status</label>
                                      <RSelect options={filterStatus} placeholder="Any Status" />
                                    </FormGroup>
                                  </Col> 
                                  <Col size="12">
                                    <FormGroup className="form-group">
                                      <Button color="secondary">Filter</Button>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                              <div className="dropdown-foot between">
                                <a
                                  href="#reset"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                  className="clickable"
                                >
                                  Reset Filter
                                </a>
                                <a
                                  href="#save"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  Save Filter
                                </a>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                              <Icon name="setting"></Icon>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-xs">
                              <ul className="link-check">
                                <li>
                                  <span>Show</span>
                                </li>
                                <li className={itemPerPage === 10 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(10);
                                    }}
                                  >
                                    10
                                  </DropdownItem>
                                </li>
                                <li className={itemPerPage === 15 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(15);
                                    }}
                                  >
                                    15
                                  </DropdownItem>
                                </li>
                              </ul>
                              <ul className="link-check">
                                <li>
                                  <span>Order</span>
                                </li>
                                <li className={sort === "dsc" ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSortState("dsc");
                                      sortFunc("dsc");
                                    }}
                                  >
                                    DESC
                                  </DropdownItem>
                                </li>
                                <li className={sort === "asc" ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSortState("asc");
                                      sortFunc("asc");
                                    }}
                                  >
                                    ASC
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className={`card-search search-wrap ${!onSearch && "active"}`}>
            <div className="card-body">
              <div className="search-content">
                <Button
                  className="search-back btn-icon toggle-search active"
                  onClick={() => {
                    setSearchText("");
                    toggle();
                  }}
                >
                  <Icon name="arrow-left"></Icon>
                </Button>
                <input
                  type="text"
                  className="border-transparent form-focus-none form-control"
                  placeholder="Search by user or email"
                  value={onSearchText}
                  onChange={(e) => onFilterChange(e)}
                />
                <Button className="search-submit btn-icon">
                  <Icon name="search"></Icon>
                </Button>
              </div>
            </div>
          </div>
        </div> */}
						<DataTableBody compact>
							<DataTableHead>
								{/* <DataTableRow className="nk-tb-col-check">
              <div className="custom-control custom-control-sm custom-checkbox notext">
                <input
                  type="checkbox"
                  className="custom-control-input form-control"
                  onChange={(e) => selectorCheck(e)}
                  id="uid"
                />
                <label className="custom-control-label" htmlFor="uid"></label>
              </div>
            </DataTableRow> */}
								<DataTableRow>
									<span className="sub-text">{t("company.name")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("company.type")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("company.status")}</span>
								</DataTableRow>
								<DataTableRow className="nk-tb-col-tools text-right">
									{/* <UncontrolledDropdown>
                <DropdownToggle tag="a" className="btn btn-xs btn-outline-light btn-icon dropdown-toggle">
                  <Icon name="plus"></Icon>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xs">
                  <ul className="link-tidy sm no-bdr">
                    <li>
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input type="checkbox" className="custom-control-input form-control" id="bl" />
                        <label className="custom-control-label" htmlFor="bl">
                          Balance
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input type="checkbox" className="custom-control-input form-control" id="ph" />
                        <label className="custom-control-label" htmlFor="ph">
                          Phone
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input type="checkbox" className="custom-control-input form-control" id="vri" />
                        <label className="custom-control-label" htmlFor="vri">
                          Verified
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input type="checkbox" className="custom-control-input form-control" id="st" />
                        <label className="custom-control-label" htmlFor="st">
                          Status
                        </label>
                      </div>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown> */}
								</DataTableRow>
							</DataTableHead>
							{/*Head*/}
							{currentCompanies?.length > 0
								? currentCompanies.map((item) => {
										return (
											<DataTableItem key={item.id}>
												{/* <DataTableRow className="nk-tb-col-check">
                    <div className="custom-control custom-control-sm custom-checkbox notext">
                      <input
                        type="checkbox"
                        className="custom-control-input form-control"
                        defaultChecked={item.checked}
                        id={item.id + "uid1"}
                        key={Math.random()}
                        onChange={(e) => onSelectChange(e, item.id)}
                      />
                      <label className="custom-control-label" htmlFor={item.id + "uid1"}></label>
                    </div>
                  </DataTableRow> */}
												<DataTableRow>
													{/* <Link to={`${process.env.PUBLIC_URL}/company-details/${item.id}`}> */}
													<div className="user-card">
														<div className="user-name">
															<span className="tb-lead">{item.name}</span>
														</div>
													</div>
													{/* </Link> */}
												</DataTableRow>
												<DataTableRow>
													{/* <Link to={`${process.env.PUBLIC_URL}/company-details/${item.id}`}> */}
													<div className="user-card">
														<div className="user-name">
															<span className="tb-lead">{item.type}</span>
														</div>
													</div>
													{/* </Link> */}
												</DataTableRow>
												<DataTableRow>
													{/* <Link to={`${process.env.PUBLIC_URL}/company-details/${item.id}`}> */}
													<div className="user-card">
														<div className="user-name">
															<span className="tb-lead">{item.status}</span>
														</div>
													</div>
													{/* </Link> */}
												</DataTableRow>
												<DataTableRow className="nk-tb-col-tools">
													<ul className="nk-tb-actions gx-1">
														{/* <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                        <TooltipComponent
                          tag="a"
                          containerClassName="btn btn-trigger btn-icon"
                          id={"edit" + item.id}
                          icon="edit-alt-fill"
                          direction="top"
                          text="Edit"
                        />
                      </li>
                      {item.status !== "Suspend" && (
                        <React.Fragment>
                          <li className="nk-tb-action-hidden" onClick={() => deleteLanguage(item.id)}>
                            <TooltipComponent
                              tag="a"
                              containerClassName="btn btn-trigger btn-icon"
                              id={"suspend" + item.id}
                              icon="property-remove"
                              direction="top"
                              text="Delete"
                            />
                          </li>
                        </React.Fragment>
                      )} */}
														<li>
															<UncontrolledDropdown>
																<DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
																	<Icon name="more-h"></Icon>
																</DropdownToggle>
																<DropdownMenu right>
																	<ul className="link-list-opt no-bdr">
																		<li onClick={() => onEditClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#edit"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="edit"></Icon>
																				<span>{t("operations.edit")}</span>
																			</DropdownItem>
																		</li>
																		{item.status !== "Suspend" && (
																			<React.Fragment>
																				<li className="divider"></li>
																				<li onClick={() => deleteCompany(item.id)}>
																					<DropdownItem
																						tag="a"
																						href="#suspend"
																						onClick={(ev) => {
																							ev.preventDefault();
																						}}
																					>
																						<Icon name="property-remove"></Icon>
																						<span>{t("operations.delete")}</span>
																					</DropdownItem>
																				</li>
																			</React.Fragment>
																		)}
																	</ul>
																</DropdownMenu>
															</UncontrolledDropdown>
														</li>
													</ul>
												</DataTableRow>
											</DataTableItem>
										);
								  })
								: null}
						</DataTableBody>
						<div className="card-inner">
							{currentCompanies.length > 0 ? (
								<PaginationComponent itemPerPage={itemPerPage} totalItems={CompaniesMeta.total} paginate={paginate} currentPage={currentPage} />
							) : (
								<div className="text-center">
									<span className="text-silent">{t("general.nodatafound")}</span>
								</div>
							)}
						</div>
					</DataTable>
				</Block>
				<Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("company.add")}</h5>
							<div className="mt-4">
								<Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}>
									<Col md="6">
										<FormGroup>
											<label className="form-label">{t("company.name")}</label>
											<input
												className="form-control"
												type="text"
												name="name"
												defaultValue={formData.name}
												placeholder={t("company.name")}
												ref={register({
													required: "Doldurulması zorunlu bir alandır.",
												})}
											/>
											{errors.name && <span className="invalid">{errors.name.message}</span>}
										</FormGroup>
										{/*<ErrorMessage*/}
										{/*    errors={errors}*/}
										{/*    name="nameInputErros"*/}
										{/*    render={({message}) => <p>{message}</p>}*/}
										{/*/>*/}
									</Col>
									<Col md="6">
										<FormGroup>
											<label className="form-label">{t("company.type")}</label>
											<RSelect name="type" placeholder={t("company.type")} options={companyTypeRList} required onChange={handleChange} />
											{errors.type && <span className="invalid">{errors.type.message}</span>}
										</FormGroup>
										{/*<ErrorMessage*/}
										{/*    errors={errors}*/}
										{/*    name="nameInputErros"*/}
										{/*    render={({message}) => <p>{message}</p>}*/}
										{/*/>*/}
									</Col>
									<Col md="6">
										<FormGroup>
											<label className="form-label">{t("status")}</label>
											<select className="form-control" name="status" ref={register({ required: t("general.requiredfield") })}>
												<option value="active">{t("company.active")}</option>
												<option value="inactive">{t("company.inactive")}</option>
											</select>
											{errors.status && <span className="invalid">{errors.status.message}</span>}
										</FormGroup>
										{/*<ErrorMessage*/}
										{/*    errors={errors}*/}
										{/*    name="nameInputErros"*/}
										{/*    render={({message}) => <p>{message}</p>}*/}
										{/*/>*/}
									</Col>
									<Col size="12">
										<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
											<li>
												<Button color="primary" size="md" type="submit">
													{t("operations.save")}
												</Button>
											</li>
											<li>
												<a
													href="#cancel"
													onClick={(ev) => {
														ev.preventDefault();
														onFormCancel();
													}}
													className="link link-light"
												>
													{t("operations.cancel")}
												</a>
											</li>
										</ul>
									</Col>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("operations.update")}</h5>
							<div className="mt-4">
								<Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
									<Col md="6">
										<FormGroup>
											{/*                       <label className="form-label">Adı</label>
											 */}{" "}
											<label className="form-label">{t("company.name")}</label>
											<input className="form-control" type="text" name="name" defaultValue={formData.name} placeholder="Enter name" ref={register({ required: t("general.requiredfield") })} />
											{errors.name && <span className="invalid">{errors.name.message}</span>}
										</FormGroup>
									</Col>
									<Col md="6">
										<FormGroup>
											<label className="form-label">{t("company.type")}</label>
											<RSelect name="type" options={companyTypeRList} required onChange={handleChange} defaultValue={formData.companyTypeValue} />
											{errors.type && <span className="invalid">{errors.type.message}</span>}
										</FormGroup>
									</Col>
									<Col md="6">
										<FormGroup>
											<label className="form-label">{t("company.status")}</label>
											<select className="form-control" name="status" defaultValue={formData.status} ref={register({ required: t("general.requiredfield") })}>
												<option value="active">{t("company.active")}</option>
												<option value="inactive">{t("company.inactive")}</option>
											</select>
											{errors.status && <span className="invalid">{errors.status.message}</span>}
										</FormGroup>
									</Col>
									<Col size="12">
										<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
											<li>
												<Button color="primary" size="md" type="submit">
													{t("operations.save")}
												</Button>
											</li>
											<li>
												<a
													href="#cancel"
													onClick={(ev) => {
														ev.preventDefault();
														onFormCancel();
													}}
													className="link link-light"
												>
													{t("operations.cancel")}
												</a>
											</li>
										</ul>
									</Col>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</Content>
		</React.Fragment>
	);
};

export default Company;
