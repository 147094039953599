import { parametersServices } from "../../../services";

export async function VideoKinds() {
	const videoKinds = await parametersServices.getVideoKinds();

	const columns = [
		{
			key: "id",
			name: "#",
			sort: 0,
			type: "id",
		},
		{
			key: "value",
			name: "static-parameters.title",
			sort: 1,
			type: "title",
		},
		{
			key: "key",
			name: "static-parameters.key",
			sort: 2,
			type: "title",
		},
	];
	const rows = [];

	await videoKinds.payload?.map((videoKind, key) => {
		rows.push({
			id: key + 1,
			value: videoKind.value,
			key: videoKind.key,
		});
	});
	const tableData = {
		columns: columns,
		rows: rows,
	};
	return tableData;
}
