import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/licensors'
 */
export async function getLicensors() {
	const endpoint = generateApiEndpoint(`licensors?limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

export async function get({ limit, page }) {
	const endpoint = generateApiEndpoint(`licensors?limit=${limit}&page=${page}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

export async function add(data) {
	const endpoint = generateApiEndpoint("licensors");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	// TODO Change response payload on UI
	return response;
}

export async function update(id, data) {
	const endpoint = generateApiEndpoint(`licensors/${id}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});

	// TODO Change response payload on UI
	return response;
}

export async function destroy(id) {
	const endpoint = generateApiEndpoint(`licensors/${id}`);
	const response = await restApiClient.delete(endpoint);

	// TODO Change response payload on UI
	return response;
}
