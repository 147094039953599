import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import { reportServices, videoServices } from "../services";
import { Link } from "react-router-dom";

const VideoWidget = ({dates, revenue}) => {
	const { t, i18n } = useTranslation("common");

	const [totalList,setTotalList] = useState();
		useEffect(() => {
			const getStatistics = async (date) => {
				const res = await videoServices.getVideosByPageAndLimit({search: "limit=10"});
				setTotalList(res.payload);
			}
			if(dates) getStatistics(dates);
	}, [dates]);
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0, 
		minimumFractionDigits: 0, 
	  });
	return (
		<Card className="h-100">
			<div className="card-inner">
				<div className="card-title-group mb-2">
					<div className="card-title">
						<h6 className="title">{t("my-dashboard.not_monetized_videos")}</h6>
						{/* <p>{t("statistic.top_earning_channels_subtext")}</p>  */}
					</div>
				</div>
				<ul className="nk-top-products">
					{totalList ? totalList.map((item, idx) => (
                        <Link to={`content/videos/${item.id}/edit`}>
						<li className="item" key={idx}>
							<div className="thumb">
								<img src={item.thumbnail} alt="" />
							</div>
							<div className="info">
								<div className="title">{item.title}</div>
							</div>
						</li>
                        </Link>
					)) : <h4 className="text-center">No Data</h4>}
				</ul>
			</div>
		</Card>
	);
};

export default VideoWidget;
