import React, { useEffect } from 'react'
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import { useTranslation } from 'react-i18next'
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, PaginationComponent } from '../../components/Component';
import { Badge, Button, Col, Form, FormGroup, Modal, ModalBody, Row, UncontrolledAlert } from 'reactstrap';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { packageService, usersService } from '../../services';
import { FilterComponent } from '../../components/FilterComponent';
import { useHistory, useLocation, useParams } from 'react-router';

const PackageDetails = () => {
    const { t } = useTranslation("common");
    const { packageId } = useParams();

    let { search } = useLocation();
    const location = useLocation();
    const history = useHistory();
    const [searchParams, setSearchParams] = useState("");

    const [modal, setModal] = useState({ add: false });
    const [csvFile, setCsvFile] = useState();
    const [data, setData] = useState();
    const [meta, setMeta] = useState();
    const [alertData, setAlertData] = useState({
        status: false,
        type: "info",
        icon: "alert-circle",
        message: t("error.unknown"),
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [parameters] = useState(['type', 'status', 'uploaded_by']);
    const [allFilterData, setAllFilterData] = useState({
        status: [
            { id: 1, label: t("content.mass_upload.package_items.valid"), value: "valid" },
            { id: 2, label: t("content.mass_upload.package_items.invalid"), value: "invalid" },
            { id: 3, label: t("content.mass_upload.package_items.duplicate"), value: "duplicate" },
            { id: 4, label: t("content.mass_upload.package_items.invalid_format"), value: "invalid_format" },
            { id: 5, label: t("content.mass_upload.package_items.invalid_field"), value: "invalid_field" },
            { id: 6, label: t("content.mass_upload.package_items.invalid_relation"), value: "invalid_relation" },

        ],
        model_type: [
            { id: 1, label: t("content.mass_upload.types.product"), value: "product" },
            { id: 2, label: t("content.mass_upload.types.inventory"), value: "inventory" },
        ]
    });

    useEffect(() => {
        location.search = searchParams;
        history.replace({ pathname: location.pathname, search: location.search });

        loadData();
    }, [searchParams]);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const hasPage = query.has('page');
        const hasLimit = query.has('limit');

        if (hasPage)
            setCurrentPage(Number(query.get('page')));
        else
            query.append("page", currentPage);
        if (hasLimit)
            setItemPerPage(Number(query.get('limit')));
        else
            query.append("limit", itemPerPage);

        setSearchParams(decodeURI(query.toString()))
        history.replace({ pathname: location.pathname, search: decodeURI(query.toString()) })

        if (searchParams?.includes('filter'))
            loadData();
    }, []);

    const loadData = async () => {
        try {
            const res = await packageService.getPackageItems({packageId, search: decodeURIComponent(location.search) });
            console.log(res)
            setData(res.payload);
            setMeta(res.meta);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        loadData();
    }, [])

    const onFormCancel = () => {
        setModal({ add: false });
    };

    const onFormSubmit = async (data) => {
        try {
            const formdata = new FormData();
            formdata.append("name", data.name);
            formdata.append("type", data.type);
            formdata.append("file", csvFile);
            const res = await packageService.postPackage(formdata);
            onFormCancel();
            setAlertData({
                status: true,
                type: "success",
                icon: "check-circle",
                message: res?.message,
            });
            loadData();
        } catch (err) {
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
            console.error(err);
        }
    };

    const onFileSelect = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            setCsvFile(e.target.files[0]);
        }
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        const query = new URLSearchParams(search);
        query.set("page", pageNumber)
        setSearchParams(decodeURI(query.toString()));
    };

    const executePackage = async () => {
        try {
            const res = await packageService.executePackage(packageId);
            setAlertData({
                status: true,
                type: "success",
                icon: "check-circle",
                message: res?.message,
            });
        } catch (err) {
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
            console.error(err);
        }
    }

    const { handleSubmit, register, errors } = useForm();

    return (
        <React.Fragment>
            <Head title={t("content.mass_upload.package_items.package_details")}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                    <BlockHeadContent className={"mb-4"}>
                        <BlockTitle tag="h3" page>
                        {t("content.mass_upload.package_items.package_details")}
                        </BlockTitle>
                    </BlockHeadContent>
                <BlockHeadContent>
                        <Button
							color="light"
							outline
							className="bg-white d-none d-sm-inline-flex"
							onClick={() => history.push(`/content/mass-upload`)}>
							<Icon name="arrow-left"></Icon>
							<span>{t('operations.back')}</span>
						</Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                        <BlockHeadContent className={"mb-3"}>
                            <Button
                                className="toggle d-none d-md-inline-flex"
                                color="primary"
                                onClick={() => {
                                    executePackage();
                                }}>
                                <span>{t('content.mass_upload.package_items.execute')}</span>
                            </Button>
                        </BlockHeadContent>
                {alertData.status && !modal.add && !modal.edit ? (
                    <Block>
                        <BlockHeadContent>
                            <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                                <Icon name={alertData.icon ?? "cross-circle"} />
                                {alertData.message ?? t("error.unknown")}
                            </UncontrolledAlert>
                        </BlockHeadContent>
                    </Block>
                ) : null}
                <Block>
                    <DataTable className="card-stretch">
                        <FilterComponent
                            type="search"
                            history={history}
                            data={allFilterData}
                            parameters={parameters}
                            search={search}
                            setSearch={setSearchParams}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemPerPage={itemPerPage}
                            setItemPerPage={setItemPerPage} />
                        <DataTableBody compact>
                            <DataTableHead>
                                <DataTableRow className="nk-tb-col-check">
                                    <span className="sub-text">#</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{t('general.name')}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{t('content.mass_upload.package_items.message')}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{t('content.inventory.status')}</span>
                                </DataTableRow>
                            </DataTableHead>
                            {data?.length > 0 &&
                                data.map((item) =>
                                    <DataTableItem className={item.status === "invalid" ? "bg-danger text-light" : ""}>
                                        <div className={"nk-tb-col"} >
                                            <span>{item.id}</span>
                                        </div>
                                        <div className={"nk-tb-col"} >
                                            <span >{item.model_data.name}</span>
                                        </div>
                                        <div className={"nk-tb-col"} >
                                            <span>{item.message}</span>
                                        </div>
                                        <div className={"nk-tb-col"} >
                                            <span>{t(`content.mass_upload.package_items.${item.status}`)}</span>
                                        </div>
                                    </DataTableItem>
                                )}
                        </DataTableBody>
                        <div className="card-inner">
                            {data?.length > 0 ? (
                                <PaginationComponent itemPerPage={itemPerPage} totalItems={meta?.total} paginate={paginate} currentPage={currentPage} />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
            </Content>
            <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{t("content.mass_upload.add")}</h5>
                        {alertData.status ? (
                            <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                                <Icon name={alertData.icon ?? "cross-circle"} />
                                {alertData.message ?? t("error.unknown")}
                            </UncontrolledAlert>
                        ) : null}
                        <div className="mt-4">
                            <Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                                <FormGroup>
                                    <label className="form-label">{t("content.mass_upload.name")}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        ref={register({
                                            required: t("operations.required"),
                                        })}
                                    />
                                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md="12">
                                            <label className="form-label">{t("content.mass_upload.csv_file")}</label>
                                            <input type="file" id="thumbnail" className="form-control" accept="text/csv" name="file" onChange={(e) => onFileSelect(e)} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md="12">
                                            <label className="form-label">{t("content.mass_upload.type")}</label>
                                            <select ref={register({})} name="type" className='form-control'>
                                                <option value="product">{t('content.mass_upload.product')}</option>
                                                <option value="inventory">{t('content.mass_upload.inventory')}</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <Row>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    {t("operations.save")}
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    {t("operations.cancel")}
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default PackageDetails;
