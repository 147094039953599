import React, { useState } from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard, Icon } from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { FormGroup, Alert, Spinner } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { LanguageContextProvider } from "../../layout/context/LanguageContext";
import { Link } from "react-router-dom";
import { ProjectSettingsContextProvider } from "../../layout/context/ProjectSettingsContext";
import { useTranslation } from "react-i18next";
import { authService } from "../../services";

const TwoFactorLogin = () => {
	const { t } = useTranslation("common");
	const [otp, setOTP] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [loading, setLoading] = useState(false);

		const username = localStorage.getItem('username');
		const passcode = localStorage.getItem('passcode');
		const login = () => {
				authService.login({ "username": username, "password": passcode, "otp" : otp }).then((res) => {
					if (res?.status === 200) {
						const response = res.data;
						localStorage.setItem("accessToken", response?.payload?.token ?? null);
						localStorage.setItem("applicationId", response?.payload?.applications[0]?.id ?? null);
						localStorage.setItem("authUser", JSON.stringify(response?.payload));
						setTimeout(function () {
							window.history.pushState(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`, "auth-login", `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`);
							window.location.reload();
						}, 1000);
					} else {
						setErrorMessage(t("login.error"));
						setLoading(false);
					}
				});
		}

	return (
		
		<ProjectSettingsContextProvider>
			<React.Fragment>
				<Head title={t("2fa_login.page_title")} />
				<PageContainer>
					<Block className="nk-block-middle nk-auth-body  wide-xs">
						<div className="brand-logo pb-4 text-center">
							<Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
								<img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
								<img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
							</Link>
						</div>
						<PreviewCard className="card-bordered" bodyClass="card-inner-lg">
							<BlockHead>
								<BlockContent>
									<BlockTitle tag="h5">{t("2fa_login.title")}</BlockTitle>
									<BlockDes>
										<p>{t("2fa_login.desc")}</p>
									</BlockDes>
								</BlockContent>
							</BlockHead>
							{errorMessage && (
								<div className="mb-3">
									<Alert color="danger" className="alert-icon">
										<Icon name="alert-circle" /> {errorMessage}
									</Alert>
								</div>
							)}{" "}
							<FormGroup> 
								<input
									type="text"
									className="form-control form-control-lg"
									id="default-01"
									value={otp}
									onChange={(e) => {
										setOTP(e.target.value);
									}}
									placeholder={t("2fa_login.placeholder")}
								/>
							</FormGroup>
							<FormGroup>
								<Button color="primary" size="lg" className="btn-block" onClick={() => login()}>
									{loading ? <Spinner size="sm" color="light" /> : t("login.login")}
								</Button>
							</FormGroup>
							<div className="form-note-s2 text-center pt-4">
								<Link to={`${process.env.PUBLIC_URL}/auth-login`}>
									<strong>{t("forgotPassword.redirectButton")}</strong>
								</Link>
							</div>
						</PreviewCard>
					</Block>
					<LanguageContextProvider>
					<AuthFooter />
					</LanguageContextProvider>
				</PageContainer>
			</React.Fragment>
		</ProjectSettingsContextProvider>
	);
};
export default TwoFactorLogin;
