import React, { useContext, useState, useEffect } from "react";
import { Block, BlockHead, BlockTitle, BlockBetween, BlockHeadContent, BlockDes, Icon, Row, Col, Button, DataTableHead, DataTableBody, DataTableRow, DataTableItem, PaginationComponent } from "../../../components/Component";
import { Progress, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown, CardTitle } from "reactstrap";
import { UploadsContext } from "../../../layout/context/UploadContext";
import { useTranslation } from "react-i18next";
import { videoServices } from "../../../services";

const ProcessListModal = (props) => {
	const { t, i18n } = useTranslation("common");
	const { modal, setModal } = props;
	const { videoFilesContext, processContext } = useContext(UploadsContext);
	const [videoFiles, putVideoFile, removeVideoFile] = videoFilesContext;
	const [videoList, setVideoList] = useState({});
	const [processStatus, setProcessStatus] = processContext;

	useEffect(() => {
		//console.log("Process List VideoFiles:", videoFiles);
		videoFiles.map((videoFile) => {
			setVideoList({
				...videoList,
				[videoFile.fileUniqueId]: videoFile,
			});
		})
	}, [videoFiles]);

	const removeVideoFromQueue = async (uniqueId) => {
		const res = await videoServices.deleteVideo(uniqueId,true);
		if(res.status === 200){
			var videosAfterRemove = videoList;
			delete videoList[`${uniqueId}`];
			setVideoList(videosAfterRemove);
			removeVideoFile(uniqueId);
		};
	};

	const DropdownTrans = () => {
		return (
			<UncontrolledDropdown>
				<DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger mr-n1">
					<Icon name="more-h"></Icon>
				</DropdownToggle>
				<DropdownMenu right>
					<ul className="link-list-opt no-bdr">
						<li>
							<DropdownItem
								tag="a"
								href="#dropdownitem"
								onClick={(ev) => {
									ev.preventDefault();
								}}
							>
								<Icon name="setting"></Icon>
								<span>Action Settings</span>
							</DropdownItem>
						</li>
						<li>
							<DropdownItem
								tag="a"
								href="#dropdownitem"
								onClick={(ev) => {
									ev.preventDefault();
								}}
							>
								<Icon name="notify"></Icon>
								<span>Use Notification</span>
							</DropdownItem>
						</li>
					</ul>
				</DropdownMenu>
			</UncontrolledDropdown>
		);
	};

	return (
		<>
			<BlockHead>
				<BlockHeadContent>
					<BlockTitle tag="h5">{t('videos.upload.process-list-title')}</BlockTitle>
					<BlockDes>
						<p></p>
					</BlockDes>
				</BlockHeadContent>
			</BlockHead>
			<Block>
				<Row className="g-3">
					<Col size="12">
						<div className="card-inner-group" key={videoList}>
							{Object.values(videoList)?.length > 0 && Object.values(videoList)?.map((videoFile, key) => {
								let filePercent = (videoFile?.progress() * 100).toFixed() ?? 0;
								return (
									<div className="card-inner card-inner-md" key={key}>
										<div className="user-card">
											<div className="user-info overflow-hidden">
												<span className="lead-text">{videoFile.fileName}</span>
												<span className="lead-text"></span>
												{
													videoFile.isError
													? <span className="sub-text">{ t("videos.upload.error") }</span>
													: <span className="sub-text">{ filePercent < 100 ? `%${filePercent}` : t("videos.upload.completed") }</span>
												}
											</div>
											{/*
											todo:cc:video action button
											<div className="user-action">
												<DropdownTrans />
											</div>
											*/}
											<div className="dropdown-toggle btn btn-icon btn-trigger mr-n1 ml-2">
												<Icon name="cross" onClick={() => (removeVideoFromQueue(videoFile.fileUniqueId))} />
											</div>
										</div>
										{filePercent < 100 && <Progress animated value={filePercent} />}
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
			</Block>
		</>
	);
};

export default ProcessListModal;
