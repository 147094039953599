import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { findUpper } from "../../utils/Utils";
import { DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, UncontrolledDropdown, UncontrolledAlert } from "reactstrap";
import {
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Button,
	Row,
	Col,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableItem,
	DataTableRow,
	Icon,
	PaginationComponent,
	UserAvatar,
	RSelect,
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LanguageContext } from "../../layout/context/LanguageContext";
import { useTranslation } from "react-i18next";
import { rolepermissionServices, usersService } from "../../services";
import DatePicker from "react-datepicker";

const RoleList = () => {
	const { t, i18n } = useTranslation("common");

	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});

	const [roleData, setRoleData] = useState([]);

	const [rolesMeta, setRolesMeta] = useState({
		total: null,
	});

	const [sm, updateSm] = useState(false);
	const [modal, setModal] = useState({
		edit: false,
		add: false,
		permission: false,
		users: false,
	});
	const [editId, setEditedId] = useState();
	const [roleName, setRoleName] = useState();
	const [formData, setFormData] = useState({
		name: "",
		guard_name: "",
	});
	const [usersRList, setUsersRList] = useState([]);
	const [userIdsList, setUserIdsList] = useState([]);
	const [rolePermission, setRolePermission] = useState([]);
	const [permissionData, setPermissionData] = useState([]);
	const [permissionGroup, setPermissionGroup] = useState([]);
	const [permissionMethods, setPermissionMethods] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);

	const loadData = async () => {
		try {
			const roles = await rolepermissionServices.getRolesByPageAndLimit({ page: currentPage, limit: itemPerPage });
			setRolesMeta({ total: roles.meta.total });
			setRoleData([...roles.payload]);
		} catch (error) {
			console.debug(error);
		}
	};

	// unselects the data on mount
	useEffect(() => {
		loadData();
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// function to reset the form
	const resetForm = () => {
		setFormData({
			name: "",
			guard_name: "",
		});
		setPermissionData([]);
		setUserIdsList([]);
		setAlertData({
			status: false,
			type: "info",
			icon: "alert-circle",
			message: t("error.unknown"),
		});
	};

	// function to close the form modal
	const onFormCancel = () => {
		setModal({ edit: false, add: false, permission: false, users: false });
		resetForm();
	};

	const checkEndDateIsValid = (date) => {};

	// submit function to add a new item
	const onFormSubmit = async (submitData) => {
		const { name, guard_name } = submitData;
		let submittedData = {
			name: name,
			guard_name: guard_name,
		};
		try {
			const response = await rolepermissionServices.addRole({
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false }, { permission: false }, { users: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				status: true,
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// submit function to update a new item
	const onEditSubmit = async (submitData) => {
		const { name, guard_name } = submitData;
		let submittedData = {
			// id: data.length + 1,
			name: name,
			guard_name: guard_name,
		};
		try {
			const response = await rolepermissionServices.updateRole(editId, {
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false }, { permission: false }, { users: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				status: true,
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// function that loads the want to editted data
	const onEditClick = (id) => {
		resetForm();
		roleData.forEach((item) => {
			if (item.id === id) {
				setFormData({
					...item,
				});
				getUsersForRList();
				setModal({ edit: true }, { add: false }, { permission: false }, { users: false });
				setEditedId(id);
			}
		});
	};

	// function that loads the want to editted data
	const onUserAssignClick = async (id) => {
		resetForm();
		const role = await rolepermissionServices.getRoleById(id);
		roleData.forEach((item) => {
			if (item.id === id) {
				setFormData({
					...item,
					users: role.payload.users,
				});
				getUsersForRList();
				setUsersRList(item?.users.map((user) => user.id));
				setModal({ users: true }, { edit: false }, { add: false }, { permission: false });
				setEditedId(id);
			}
		});
	};

	const handleChangeUser = (result, e) => {
		const userIds = result.map((user) => user.value);
		setUserIdsList(userIds);
	};

	const onUserAssignSubmit = async () => {
		try {
			const response = await rolepermissionServices.userAssign({
				userIds: userIdsList,
				role: formData.name,
			});
			loadData();
			setModal({ edit: false }, { add: false }, { permission: false }, { users: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				status: true,
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	const getUsersForRList = async () => {
		try {
			const usersResponse = await usersService.getUsers();
			const usersOption = usersResponse.payload.map((user) => {
				return { value: user.id, label: user.fullname };
			});
			setUsersRList(usersOption);
		} catch (error) {
			console.debug(error);
		}
	};

	// function that loads the want to editted data
	const onPermissionClick = async (id) => {
		await resetForm();
		const role = await rolepermissionServices.getRoleById(id);
		const allPermissions = await rolepermissionServices.getAllPermissions();
		if (allPermissions && role) {
			await setRolePermission(role.payload.permissions);
			await permissionGrouping(allPermissions.payload);
			setModal({ permission: true }, { edit: false }, { add: false }, { users: false });
			setRoleName(role.payload.name);
		}
	};

	useEffect(() => {
		const newPermissionData = rolePermission?.map((permisson) => {
			return permisson.name;
		});
		setPermissionData(newPermissionData);
	}, [rolePermission]);

	const handleChange = (e) => {
		if (e.target.checked == true) {
			setPermissionData([...permissionData, e.target.name]);
		} else if (e.target.checked == false) {
			const newPermissionData = permissionData.filter((permission) => permission != e.target.name);
			setPermissionData(newPermissionData);
		}
	};

	const onPermissionAssginSubmit = async (submitData) => {
		try {
			const response = await rolepermissionServices.syncPermissions({
				role_name: roleName,
				permission_name: permissionData,
			});
			loadData();
			setModal({ permission: false }, { edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				status: true,
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	const permissionGrouping = (permissionItems) => {
		let perGroup = [];
		let perMethod = [];
		permissionItems.map((permissionItem) => {
			//console.log("perm,i:",permissionItem.name);
			let splitIndex;
			permissionItem.name
				.split("")
				.reverse()
				.find((letter, index) => {
					if (letter == "-") {
						splitIndex = permissionItem.name.length - index;
						return splitIndex;
					}
				});

			let permissionName = permissionItem.name.slice(0, splitIndex - 1);
			let permissionMethod = permissionItem.name.slice(splitIndex, permissionItem.name.length);
			if (!perMethod.find((pM) => pM == permissionMethod)) {
				perMethod.push(permissionMethod);
			}
			if (!perGroup.find((pG) => pG.name == permissionName)) {
				perGroup.push({
					name: permissionName,
					method: [permissionMethod],
				});
			} else {
				perGroup.map((pG) => {
					if (pG.name == permissionName) {
						pG.method.push(permissionMethod);
					}
					return pG;
				});
			}
		});
		setPermissionMethods(perMethod);
		setPermissionGroup(perGroup);
	};

	// function to change to suspend property for an item
	const activeUser = async (id) => {
		try {
			const roles = await rolepermissionServices.updateRole(id, {
				status: "Active",
			});
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	// function to change to suspend property for an item
	const deleteRole = async (id) => {
		try {
			const role = await rolepermissionServices.deleteRole(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	const animatedComponents = makeAnimated();

	const { errors, register, handleSubmit } = useForm();

	// Get current list, pagination
	// const indexOfLastItem = currentPage * itemPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemPerPage;
	// const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
	const currentItems = roleData;

	// Change Page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const nextPage = () => {
		paginate(currentPage + 1);
	};

	const prevPage = () => {
		paginate(currentPage - 1);
	};

	return (
		<React.Fragment>
			<Head title={t("roles.list")}></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle tag="h3" page>
								{t("roles.list")}
							</BlockTitle>
							<BlockDes className="text-soft">
								<p>
									{t("general.total")} {rolesMeta.total} {t("general.role")}.
								</p>
							</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
									<Icon name="menu-alt-r"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3">
										<li className="nk-block-tools-opt">
											<Button
												color="primary"
												className="btn-icon"
												onClick={() => {
													resetForm();
													setModal({ add: true });
												}}
											>
												<Icon name="plus"></Icon>
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>

				{alertData.status && !modal.add && !modal.edit ? (
					<Block>
						<BlockHeadContent>
							<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
								<Icon name={alertData.icon ?? "cross-circle"} />
								{alertData.message ?? t("error.unknown")}
							</UncontrolledAlert>
						</BlockHeadContent>
					</Block>
				) : null}

				<Block>
					<DataTable className="card-stretch">
						<DataTableBody compact>
							<DataTableHead>
								<DataTableRow className="nk-tb-col-check">
									<span className="sub-text">#</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("roles.name")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("roles.guard_name")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text float-right">{t("roles.action")}</span>
								</DataTableRow>
							</DataTableHead>
							{/*Head*/}
							{currentItems.length > 0
								? currentItems.map((item, key) => {
										return (
											<DataTableItem key={item.id + key}>
												<DataTableRow className="nk-tb-col-check">{item.id}</DataTableRow>
												<DataTableRow>
													<span className="tb-lead">{item.name}</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>{item.guard_name}</span>
												</DataTableRow>
												<DataTableRow className="nk-tb-col-tools">
													<ul className="nk-tb-actions gx-1">
														<li>
															<UncontrolledDropdown>
																<DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
																	<Icon name="more-h"></Icon>
																</DropdownToggle>
																<DropdownMenu right>
																	<ul className="link-list-opt no-bdr">
																		<li onClick={() => onEditClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#edit"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="edit"></Icon>
																				<span>{t("operations.edit")}</span>
																			</DropdownItem>
																		</li>
																		<li onClick={() => onUserAssignClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#user-assign"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="user-add"></Icon>
																				<span>{t("operations.users-assign")}</span>
																			</DropdownItem>
																		</li>
																		<li onClick={() => onPermissionClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#permission"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="shield"></Icon>
																				<span>{t("operations.permission")}</span>
																			</DropdownItem>
																		</li>
																		<li
																			onClick={() => {
																				if (window.confirm(t("roles.delete-confirm"))) deleteRole(item.id);
																			}}
																		>
																			<DropdownItem
																				tag="a"
																				href="#delete"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="delete"></Icon>
																				<span>{t("operations.delete")}</span>
																			</DropdownItem>
																		</li>
																	</ul>
																</DropdownMenu>
															</UncontrolledDropdown>
														</li>
													</ul>
												</DataTableRow>
											</DataTableItem>
										);
								  })
								: null}
						</DataTableBody>
						<div className="card-inner">
							{currentItems.length > 0 ? (
								<PaginationComponent itemPerPage={itemPerPage} totalItems={rolesMeta.total} paginate={paginate} currentPage={currentPage} />
							) : (
								<div className="text-center">
									<span className="text-silent">No data found</span>
								</div>
							)}
						</div>
					</DataTable>
				</Block>
				<Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("roles.add")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("roles.name")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-z_]*$/i,
															message: t("roles.required-alfa"),
														},
													})}
												/>
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("roles.guard_name")}</label>
												<input
													className="form-control"
													type="text"
													name="guard_name"
													defaultValue={formData.guard_name}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-z_]*$/i,
															message: t("roles.required-alfa"),
														},
													})}
												/>
												{errors.guard_name && <span className="invalid">{errors.guard_name.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("roles.edit")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onEditSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("roles.name")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-z_]*$/i,
															message: t("roles.required-alfa"),
														},
													})}
												/>
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("roles.guard_name")}</label>
												<input
													className="form-control"
													type="text"
													name="guard_name"
													defaultValue={formData.guard_name}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-z_]*$/i,
															message: t("roles.required-alfa"),
														},
													})}
												/>
												{errors.guard_name && <span className="invalid">{errors.guard_name.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.users} toggle={() => setModal({ users: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("roles.user-assign")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onUserAssignSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("roles.name")}</label>
												<input className="form-control" type="text" name="name" defaultValue={formData.name} disabled={true} />
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("roles.guard_name")}</label>
												<input className="form-control" type="text" name="guard_name" defaultValue={formData.guard_name} disabled={true} />
												{errors.guard_name && <span className="invalid">{errors.guard_name.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("roles.users")}</label>
												<RSelect
													name="users"
													isMulti
													components={animatedComponents}
													options={usersRList}
													onChange={handleChangeUser}
													defaultValue={formData?.users?.map((user) => {
														return { value: user.id, label: user.fullname };
													})}
												/>
												{errors.users && <span className="invalid">{errors.users.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>

				<Modal isOpen={modal.permission} toggle={() => setModal({ permission: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("roles.edit")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onPermissionAssginSubmit)}>
									<Row>
										<Col md="12">
											<FormGroup>
												<table className="table table-striped">
													<thead>
														<tr>
															<th scope="col">Permisson Name</th>
															{permissionMethods.map((method, key) => {
																return (
																	<th key={key} scope="col" className={"text-center"}>
																		{method}
																	</th>
																);
															})}
														</tr>
													</thead>
													<tbody>
														{permissionGroup.map((permission, key) => {
															return (
																<tr key={key}>
																	<th scope="row">{permission.name}</th>
																	{permissionMethods.map((methodSort, methodIndex) => {
																		let findMethod = permission?.method.find((method) => method == methodSort);
																		if (findMethod) {
																			const permissionString = `${permission.name}-${findMethod}`;
																			return (
																				<td key={methodIndex + key} className={"text-center"}>
																					<input type="checkbox" name={permissionString} onChange={handleChange} defaultChecked={rolePermission.find((permission) => permissionString == permission.name)} />
																				</td>
																			);
																		} else {
																			return <td key={methodIndex + key}> </td>;
																		}
																	})}
																</tr>
															);
														})}
													</tbody>
												</table>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</Content>
		</React.Fragment>
	);
};
export default RoleList;
