import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: 'api/settings/project'
 */
export async function getProject() {
	try {
		const endpoint = generateApiEndpoint("settings/project");
		const response = await restApiClient.get(endpoint);
		return response;
	} catch (error) {
		console.log("error", error?.response?.data);
		return error?.response?.data;
	}
}

/**
 * GET: 'api/settings/project/list'
 */
export async function getPrivateProject() {
	try {
		const endpoint = generateApiEndpoint("settings/project/list");
		const response = await restApiClient.get(endpoint);
		return response;
	} catch (error) {
		console.log("error", error?.response?.data);
		return error?.response?.data;
	}
}

/**
 * POST: 'api/settings/project'
 */
export async function postProject(data) {
	console.log("data", data);
	if (data) {
		try {
			const endpoint = generateApiEndpoint("settings/project");
			const response = await restApiClient.post(endpoint, data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			console.log("response", response);
			return response;
		} catch (error) {
			console.log("error", error?.response?.data);
			return error?.response?.data;
		}
	} else {
		return "Please fill missing the fields";
	}
}

/**
 * POST: 'api/settings/reapitoken'
 */
export async function generateReApiToken() {
	const endpoint = generateApiEndpoint("settings/reapitoken");

	const response = await restApiClient.post(endpoint);

	return response.data;
}

/**
 * GET: 'api/settings/smtp'
 */
export async function getSmtp() {
	try {
		const endpoint = generateApiEndpoint("settings/smtp");
		const response = await restApiClient.get(endpoint);
		return response?.data;
	} catch (error) {
		console.log("error", error?.response?.data);
		return error?.response?.data;
	}
}

/**
 * GET: 'api/settings/smtp'
 */
export async function postSmtp(data) {
	try {
		const endpoint = generateApiEndpoint("settings/smtp");
		const response = await restApiClient.post(endpoint, data);
		return response?.data;
	} catch (error) {
		console.log("error", error?.response?.data);
		return error?.response?.data;
	}
}
