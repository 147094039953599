import React from "react";
import { useTranslation } from "react-i18next";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import Icon from "../icon/Icon";

const FacebookPagination = ({ nextToken, prevToken, paginate }) => {
	const { t, i18n } = useTranslation("common");

    const nextPage = () => {
		paginate("next", nextToken);
	};

	const prevPage = () => {
		paginate("prev", prevToken);
	};

	return (
		<Pagination>
			{ nextToken || prevToken ?
			<>
			<PaginationItem disabled={!prevToken}>
				<PaginationLink
					className="page-link-prev"
					onClick={(ev) => {
						ev.preventDefault();
						prevPage();
					}}
					href="#prev">
					<Icon name="chevrons-left" />
					<span>{t("general.prev")}</span>
				</PaginationLink>
			</PaginationItem>

			<PaginationItem disabled={!nextToken}>
				<PaginationLink
					className="page-link-next"
					onClick={(ev) => {
						ev.preventDefault();
						nextPage();
					}}
					href="#next">
					<span>{t("general.next")}</span>
					<Icon name="chevrons-right" />
				</PaginationLink>
			</PaginationItem>
			</> : null}
		</Pagination>
	);
};
export default FacebookPagination;
