import React, { createContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { profileServices } from "../../services"

export const PermissionContext = createContext();

export const PermissionContextProvider = (props) => {
	const [permissionList, setPermissionList] = useState([]);
	const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));

	const getPermission = async () => {
		const myData = await profileServices.getMe();
		if(myData?.payload?.permissions){
			const permissionData = myData?.payload?.permissions.map((item, index) => {
				return item.name;
			})
			setPermissionList(permissionData);
		}
	};

	useEffect(() => {
		getPermission();

		const interval = setInterval(() => {
			getPermission();
		}, 10000);

		return () => clearInterval(interval);
	}, []);


	return <PermissionContext.Provider value={{ contextPermission: [permissionList, setPermissionList] }}>{props.children}</PermissionContext.Provider>;
};