import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/channels'
 */
export async function getChannels() {
	const endpoint = generateApiEndpoint("channels?limit=all");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels/{channelId}'
 */
export async function getChannelById(channelId) {
	const endpoint = generateApiEndpoint(`channels/${channelId}?include=company,country,cms`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels'
 * Params: { page, limit }
 */
export async function getAllChannelsByCompanyAndPlatform({ company, platform }) {
	const endpoint = generateApiEndpoint(`channels?filter[company.id]=${company}&filter[platform]=${platform}&limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels'
 * Params: { page, limit }
 */
export async function getAllChannelsByPlatform({ platform }) {
	const endpoint = generateApiEndpoint(`channels?filter[platform]=${platform}&limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels'
 * Params: { page, limit }
 */
export async function getChannelsByPageAndLimit({ search }) {
	if(search.includes('?')) search = search.substring(1);
	const endpoint = generateApiEndpoint(`channels?${search ?? ''}&include=company,country,cms,language`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels'
 * Params: { page, limit }
 */
export async function getChannelsWithQuery(search) {
	const endpoint = generateApiEndpoint(`channels?${search ?? ''}&include=company,country,cms,language&limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/channels'
 * Params: { data: {
 *     company, platform, platform_id, country, channel_type, cms_id
 * }}
 */
export async function addChannel(data) {
	const endpoint = generateApiEndpoint("channels");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	return response.data;
}

/**
 * PUT: '/api/channels/{channelId}'
 * Params: { data: {
 *     name, guard_name
 * 	}}
 */
export async function updateChannel(channelId, data = null) {
	const endpoint = generateApiEndpoint(`channels/${channelId}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * DELETE: '/api/channels/{channelId}'
 */
export async function deleteChannel(channelId) {
	const endpoint = generateApiEndpoint(`channels/${channelId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels/{channelId}/auth'
 */
export async function getChannelAuthUrl(channelId, redirectUrl) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/auth?redirect_url=${redirectUrl}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels/{channelId}/users'
 */
 export async function getChannelUsers(channelId) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/users`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * DELETE: '/api/channels/{channelId}/users'
 */
 export async function removeChannelUser(channelId,body) {
	body['_method'] = "DELETE";
	const endpoint = generateApiEndpoint(`channels/${channelId}/users`);
	const response = await restApiClient.post(endpoint,body);

	return response.data;
}

/**
 * POST: '/api/channels/{channelId}/users'
 */
 export async function addChannelUser(channelId,body) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/users`);
	const response = await restApiClient.post(endpoint,body);

	return response.data;
}

/**
 * GET: '/api/channels'
 * Params: { page, limit, sortBy }
 */
 export async function getFilteredChannels({ page, limit, filterQuery, searchText }) {
	const endpoint = generateApiEndpoint(`channels?limit=${limit}&page=${page}&${filterQuery && filterQuery}&${searchText && searchText}&include=company,country,cms,language`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels'
 * Params: { page, limit, sortBy }
 */
 export async function getChannelsByPageAndLimitAndSort({ page, limit, sortBy }) {
	const endpoint = generateApiEndpoint(`channels?limit=${limit}&page=${page}&sort${sortBy}&include=company,cms,language`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/channels/{channelId}/credentials'
 */
 export async function updateChannelCredentials(channelId,body) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/credentials`);
	const response = await restApiClient.post(endpoint,body);

	return response.data;
 }

/**
 * GET: '/api/channels/{channelId}/{platform}'
 */
 export async function getChannelSettings(channelId ) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/youtube`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * PUT: '/api/channels/{channelId}/{platform}'
 */
 export async function updateChannelSettings(channelId, data = null) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/youtube`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * GET: '/api/channels/{channelId}/{playlists}'
 */
 export async function getChannelPlaylists(channelId, query = null) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists?${query ?? ""}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels/{channelId}/playlists/{playlistId}/items'
 */
 export async function getPlaylistItems(channelId, playlistId, query) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists/${playlistId}/items?${query ?? ""}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/channels/{channelId}/playlists/{playlistId}'
 */
 export async function getPlaylist(channelId, playlistId) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists/${playlistId}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/channels/{channelId}/playlists'
 */
 export async function insertPlaylist(channelId, data) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists`);
	const response = await restApiClient.post(endpoint, {
		...data
	});

	return response.data;
}

/**
 * PUT: '/api/channels/{channelId}/playlists/{playlistId}'
 */
 export async function updatePlaylist(channelId, playlistId, data) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists/${playlistId}`);
	const response = await restApiClient.put(endpoint, {
		...data
	});

	return response.data;
}

/**
 * POST: '/api/channels/{channelId}/playlists/{playlistId}/items'
 */
 export async function insertItemToPlaylist(channelId, playlistId, data) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists/${playlistId}/items`);
	const response = await restApiClient.post(endpoint, {
		...data
	});

	return response.data;
}

/**
 * PUT: '/api/channels/{channelId}/playlists/{playlistId}/items'
 */
 export async function playlistItemReorder(channelId, playlistId, itemId, data) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists/${playlistId}/items/${itemId}`);
	const response = await restApiClient.put(endpoint, {
		...data
	});

	return response.data;
}

/**
 * DELETE: '/api/channels/{channelId}/playlists/{playlistId}'
 */
 export async function deletePlaylist(channelId, playlistId) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists/${playlistId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}

/**
 * DELETE: '/api/channels/{channelId}/playlists/{playlistId}'
 */
 export async function deletePlaylistItem(channelId, playlistId, videoId) {
	const endpoint = generateApiEndpoint(`channels/${channelId}/playlists/${playlistId}/items/${videoId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}