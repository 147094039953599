import { parametersServices } from "../../../services";

export async function ProductTypes() {
	const productTypes = await parametersServices.getProductTypes();

	const columns = [
		{
			key: "id",
			name: "#",
			sort: 0,
			type: "id",
		},
		{
			key: "value",
			name: "static-parameters.title",
			sort: 1,
			type: "title",
		},
		{
			key: "key",
			name: "static-parameters.key",
			sort: 2,
			type: "title",
		},
	];
	const rows = [];

	await productTypes.payload?.map((productTypes, key) => {
		rows.push({
			id: key + 1,
			value: productTypes.value,
			key: productTypes.key,
		});
	});
	const tableData = {
		columns: columns,
		rows: rows,
	};
	return tableData;
}
