import config from "../config/environment";

/**
 * Generate full url for merzigo internal api.
 * @param {*} url
 */

export function generateApiEndpoint(url) {
	//console.log(`${localStorage["apiUrl"]}/api/${url}`)
	switch (process.env.REACT_APP_STATUS) {
		case "development":
			if (localStorage["apiUrl"])
				return `${localStorage["apiUrl"]}/api/${url}`
			else
				return `${process.env.REACT_APP_API_URL}/api/${url}`
		case "test":
		if (localStorage["apiUrl"])
				return `${localStorage["apiUrl"]}/api/${url}`
			else
				return `${process.env.REACT_APP_API_URL}/api/${url}`
		case "local":
		if (localStorage["apiUrl"])
				return `${localStorage["apiUrl"]}/api/${url}`
			else
				return `${process.env.REACT_APP_API_URL}/api/${url}`
		default:
			return `${process.env.REACT_APP_API_URL}/api/${url}`;
	}

}

export function generateReApiEndpoint(url) {
	return `${process.env.REACT_APP_REAPI_URL}/api/${url}`;
}

export function generateWebEndpoint(url) {
	return `${process.env.REACT_APP_API_URL}/${url}`;
}
