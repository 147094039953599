import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/cms'
 */
export async function getPlatformCms() {
	const endpoint = generateApiEndpoint("cms?limit=all");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/cms'
 * Params: { page, limit }
 */
export async function getPlatformCmsByPageAndLimit({ page, limit }) {
	const endpoint = generateApiEndpoint(`cms?limit=${limit}&page=${page}&include=company`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/cms'
 * Params: { data: {
 *     name, comapny_id, platform, platform_id, token
 * }}
 */
export async function addPlatformCms(data) {
	const endpoint = generateApiEndpoint("cms");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	return response.data;
}

/**
 * PUT: '/api/cms/{cmsId}'
 * Params: { data: {
 *     name, comapny_id, platform, platform_id, token
 * 	}}
 */
export async function updatePlatformCms(cmsId, data = null) {
	const endpoint = generateApiEndpoint(`cms/${cmsId}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * DELETE: '/api/cms/{cmsId}'
 */
export async function deletePlatformCms(cmsId) {
	const endpoint = generateApiEndpoint(`cms/${cmsId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}

/**
 * GET: '/api/cms/{cmsId}/auth'
 */
export async function getPlatformCmsAuthUrl(cmsId, redirectUrl) {
	const endpoint = generateApiEndpoint(`cms/${cmsId}/auth?redirect_url=${redirectUrl}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/cms/{cmsId}/channels/sync'
 */
export async function syncCmsChannels(cmsId) {
	const endpoint = generateApiEndpoint(`cms/${cmsId}/channels/sync`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/cms/{cmsId}/policies'
 */
export async function getCmsPolicies(cmsId) {
	const endpoint = generateApiEndpoint(`cms/${cmsId}/policies`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}
