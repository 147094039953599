import React, { useEffect, useState } from 'react'
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { BlockHeadContent, Icon, Button } from "./Component";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DateRangePicker, CustomProvider } from 'rsuite';
import tr from 'rsuite/locales/tr_TR';
import en from 'rsuite/locales/en_US';

export const DateSelect = (props) => {

    const { t } = useTranslation("common");
    const [timeRange, setTimerange] = useState("last-28");
    const [sm, updateSm] = useState(false);
    const [customDateRange, setCustomDateRange] = useState({
        status: false,
        inputStatus: false,
        dates: []
    });
    const [dates, setDates] = useState();

    const getDates = () => {
        const query = new URLSearchParams(props.searchParams);
        let dateTo = moment().subtract(1, 'days');
        let dateFrom;
        switch (timeRange) {
            case "last-7":
                setCustomDateRange(current => ({...current, status: false}));
                dateFrom = moment(dateTo).subtract(7, 'days');
                break;
            case "last-28":
                setCustomDateRange(current => ({...current, status: false}));
                dateFrom = moment(dateTo).subtract(28, 'days');
                break;
            case "last-90":
                setCustomDateRange(current => ({...current, status: false}));
                dateFrom = moment(dateTo).subtract(90, 'days');
                break;
            case "last-365":
                setCustomDateRange(current => ({...current, status: false}));
                dateFrom = moment(dateTo).subtract(365, 'days');
                break;
            case "custom":
                dateFrom = moment(customDateRange.dates[0]);
                dateTo = moment(customDateRange.dates[1]);
            default:
                break;
        }
        setDates({from: moment(dateFrom)?.format('DD.MM.YYYY'), to: moment(dateTo)?.format('DD.MM.YYYY')})
        query.set('from', dateFrom?.format('YYYY-MM-DD'));
        query.set('to', dateTo.format('YYYY-MM-DD'));
        props.setSearchParams(decodeURIComponent(query.toString()));
    }

    useEffect(() => {
        if (props.searchParams)
            getDates();
    }, [timeRange, props.searchParams, customDateRange.dates]);

    const onDatePickerChange = (result) => {
        setCustomDateRange({
            status: true,
            inputStatus: false,
            dates: result
        });
        setTimerange("custom");
    };

    useEffect(() => {
        const query = new URLSearchParams(props.searchParams);
        const hasDateFrom = query.has('from');
        const hasDateTo = query.has("to");
        if(hasDateFrom && hasDateTo){
            setCustomDateRange({
                status: true,
                inputStatus: false,
                dates: [query.get('from'), query.get('to')]
            })
            setTimerange("custom");
        }
    },[])

    return (
        <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle" style={customDateRange.inputStatus ? {width : '500px'} : {}}>
                <Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
                    <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <div className="form-label font-weight-bold">{(dates && !customDateRange.inputStatus) && (dates.from + " - " + dates.to) }</div>
                    <ul className="nk-block-tools g-3">
                        <li>
                            {customDateRange.inputStatus ?
                                <CustomProvider locale={localStorage['lang'] === "tr" ? tr : en}>
                                    <DateRangePicker ranges={[]} block defaultOpen onChange={(result) => onDatePickerChange(result)} placeholder={t('statistic.select_date_range')} before={new Date()} />
                                </CustomProvider>
                                :
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                                        <Icon className="d-none d-sm-inline" name="calender-date"></Icon>
                                        <span>
                                            {!customDateRange.status ?
                                                <span className="d-none d-md-inline">{t("statistic.last")} {timeRange.split("-")[1]} {t("statistic.days")} </span>
                                                : <span className="d-none d-md-inline">{t('statistic.custom')}</span>}
                                        </span>
                                        <Icon className="dd-indc" name="chevron-right"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <ul className="link-list-opt no-bdr">
                                            <li>
                                                <DropdownItem
                                                    href="#dropdownitem"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setTimerange("last-7")
                                                    }} >
                                                    {t("statistic.last")} 7 {t("statistic.days")}
                                                </DropdownItem>
                                            </li>
                                            <li>
                                                <DropdownItem
                                                    href="#dropdownitem"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setTimerange("last-28");
                                                    }} >
                                                    {t("statistic.last")} 28 {t("statistic.days")}
                                                </DropdownItem>
                                            </li>
                                            <li>
                                                <DropdownItem
                                                    href="#dropdownitem"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setTimerange("last-90")
                                                    }} >
                                                    {t("statistic.last")} 90 {t("statistic.days")}
                                                </DropdownItem>
                                            </li>
                                            <li>
                                                <DropdownItem
                                                    href="#dropdownitem"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setTimerange("last-365")
                                                    }} >
                                                    {t("statistic.last")} 365 {t("statistic.days")}
                                                </DropdownItem>
                                            </li>
                                            <li>
                                                <DropdownItem
                                                    href="#dropdownitem"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        setCustomDateRange(current => ({ ...current, inputStatus: true }));
                                                    }} >
                                                    Custom
                                                </DropdownItem>
                                            </li>
                                        </ul>
                                    </DropdownMenu>
                                </UncontrolledDropdown>}
                        </li>
                    </ul>
                </div>
            </div>
        </BlockHeadContent>
    )
}
