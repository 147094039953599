import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, UncontrolledDropdown, UncontrolledAlert, Badge } from "reactstrap";
import {
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Button,
	Row,
	Col,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableItem,
	DataTableRow,
	Icon,
	PaginationComponent,
	RSelect,
} from "../../components/Component";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { channelServices, companyService, parametersServices, platformcmsServices } from "../../services";
import youtubeLightLogo from "../../assets/images/platform/youtube/yt_logo_rgb_light.png"
import youtubeRedIcon from "../../assets/images/platform/youtube/youtube_social_icon_red.png"
import facebookIcon from "../../assets/images/platform/facebook/facebook-icon-sm.png"
import { FilterComponent } from "../../components/FilterComponent";

const Channel = () => {
	const history = useHistory();
   	const location = useLocation();
	let { search } = useLocation();
	const [searchParams, setSearchParams] = useState();
	const [loading, setLoading] = useState();

	useEffect(() => {
		location.search = searchParams;
		history.replace({ pathname: location.pathname, search: location.search});
		loadData();
	},[searchParams]); 

	const { t } = useTranslation("common");

	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});

	const [channelData, setChannelData] = useState([]);
	const [channelMeta, setChannelMeta] = useState({
		total: null,
	});

	const [companyRList, setCompanyRList] = useState([]);
	const [platformRList, setPlatformRList] = useState([]);
	const [cmsRList, setCmsRList] = useState([]);
	const [channelTypeRList, setChannelTypeRList] = useState([]);
	const [languageRList,setlanguageRList] = useState();

	const [sm, updateSm] = useState(false);
	const [modal, setModal] = useState({
		edit: false,
		add: false,
	});
	const [editId, setEditedId] = useState();
	const [formData, setFormData] = useState({
		company: "",
		platform: "",
		platform_id: "",
		channel_type: "",
		cms: "",
		title: "",
		status: "",
		language_id : ""
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);

	const getChannelAuthUrl = async (channelId) => {
		try {
			const channelAuthUrl = await channelServices.getChannelAuthUrl(channelId, window.location.href);
			if (channelAuthUrl) {
				window.open(channelAuthUrl?.payload?.url);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const query = new URLSearchParams(search);
		const hasPage = query.has('page');
		const hasLimit = query.has('limit'); 

		if(hasPage)
		setCurrentPage(Number(query.get('page')));
		else 
		query.append("page", currentPage);
		if(hasLimit)
		setItemPerPage(Number(query.get('limit')));
		else 
		query.append("limit", itemPerPage);
		
		setSearchParams(decodeURI(query.toString())) 
		history.replace({ pathname: location.pathname, search: decodeURI(query.toString()) })
		loadData();
	},[])

	const loadData = async () => {
		try {
			setLoading(true);
			const channel = await channelServices.getChannelsByPageAndLimit({ search : decodeURIComponent(location.search) });
			setChannelData([...channel.payload]);
			setChannelMeta({ total: channel.meta.total });
			if(currentPage > channel.meta.last_page)
			paginate(channel.meta.last_page);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.debug(error);
		}
	};

	useEffect(() => {
		getChannelTypeList();
		getCompanyList();
		getPlatformList();
		getCmsList();
		if (modal.add || modal.edit) {
			getLanguageList();
		}
	}, [modal.add, modal.edit]);

	const getCompanyList = async () => {
		const companyList = await companyService.getCompanies();
		if (companyList?.data?.payload?.length > 0) {
			const createRList = companyList.data.payload.map((company, key) => {
				return { value: company.id, label: company.name };
			});
			setCompanyRList(createRList);
			setAllFilterData(current => ({...current, 'company.id' : createRList }));
		}
	};

	const getCmsList = async () => {
		const cmsList = await platformcmsServices.getPlatformCms();
		if (cmsList?.payload?.length > 0) {
			const createRList = cmsList.payload.map((cms, key) => {
				return { value: cms.id, label: cms.name };
			});
			setCmsRList(createRList);
			console.log(createRList)
			setAllFilterData(current => ({...current, 'cms.id' : createRList}));
		}
	};

	const getPlatformList = async () => {
		const platformList = await parametersServices.getPlatforms();
		if (platformList?.payload?.length > 0) {
			const createRList = platformList.payload.map((platform, key) => {
				return { value: platform.key, label: platform.value };
			});
			setPlatformRList(createRList);
			setAllFilterData(current => ({...current, 'platform' : createRList}));
		}
	};

	const getChannelTypeList = async () => {
		const channelTypeList = await parametersServices.getChannelTypes();
		if (channelTypeList?.payload?.length > 0) {
			const createRList = channelTypeList.payload.map((channelType, key) => {
				return { value: channelType.value, label: channelType.value };
			});
			setChannelTypeRList(createRList);
			setAllFilterData(current => ({...current, 'channel_type' : createRList}));
		}
	};

	const getLanguageList = async () => {
		const languageList = await parametersServices.getLanguages();
		if (languageList?.payload?.length > 0) {
			const createRList = languageList.payload.map((language, key) => {
				return { value: language.id, label: language.name };
			});
			setlanguageRList(createRList);
		}
	};
	// function to reset the form
	const resetForm = () => {
		setFormData({
			company: "",
			platform: "",
			platform_id: "",
			channel_type: "",
			cms: "",
			title: "",
			status: "",
			language_id : ""
		});
		setAlertData({
			status: false,
			type: "info",
			icon: "alert-circle",
			message: t("error.unknown"),
		});
	};

	const handleChange = (result, e) => {
		setFormData({ ...formData, [e.name]: result.value });
	};

	// function to close the form modal
	const onFormCancel = () => {
		setModal({ edit: false, add: false });
		resetForm();
	};

	// submit function to add a new item
	const onFormSubmit = async (submitData) => {
		const { title, platform_id } = submitData;
		let submittedData = {
			title: title,
			platform: formData.platform,
			platform_id: platform_id,
 			company_id: formData.company, 
			cms_id: formData.cms,
/* 			country_id: formData.country, */
			channel_type: formData.channel_type,
			language_id : formData.language_id
		};
		try {
			const response = await channelServices.addChannel({
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// submit function to update a new item
	const onEditSubmit = async (submitData) => {
		const { title, platform_id } = submitData;
		let submittedData = {
			title: title,
			platform: formData.platform,
			platform_id: platform_id,
			company_id: formData.company,
			cms_id: formData.cms,
/* 			country_id: formData.country, */
			channel_type: formData.channel_type,
			language_id : formData.language_id
		};
		try {
			const response = await channelServices.updateChannel(editId, {
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// function that loads the want to editted data
	const onEditClick = (id) => {
		resetForm();
		channelData.forEach((item) => {
			if (item.id === id) {
				setFormData({
					company: item.company_id,
					companyValue: { value: item.company?.id, label: item.company?.name },
					platform: item.plaform,
					platformValue: { value: item.platform, label: item.platform },
					platform_id: item.platform_id,
					language_id : item.language?.id,
					languageValue : {value: item?.language?.id, label : item?.language?.name},
					channel_type: item.channel_type,
					channelTypeValue: { value: item.channel_type, label: item.channel_type },
					cms: item.cms_id,
					cmsValue: { value: item.cms?.id ?? item.cms_id, label: item.cms?.name ?? item.cms_id },
					title: item.title,
					status: item.status,
				});
				setEditedId(id);
				setModal({ edit: true }, { add: false });
			}
		});
	};

	// function to change to suspend property for an item
	const deleteChannel = async (id) => {
		try {
			await channelServices.deleteChannel(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	const { errors, register, handleSubmit } = useForm();

	// Get current list, pagination
	// const indexOfLastItem = currentPage * itemPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemPerPage;
	// const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
	const currentItems = channelData;

	// Change Page
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		const query = new URLSearchParams(search);
		query.set("page", pageNumber);
		query.set('limit', itemPerPage);
		setSearchParams(decodeURI(query.toString()));
	}

	const [allFilterData, setAllFilterData] = useState([]);
	const [parameters] = useState(["company.id", "platform", "channel_type", "cms.id",]);

	useEffect(() => {
		const setChannelSyncAlert = () => {
			if(search && channelData.length > 0){
				const query = new URLSearchParams(search);
				const channelId = query?.get('filter[channel_id]');
				const channelTitle = channelData?.find((ch) => ch.id === parseInt(channelId))?.title;
				const connectStatus = query?.get('connect_status');
					switch (connectStatus) {
						case '0':
							setAlertData({
								status: true,
								type: "danger",
								icon: "cross-circle",
								message: `${channelTitle ?? ''} ${t('content.channel.sync_error')}`,
							});
							break;
						case '1':
							setAlertData({
								status: true,
								type: "success",
								icon: "check-circle",
								message: `${channelTitle ?? ''} ${t('content.channel.sync_success')}`,
							});
							break;
						default:
							break;
					}}
		};
		setChannelSyncAlert();
	},[channelData])

	return (
		<React.Fragment>
			<Head title={t("content.channel.list")}></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle tag="h3" page>
								{t("content.channel.list")}
							</BlockTitle>
							<BlockDes className="text-soft">
								<p>
									{t("general.total")} {channelMeta.total ?? "0"} {t("general.channel")}.
								</p>
							</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
									<Icon name="menu-alt-r"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3">
										<li className="nk-block-tools-opt">
											<Button
												color="primary"
												className="btn-icon"
												onClick={() => {
													resetForm();
													setModal({ add: true });
												}}
											>
												<Icon name="plus"></Icon>
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>

				{alertData.status && !modal.add && !modal.edit ? (
					<Block>
						<BlockHeadContent>
							<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
								<Icon name={alertData.icon ?? "cross-circle"} />
								{alertData.message ?? t("error.unknown")}
							</UncontrolledAlert>
						</BlockHeadContent>
					</Block>
				) : null}

				<Block>

					<DataTable className="card-stretch">
					<FilterComponent
					type="title"
					history={history} 
					data={allFilterData} 
					parameters={parameters} 
					search={search} 
					setSearch={setSearchParams} 
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					itemPerPage={itemPerPage} 
					setItemPerPage={setItemPerPage} />

						<DataTableBody compact>
							<DataTableHead>
								<DataTableRow>
									<span className="sub-text">
										#
									</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">
										{t("content.channel.name")}
									</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">
										{t("general.language")}
									</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">
										{t("content.channel.company")}
									</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">
										{t("content.channel.cms")}
									</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">
										{t("content.channel.platform")}
									</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">
										{t("content.channel.is_token")}
									</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">
										{t("content.channel.channel_type")}
									</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">
										{t("content.channel.status")}
									</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text float-right">{t("content.channel.action")}</span>
								</DataTableRow>
							</DataTableHead>
							{/*Head*/}
							{currentItems.length > 0
								? currentItems.map((item, key) => {
									let channel_url;
									switch (item?.platform.toLowerCase()) {
										case "youtube":
											channel_url = `https://youtube.com/channel/${item?.platform_id}`;
											break;
										case "facebook":
											channel_url = `https://www.facebook.com/${item?.platform_id}`;
											break;
										default:
											channel_url = null;
											break;
									}
									return (
										<DataTableItem key={key}>
											<DataTableRow className="nk-tb-col-check">{item.id}</DataTableRow>
											<DataTableRow>
												<span className="tb-lead">{item?.title}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{item?.language?.name}</span>
											</DataTableRow>
											<DataTableRow>
												<span className="tb-lead">{item?.company?.name}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{item?.cms?.name ?? item?.cms_id}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<Link to={{ pathname: `${channel_url ?? null}` }} target="_blank">
													<Icon name={`${item?.platform.toLowerCase()}-fill`}></Icon>
													<span>{item?.platform}</span>
												</Link>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>
													{item.token_status ? (
														<Badge className="badge-dot" color="success">
															Success
														</Badge>
													) : (
														<Badge className="badge-dot" color="danger">
															Not Found
														</Badge>
													)}
												</span>
											</DataTableRow>
							
											<DataTableRow size="sm">
												<span>{item?.channel_type}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>
													<Badge className="badge-dot" color={item.status.toLowerCase() === "active" ? "success" : "danger"}>
														{item?.status}
													</Badge>
												</span>
											</DataTableRow>
											<DataTableRow className="nk-tb-col-tools">
												<ul className="nk-tb-actions gx-1">
													<li>
														<UncontrolledDropdown>
															<DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
																<Icon name="more-h"></Icon>
															</DropdownToggle>
															<DropdownMenu right>
																<ul className="link-list-opt no-bdr">
																	<li onClick={() => getChannelAuthUrl(item.id)}>
																		<DropdownItem
																			tag="a"
																			href="#connect"
																			onClick={(ev) => {
																				ev.preventDefault();
																			}}
																		>
																			{
																				item?.platform.toLowerCase() == "facebook" ?
																					<>
																						<img src={facebookIcon} width={"17px"} />
																						<span>&nbsp;&nbsp;&nbsp;{t("operations.connect-with-facebook")}</span>
																					</>
																				:
																					<>
																						<img src={youtubeRedIcon} width={"17px"} />
																						<span>&nbsp;&nbsp;&nbsp;{t("operations.connect-with-youtube")}</span>
																					</>
																			}
																		</DropdownItem>
																	</li>
																	<li>
																		<DropdownItem
																			tag="a"
																			href={`/content/channel/${item.id}/settings`}
																		>
																			<Icon name="setting"></Icon>
																			<span>{t("operations.settings")}</span>
																		</DropdownItem>
																	</li>
																	<li onClick={() => onEditClick(item.id)}>
																		<DropdownItem
																			tag="a"
																			href="#edit"
																			onClick={(ev) => {
																				ev.preventDefault();
																			}}
																		>
																			<Icon name="edit"></Icon>
																			<span>{t("operations.edit")}</span>
																		</DropdownItem>
																	</li>
																	<li
																		onClick={() => {
																			if (window.confirm(t("content.channel.delete-confirm"))) deleteChannel(item.id);
																		}}
																	>
																		<DropdownItem
																			tag="a"
																			href="#delete"
																			onClick={(ev) => {
																				ev.preventDefault();
																			}}
																		>
																			<Icon name="trash-fill"></Icon>
																			<span>{t("operations.delete")}</span>
																		</DropdownItem>
																	</li>
																</ul>
															</DropdownMenu>
														</UncontrolledDropdown>
													</li>
												</ul>
											</DataTableRow>
										</DataTableItem>
									);
								})
								: null}
						</DataTableBody>
						
						<div className="card-inner">
							{currentItems.length > 0 ? (
								<PaginationComponent itemPerPage={itemPerPage} totalItems={channelMeta.total} paginate={paginate} currentPage={currentPage} />
							) : !currentItems.length > 0 && !loading ? (
								<div className="text-center">
									<span className="text-silent">No data found</span>
								</div>
							) : null}
						</div>
					</DataTable>
				</Block>
				<Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("content.channel.add")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.name")}</label>
												<input
													className="form-control"
													type="text"
													name="title"
													defaultValue={formData.title}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.title && <span className="invalid">{errors.title.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.company")}</label>
												<RSelect name="company" options={companyRList} required onChange={handleChange} />
												{errors.company && <span className="invalid">{errors.company.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.platform")}</label>
												<RSelect name="platform" options={platformRList} required onChange={handleChange} />
												{errors.platform && <span className="invalid">{errors.platform.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.platform_id")}</label>
												<input
													className="form-control"
													type="text"
													name="platform_id"
													defaultValue={formData.platform_id}
													ref={register({
														pattern: {
															value: /^UC[\w-]{21}[AQgw]/i,
															message: t("content.channel.platform_id-invalid"),
														},
														required: t("operations.required"),
													})}
												/>
												{errors.platform_id && <span className="invalid">{errors.platform_id.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.cms")}</label>
												<RSelect name="cms" options={cmsRList} required onChange={handleChange} />
												{errors.cms && <span className="invalid">{errors.cms.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.channel_type")}</label>
												<RSelect name="channel_type" options={channelTypeRList} required onChange={handleChange} />
												{errors.channel_type && <span className="invalid">{errors.channel_type.message}</span>}
											</FormGroup>
										</Col>
									</Row>
{/* 									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("content.channel.location")}</label>
												<RSelect name="country" options={countryRList} required onChange={handleChange} />
												{errors.country && <span className="invalid">{errors.country.message}</span>}
											</FormGroup>
										</Col>
									</Row> */}
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("general.language")}</label>
												<RSelect name="language_id" options={languageRList} required onChange={handleChange} defaultValue={formData.languageValue} />
												{errors.language && <span className="invalid">{errors.language.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("content.channel.edit")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onEditSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.name")}</label>
												<input
													className="form-control"
													type="text"
													name="title"
													defaultValue={formData.title}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.title && <span className="invalid">{errors.title.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.company")}</label>
												<RSelect name="company" options={companyRList} required onChange={handleChange} defaultValue={formData.companyValue} />
												{errors.company && <span className="invalid">{errors.company.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.platform")}</label>
												<RSelect name="platform" options={platformRList} required onChange={handleChange} defaultValue={formData.platformValue} />
												{errors.platform && <span className="invalid">{errors.platform.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.platform_id")}</label>
												<input
													className="form-control"
													type="text"
													name="platform_id"
													defaultValue={formData.platform_id}
													ref={register({
														pattern: {
															value: /^UC[\w-]{21}[AQgw]/i,
															message: t("content.channel.platform_id-invalid"),
														},
														required: t("operations.required"),
													})}
												/>
												{errors.platform_id && <span className="invalid">{errors.platform_id.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.cms")}</label>
												<RSelect name="cms" options={cmsRList} required onChange={handleChange} defaultValue={formData.cmsValue} />
												{errors.cms && <span className="invalid">{errors.cms.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.channel.channel_type")}</label>
												<RSelect name="channel_type" options={channelTypeRList} required onChange={handleChange} defaultValue={formData.channelTypeValue} />
												{errors.channel_type && <span className="invalid">{errors.channel_type.message}</span>}
											</FormGroup>
										</Col>
									</Row>
{/* 									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("content.channel.location")}</label>
												<RSelect name="country" options={countryRList} required onChange={handleChange} defaultValue={formData.countryValue} />
												{errors.country && <span className="invalid">{errors.country.message}</span>}
											</FormGroup>
										</Col>
									</Row> */}
										<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("general.language")}</label>
												<RSelect name="language_id" options={languageRList} required onChange={handleChange} defaultValue={formData.languageValue} />
												{errors.language && <span className="invalid">{errors.language.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</Content>
		</React.Fragment>
	);
};
export default Channel;
