import { Modal, ModalBody, ModalHeader, ModalFooter, ListGroupItemHeading, ListGroupItem, ListGroupItemText, Button, ListGroup, Form, Row, Col, FormGroup, UncontrolledAlert, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RSelect, RAsyncSelect, Icon } from "../Component";
import { channelServices } from "../../services";
import { useEffect } from "react";

export const AddToPlaylistModal = (props) => {
    const { t } = useTranslation("common");

    const [alertData, setAlertData] = useState({
        status: false,
        type: "info",
        icon: "alert-circle",
        message: t("error.unknown"),
    });
    const [channelPlaylists, setChannelPlaylists] = useState();
    const [videoPlaylists, setVideoPlaylists] = useState();
    const [selectedPlaylists, setSelectedPlaylists] = useState();
    const [loading, setLoading] = useState(false);

    const getChannelPlaylists = async (search = null) => {
        let searchQuery = search ? "filter[search]="+search : null;
        try {
            const res = await channelServices.getChannelPlaylists(props?.ids.channelId, searchQuery);
            if (res?.payload?.length > 0) {
                const createRList = res.payload.map((item, key) => {
                    return { value: item.id, label: item.snippet?.title };
                });
                setChannelPlaylists(createRList);
                return createRList
            }
        } catch (err) {
            console.error(err);
        }
    }

    const handleSelectChange = (result) => {
        setSelectedPlaylists(result.map((res) => res.value));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(selectedPlaylists?.length > 1){
                setLoading(true);
                for(var selected of selectedPlaylists){
                    await channelServices.insertItemToPlaylist(props.ids.channelId, selected, { video_id: props.ids.id });

                }
            }else if(selectedPlaylists?.length === 1) {
                setLoading(true);
                await channelServices.insertItemToPlaylist(props.ids.channelId, selectedPlaylists?.[0], { video_id: props.ids.id });
            }else return;
            setAlertData({
                status: true,
                type: "success",
                icon: "check",
                message: t('error.success'),
            });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
        }
    }

    const onFormCancel = () => {
        props.toggle();
    }

    useEffect(() => {
        if (props.ids.channelId)
            getChannelPlaylists();
    }, [props.ids])

    useEffect(() => {
        setChannelPlaylists([]);
    }, [props.status])

	const playlistLoadOptions = async (
		inputValue: string,
		callback: (options: channelPlaylists[]) => void
	) => {
        let channelPlaylistsRList = await getChannelPlaylists(inputValue)
        callback(channelPlaylistsRList)
	};

    return (

        <Modal isOpen={props.status} toggle={() => props.toggle()}>
            <ModalHeader toggle={() => props.toggle()}>{t("content.channel.playlists.add_to_playlist")}</ModalHeader>
            <ModalBody>
                {alertData.status ? (
                    <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                        <Icon name={alertData.icon ?? "cross-circle"} />
                        {alertData.message ?? t("error.unknown")}
                    </UncontrolledAlert>
                ) : null}
                <Form className="gy-4" onSubmit={handleSubmit}>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <label className="form-label">{t('operations.playlists')}</label>
                                <RAsyncSelect isMulti defaultOptions={channelPlaylists} name="playlists" loadOptions={playlistLoadOptions} required onChange={handleSelectChange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                    <Button color="primary" size="md" type="submit">
                                        {loading ? <Spinner size="sm" color="light" /> : t("operations.save")}
                                    </Button>
                                </li>
                                <li>
                                    <a
                                        href="#cancel"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            onFormCancel();
                                        }}
                                        className="link link-light">
                                        {t("operations.cancel")}
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>

    );
};