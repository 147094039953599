import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import React from "react";
import { useTranslation } from "react-i18next";

export const Chart = ({ data, labels, column }) => {

    const { t, i18n } = useTranslation("common");
    const [dataSets,setDatasets] = useState();
    const [chartData,setChartData] = useState();

	const colors = ['#ca5c3d','#fd44f9', '#30187e','#2fe2ab','#68416e','#1da952', '#9e3e35', '#ee1a4a'];
    useEffect(() => {
        var seriesArray = [];
        if(data){
            for(var i = 0; i < data.length; i++){
                seriesArray.push({
                    label: data[i].title ? data[i].title : data[i].name ? data[i].name : data[i].fullname ,
                    color: colors[i],
                    borderWidth: 2,
                    borderColor: colors[i],
                    fill: true,
                    dash: 0,
                    pointRadius: 0,
                    lineTension: 0,
                    backgroundColor: "transparent",
                    pointBorderColor: "transparent",
                    pointBackgroundColor: "transparent",
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: colors[i],
                    pointBorderWidth: 2,
                    pointHoverRadius: 4,
                    pointHoverBorderWidth: 2,
                    pointHitRadius: 4,
                    data: data[i].data.series && data[i]?.data?.series[column],
                })
            };
            setDatasets(seriesArray);
        };
    },[data,column,labels])

    useEffect(() => {
        setChartData({
            labels : labels,
            dataUnit: "Views",           
            datasets: dataSets
        })
    },[dataSets])

    function numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }


    return (
        <React.Fragment>
            {chartData && <Line
            className="ecommerce-line-s4"
            data={chartData}
            options={{
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                hover: {
                    mode: 'index',
                    intersect: false
                },
                tooltips: {
                    enabled: true,
                    mode : 'label',
                    itemSort: function(a, b) {
                        return b.value - a.value;
                      },
                    intersect : false,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return `${data.datasets[tooltipItem.datasetIndex].label}: ${numberWithCommas(tooltipItem.yLabel)}`;
                        }
                    },
                    backgroundColor: "#1c2b46",
                    titleFontSize: 13,
                    titleFontColor: "#fff",
                    titleMarginBottom: 6,
                    bodyFontColor: "#fff",
                    bodyFontSize: 12,
                    bodySpacing: 4,
                    yPadding: 10,
                    xPadding: 10,
                    footerMarginTop: 0,
                    displayColors: false,
                },
                scales: {
                    yAxes: [
                        {
                            display: true,
                            position: "left" ,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11,
                                fontColor: "#000",
                                padding: 10,
                                callback: function (value) {
                                    return Intl.NumberFormat('en', {notation : 'compact'}).format(value);
                                },
                                min: 0,
                                stepSize: 9000,
                            },
                            gridLines: {
                                color: "rgba(82, 100, 132, 0.2)",
                                tickMarkLength: 0,
                                zeroLineColor: "rgba(82, 100, 132, 0.2)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            display: true,
                            stacked: false,
                            ticks: {
                                fontSize: 9,
                                fontColor: "#9eaecf",
                                source: "auto",
                                padding: 10,
                            },
                            gridLines: {
                                color: "transparent",
                                tickMarkLength: 0,
                                zeroLineColor: "transparent",
                            },
                        },
                    ],
                },
            }}
        />}
        </React.Fragment>
    
    );
};