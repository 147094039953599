import _ from "lodash";

const all = {
	env: process.env.REACT_APP_ENVIRONMENT || "development",
	urls: {
		app: process.env.PUBLIC_URL,
		// Since we use proxy for local development.
		// we don't need to set an api url.
		// Check package.json file proxy property.
		api: process.env.REACT_APP_API_URL,
	},
	// DEBUG, INFO, WARN, ERROR, OFF
	logLevel: "DEBUG",
};

// Export the config object based on the REACT_APP_ENVIRONMENT
// ==============================================
export default _.merge(all, require("./shared") || {});
