import React, { createContext, useState, useEffect } from "react";

export const UploadsContext = createContext({});

export const UploadsContextProvider = (props) => {
	const [videoFiles, setVideoFiles] = useState([]);
	const [activeProcess, setActiveProcess] = useState(false);
	const [percentage, setPercentage] = useState({});

	/*const useUpload = (newUpload) => {
		setUploads([...uploads, newUpload]);
	};*/

	const putVideoFile = (newVideoFile) => {
		//console.log("Context videoFiles: ",videoFiles);
		setVideoFiles([...videoFiles, newVideoFile]);
	};

	const removeVideoFile = (uniqueId) => {
		const videoListAfterRemove = [...videoFiles.filter((video) => video.fileUniqueId !== uniqueId)];
		setVideoFiles(videoListAfterRemove);
	};

	const startQuiueUpload = () => {
		if (!activeProcess && videoFiles.length > 0) {
			const lastVideoFile = videoFiles.find((file) => {
				return !file.isComplete();
			});
			if (lastVideoFile) {
				setActiveProcess(lastVideoFile.uniqueIdentifier);
				lastVideoFile.resumableObj.on("fileSuccess", function (file, message) {
					//message.payload.finish_update.payload.title
					//console.log('fileSuccess: complated', file, typeof message);
					window.onbeforeunload = false;
					setActiveProcess(false);
				});
				lastVideoFile.resumableObj.on("fileProgress", function (file) {
					let percent = (file.progress() * 100).toFixed();
					setPercentage({ ...percentage, [lastVideoFile.uniqueIdentifier]: percent });
				});
				lastVideoFile.resumableObj.on("fileError", function (file) {
					//setPercentage({ ...percentage, [lastVideoFile.uniqueIdentifier]: "error" });
					setActiveProcess(false);
				});
				window.onbeforeunload = () => true;
				setTimeout(() => {
					lastVideoFile.resumableObj.upload();
				}, 1000)
			} else {
				console.log("sırada yükleme bulunmuyor.");
			}
		}
	};

	useEffect(() => {
		if (activeProcess == false) {
			startQuiueUpload();
		}
	}, [activeProcess]);

	//Bu Kısım ilk video gelince çalışıyor ve multiupload yaparken ikinci video içinde active upload olmadığı için ayrıca çalışıyor.
	useEffect(() => {
		startQuiueUpload();
	}, [videoFiles]);
	/*if(videoFiles.length > 0){
		const lastVideoFile = videoFiles[videoFiles.length-1];
		setUploadList({...uploadList, [lastVideoFile?.uniqueIdentifier]: lastVideoFile.isComplete()})
	}*/

	return <UploadsContext.Provider value={{ videoFilesContext: [videoFiles, putVideoFile, removeVideoFile], processContext: [activeProcess, setActiveProcess], percentageContext: percentage }}>{props.children}</UploadsContext.Provider>;
};
