import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, UncontrolledDropdown } from 'reactstrap';
import { Icon, Button, Row, Col, RSelect, RAsyncSelect } from './Component';

export const FilterComponent = (props) => {
    //props: { data*, inputType*, search*, setSearch*, parameters*, currentPage*, itemPerPage*, setItemPerPage*, setSelectedItems

    const { t } = useTranslation("common");
    const [tablesm, updateTableSm] = useState(false);

    const {handleSubmit } = useForm();

    const [filterParams, setFilterParams] = useState([]);

    const handleFilter = (result, e) => {
        const query = new URLSearchParams(props.search);
        let hasName = query.has(`filter[${e.name}]`)
        if (hasName) {
            const currentParamValues = query.get(`filter[${e.name}]`)
            if (currentParamValues.includes(result.value)) return;
            else query.set(`filter[${e.name}]`, (currentParamValues + `,${result.value}`));
        } else query.set(`filter[${e.name}]`, result.value);
        if(props.currentPage !== 1){
            query.set('page', 1);
            props.setCurrentPage(1);
        }
        props.setSearch(decodeURI(query.toString()));
    };

    useEffect(() => {
        const query = new URLSearchParams(props.search);
        const filterParamsArray = [];
        if (query && props.data) {
            for (var param of props.parameters) {
                let hasParam = query.has(`filter[${param}]`);
                if (hasParam) {
                    const values = query.get(`filter[${param}]`).split(',')
                    if (values.length > 1) {
                        for (var value of values) {
                            let data = props.data[param]?.find((data) => data.value === Number(value)) ??
                                props.data[param]?.find((data) => data.value === value);
                            filterParamsArray.push({ value: data?.value, label: data?.label, name: param });
                        }
                    } else {
                        let data = props.data[param]?.find((data) => data.value === Number(values)) ??
                            props.data[param]?.find((data) => data.value == values);
                        filterParamsArray.push({ value: data?.value, label: data?.label, name: param });
                    }
                }
            }
            let hasSearch = query.has("filter[title]");
            if (hasSearch) {
                setSearchText(query.get("filter[title]"));
            }
            setFilterParams(filterParamsArray);
        };
    }, [props.search, props.data, props.currentPage, props.itemPerPage])

    // clears filterParams state
    const removeFilter = (name, value) => {
        const query = new URLSearchParams(props.search);
        const currentParamValues = query.get(`filter[${name}]`);
        if (currentParamValues?.includes(',')) {
            let result = currentParamValues.split(',').filter((param) => param != value);
            query.set(`filter[${name}]`, result);
        } else {
            query.delete(`filter[${name}]`);
        }
        props.setSearch(decodeURI(query.toString()));
    };

    const clearFilters = () => {
        let query = props.search.split('&')
        query = query.filter((q) => !q.includes("filter"));
        props.setSearch(query.join('&'));
        setSearchText();
    }

    // SEARCH SECTION
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState();

    const toggle = () => {setonSearch(current => !current); document.getElementById("search-input").focus();};

    const onFilterSearch = (e) => {
        setSearchText(e.target.value);
    };
    // onChange function for searching name
    const submitSearch = () => {
        const query = new URLSearchParams(props.search);
        if(props.currentPage !== 1){
            query.set('page', 1);
            props.setCurrentPage(1);
        }
        const hasSearch = query.has(`filter[${props.type}]`);
        if (hasSearch) query.set(`filter[${props.type}]`, onSearchText);
        else query.append(`filter[${props.type}]`, onSearchText);
        props.setSearch(decodeURI(query.toString()));
        toggle();
    };

    const clearSearch = () => {
        setSearchText();
        const query = new URLSearchParams(props.search);
        query.delete(`filter[${props.type}]`);
        props.setSearch(decodeURI(query.toString()));
    }

    const setLimit = (limit) => {
        props.setItemPerPage(limit);
        const query = new URLSearchParams(props.search);
        const hasLimit = query.has('limit');
        if (hasLimit) query.set("limit", limit);
        else query.append("limit", limit);
        props.setSearch(decodeURI(query.toString()));
    }

    return (
        <div className="card-inner position-relative card-tools-toggle">
            <div className="card-title-group">
                <div className="card-tools">
                    <div>
                        {(filterParams.length > 0 || onSearchText) &&
                            <Badge className="badge-sm" pill color="primary">
                                <a
                                    href="#reset"
                                    style={{ color: '#fff' }}
                                    onClick={(ev) => {
                                        ev.preventDefault();
                                        clearFilters();
                                    }}
                                    className="clickable"
                                >
                                    {t('settings:list-filter.reset')}
                                </a>
                            </Badge>}
                        {onSearchText &&
                            <Badge className="badge-sm ml-2" pill color="gray" style={{ cursor: 'pointer' }} onClick={() => clearSearch()}>
                                <Icon className="mr-1" name="cross-circle"></Icon>
                                {t("filter.search")}{onSearchText}
                            </Badge>
                        }
                        {filterParams && filterParams.map((param) =>
                            <Badge className="badge-sm ml-2" pill color="secondary" style={{ cursor: 'pointer' }} key={Math.random().toString(16).slice(2)} onClick={() => removeFilter(param.name, param.value)}>
                                <Icon className="mr-1" name="cross-circle"></Icon>
                                {t(`filter_component.${param.name}`).toLowerCase()} : {param.label}
                            </Badge>
                        )}
                    </div>
                </div>
                <div className="card-tools mr-n1">
                    <ul className="btn-toolbar gx-1">
                        {/** Search Button **/}
                        <li>
                            <a
                                href="#search"
                                onClick={(ev) => {
                                    ev.preventDefault();
                                    toggle();
                                }}
                                className="btn btn-icon search-toggle toggle-search"
                            >
                                <Icon name="search"></Icon>
                            </a>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        {/** Filter and Sorting Button **/}
                        <li>
                            <div className="toggle-wrap">
                                <Button
                                    className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                                    onClick={() => updateTableSm(true)}
                                >
                                    <Icon name="menu-right"></Icon>
                                </Button>
                                <div
                                    className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                                    <ul className="btn-toolbar gx-1">
                                        <li className="toggle-close">
                                            <Button className="btn-icon btn-trigger toggle"
                                                onClick={() => updateTableSm(false)}>
                                                <Icon name="arrow-left"></Icon>
                                            </Button>
                                        </li>
                                        <li>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="a"
                                                    className="btn btn-trigger btn-icon dropdown-toggle">
                                                    <div className={filterParams.length > 0 ? "dot dot-primary" : ""}></div>
                                                    <Icon name="filter-alt"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu
                                                    right
                                                    className="filter-wg dropdown-menu-xl"
                                                    style={{ overflow: "visible" }}
                                                >
                                                    <div className="dropdown-head">
                                                        <span className="sub-title dropdown-title">{t('filter_component.filter')}</span>
                                                    </div>
                                                    <div className="dropdown-body dropdown-body-rg">
                                                        <Row className="gx-6 gy-3">
                                                            {Object.keys(props?.data)?.map((data, index) =>
                                                                <Col key={Math.random().toString(16).slice(2)} size={(index === (Object.keys(props.data).length - 1)) ? "12" : "6"}>
                                                                    <FormGroup>
                                                                        <label className="overline-title overline-title-alt">{t(`filter_component.${data}`)}</label>
                                                                        {
                                                                            props?.inputType?.hasOwnProperty(data) && props?.inputType[data].type === "RAsyncSelect" ?
                                                                                <RAsyncSelect name={data} cacheOptions loadOptions={props?.inputType[data].method} placeholder={t(`filter_component.${data}`)}  onChange={handleFilter} defaultOptions noOptionsMessage={() => t('general.async-select-no-options')} />
                                                                                :
                                                                                <RSelect name={data} options={props.data[data]} placeholder={t(`filter_component.${data}`)} onChange={handleFilter} />
                                                                        }
                                                                    </FormGroup>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </div>

                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                        <li>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                                    <Icon name="setting"></Icon>
                                                </DropdownToggle>
                                                <DropdownMenu right className="dropdown-menu-xs">
                                                    <ul className="link-check">
                                                        <li>
                                                            <span>{t('settings:list-filter.show-title')}</span>
                                                        </li>
                                                        <li className={props.itemPerPage === 10 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setLimit(10);
                                                                }}
                                                            >
                                                                10
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={props.itemPerPage === 15 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setLimit(15);
                                                                }}
                                                            >
                                                                15
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={props.itemPerPage === 25 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setLimit(25);
                                                                }}
                                                            >
                                                                25
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={props.itemPerPage === 50 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setLimit(50);
                                                                }}
                                                            >
                                                                50
                                                            </DropdownItem>
                                                        </li>
                                                        <li className={props.itemPerPage === 100 ? "active" : ""}>
                                                            <DropdownItem
                                                                tag="a"
                                                                href="#dropdownitem"
                                                                onClick={(ev) => {
                                                                    ev.preventDefault();
                                                                    setLimit(100);
                                                                }}
                                                            >
                                                                100
                                                            </DropdownItem>
                                                        </li>
                                                    </ul>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            {/** Search Input Area **/}
            <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                    <div className="search-content">
                        <Button
                            className="search-back btn-icon toggle-search active"
                            onClick={() => {
                                toggle();
                            }}
                        >
                            <Icon name="arrow-left"></Icon>
                        </Button>
                        <Form onSubmit={handleSubmit(submitSearch)}>
                            <input
                                type="text"
                                id="search-input"
                                value={onSearchText ?? ""}
                                className="border-transparent form-focus-none form-control"
                                placeholder={t("settings:list-filter.search-placeholder")}
                                onChange={(e) => onFilterSearch(e)}
                            />
                            <Button type="submit" className="search-submit btn-icon">
                                <Icon name="search"></Icon>
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
