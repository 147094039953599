import React, { useEffect, useState } from "react";
import { Icon, DataTableHead, DataTableRow, DataTableItem,TooltipComponent } from "../../../Component";
import { reportServices } from "../../../../services";
import {LineChartMini} from "../../dashboard/LineChartMini";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useParams } from "react-router";
import { createBrowserHistory } from "history";


const TrafficChannel = ({dates}) => {

	const { t, i18n } = useTranslation("common");

	const [query,setQuery] = useState();
	const history = createBrowserHistory();

	const [totalList,setTotalList] = useState();
	const [timeseries,setTimeseries] = useState();

	useEffect(() => {
		const getStatistics = async (dates) => {
			const params = {
				"from": dates.from,
				"to": dates.to,
				"type": "totallist",
			};
			if(query){
				params.sort = query;
				params.order = query;
			}
			const total = await reportServices.getChannelData(params,query && query);
			var channels = total.payload.channels;
			channels.length = 3;
			setTotalList(channels);
		}
		if(dates) getStatistics(dates);
	}, [dates,query]);


	useState(async () => {
		const dateFrom = moment().startOf('month').subtract(7, 'month').format('YYYY-MM-DD');
		const dateTo = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD');
		const time = {
			"from": dateFrom,
			"to": dateTo,
			"type": "timeseries",
			"dimension" : "month"
		};
		const times = await reportServices.getChannelData(time);
		setTimeseries(times.payload.channels);
	},[])

	const format = (number, fixed = 2) => {
		var num = Number(number).toFixed(fixed);
		if(!isNaN(num)) return num
		else return "-"
	}

	const [sortDirection,setSortDirection] = useState(true);
	const [sortBy,setSortBy] = useState();

	const sortData = (sort) => {
		if (sortDirection) {
			setSortBy("+" + sort);
			setQuery(sort);
		} else {
			setSortBy("-" + sort);
			setQuery("-" + sort);
		}
		setSortDirection(prevSortDirection => !prevSortDirection);
	};

/* 	useEffect(() => {
		history.push({
			search: query
		  });
	},[query])
 */

    var viewNumberFormat = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
    });

    var watchTimeNumberFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

	return (
		<React.Fragment>
			<div className="card-inner mb-n2">
				<div className="card-title-group">
					<div className="card-title card-title-sm">
						<h6 className="title">{t("statistic.best_four_channels")}</h6>
						<p>{t("statistic.best_four_channels_subtext")}</p>
					</div>
				</div>
			</div>
			<div className="nk-tb-list is-loose traffic-channel-table">
				<DataTableHead>
					<DataTableRow sort={sortBy} isSortable className="nk-tb-channel">
						<span name="channels.title" onClick={(e) => sortData(e.target.attributes.name.textContent)} style={{cursor : 'pointer'}}>{t("statistic.channel_name")}</span>
					</DataTableRow>
					<DataTableRow sort={sortBy} isSortable className="nk-tb-prev-sessions">
						<span name="views" onClick={(e) => sortData(e.target.attributes.name.textContent)} style={{cursor : 'pointer'}}>{t("statistic.views")}</span>
					</DataTableRow>
					<DataTableRow sort={sortBy} isSortable className="nk-tb-sessions">
						<span name="emw" onClick={(e) => sortData(e.target.attributes.name.textContent)} style={{cursor : 'pointer'}}>{t("statistic.estimated_minutes_watched")}</span>
					</DataTableRow>
					<DataTableRow className="nk-tb-trend tb-col-sm">
						<span className="mr-1">{t("statistic.trend")}</span>
						<TooltipComponent  icon="help-fill" iconClass="card-hint" direction="left" id="tooltip-3" text={t("statistic.last") + " 6 " + t("statistic.months")} />
					</DataTableRow>
				</DataTableHead>
				{totalList && totalList.map((item) => {
					return (
						<DataTableItem className="nk-tb-item " key={item.id}>
							<DataTableRow className="nk-tb-channel">
								<span className="tb-lead">{item.title}</span>
							</DataTableRow>
							<DataTableRow className="nk-tb-prev-sessions">
								<span className="tb-sub tb-amount">
									<span>{viewNumberFormat.format(item.data?.view_sum, 0)}</span>
								</span>
							</DataTableRow>
							<DataTableRow className="nk-tb-sessions">
								<span className="tb-sub tb-amount">
									<span>{watchTimeNumberFormat.format(item.data?.emw_sum/60)}</span>
								</span>
							</DataTableRow>
			
							<DataTableRow className="nk-tb-trend">
								<div className="traffic-channel-ck ">
								{ timeseries && timeseries.find((channel) => channel.id === item.id)?.data ? <LineChartMini id={item.id} data={timeseries.find((channel) => channel.id === item.id)?.data}></LineChartMini> : <h4 className="text-center">No Data</h4> }
								</div>
							</DataTableRow>
						</DataTableItem>
					);
				})}
			</div>
		</React.Fragment>
	);
};
export default TrafficChannel;
