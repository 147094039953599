import React, { useContext, useState, useEffect } from "react";
import {  Form, FormGroup, ModalBody, UncontrolledAlert, ListGroup, ListGroupItem } from "reactstrap";
import {  Icon, Row, Col, Button,  RSelect } from "../../../components/Component";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import makeAnimated from "react-select/animated";
import ReactResumableJs from "./ReactResumableCc";
import { UploadsContext } from "../../../layout/context/UploadContext";
import { videoServices, parametersServices, companyService, channelServices } from "../../../services";
import debounce from "lodash.debounce";

const VideoCreateModal = (props) => {
	const { t } = useTranslation("common");
	const { modal, setModal } = props;
	const formRawData = {
		company: "",
		platform: "",
		channel: "",
		content: "",
		title: "",
		description: "",
		videoKind: "",
	};
	const alertRawData = {
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	};

	//const [uploads, useUpload] = useContext(UploadsContext);
	const { videoFilesContext, processContext } = useContext(UploadsContext);
	const [videoFiles, putVideoFile] = videoFilesContext;
	const [processStatus, setProcessStatus] = processContext;
	const [filenames,setFilenames] = useState("");
	//desc:cc:added access token parameter for each request.
	const accessToken = localStorage.getItem("accessToken");

	const [uploadFiles, setUploadsFile] = useState([]);
	const [formData, setFormData] = useState(formRawData);
	const [alertData, setAlertData] = useState(alertRawData);
	//const [files, setFiles] = useState([]);
	const [companyRList, setCompanyRList] = useState([]);
	const [videoKindRList, setVideoKindRList] = useState([]);
	const [platformRList, setPlatformRList] = useState([]);
	const [channelRList, setChannelRList] = useState([]);
	const [channelTokenStatuses,setChannelTokenStatuses] = useState([]);

	useEffect(() => {
		if (modal.create) {
			getCompanyList();
			getPlatformList();
			getVideoKindList();
		}
	}, [modal.create]);

	/*
	useEffect(() => {
		console.log("videoList Page - uploadFiles: ", uploadFiles);
	}, [uploadFiles]);
	*/

	const getCompanyList = async () => {
		const companyList = await companyService.getCompanies();
		if (companyList?.data?.payload?.length > 0) {
			const createRList = companyList.data.payload.map((company, key) => {
				return { value: company.id, label: company.name };
			});
			setCompanyRList(createRList);
		}
	};

	const getPlatformList = async () => {
		const platformList = await parametersServices.getPlatforms();
		if (platformList?.payload?.length > 0) {
			const createRList = platformList.payload.map((platform, key) => {
				return { value: platform.key, label: platform.value };
			});
			setPlatformRList(createRList);
		}
	};

	const getVideoKindList = async () => {
		const videoKindList = await parametersServices.getVideoKinds();
		if (videoKindList?.payload?.length > 0) {
			const createRList = videoKindList.payload.map((videoKind, key) => {
				return { value: videoKind.key, label: videoKind.value };
			});
			setVideoKindRList(createRList);
		}
	};

	const getChannelList = async () => {
		const channelList = await channelServices.getAllChannelsByCompanyAndPlatform({
			company: formData.company,
			platform: formData.platform,
		});
		if (channelList?.payload?.length > 0) {
			const createRList = channelList.payload.map((channel, key) => {
				return { value: channel.id, label: channel.title };
			});
			const tokenStatuses = channelList.payload.map((channel, key) => {
				return { id: channel.id, status: channel.token_status };
			});
			setChannelRList(createRList);
			setChannelTokenStatuses(tokenStatuses);
		}
	};

	useEffect(() => {
		if (formData.company && formData.platform) {
			getChannelList();
		}
	}, [formData.company, formData.platform]);

	const onFormSubmit = async (submitData) => {
		const tokenStatus = channelTokenStatuses.find((ch) => ch.id === formData.channel).status;
		if(!tokenStatus){
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: t('videos.upload.channel_not_connected_error'),
			});
			return;
		}
		const { content } = submitData;
		let submittedData = {
			//title: title,
			company_id: formData.company,
			platform: formData.platform,
			channel_id: formData.channel,
			inventory_uid: content,
			video_kind: formData.videoKind,
		};
		try {
			const configuredFiles2Upload = await uploadFiles.map(async (uploadFile, index) => {
					//console.log('upload Files in upload File: ', uploadFile);

					const video = await videoServices.addVideo({
						...submittedData,
						title: uploadFile.file.name ?? "file-name-not-found",
						identifier: uploadFile.uniqueIdentifier,
					});
					const videoId = await video?.payload?.id;
					if (videoId) {
						window.onbeforeunload = () => true;
						uploadFile.resumableObj.opts.target = `${process.env.REACT_APP_REAPI_URL}/api/videos/upload`;
						uploadFile.fileUniqueId = await videoId;
						//await putVideoFile(uploadFile);
					}
					return uploadFile;
			});

			configuredFiles2Upload.map((configuredfile) => {
				configuredfile.then((file) => {
					putVideoFile(file);
					return file;
				})
			})

			setModal({ create: false, processlist: true });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: t('videos.upload.success_add_queue'),
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// function to reset the form
	const resetForm = () => {
		setFormData(formRawData);
		setAlertData(alertRawData);
	};
	// function to close the form modal
	const onFormCancel = () => {
		setModal({ create: false });
		resetForm();
	};

	const handleChange = (result, e) => {
		setFormData({ ...formData, [e.name]: result.value });
	};

/* 	// handles ondrop function of dropzone
	const handleDropChange = (acceptedFiles) => {
		setFiles(
			acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			)
		);
	}; */

	const animatedComponents = makeAnimated();

	const { errors, register, handleSubmit } = useForm();

	// Get current list, pagination
	// const indexOfLastItem = currentPage * itemPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemPerPage;
	// const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
	//const currentItems = userData;

	/* // Change Page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const nextPage = () => {
		paginate(currentPage + 1);
	};
 */

	const fetchOptions = async (inputValue) => {
		if(inputValue.length > 0){
			const response = await channelServices.getChannelsWithQuery(`filter[title]=${inputValue}`);
			const createRList = response.payload.map((channel) => {
				return { value: channel.id, label: channel.title };
			});
			setChannelRList(createRList);
		}else {
			getChannelList();
		}
	  };

	const handleInputChange = debounce((inputValue) => {
		fetchOptions(inputValue);
	  }, 500);

	return (
		<ModalBody>
			<a
				href="#cancel"
				onClick={(ev) => {
					ev.preventDefault();
					onFormCancel();
				}}
				className="close"
			>
				<Icon name="cross-sm"></Icon>
			</a>
			<div className="p-2">
				<h5 className="title">{t("videos.new-upload")}</h5>
				{alertData.status ? (
					<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
						<Icon name={alertData.icon ?? "cross-circle"} />
						{alertData.message ?? t("error.unknown")}
					</UncontrolledAlert>
				) : null}
				<div className="mt-4">
					<Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
						<Row>
							<Col md="6">
								<FormGroup>
									<label className="form-label">{t("modules.company")}</label>
									<RSelect name="company" components={animatedComponents} options={companyRList} onChange={handleChange} required={true} />
									{errors.company && <span className="invalid">{errors.company.message}</span>}
								</FormGroup>
							</Col>
							<Col md="6">
								<FormGroup>
									<label className="form-label">{t("modules.platform")}</label>
									<RSelect name="platform" components={animatedComponents} options={platformRList} onChange={handleChange} required={true} />
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md="6">
								<FormGroup>
									<label className="form-label">{t("modules.channel")}</label>
									<RSelect 
									name="channel" 
									components={animatedComponents} 
									options={channelRList} 
									onChange={handleChange} 
									onInputChange={handleInputChange}
									noOptionsMessage={() => t("videos.upload.company&platform-unselected")} 
									required={true} />
								</FormGroup>
							</Col>
							<Col md="6">
								<FormGroup>
									<label className="form-label">{t("modules.content")} <a href={`/content/inventory${formData.company && `?filter[company.id]=${formData.company}`}`} rel="noreferrer" target={"_blank"}><Icon name="link-alt" /></a></label>
									<input
										className="form-control"
										name="content"
										type="text"
										autoComplete="off"
										required={true}
										ref={register({
											required: t("operations.required"),
										})}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md="12">
								<FormGroup>
									<label className="form-label">{t("modules.video-kind")}</label>
									<RSelect name="videoKind" components={animatedComponents} options={videoKindRList} onChange={handleChange} noOptionsMessage={() => t("videos.upload.video_kind")} required={true} />
								</FormGroup>
							</Col>
							{/*
							<Col md="6">
								<FormGroup>
									<label className="form-label">{t("videos.upload.title")}</label>
									<input
										className="form-control"
										name="title"
										type="text"
										required={true}
										ref={register({
											required: t("operations.required"),
										})}
									/>
								</FormGroup>
							</Col>
							*/}
						</Row>
						<Row>
							<Col size="12">
								<ReactResumableJs
									uploaderID="uploader"
									dropTargetID="drop-target"
									//filetypes={["*"]}
									fileAccept={["video/*"]}
									fileAddedMessage="Started!"
									headerObject={{
										"Access-Control-Request-Private-Network": "true",
										//desc:cc:added access token parameter for each request.
										"Authorization": `Bearer ${accessToken}`,
									}}
									completedMessage="Complete!"
									//service={`${process.env.REACT_APP_REAPI_URL}/api/videos/upload`}
									//textLabel="Uploaded files"
									previousText={t("videos.upload.drop-video-previous-text")}
									//disableDragAndDrop={false}}
									onFileSuccess={(file, message) => {
										//todo:cc:UploadContext fileSuccess function usage
										//console.log("onFileSuccess - file: ", file);
										//console.log("onFileSuccess - message: ", message);
										//window.onbeforeunload = false;
										//console.log("file: ", file);
										//console.log("message: ", message);
									}}
									onFileAdded={async (file, resumable) => {
										file.isError = false;
										window.onbeforeunload = () => true;
										setFilenames(current => [...current,file.fileName]);
										//console.log("onFileAdded - file: ", file);
										//console.log("onFileAdded - resumable: ", resumable);
										//console.log("------------------------------------");
										//resumable.upload();
										setUploadsFile([...uploadFiles, file]);
									}}
									onUploadErrorCallback={async (file, errorCount) => {
										file.isError = true
										//todo:cc:backend error message will be deleted after for prod
										console.log("errorCount: ", errorCount);
										console.log("Upload Error: ", file);
									}}
									maxFileSize={274877906944}
									chunkSize={4000000}
									maxFiles={25}
									simultaneousUploads={1}
									startButton={false}
									pauseButton={false}
									cancelButton={false}
									onStartUpload={(file, resumable) => {
										//console.log("onStartUpload - file: ", file);
										//console.log("onStartUpload - resumable: ", resumable);
										//resumable.upload();
									}}
									onCancelUpload={() => {
										window.onbeforeunload = false;
										//todo:cc:video delete endpoint will be run
									}}
									onPauseUpload={() => {
										//this.inputDisable = true;
									}}
									onResumeUpload={() => {
										//this.inputDisable = true;
									}}
								/>
								{filenames &&
								<label htmlFor="uploader">
								<ListGroup style={{maxHeight: '200px', overflow : 'auto'}}>
									{filenames.map((name,key) => 
										<ListGroupItem key={key}>{name}</ListGroupItem>									
								)}
								</ListGroup>
								</label>
								}
							</Col>
						</Row>
						<Row>
							<Col size="12">
								<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
									<li>
										<Button color="primary" size="md" type="submit">
											{t("operations.upload")}
										</Button>
									</li>
									<li>
										<a
											href="#cancel"
											onClick={(ev) => {
												ev.preventDefault();
												onFormCancel();
											}}
											className="link link-light"
										>
											{t("operations.cancel")}
										</a>
									</li>
								</ul>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</ModalBody>
	);
};

export default VideoCreateModal;
