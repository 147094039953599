import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Badge, UncontrolledAlert } from "reactstrap"
import {
	Button,
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Icon,
	Sidebar,
	UserAvatar,
	RSelect,
} from "../../components/Component";
import { TagsInput } from "react-tag-input-component";
import { useHistory, useLocation } from "react-router";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { findUpper } from "../../utils/Utils";
import { channelServices, profileServices, parametersServices } from "../../services";
import { PlaylistTab } from "../../components/channel/PlaylistTab";
import classNames from "classnames";
import { MultiSelect } from "../../components/MultiSelect";
import { Link } from "react-router-dom";

const YoutubeVideoCategory = [
	{
		"kind": "youtube#videoCategory",
		"etag": "JherwDYjWkAPxocPXFkk3hG7k0U",
		"id": "1",
		"snippet": {
			"title": "Film ve Animasyon",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "QT2Q8C9K8F_O2nUNHnGzYfuClik",
		"id": "2",
		"snippet": {
			"title": "Otomobiller ve Araçlar",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "ZAeahNHKgMd4akwJx2REUkCHXgo",
		"id": "10",
		"snippet": {
			"title": "Müzik",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "F3b-IfpBKoIzIyNeYUiBJGTfRQ0",
		"id": "15",
		"snippet": {
			"title": "Ev Hayvanları ve Hayvanlar",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "ZB1qChwb--bHtsmS6DNuBFxFepk",
		"id": "17",
		"snippet": {
			"title": "Spor",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "E9VW6A7LetXyxlf52OKga70a1D8",
		"id": "18",
		"snippet": {
			"title": "Kısa Filmler",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "7CLK_ZOaaZpfiWyTwURnDUVL_DA",
		"id": "19",
		"snippet": {
			"title": "Seyahat ve Etkinlikler",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "cOxkhf1co0JvicmvSzwqabOAtdk",
		"id": "20",
		"snippet": {
			"title": "Oyun",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "0cPuQJsgaDPDzxpU6MGzMbKk08o",
		"id": "21",
		"snippet": {
			"title": "Video blogu",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "OHzvpzN-KKHln8wOJ7kFy-rpRJE",
		"id": "22",
		"snippet": {
			"title": "Kişiler ve Bloglar",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "eZGq5tzdruhszBJTtCBXizhxywo",
		"id": "23",
		"snippet": {
			"title": "Komedi",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "oOUyKFRfeoXSvSJJK8JSeY_axhs",
		"id": "24",
		"snippet": {
			"title": "Eğlence",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "iKACeXRXBbmesbNgyA77vqA38aQ",
		"id": "25",
		"snippet": {
			"title": "Haberler ve Politika",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "kDEAteIjxLqTFaYO-QOR6hDYYn4",
		"id": "26",
		"snippet": {
			"title": "Nasıl Yapılır ve Stil",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "fokLGKkYWrdu1zvLQKc35JvG9sw",
		"id": "27",
		"snippet": {
			"title": "Eğitim",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "TLOzzcr78c9x7vUgVmbBSZ2CJjY",
		"id": "28",
		"snippet": {
			"title": "Bilim ve Teknoloji",
			"assignable": true,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "bR3V3VHj86pOC1GBr6VLX-LuOIA",
		"id": "30",
		"snippet": {
			"title": "Filmler",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "cJ5lfaGGwHXd8Nd_dyFQ8_LhC9A",
		"id": "31",
		"snippet": {
			"title": "Anime/Animasyon",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "0JdA7gPqqpy0L7VumJinRVqxSV0",
		"id": "32",
		"snippet": {
			"title": "Aksiyon/Macera",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "Q30wjTdvKh5rdtByytnUuQFq5FE",
		"id": "33",
		"snippet": {
			"title": "Klasikler",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "dBgzufADy9LwONL0fcIJYoCoESs",
		"id": "34",
		"snippet": {
			"title": "Komedi",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "_skEnUWZAQ4ppPDRF6AAEPGCVog",
		"id": "35",
		"snippet": {
			"title": "Belgesel",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "eAl2b-uqIGRDgnlMa0EsGZjXmWg",
		"id": "36",
		"snippet": {
			"title": "Drama",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "xgy9vL-dINCzwqq4qyT2c8hyCu0",
		"id": "37",
		"snippet": {
			"title": "Aile",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "rf4qOBFsx-zZsqqbHpOvEshjnmc",
		"id": "38",
		"snippet": {
			"title": "Yabancı",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "bQoddpwYRDuM2Uum96IvbzvdqQs",
		"id": "39",
		"snippet": {
			"title": "Korku",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "iRG_Z9bBwkS_ro8EYd0PSl-NKg8",
		"id": "40",
		"snippet": {
			"title": "Bilim Kurgu/Fantastik",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "r6ILLWqJQc1V1cnEHlqfuCKCfSo",
		"id": "41",
		"snippet": {
			"title": "Gerilim",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "tO_xcxe6bE9H7Cnq877ro9xCVXE",
		"id": "42",
		"snippet": {
			"title": "Kısa Filmler",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "PmNQDKNVbvVsDgc6ED796oSeee8",
		"id": "43",
		"snippet": {
			"title": "Gösterimler",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	},
	{
		"kind": "youtube#videoCategory",
		"etag": "nnPE3CbxdYkc0JpIj0b1PhMNVTM",
		"id": "44",
		"snippet": {
			"title": "Fragmanlar",
			"assignable": false,
			"channelId": "UCBR8-60-B28hp2BmDPdntcQ"
		}
	}
];

const ChannelSettingsPage = ({ match }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	let { search } = useLocation();
	const query = new URLSearchParams(search);
	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});
	const [channelData, setChannelData] = useState({});
	const [defaultSettings, setDefaultSettings] = useState({});
	const [formData, setFormData] = useState({});
	const [teamUsers, setTeamUsers] = useState();
	const [sideBar, setSidebar] = useState(false);
	const [activeTab, setActivetab] = useState(query.get('activeTab') ?? "1");
	const [selected, setSelected] = useState([]);
	const [toBeRemoved, setToBeRemoved] = useState([]);
	const [toBeAdded, setToBeAdded] = useState([]);
	const [tags, setTags] = useState([]);
	const [unsentTagLength, setUnsentTagLength] = useState(0);

	const madeForKidsHandleChange = (event) => {
		let key = event.target.name;
		let value = event.target.value === 'true' ? true : false;
		setFormData({
			...formData,
			[key]: value,
		});
	}

	const checkboxHandleChange = (event) => {
		let key = event.target.name;
		let value = event.target.checked;
		setFormData({
			...formData,
			[key]: value,
		})
	}

	const selectboxHandleChange = (event) => {
		let key = event.target.name;
		let value = event.target.value;
		setFormData({
			...formData,
			[key]: value,
		})
	}

	const changeTags = (tags) => {
		setFormData({ ...formData, tags: tags });
	};

	const messages = {
		searchPlaceholder: t("content.channel.searchPlaceholder"),
		noItemsMessage: t("content.channel.noItemsMessage"),
		noneSelectedMessage: t("content.channel.noneSelectedMessage"),
		selectedMessage: t("content.channel.selectedMessage"),
		selectAllMessage: t("content.channel.selectAllMessage"),
		clearAllMessage: t("content.channel.clearAllMessage"),
		disabledItemsTooltip: t("content.channel.disabledItemsTooltip"),
	}

	// grabs the id of the url and loads the corresponding data
	useEffect(() => {
		let id = match.params.channelId;
		const loadVideoSettingsData = async () => {
			if (id !== undefined || null || "") {
				const channel = await channelServices.getChannelById(id);
				setChannelData(channel.payload);
				setDefaultSettings(JSON.parse(channel.payload.default_settings));
				const defSettings = JSON.parse(channel.payload.default_settings);
				formData.madeForKids = defSettings?.video_settings.status_made_for_kids ?? "";
				formData.madeForKids = defSettings?.video_settings.status_self_declared_made_for_kids ?? "";
				formData.categoryId = defSettings?.video_settings.snippet_category_id ?? "";
				formData.description = defSettings?.video_settings.snippet_description ?? "";
				setTags(defSettings?.video_settings.snippet_tags ? defSettings?.video_settings.snippet_tags : []);
				formData.embeddable = defSettings?.video_settings.status_embeddable ?? "";
				formData.publicStatsViewable = defSettings?.video_settings.status_public_stats_viewable ?? "";
			}
		}
		const loadChannelSettingsData = async () => {
			if (id !== undefined || id !== null || id !== "") {
				const channel = await channelServices.getChannelSettings(id);
				setChannelFormData({
					title: channel?.payload?.brandingSettings?.channel?.title,
					description: channel?.payload?.brandingSettings?.channel?.description,
					default_language: channel?.payload?.brandingSettings?.channel?.defaultLanguage,
					country: channel?.payload?.brandingSettings?.channel?.country,
					keywords: channel?.payload?.brandingSettings?.channel?.keywords?.split(" ")
				});
				setTags(channel?.payload?.brandingSettings?.channel?.keywords?.split(" ") ? channel?.payload?.brandingSettings?.channel?.keywords?.split(" ") : []);
			}
		}
		loadVideoSettingsData();
		loadChannelSettingsData();
	}, []);

	useState(async () => {
		const userId = JSON.parse(localStorage.getItem("authUser")).id;
		const channelId = match.params.channelId;
		const users = await channelServices.getChannelUsers(channelId);
		const team = await profileServices.getMyTeam();
		var teamList = []; var channelArr = []; var teamArr = []; var newArr = [];
		team.payload.forEach((mem) => mem.id !== userId && teamArr.push(mem.id));
		users.payload.forEach((mem) => mem.id !== userId && channelArr.push(mem.id));
		const ids = channelArr.filter(val => teamArr.includes(val));
		team.payload.forEach((mem) => mem.id !== userId && teamList.push({ id: mem.id, label: mem.fullname }));
		for (var i = 0; i < ids.length; i++) {
			newArr.push(teamList.find((tm) => tm.id === ids[i]));
		}
		setSelected(newArr);
		setTeamUsers(teamList);
		getLanguageList();
		getCountryList();
		history.replace({ pathname: search.pathname, search: decodeURI(query.toString()) });
	}, [])

	const onVideoDefaultSettingsSubmit = async (submitData) => {
		const { description } = submitData;
		let submittedData = {
			...defaultSettings,
			"video_settings": {
				snippet_category_id: formData.categoryId,//(int)
				snippet_description: description,//(string)
				snippet_tags: tags,//(array)
				status_embeddable: formData.embeddable,//(boolean)
				status_made_for_kids: formData.madeForKids,//(boolean)
				status_self_declared_made_for_kids: formData.madeForKids,//(boolean)
				status_public_stats_viewable: formData.publicStatsViewable,//(boolean)
			}
		};
		try {
			//console.log("submittedData: ",submittedData);
			const response = await channelServices.updateChannel(match.params.channelId, {
				default_settings: JSON.stringify(submittedData),
			});
			//console.log("response: ",response);
			response.message = response?.message === "" ? t("error.success") : response?.message;
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};


	const toggleTab = (tabId) => {
		query.set('activeTab', tabId);
		setActivetab(tabId);
		history.replace({ pathname: search.pathname, search: decodeURI(query.toString()) });
	};

	// function to toggle sidebar
	const toggle = () => {
		setSidebar(!sideBar);
	};

	const { errors, register, handleSubmit } = useForm();

	const onChangeState = async (selectedItems) => {
		var arr = { selectedItems: [], selected: [] };
		selectedItems.map((item) => arr.selectedItems.push(item.id));
		selected.map((item) => arr.selected.push(item.id));
		const removeId = arr.selected.filter(val => !arr.selectedItems.includes(val));
		if (selected.length > selectedItems.length) {
			let items = toBeRemoved;
			items.push(...removeId)
			setToBeRemoved(items);
			setSelected(selectedItems);
		} else if (selectedItems.length > selected.length) {
			setToBeAdded(arr.selectedItems)
			setSelected(selectedItems);
		}
	}

	const handleUserSubmit = async () => {
		const channelId = match.params.channelId;
		let body = {
			"user_ids": "",
			"type": "editor"
		}
		if (toBeAdded.length > 0) {
			body.user_ids = toBeAdded;
			try {
				const res = await channelServices.addChannelUser(channelId, body);
				res.message = res?.message === "success" ? t("error.success") : res?.message;
				setAlertData({
					status: true,
					type: "success",
					icon: "check-circle",
					message: res?.message,
				});
			} catch (err) {
				const { response } = err;
				setAlertData({
					status: true,
					type: "danger",
					icon: "cross-circle",
					message: response?.data?.message ?? err.message,
				});
			}
			setToBeAdded([]);
		}
		if (toBeRemoved.length > 0) {
			body.user_ids = toBeRemoved;
			try {
				const res = await channelServices.removeChannelUser(channelId, body);
				res.message = res?.message === "success" ? t("error.success") : res?.message;
				setAlertData({
					status: true,
					type: "success",
					icon: "check-circle",
					message: res?.message,
				});
			} catch (err) {
				const { response } = err;
				setAlertData({
					status: true,
					type: "danger",
					icon: "cross-circle",
					message: response?.data?.message ?? err.message,
				});
			}
			setToBeRemoved([]);
		}
	}
	const [channelFormData, setChannelFormData] = useState({
		title: "",
		description: "",
		default_language: "",
		country: "",
		keywords: []
	});
	const [languageRList, setLanguageRList] = useState();
	const [countryRList, setcountryRList] = useState();

	const getLanguageList = async () => {
		const languageList = await parametersServices.getLanguages();
		if (languageList?.payload?.length > 0) {
			const createRList = languageList.payload.map((language) => {
				return { value: language.id, label: language.name, iso: language.iso };
			});
			setLanguageRList(createRList);
		}
	};
	const getCountryList = async () => {
		const countryList = await parametersServices.getCountries();
		if (countryList?.payload?.length > 0) {
			const createRList = countryList.payload.map((country) => {
				return { value: country.id, label: country.name, code: country.code };
			});
			setcountryRList(createRList);
		}
	};

	const titleChange = (e) => {
		setChannelFormData({ ...channelFormData, title: e.target.value });
	}
	const languageChange = (result) => {
		setChannelFormData({ ...channelFormData, default_language: result.iso });
	}

	const countryChange = (result) => {
		setChannelFormData({ ...channelFormData, country: result.code });
	}

	const descriptionChange = (e) => {
		setChannelFormData({ ...channelFormData, description: e.target.value });
	}

	const changeKeywords = (keywords) => {
		setChannelFormData({ ...channelFormData, keywords: keywords });
	};

	const onChannelFormSubmit = async () => {
		const id = match.params.channelId;
		try {
			const response = await channelServices.updateChannelSettings(id, {
				...channelFormData,
			});
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				status: true,
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	}

	/* 	const [loginInfo,setLoginInfo] = useState({
			username : '',
			password : ''
		});
	
		const handleLoginInfoSubmit = async (e) => {
			e.preventDefault();
			const channelId = match.params.channelId;
			if(loginInfo.username && loginInfo.password){
				try {
					const res = await channelServices.updateChannelCredentials(channelId,loginInfo);
					res.message = res?.message === "success" ? t("error.success") : res?.message;
					setAlertData({
						status: true,
						type: "success",
						icon: "check-circle",
						message: res?.message,
					});
				} catch (err) {
					const {response} = err;
					setAlertData({
						status: true,
						type: "danger",
						icon: "cross-circle",
						message: response?.data?.message ?? err.message,
					});
				}
			}
		} */

	const [passwordState, setpasswordState] = useState(false);

	const handleOnKeyUp = async (event) => {
		let value = event.target.value;
		if (tags.toString().length + value.length > 500) return;
		let isDuplicate =
			tags.find((tag) => tag == value) || tags.find((tag) => tag == value.slice(0, -1)) ? true : false;
		const removeDuplicates = (multipleTags) => {
			let result = [];
			let uniqueTags = [];
			multipleTags.forEach((c) => {
				if (!uniqueTags.includes(c)) {
					uniqueTags.push(c);
				}
			});
			for (var tag of uniqueTags) {
				var duplicate = tags.find((t) => t === tag);
				if (!duplicate && tag !== "") result.push(tag);
			}
			return result;
		}
		if (event.key === "Enter" || event.key === "," || event.key === "Tab") {
			if (!isDuplicate && value.length > 0) {
				if (event.key === ",") {
					if (value.slice(0, -1).split(',').length > 1) {
						setTags(current => [...current, ...removeDuplicates(event.target.value.slice(0, -1).split(','))]);
						event.target.value = "";
					} else {
						setTags(current => [...current, event.target.value.slice(0, -1)]);
						event.target.value = "";
					}
				} else {
					if (value.split(',').length > 1) {
						setTags(current => [...current, ...removeDuplicates(event.target.value.split(','))]);
						event.target.value = "";
					} else {
						setTags(current => [...current, event.target.value]);
						event.target.value = "";
					}
				}
			}
			setUnsentTagLength(0);
		}
	};

	const handleOnKeyDown = async (event) => {
		var value = event.target.value
		if (!value && tags.length) {
			var newTagsArray = tags.slice(0, -1);
			setTags(newTagsArray);
		}
	}

	const removeTag = (tags, tag) => {
		var newTagsArray = tags.filter((t) => t !== tag)
		setTags(newTagsArray);
	};

	return (<React.Fragment>
		<Head title={t("content.channel.settings.title")}></Head>
		{channelData && (<Content>
			<BlockHead size="sm">
				<BlockBetween>
					<BlockHeadContent>
						<BlockTitle tag="h3" page>
							{t("content.channel.settings.title")} / <strong
								className="text-primary small">{channelData.title}</strong>
						</BlockTitle>
						<BlockDes className="text-soft">
							<ul className="list-inline">
								<li>
									{channelData.platform &&
										<p>
											Platform: <span className="text-base"> <Icon
												name={`${channelData.platform}-fill`}></Icon> {channelData.platform}</span>
										</p>
									}
								</li>
								<li>
									{channelData.platform_id &&
										<p>
											Channel ID: <span className="text-base">{channelData.platform_id}</span>
										</p>
									}
								</li>
							</ul>
						</BlockDes>
					</BlockHeadContent>
					<BlockHeadContent>
						<Button
							color="light"
							outline
							className="bg-white d-none d-sm-inline-flex"
							onClick={() => history.goBack()}
						>
							<Icon name="arrow-left"></Icon>
							<span>Back</span>
						</Button>
						<Link
							to="content/channel"
							className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
						>
							<Icon name="arrow-left"></Icon>
						</Link>
					</BlockHeadContent>
				</BlockBetween>
			</BlockHead>

			{alertData.status ? (
				<Block>
					<BlockHeadContent>
						<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
							<Icon name={alertData.icon ?? "cross-circle"} />
							{alertData.message ?? t("error.unknown")}
						</UncontrolledAlert>
					</BlockHeadContent>
				</Block>
			) : null}

			<Block>
				<Card className="card-bordered">
					{channelData.banner && <div className="card-content" style={{
						height: "230px",
						backgroundImage: "url(" + channelData.banner + ")",
						backgroundSize: "cover",
						backgroundPosition: "center",
						borderBottom: "1px solid #dbdfea"
					}}></div>}
					<div className="card-aside-wrap" id="user-detail-block">
						<div className="card-content">
							<Nav tabs className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
								<NavItem className="nav-item">
									<NavLink
										tag="a"
										className={classNames('nav-link', {
											active: activeTab === "1"
										})}
										href="#channel-settings"
										onClick={(ev) => {
											ev.preventDefault();
											toggleTab("1");
										}}
									>
										<Icon name="user-circle"></Icon>
										<span>{t('content.channel.settings.channel-tab')}</span>
									</NavLink>
								</NavItem>
								<NavItem className="nav-item">
									<NavLink
										tag="a"
										className={classNames('nav-link', {
											active: activeTab === "2"
										})}
										href="#video-settings"
										onClick={(ev) => {
											ev.preventDefault();
											toggleTab("2");
										}}
									>
										<Icon name="repeat"></Icon>
										<span>{t('content.channel.settings.video-tab')}</span>
									</NavLink>
								</NavItem>
								<NavItem className="nav-item">
									<NavLink
										tag="a"
										className={classNames('nav-link', {
											active: activeTab === "3"
										})}
										href="#user-management"
										onClick={(ev) => {
											ev.preventDefault();
											toggleTab("3");
										}}
									>
										<Icon name="users"></Icon>
										<span>{t('operations.users-assign')}</span>
									</NavLink>
								</NavItem>
								{/* 								<NavItem className="nav-item">
									<NavLink
										tag="a"
										className={"nav-link " + activeTab === "4" ? "active" : ""}
										href="#login-information"
										onClick={(ev) => {
											ev.preventDefault();
											toggleTab("4");
										}}>
										<Icon name="lock-alt-fill"></Icon>
										<span>{t('content.channel.settings.login-info.title')}</span>
									</NavLink>
								</NavItem> */}
								<NavItem className="nav-item">
									<NavLink
										tag="a"
										className={classNames('nav-link', {
											active: activeTab === "4"
										})}
										href="#channel-playlists"
										onClick={(ev) => {
											ev.preventDefault();
											toggleTab("4");
										}}>
										<Icon name="view-list"></Icon>
										<span>{t('operations.playlists')}</span>
									</NavLink>
								</NavItem>
								<li className="nav-item nav-item-trigger d-xxl-none">
									<Button className={`toggle btn-icon btn-trigger ${sideBar && "active"}`} onClick={toggle}>
										<Icon name="list-fill"></Icon>
									</Button>
								</li>
							</Nav>

							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									{/** Channel Settings */}
									<div className="card-inner">
										<Block>
											<BlockHead>
												<BlockTitle tag="h5">{t('content.channel.settings.channel.title')}</BlockTitle>
												<p>{t('content.channel.settings.channel.description')}</p>
												{!channelData.token_status &&
													<div className="alert alert-icon alert-danger" role="alert">
														<em className="icon ni ni-alert-circle" />
														{t('content.channel.settings.not_connected')}
													</div>
												}
											</BlockHead>
										</Block>
										<Block>
											<Form>
												<FormGroup>
													<label className="form-label">{t('content.channel.name')}</label>
													<input
														className="form-control form-control-lg"
														type="text"
														name="title"
														defaultValue={channelFormData.title}
														onChange={titleChange}
														ref={register({
															required: t("operations.required"),
														})}
														disabled={channelData.token_status ? false : true}
													/>
													{errors.description &&
														<span className="invalid">{errors.description.message}</span>}
												</FormGroup>
												<FormGroup>
													<label className="form-label">{t('content.channel.description')}</label>
													<textarea
														className="form-control form-control-lg"
														type="text"
														defaultValue={channelFormData.description}
														onChange={descriptionChange}
														name="channeldescription"
														maxLength="5000"
														ref={register({
															required: t("operations.required"),
														})}
														style={{ maxHeight: "960px", minHeight: "260px" }}
														disabled={channelData.token_status ? false : true}
													/>
													{errors.description &&
														<span className="invalid">{errors.description.message}</span>}
												</FormGroup>

												<FormGroup key={languageRList}>
													<label className="form-label">{t("content.inventory.language")}</label>
													<RSelect name="language" options={languageRList} required onChange={languageChange} defaultValue={languageRList?.find((lang) => lang.iso === channelFormData.default_language)} isDisabled={channelData.token_status ? false : true} />
													{errors.language && <span className="invalid">{errors.language.message}</span>}
												</FormGroup>
												<FormGroup key={countryRList}>
													<label className="form-label">{t("content.channel.settings.country")}</label>
													<RSelect name="country" options={countryRList} required onChange={countryChange} defaultValue={countryRList?.find((country) => country.code === channelFormData.country)} isDisabled={channelData.token_status ? false : true} />
													{errors.language && <span className="invalid">{errors.language.message}</span>}
												</FormGroup>
												<FormGroup key={formData.tags}>
													<label className="form-label">{t('content.channel.keywords')}</label>
													<label id="tags-wrapper" aria-labelledby="tags" className="wrapper form-control form-control-md">
														<span className="tags">
															{tags && tags.map((tag, key) =>
																<span className="tag my-auto mr-1" key={key}>{tag}
																	<Icon onClick={() => removeTag(tags, tag)} className="tag-remove" name="cross-sm"></Icon>
																</span>
															)}
														</span>
														<input
															id="input-tags"
															type="text"
															name="tags"
															placeholder={t('content.channel.keywords_ph')}
															onChange={(e) => setUnsentTagLength(e.target.value.length ?? 0)}
															onKeyDown={(e) => { if (e.key === "Tab") { e.preventDefault() } else if (e.key === "Backspace") { handleOnKeyDown(e) } }}
															onKeyUp={handleOnKeyUp}
															style={{ outline: '0', border: '0', width: '50%' }}
														/>
													</label>
													<div className="text-right"> <span className={(tags.toString().length + (unsentTagLength ?? 0)) < 500 ? "text-success" : "text-danger"}>{tags.toString().length + (unsentTagLength ?? 0)}</span> / 500 { }</div>
													{errors.tags && <span className="invalid">{errors.tags.message}</span>}
												</FormGroup>
												<FormGroup className={"mt-5"}>
													<Button onClick={(e) => (e.preventDefault(), onChannelFormSubmit(e))} color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</FormGroup>
											</Form>
										</Block>
									</div>
								</TabPane>
								<TabPane tabId="2">
									{/** Video Default Settings */}
									<div className="card-inner">
										<Block>
											<BlockHead>
												<BlockTitle tag="h5">{t('content.channel.settings.video.default-settings')}</BlockTitle>
												<p>{t('content.channel.settings.video.description')}</p>
												{!channelData.token_status &&
													<div className="alert alert-icon alert-danger" role="alert">
														<em className="icon ni ni-alert-circle" />
														{t('content.channel.settings.not_connected')}
													</div>
												}
											</BlockHead>
										</Block>
										{/*
										<Block>
											<ul>
												{defaultSettings['video_settings'].map((setting, key) => {
													return <li key={key}>
														<p><b>Name:</b> {setting.name} <b>Type:</b> {setting.type}</p>

													</li>
												})}
											</ul>
										</Block>
										*/}

										<Block>
											<Form onSubmit={handleSubmit(onVideoDefaultSettingsSubmit)}>
												<FormGroup>
													<label className="form-label">{t("videos.detail.description")}</label>
													<textarea
														className="form-control form-control-lg"
														type="text"
														name="description"
														defaultValue={defaultSettings?.video_settings?.snippet_description}
														maxLength="5000"
														ref={register({
															required: t("operations.required"),
														})}
														style={{ maxHeight: "960px", minHeight: "260px" }}
														disabled={channelData.token_status ? false : true}
													/>
													{errors.description &&
														<span className="invalid">{errors.description.message}</span>}
												</FormGroup>

												<FormGroup>
													<label className="form-label">{t("videos.detail.tags")}</label>

													<TagsInput
														value={defaultSettings?.video_settings?.snippet_tags ?? []}
														onChange={changeTags}
														name="tags"
														placeHolder="Video etiketlerini giriniz..."
														disabled={channelData.token_status ? false : true}
													/>
													{errors.tags && <span className="invalid">{errors.tags.message}</span>}
												</FormGroup>

												<FormGroup>
													<span className="preview-title overline-title">Bu video Çocuklara Özel mi?</span>
													<FormGroup>
														<div className={"custom-control custom-radio"}>
															<input id={"madeForKidsRadioYes"}
																type="radio"
																className={"custom-control-input form-control"}
																name={"madeForKids"}
																value={true}
																onChange={madeForKidsHandleChange}
																defaultChecked={defaultSettings?.video_settings?.status_made_for_kids === true ? true : false}
																disabled={channelData.token_status ? false : true}
															/>
															<label className="custom-control-label" htmlFor={"madeForKidsRadioYes"}>
																Evet, çocuklara özel
															</label>
														</div>
													</FormGroup>
													<FormGroup>
														<div className={"custom-control custom-radio"}>
															<input id={"madeForKidsRadioNo"}
																type="radio"
																className={"custom-control-input form-control"}
																name={"madeForKids"}
																value={false}
																onChange={madeForKidsHandleChange}
																defaultChecked={defaultSettings?.video_settings?.status_made_for_kids === false ? true : false}
																disabled={channelData.token_status ? false : true}
															/>
															<label className="custom-control-label" htmlFor={"madeForKidsRadioNo"}>
																Hayır, çocuklara özel değil
															</label>
														</div>
													</FormGroup>
												</FormGroup>

												<FormGroup>
													<span className="preview-title overline-title">Yerleştirmeye izin ver</span>
													<FormGroup>
														<div className={"custom-control custom-checkbox"}>
															<input name={"embeddable"}
																id={"embeddable"}
																type="checkbox"
																className={"custom-control-input form-control"}
																onChange={checkboxHandleChange}
																defaultChecked={defaultSettings?.video_settings?.status_embeddable === true ? true : false}
																disabled={channelData.token_status ? false : true}
															/>
															<label className="custom-control-label" htmlFor={"embeddable"}>
																Diğer kullanıcıların videonuzu sitelerine yerleştirmelerine izin verin.
															</label>
														</div>
													</FormGroup>
												</FormGroup>

												<FormGroup>
													<span className="preview-title overline-title">Kategori</span>
													<FormGroup>
														<select id="yt-video-category"
															name={"categoryId"}
															className="form-control"
															onChange={selectboxHandleChange}
															defaultValue={defaultSettings?.video_settings?.snippet_category_id}
															disabled={channelData.token_status ? false : true}
														>
															{YoutubeVideoCategory.sort((a, b) => {
																const nameA = a.snippet.title.toUpperCase(); // ignore upper and lowercase
																const nameB = b.snippet.title.toUpperCase(); // ignore upper and lowercase
																if (nameA < nameB) {
																	return -1;
																}
																if (nameA > nameB) {
																	return 1;
																}

																// names must be equal
																return 0;
															}).map((value, key) => {
																if (value.snippet.assignable) {
																	return <option key={key} value={value.id}>{value.snippet.title}</option>
																}

																return false;
															})}
														</select>
													</FormGroup>
												</FormGroup>

												<FormGroup>
													<span className="preview-title overline-title">Yorumlar ve puanlar</span>
													<FormGroup>
														<div className={"custom-control custom-checkbox"}>
															<input name={"publicStatsViewable"}
																id={"publicStatsViewable"}
																type="checkbox"
																className={"custom-control-input form-control"}
																onChange={checkboxHandleChange}
																defaultChecked={defaultSettings?.video_settings?.status_public_stats_viewable === true ? true : false}
																disabled={channelData.token_status ? false : true}
															/>
															<label className="custom-control-label"
																htmlFor={"publicStatsViewable"}>Videonun izleme sayfasındaki
																genişletilmiş video istatistiklerinin herkese açık olarak
																yayınlansın mı?</label>
														</div>
													</FormGroup>
												</FormGroup>

												<FormGroup className={"mt-5"}>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</FormGroup>
											</Form>
										</Block>

									</div>
								</TabPane>
								<TabPane tabId="3">
									{/** Channel Settings */}
									<div className="card-inner">
										<Block>
											<BlockHead>
												<BlockTitle tag="h5">{t('operations.users-assign')}</BlockTitle>
												{
													teamUsers &&
													<MultiSelect
														items={teamUsers}
														selectedItems={selected}
														onChange={onChangeState}
														messages={messages}
													/>

												}
												<FormGroup className={"mt-5"}>
													<Button onClick={handleUserSubmit} color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</FormGroup>
											</BlockHead>
										</Block>
									</div>
								</TabPane>
								{/* 								<TabPane tabId="4">
									<div className="card-inner">
										<Block>
											<BlockHead>
												<BlockTitle tag="h5">{t('content.channel.settings.login-info.title')}</BlockTitle>
												<Form id="login-info" onSubmit={handleLoginInfoSubmit}>
													<FormGroup style={{width : '50%'}}>
														<div className="form-label-group">
															<label className="form-label" htmlFor="default-01">
																Email
															</label>
														</div>
														<input
															type="text"
															className="form-control form-control-lg"
															onChange={(e) => setLoginInfo(current => ({...current, username : e.target.value}))}
															id="default-01"
															placeholder={t("forgotPassword.placeholder")}
															required
														/>
													</FormGroup>
													<FormGroup style={{width : '50%'}}>
														<div className="form-label-group">
															<label className="form-label" htmlFor="default-01">
															{t("login.password")}
															</label>
														</div>
													<div className="form-control-wrap">
														<a
															href="#password"
															onClick={(ev) => {
																ev.preventDefault();
																setpasswordState(!passwordState);
															}}
															className={`form-icon lg form-icon-right password-switch ${passwordState ? "is-hidden" : "is-shown"}`}
															required
														>
															{!passwordState ? <Icon name="eye" className="password-icon icon-show"></Icon> : <Icon name="eye-off" className="password-icon icon-hide"></Icon>}
														</a>
														<input
															type={passwordState ? "text" : "password"}
															className={`form-control-lg form-control ${passwordState ? "is-hidden" : "is-shown"}`}
															onChange={(e) => setLoginInfo(current => ({...current, password : e.target.value}))}
															id="default-01"
															placeholder={t("login.password-placeholder")}
															required
														/>
														</div>
													</FormGroup>
													<FormGroup className={"mt-2"}>
													<Button form="login-info" color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</FormGroup>
												</Form>
											</BlockHead>
										</Block>
									</div>
								</TabPane> */}
								{activeTab === "4" && 
								<PlaylistTab tokenStatus={channelData.token_status} />
								}
							</TabContent>

						</div>

						<Sidebar toggleState={sideBar}>
							<div className="card-inner">
								<div className="user-card user-card-s2 mt-5 mt-xxl-0">
									{channelData.cover
										?
										<UserAvatar className="lg" theme="primary" image={channelData.cover} />
										:
										<UserAvatar className="lg" theme="primary" text={findUpper(channelData.title)} />
									}
									<div className="user-info">
										<div
											className="badge badge-outline-light badge-pill ucap">{channelData.channel_type}</div>
										<h5>{channelData.title}</h5>
										<Badge className="badge-dot" pill color={channelData?.status?.toLowerCase() === "active" ? "success" : "danger"}>{channelData.status}</Badge>
									</div>
								</div>
							</div>
						</Sidebar>
						{sideBar && <div className="toggle-overlay" onClick={() => toggle()}></div>}
					</div>
				</Card>
			</Block>
		</Content>)}
	</React.Fragment>);
};
export default ChannelSettingsPage;
