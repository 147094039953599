import React, { useContext, useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, Row, Col, Button, RSelect, LanguageSelector } from "../../components/Component";
import { getDateStructured } from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../layout/context/LanguageContext";

const UserProfile = ({ sm, updateSm, authUser, setAuthUser }) => {
	const { t, i18n } = useTranslation("common");
	const { contextData } = useContext(LanguageContext);
	const [langData, setLangData] = contextData;

	const [modalTab, setModalTab] = useState("1");
	const [userInfo, setUserInfo] = useState(authUser);
	const [modal, setModal] = useState(false);

	return (
		<React.Fragment>
			<Head title={t("profile.profile")}></Head>
			<BlockHead size="lg">
				<BlockBetween>
					<BlockHeadContent>
						<BlockTitle tag="h4">{t("profile.personal-infos")}</BlockTitle>
						<BlockDes>
							<p>{t("profile.personal-infos-desc")}</p>
						</BlockDes>
					</BlockHeadContent>
					<BlockHeadContent className="align-self-start d-lg-none">
						<Button className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
							<Icon name="menu-alt-r"></Icon>
						</Button>
					</BlockHeadContent>
				</BlockBetween>
			</BlockHead>

			<Block>
				<div className="nk-data data-list">
					<div className="data-head">
						<h6 className="overline-title">{t("profile.basics")}</h6>
					</div>
					<div className="data-item" onClick={() => setModal(true)}>
						<div className="data-col">
							<span className="data-label">{t("users.fullname")}</span>
							<span className="data-value">{authUser.fullname}</span>
						</div>
						<div className="data-col data-col-end">
							<span className="data-more disable">
								<Icon name="lock-alt"></Icon>
							</span>
						</div>
					</div>
					<div className="data-item" onClick={() => setModal(true)}>
						<div className="data-col">
							<span className="data-label">{t("users.username")}</span>
							<span className="data-value">{authUser.username}</span>
						</div>
						<div className="data-col data-col-end">
							<span className="data-more disable">
								<Icon name="lock-alt"></Icon>
							</span>
						</div>
					</div>
					<div className="data-item">
						<div className="data-col">
							<span className="data-label">{t("users.email")}</span>
							<span className="data-value">{authUser.email}</span>
						</div>
						<div className="data-col data-col-end">
							<span className="data-more disable">
								<Icon name="lock-alt"></Icon>
							</span>
						</div>
					</div>
				</div>
				<div className="nk-data data-list">
					<div className="data-head">
						<h6 className="overline-title">{t("profile.preferences")}</h6>
					</div>
					<div className="data-item">
						<div className="data-col">
							<span className="data-label">{t("profile.language")}</span>
							<span className="data-value">{t("languages:" + langData.selectedLangId)}</span>
						</div>
						<div className="data-col data-col-end">
							<LanguageSelector>
								<a
									href="#language"
									onClick={(ev) => {
										ev.preventDefault();
									}}
									className="link link-primary"
								>
									{t("profile.change-language")}
								</a>
							</LanguageSelector>
						</div>
					</div>
				</div>
			</Block>
		</React.Fragment>
	);
};
export default UserProfile;
