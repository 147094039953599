import { Modal, ModalBody, ModalHeader, ModalFooter, ListGroupItemHeading, ListGroupItem, ListGroupItemText, Button, ListGroup } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const VideoDetailsModal = (props) => {
    const { t } = useTranslation("common");

    return(

    <Modal isOpen={props.status} toggle={() => props.toggle()}>
    <ModalHeader toggle={() => props.toggle()}>{t('videos.detail.page-title')}</ModalHeader>
    <ModalBody>
    <ListGroup>
          <ListGroupItem>
              <ListGroupItemHeading tag="b" className="mr-1">
                  {t('video_details_modal.create')}:
              </ListGroupItemHeading>
              <ListGroupItemText className="mr-1" tag="span">
                  {props.item?.uploader?.fullname ?? " - "}
              </ListGroupItemText>
              <ListGroupItemText tag="span">
                  {`(${moment.utc(props.item?.created_at).format('DD.MM.YYYY HH:mm ') !== "Invalid date" ? moment.utc(props.item?.created_at).local().format('DD.MM.YYYY HH:mm ') : " - "})`}
              </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
              <ListGroupItemHeading tag="b" className="mr-1">
                  {t('video_details_modal.system_upload')}:
              </ListGroupItemHeading>
              <ListGroupItemText tag="span">
                  {moment.utc(props.item?.uploaded_at).local().format('DD.MM.YYYY HH:mm ') ?? " - "}
              </ListGroupItemText>
          </ListGroupItem>

          <ListGroupItem>
              <ListGroupItemHeading tag="b" className="mr-1">
                  {t('video_details_modal.publisher')}:
              </ListGroupItemHeading>
              <ListGroupItemText className="mr-1" tag="span">
                  {props.item?.publisher?.fullname ?? " - "}
              </ListGroupItemText>
              <ListGroupItemText tag="span">
                  {`(${moment.utc(props.item?.published_at).format('DD.MM.YYYY HH:mm ') !== "Invalid date" ? moment.utc(props.item?.published_at).local().format('DD.MM.YYYY HH:mm ') : " - "})`}
              </ListGroupItemText>
      </ListGroupItem>

          <ListGroupItem>
              <ListGroupItemHeading tag="b" className="mr-1">
                  {t('video_details_modal.resolution')}:
              </ListGroupItemHeading>
              <ListGroupItemText tag="span">
                  {props.item?.detail?.dimensions ?? " - "}
              </ListGroupItemText>
      </ListGroupItem>

      <ListGroupItem>
          <ListGroupItemHeading tag="b" className="mr-1">
              {t('video_details_modal.system_log')}:
          </ListGroupItemHeading>
          <ListGroupItemText tag="span">
              {props.item?.upload_message ?? " - "}
          </ListGroupItemText>
      </ListGroupItem>

  </ListGroup>
    </ModalBody>
    <ModalFooter>
      <Button color="danger" onClick={() => props.toggle()}>{t('general.close')}</Button>
    </ModalFooter>
  </Modal>

    );
};