import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { useTranslation } from "react-i18next";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, PaginationComponent } from "../../components/Component";
import { Button, UncontrolledAlert } from "reactstrap";
import { notificationServices } from "../../services";
import { useHistory, useLocation } from "react-router";
import moment from 'moment';
import { FilterComponent } from "../../components/FilterComponent";

const Notifications = () => {

    const { t } = useTranslation("common");
    const history = useHistory();
    const location = useLocation();
    let { search } = useLocation();
    const [searchParams, setSearchParams] = useState();
    const [data, setData] = useState();
    const [meta, setMeta] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [allFilterData, setAllFilterData] = useState({
        model_type: [
            { id: 1, label: t('notifications.model_types.video'), value: "video" },
            { id: 2, label: t('notifications.model_types.product'), value: "product" },
            { id: 3, label: t('notifications.model_types.channel'), value: "channel" },
            { id: 4, label: t('notifications.model_types.cms'), value: "cms" },
        ]
    });
    const [parameters] = useState(["model_type"]);
    const [alertData, setAlertData] = useState({
        status: false,
        type: "info",
        icon: "alert-circle",
        message: t("error.unknown"),
    });

    const loadData = async () => {
        try {
            const res = await notificationServices.getNotifications(decodeURIComponent(location.search));
            setData(res.payload);
            setMeta(res.meta);
        } catch (err) {
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
        }
    };

    useEffect(() => {
        const query = new URLSearchParams(search);
        const hasPage = query.has('page');
        const hasLimit = query.has('limit');

        if (hasPage)
            setCurrentPage(Number(query.get('page')));
        else
            query.append("page", currentPage);
        if (hasLimit)
            setItemPerPage(Number(query.get('limit')));
        else
            query.append("limit", itemPerPage);

        setSearchParams(decodeURI(query.toString()))
        history.replace({ pathname: location.pathname, search: decodeURI(query.toString()) })
        loadData();
    }, []);

    useEffect(() => {
        location.search = searchParams;
        history.replace({ pathname: location.pathname, search: location.search });
        loadData();
    }, [searchParams]);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        const query = new URLSearchParams(search);
        query.set("page", pageNumber);
        query.set('limit', itemPerPage);
        setSearchParams(decodeURI(query.toString()));
    };

    const markAsRead = async (notificationId) => {
        try {
            await notificationServices.setNotificationAsRead(notificationId);
            loadData();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <React.Fragment>
            <Head title={t('notifications.notifications')} />
            <Content>
                <BlockHead size="sm">
                    <BlockBetween>
                        <BlockHeadContent>
                            <BlockTitle tag="h3" page>
                                {t("notifications.notifications")}
                            </BlockTitle>
                            <BlockDes className="text-soft">
                                <p>
                                    {t("notifications.total_notifications", { count: meta?.total ?? 0 })}
                                </p>
                            </BlockDes>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <Button
                                color="light"
                                outline
                                className="bg-white d-none d-sm-inline-flex"
                                onClick={() => history.push(`/`)}>
                                <Icon name="arrow-left"></Icon>
                                <span>{t('operations.back_to_home')}</span>
                            </Button>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>

                {alertData.status && (
                    <Block>
                        <BlockHeadContent>
                            <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                                <Icon name={alertData.icon ?? "cross-circle"} />
                                {alertData.message ?? t("error.unknown")}
                            </UncontrolledAlert>
                        </BlockHeadContent>
                    </Block>
                )}

                <DataTable className="card-stretch">
                    <FilterComponent
                        type="title"
                        history={history}
                        data={allFilterData}
                        parameters={parameters}
                        search={search}
                        setSearch={setSearchParams}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        itemPerPage={itemPerPage}
                        setItemPerPage={setItemPerPage} />
                    <DataTableBody compact>
                        <DataTableHead>
                            <DataTableRow>
                                <span className="sub-text">
                                    #
                                </span>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="sub-text">
                                    {t('notifications.title')}
                                </span>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="sub-text">
                                    {t('notifications.model_type')}
                                </span>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="sub-text">
                                    {t('notifications.readed_at')}
                                </span>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="sub-text">
                                    {t('notifications.created_at')}
                                </span>
                            </DataTableRow>
                            <DataTableRow>
                                <span className="sub-text">
                                    {t('general.action')}
                                </span>
                            </DataTableRow>
                        </DataTableHead>
                        {data?.length > 0 && data.map((item, key) =>
                            <DataTableItem key={key + Math.random() + 100101}>
                                <DataTableRow className="nk-tb-col-check">{item.id}</DataTableRow>
                                <DataTableRow>
                                    <span className="tb-lead">{item.title}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{t(`notifications.model_types.${item.model_type}`)}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{moment.utc(item?.readed_at).local().format('DD.MM.YYYY HH:mm ') !== 'Invalid date' ? moment.utc(item?.readed_at).local().format('DD.MM.YYYY HH:mm ') : "-"}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{moment.utc(item?.readed_at).local().format('DD.MM.YYYY HH:mm ') !== 'Invalid date' ? moment.utc(item?.created_at).local().format('DD.MM.YYYY HH:mm ') : "-"}</span>
                                </DataTableRow>
                                {item.readed_at === null ?
                                    <DataTableRow className="nk-tb-col-tools">
                                        <Icon onClick={() => markAsRead(item.id)} style={{ fontSize: '20px' }} name="check-circle-cut cursor-pointer" />
                                    </DataTableRow> :
                                    <DataTableRow className="nk-tb-col-tools"></DataTableRow>
                                }
                            </DataTableItem>
                        )}
                    </DataTableBody>
                    <div className="card-inner">
                        {data?.length > 0 ? (
                            <PaginationComponent itemPerPage={itemPerPage} totalItems={meta?.total} paginate={paginate} currentPage={currentPage} />
                        ) : !data?.length > 0 ? (
                            <div className="text-center">
                                <span className="text-silent">No data found</span>
                            </div>
                        ) : null}
                    </div>
                </DataTable>
            </Content>
        </React.Fragment>
    );
};

export default Notifications;
