import React from "react";
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { ProjectSettingsContextProvider } from "../../layout/context/ProjectSettingsContext";
import DeveloperMode from '../../images/extension/DeveloperMode.png'
import LoadUnpacked from '../../images/extension/LoadUnpacked.png'
import ExtensionInfo from '../../images/extension/ExtensionInfo.png'
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ExtensionInstructions = ({ ...props }) => {

    const {t, i18n} = useTranslation("common");
    const [copyOk, setCopyOk] = React.useState(false);

    const iconColor = copyOk ? '#0af20a' : '#000';
    const icon = copyOk ? 'check-circle-fill' : 'copy';

    const handleClick = (e) => {
        navigator.clipboard.writeText("chrome://extensions");
        setCopyOk(true);
        setTimeout(() => {
            setCopyOk(false);
        }, 500);
    }

    return (
        <ProjectSettingsContextProvider>
            <React.Fragment>
                <Head title={t("extension.instructions.title")} />
                <Content>
                    <div className="content-page wide-md m-auto">
                        <BlockHead size="lg" className="mx-auto">
                            <BlockHeadContent className="text-center">
                                <BlockTitle tag="h2" className="fw-normal">
                                    {t("extension.instructions.title")}
                                </BlockTitle>
                            </BlockHeadContent>
                        </BlockHead>
                        <Block>
                            <PreviewCard className="card-bordered">
                                <div className="entry">
                                    <h4>{t("extension.instructions.introduction")}</h4>
                                    <p>{t("extension.instructions.introduction_text")}</p>
                                    <h5>{t("extension.instructions.step1_title")}</h5>
                                    <p>
                                    {t("extension.instructions.step1_text")} <Link to="">{t("extension.instructions.step1_link")}</Link>
                                    </p>
                                    <h5>{t("extension.instructions.step2_title")}</h5>
                                    <p>
                                    {t("extension.instructions.step2_text")}
                                    </p>
                                    <h5>{t("extension.instructions.step3_title")}</h5>
                                    <p>{t("extension.instructions.step3_text")}</p>
                                    <div onClick={handleClick} style={{position : 'relative'}} className="wrapper">
                                        <Icon className="ml-1" style={{ color: iconColor, fontSize: '20px', cursor: 'pointer', position : 'absolute', top : '25%',  margin : 'auto 0' }}  name={icon} />
                                        <input style={{display : 'inline'}} className="form-control form-control-lg" disabled type="text" value="    chrome://extensions"></input>
                                    </div> <br/>
                                    <h5>{t("extension.instructions.step4_title")} </h5>
                                    <img src={DeveloperMode}></img>
                                    <p>{t("extension.instructions.step4_text")}</p>
                                    <h5>{t("extension.instructions.step5_title")} </h5>
                                    <img src={LoadUnpacked}></img>
                                    <p>{t("extension.instructions.step5_text")}</p>
                                    <h5>{t("extension.instructions.step6_title")} </h5>
                                    <img src={ExtensionInfo}></img>
                                    <p>{t("extension.instructions.step6_text")}</p>

                                </div>
                            </PreviewCard>
                        </Block>
                    </div>
                </Content>
            </React.Fragment>
        </ProjectSettingsContextProvider>
    );
};

export default ExtensionInstructions;
