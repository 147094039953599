import React, { useState, useEffect } from "react";
import { loginData, orderData, transactionData } from "./TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Icon from "../../components/icon/Icon";
import Button from "../../components/button/Button";
import { useTranslation } from "react-i18next";

const ParameterTable = ({ action, isCompact, data }) => {
	const { t, i18n } = useTranslation("common");

	const [tableData, setTableData] = useState({
		columns: [],
		rows: [],
	});

	useEffect(async () => {
		const awaitData = await data();
		setTableData({
			columns: awaitData?.columns,
			rows: awaitData?.rows,
		});
	}, []);

	useEffect(() => {
		const hasColumns = tableData.hasOwnProperty("columns");
		const hasRows = tableData.hasOwnProperty("rows");
		if (hasColumns && hasRows) {
			if (tableData.columns[0]?.hasOwnProperty("sort")) {
				tableData.columns.sort((a, b) => a.sort - b.sort);
			}
		}
	}, [tableData]);

	return (
		<table className={`table table-tranx ${isCompact ? "is-compact" : ""}`}>
			<thead>
				<tr className="tb-tnx-head">
					{tableData &&
						tableData?.columns?.map((column, columnKey) => {
							if (column.type == "id") {
								return (
									<th key={columnKey} className="tb-tnx-id">
										<span>{t(column.name)}</span>
									</th>
								);
							} else if (column.type == "title") {
								return (
									<th key={columnKey} className="tb-tnx-info">
										<span className="tb-tnx-desc">
											<span>{t(column.name)}</span>
										</span>
									</th>
								);
							} else if (column.type == "date") {
								return (
									<th key={columnKey} className="tb-tnx-info">
										<span className="tb-tnx-date">
											<span>{t(column.name)}</span>
										</span>
									</th>
								);
							}
						})}
				</tr>
			</thead>
			<tbody>
				{tableData &&
					tableData?.rows?.map((item, rowKey) => {
						return (
							<tr key={rowKey} className="tb-tnx-item">
								{tableData &&
									tableData?.columns?.map((column, columnKey) => {
										if (column.type == "id") {
											return (
												<td key={rowKey + columnKey} className="tb-tnx-id">
													<span>{item[column.key]}</span>
												</td>
											);
										} else if (column.type == "title") {
											return (
												<td key={rowKey + columnKey} className="tb-tnx-info">
													<div className="tb-tnx-desc">
														<span className="title">{item[column.key]}</span>
													</div>
												</td>
											);
										} else if (column.type == "date") {
											return (
												<th key={rowKey + columnKey} className="tb-tnx-info">
													<span className="tb-tnx-date">
														<span className="date">{item[column.key]}}</span>
													</span>
												</th>
											);
										}
									})}
							</tr>
						);
					})}
			</tbody>
		</table>
	);
};

export default ParameterTable;
