import React, { useEffect } from 'react'
import Content from '../../layout/content/Content';
import Head from '../../layout/head/Head';
import { useTranslation } from 'react-i18next'
import { Block, BlockBetween, BlockHead, BlockHeadContent, BlockTitle, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, PaginationComponent } from '../../components/Component';
import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, Row, UncontrolledAlert, UncontrolledDropdown } from 'reactstrap';
import { useState } from 'react';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { packageService, usersService } from '../../services';
import { generateWebEndpoint } from '../../utils/index';
import { FilterComponent } from '../../components/FilterComponent';
import { useHistory, useLocation } from 'react-router';

const MassUpload = () => {
    const { t } = useTranslation("common");

    let { search } = useLocation();
    const location = useLocation();
    const history = useHistory();
    const [searchParams, setSearchParams] = useState("");

    const [modal, setModal] = useState({ add: false, download: false });
    const [csvFile, setCsvFile] = useState();
    const [sm, updateSm] = useState(false);
    const [data, setData] = useState();
    const [meta, setMeta] = useState();
    const [alertData, setAlertData] = useState({
        status: false,
        type: "info",
        icon: "alert-circle",
        message: t("error.unknown"),
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [parameters] = useState(['type', 'status', 'uploaded_by']);
    const [allFilterData, setAllFilterData] = useState({
        status: [
            { id: 1, label: t("content.mass_upload.statuses.uploaded"), value: "uploaded" },
            { id: 2, label: t("content.mass_upload.statuses.processing"), value: "processing" },
            { id: 3, label: t("content.mass_upload.statuses.draft"), value: "draft" },
            { id: 4, label: t("content.mass_upload.statuses.error"), value: "error" },
            { id: 5, label: t("content.mass_upload.statuses.success"), value: "success" }
        ],
        type: [
            { id: 1, label: t("content.mass_upload.types.product"), value: "product" },
            { id: 2, label: t("content.mass_upload.types.inventory"), value: "inventory" },
        ]
    });

    useEffect(() => {
        location.search = searchParams;
        history.replace({ pathname: location.pathname, search: location.search });

        loadData();
    }, [searchParams]);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const hasPage = query.has('page');
        const hasLimit = query.has('limit');

        if (hasPage)
            setCurrentPage(Number(query.get('page')));
        else
            query.append("page", currentPage);
        if (hasLimit)
            setItemPerPage(Number(query.get('limit')));
        else
            query.append("limit", itemPerPage);

        setSearchParams(decodeURI(query.toString()))
        history.replace({ pathname: location.pathname, search: decodeURI(query.toString()) })

        if (searchParams?.includes('filter'))
            loadData();
        getUserList();
    }, []);

    const loadData = async () => {
        try {
            const res = await packageService.getPackages({ search: decodeURIComponent(location.search) });
            setData(res.payload);
            setMeta(res.meta);
        } catch (err) {
            console.error(err);
        }
    };

    const getUserList = async () => {
        const userList = await usersService.getUsers();
        if (userList?.payload?.length > 0) {
            const createRList = userList.payload.map((user, key) => {
                return { value: user.id, label: user.fullname };
            });
            setAllFilterData(current => ({ ...current, 'uploaded_by': createRList }));
        }
    };

    useEffect(() => {
        loadData();
    }, [])

    const onFormCancel = () => {
        setModal({ add: false, download: false });
    };

    const onFormSubmit = async (data) => {
        try {
            const formdata = new FormData();
            formdata.append("name", data.name);
            formdata.append("type", data.type);
            formdata.append("file", csvFile);
            const res = await packageService.postPackage(formdata);
            onFormCancel();
            setAlertData({
                status: true,
                type: "success",
                icon: "check-circle",
                message: res?.message,
            });
            loadData();
        } catch (err) {
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
            console.error(err);
        }
    };

    const deletePackage = async (packageId) => {
        try {
            const res = await packageService.deletePackage(packageId);
            loadData();
            setAlertData({
                status: true,
                type: "success",
                icon: "check-circle",
                message: res?.message,
            });
        } catch (err) {
            console.error(err);
            const { response } = err;
            setAlertData({
                status: true,
                type: "danger",
                icon: "cross-circle",
                message: response?.data?.message ?? err.message,
            });
        }
    };

    const onFileSelect = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            setCsvFile(e.target.files[0]);
        }
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        const query = new URLSearchParams(search);
        query.set("page", pageNumber)
        setSearchParams(decodeURI(query.toString()));
    };

    const { handleSubmit, register, errors } = useForm();

    return (
        <React.Fragment>
            <Head title={t("mainMenu.manage-content-mass-upload")}></Head>
            <Content>
                <BlockHead size="sm">
                    <BlockHeadContent className={"mb-4"}>
                        <BlockTitle tag="h3" page>
                            {t("mainMenu.manage-content-mass-upload")}
                        </BlockTitle>
                    </BlockHeadContent>
                    <BlockBetween>
                        <BlockHeadContent>
                            <Button
                                className="toggle d-none d-md-inline-flex"
                                color="primary"
                                onClick={() => {
                                    setModal({ download: true, add: false });
                                }}>
                                <span>{t('content.mass_upload.download_template')}</span>
                            </Button>
                        </BlockHeadContent>
                        <BlockHeadContent>
                            <div className="toggle-wrap nk-block-tools-toggle">
                                <Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
                                    <Icon name="menu-alt-r"></Icon>
                                </Button>
                                <div className="toggle-expand-content">
                                    <ul className="nk-block-tools g-3">
                                        <li className="nk-block-tools-opt">
                                            <Button
                                                color="primary"
                                                className="btn-icon"
                                                onClick={() => {
                                                    setModal({ add: true });
                                                }}
                                            >
                                                <Icon name="plus"></Icon>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </BlockHeadContent>
                    </BlockBetween>
                </BlockHead>
                {alertData.status && !modal.add && !modal.edit ? (
                    <Block>
                        <BlockHeadContent>
                            <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                                <Icon name={alertData.icon ?? "cross-circle"} />
                                {alertData.message ?? t("error.unknown")}
                            </UncontrolledAlert>
                        </BlockHeadContent>
                    </Block>
                ) : null}
                <Block>
                    <DataTable className="card-stretch">
                        <FilterComponent
                            type="search"
                            history={history}
                            data={allFilterData}
                            parameters={parameters}
                            search={search}
                            setSearch={setSearchParams}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemPerPage={itemPerPage}
                            setItemPerPage={setItemPerPage} />
                        <DataTableBody compact>
                            <DataTableHead>
                                <DataTableRow className="nk-tb-col-check">
                                    <span className="sub-text">#</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{t('content.mass_upload.name')}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{t('content.mass_upload.type')}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{t('content.inventory.status')}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <span>{t('content.inventory.action')}</span>
                                </DataTableRow>
                            </DataTableHead>
                            {data?.length > 0 &&
                                data.map((item) =>
                                    <DataTableItem>
                                        <div className={"nk-tb-col cursor-pointer"} onClick={() => history.push(`/content/mass-upload/package-items/${item.id}`)} >
                                            <span>{item.id}</span>
                                        </div>
                                        <div className={"nk-tb-col cursor-pointer"} onClick={() => history.push(`/content/mass-upload/package-items/${item.id}`)} >
                                            <span className='tb-lead'>{item.name}</span>
                                        </div>
                                        <div className={"nk-tb-col cursor-pointer"} onClick={() => history.push(`/content/mass-upload/package-items/${item.id}`)} >
                                            <span>{t(`content.mass_upload.types.${item.type}`)}</span>
                                        </div>
                                        <div className={"nk-tb-col cursor-pointer"} onClick={() => history.push(`/content/mass-upload/package-items/${item.id}`)} >
                                            <span>{t(`content.mass_upload.statuses.${item.status}`)}</span>
                                        </div>
                                        <DataTableRow className="nk-tb-col-tools">
                                            <ul className="gx-1">
                                                <li>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                                            <Icon name="more-h"></Icon>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <ul className="link-list-opt no-bdr">
                                                                <li
                                                                    onClick={() => {
                                                                        if (window.confirm(t("content.mass_upload.delete-confirm"))) deletePackage(item.id);
                                                                    }}>
                                                                    <DropdownItem
                                                                        tag="a"
                                                                        href="#delete"
                                                                        onClick={(ev) => {
                                                                            ev.preventDefault();
                                                                        }}>
                                                                        <Icon name="trash-fill"></Icon>
                                                                        <span>{t("operations.delete")}</span>
                                                                    </DropdownItem>
                                                                </li>
                                                            </ul>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </DataTableRow>
                                    </DataTableItem>
                                )}
                        </DataTableBody>
                        <div className="card-inner">
                            {data?.length > 0 ? (
                                <PaginationComponent itemPerPage={itemPerPage} totalItems={meta?.total} paginate={paginate} currentPage={currentPage} />
                            ) : (
                                <div className="text-center">
                                    <span className="text-silent">No data found</span>
                                </div>
                            )}
                        </div>
                    </DataTable>
                </Block>
            </Content>
            <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close"
                    >
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <div className="p-2">
                        <h5 className="title">{t("content.mass_upload.add")}</h5>
                        {alertData.status ? (
                            <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                                <Icon name={alertData.icon ?? "cross-circle"} />
                                {alertData.message ?? t("error.unknown")}
                            </UncontrolledAlert>
                        ) : null}
                        <div className="mt-4">
                            <Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
                                <FormGroup>
                                    <label className="form-label">{t("content.mass_upload.name")}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        ref={register({
                                            required: t("operations.required"),
                                        })}
                                    />
                                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md="12">
                                            <label className="form-label">{t("content.mass_upload.csv_file")}</label>
                                            <input type="file" id="thumbnail" className="form-control" accept="text/csv" name="file" onChange={(e) => onFileSelect(e)} />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md="12">
                                            <label className="form-label">{t("content.mass_upload.type")}</label>
                                            <select ref={register({})} name="type" className='form-control'>
                                                <option value="product">{t('content.mass_upload.product')}</option>
                                                <option value="inventory">{t('content.mass_upload.inventory')}</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <Row>
                                    <Col size="12">
                                        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                            <li>
                                                <Button color="primary" size="md" type="submit">
                                                    {t("operations.save")}
                                                </Button>
                                            </li>
                                            <li>
                                                <a
                                                    href="#cancel"
                                                    onClick={(ev) => {
                                                        ev.preventDefault();
                                                        onFormCancel();
                                                    }}
                                                    className="link link-light"
                                                >
                                                    {t("operations.cancel")}
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={modal.download} toggle={() => setModal({ download: false })} className='modal-dialog-centered' size="lg">
                <ModalBody>
                    <a
                        href="#cancel"
                        onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                        }}
                        className="close">
                        <Icon name="cross-sm"></Icon>
                    </a>
                    <h5 className="title">{t('content.mass_upload.download_template')}</h5>
                    <DataTable className={"my-2"}>
                        <DataTableBody compact>
                            <DataTableItem>
                                <DataTableRow>
                                    <span>{t('content.mass_upload.product')}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <Link to={{ pathname: generateWebEndpoint('package-upload/product-sample-file.csv') }} target="_blank">
                                        <Icon className={"cursor-pointer"} style={{fontSize : '20px'}} name="download" />
                                    </Link>
                                </DataTableRow>
                            </DataTableItem>
                            <DataTableItem>
                                <DataTableRow>
                                    <span>{t('content.mass_upload.inventory')}</span>
                                </DataTableRow>
                                <DataTableRow>
                                    <Link to={{ pathname: generateWebEndpoint('package-upload/inventory-sample-file.csv') }} target="_blank">
                                        <Icon className={"cursor-pointer"} style={{fontSize : '20px'}} name="download" />
                                    </Link>
                                </DataTableRow>
                            </DataTableItem>
                        </DataTableBody>
                    </DataTable>
                    <Row>
                        <Col size="12" className='my-2'>
                            <ul className="align-center flex-wrap justify-content-end flex-sm-nowrap gx-4 gy-2">
                                <li>
                                    <Button onClick={() => onFormCancel()} color="danger" size="md">
                                        {t("general.close")}
                                    </Button>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default MassUpload;
