import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/product-genres'
 * Params: { page, limit }
 */
export async function getProductGenres() {
	const endpoint = generateApiEndpoint(`product-genres?limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/ProductGenres'
 */
export async function get({ limit, page }) {
	const endpoint = generateApiEndpoint(`product-genres?limit=${limit}&page=${page}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/ProductGenres'
 */
export async function add(data) {
	const endpoint = generateApiEndpoint("product-genres");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	// TODO Change response payload on UI
	return response;
}

/**
 * PUT: '/api/ProductGenres/{id}'
 */
export async function update(id, data) {
	const endpoint = generateApiEndpoint(`product-genres/${id}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});

	// TODO Change response payload on UI
	return response;
}

/**
 * DELETE: '/api/ProductGenres/{id}'
 */
export async function destroy(id) {
	const endpoint = generateApiEndpoint(`product-genres/${id}`);
	const response = await restApiClient.delete(endpoint);

	// TODO Change response payload on UI
	return response;
}
