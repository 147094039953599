import React, { useEffect, useState } from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, Icon, PreviewCard, TooltipComponent } from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProjectSettingsContextProvider } from "../../layout/context/ProjectSettingsContext";

import { authService } from "../../services";

const Login = () => {
	const { t } = useTranslation("common");
	const [loading, setLoading] = useState(false);
	const [passwordState, setpasswordState] = useState(false);
	const [errorVal, setError] = useState("");

	const onFormSubmit = (formData) => {
		try {
			authService.login({ username: formData.name, password: formData.passcode }).then((res) => {
				if (res?.status === 200) {
					if (res.data.payload.twofa) {
						window.history.pushState(`${"/auth-login-2fa"}`, "auth-login-2fa", `${"/auth-login-2fa"}`);
						localStorage.setItem("username", formData.name);
						localStorage.setItem("passcode", formData.passcode);
						window.location.reload();
					} else {
						const response = res.data;
						localStorage.setItem("accessToken", response?.payload?.token ?? null);
						localStorage.setItem("applicationId", response?.payload?.applications[0]?.id ?? null);
						localStorage.setItem("authUser", JSON.stringify(response?.payload));
						setTimeout(function () {
							window.history.pushState(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`, "auth-login", `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`);
							window.location.reload();
						}, 1000);
					}
				} else {
					setError(t("login.error"));
					setLoading(false);
				}
			});
		} catch (error) { }
	};

	const { errors, register, handleSubmit } = useForm();

	useEffect(() => {
	  if(window.location.href.includes("merzigo")){
		localStorage.setItem("applicationId", 1);
	  }
	}, [])
	

	return (
		<ProjectSettingsContextProvider>
			<React.Fragment>
				<Head title="Login" />
				<PageContainer>
					<Block className="nk-block-middle nk-auth-body  wide-xs">
						<div className="brand-logo pb-4 text-center">
							<Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
								<img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
								<img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
							</Link>
						</div>

						<PreviewCard className="card-bordered" bodyClass="card-inner-lg">
							<BlockHead>
								<BlockContent>
									<BlockTitle tag="h4">{t("login.title")}</BlockTitle>
									<BlockDes>
										<p>{t("login.description")}</p>
									</BlockDes>
								</BlockContent>
							</BlockHead>
							{errorVal && (
								<div className="mb-3">
									<Alert color="danger" className="alert-icon">
										{" "}
										<Icon name="alert-circle" /> {t("login.error")}{" "}
									</Alert>
								</div>
							)}
							<Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
								<FormGroup>
									<div className="form-label-group">
										<label className="form-label" htmlFor="default-01">
											{t("login.username")}
										</label>
									</div>
									<div className="form-control-wrap">
										<input
											type="text"
											id="default-01"
											name="name"
											ref={register({
												required: t("login.required"),
											})}
											placeholder={t("login.username-placeholder")}
											className="form-control-lg form-control"
											tabIndex="1"
										/>
										{errors.name && <span className="invalid">{errors.name.message}</span>}
									</div>
								</FormGroup>
								<FormGroup>
									<div className="form-label-group">
										<label className="form-label" htmlFor="password">
											{t("login.password")}
										</label>
										<Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`} tabIndex="4">
											{t("login.forgotPassword")}
										</Link>
									</div>
									<div className="form-control-wrap">
										<a
											href="#password"
											onClick={(ev) => {
												ev.preventDefault();
												setpasswordState(!passwordState);
											}}
											className={`form-icon lg form-icon-right password-switch ${passwordState ? "is-hidden" : "is-shown"}`}
										>
											{!passwordState ? <Icon name="eye" className="password-icon icon-show"></Icon> : <Icon name="eye-off" className="password-icon icon-hide"></Icon>}
										</a>
										<input
											type={passwordState ? "text" : "password"}
											id="password"
											name="passcode"
											ref={register({
												required: t("login.required"),
											})}
											placeholder={t("login.password-placeholder")}
											className={`form-control-lg form-control ${passwordState ? "is-hidden" : "is-shown"}`}
											tabIndex="2"
										/>
										{errors.password && <span className="invalid">{errors.password.message}</span>}
									</div>
								</FormGroup>
								<FormGroup>
									<Button size="lg" className="btn-block" type="submit" color="primary" tabIndex="3">
										{loading ? <Spinner size="sm" color="light" /> : t("login.login")}
									</Button>
								</FormGroup>
							</Form>
							{/*
						<div className="form-note-s2 text-center pt-4">
							{" "}
							New on our platform? <Link to={`${process.env.PUBLIC_URL}/auth-register`}>Create an account</Link>
						</div>
						<div className="text-center pt-4 pb-3">
							<h6 className="overline-title overline-title-sap">
								<span>OR</span>
							</h6>
						</div>
						<ul className="nav justify-center gx-4">
							<li className="nav-item">
								<a
									className="nav-link"
									href="#socials"
									onClick={(ev) => {
										ev.preventDefault();
									}}
								>
									Facebook
								</a>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									href="#socials"
									onClick={(ev) => {
										ev.preventDefault();
									}}
								>
									Google
								</a>
							</li>
						</ul>
						*/}
						</PreviewCard>
						{(process.env.REACT_APP_STATUS === "development" ||
							process.env.REACT_APP_STATUS === "test" ||
							process.env.REACT_APP_STATUS === "local"
						) && (
								<FormGroup>
									<br />
									<div className="form-label-group">
										<label className="form-label" htmlFor="password">
											API URL
										</label>
										<span>
											<TooltipComponent iconClass="card-hint text-primary" icon="help" direction="left" id="Tooltip-users" 
											text={`You can change API URL if necessary, by default it is: ${process.env.REACT_APP_API_URL}`}></TooltipComponent>
										</span>
									</div>
									<div className="form-control-wrap">
										<input
											placeholder="API URL"
											type={"text"}
											id="text"
											name="api-url"
											defaultValue={localStorage["apiUrl"]}
											onChange={(e) => {
												if(e.target.value.length > 0)
												localStorage.setItem("apiUrl", e.target.value)
												else
												localStorage.removeItem("apiUrl")}
											}
											className={`form-control-lg form-control`}
											tabIndex="3"
										/>
									</div>
								</FormGroup>)}
					</Block>

					{/*
				<AuthFooter />
				*/}
				</PageContainer>
			</React.Fragment>
		</ProjectSettingsContextProvider>
	);
};
export default Login;
