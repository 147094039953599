import React, { useState } from "react";
import { profileServices } from "../services";
import DashboardWithRevenues from "./DashboardWithRevenues";
import DashboardWithoutRevenues from "./DashboardWithoutRevenues";


const Homepage = ({ ...props }) => {

	const [revenuePermission,setRevenuePermission] = useState();

	const getMeData = async () => {
		const me = await profileServices.getMe();
		var perm = me.payload.permissions.find((permission) => permission.name === "reports-revenue-list");
		perm ? setRevenuePermission(true) : setRevenuePermission(false);
	}

	useState( () => {
		getMeData();
	},[]);

	return (
		<React.Fragment>
			{
				revenuePermission === true ?
					<DashboardWithRevenues />
					: revenuePermission === false ? <DashboardWithoutRevenues /> : null
			}
		</React.Fragment>

	);
}
export default Homepage;

