import React, { useEffect, useState, useContext } from "react";
import Head from "../../layout/head/Head";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, Row, Col, Button, RSelect } from "../../components/Component";
import DatePicker from "react-datepicker";
import { Form, Modal, ModalBody, FormGroup } from "reactstrap";
import { getDateStructured } from "../../utils/Utils";
import { projectServices } from "../../services";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ProjectSettingsContext } from "../../layout/context/ProjectSettingsContext";
import CopyToClipboard from "react-copy-to-clipboard";
import defaultLogo from "../../images/logo.png";
import defaultFavicon from "../../images/logo-small.png";

const ProjectSettings = ({ sm, updateSm }) => {
	const { t } = useTranslation("common");
	const { projectContextData } = useContext(ProjectSettingsContext);
	const [projectData, setProjectData] = projectContextData;

	const [logo, setLogo] = useState({});
	const [favicon, setFavicon] = useState({});

	const fileSelectedHandler = (e) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length > 0) {
			switch (e.target.name) {
				case "logo":
					setLogo({
						logoAsFile: e.target.files[0],
						url: URL.createObjectURL(e.target.files[0]),
					});
					break;
				case "favicon":
					setFavicon({
						faviconAsFile: e.target.files[0],
						url: URL.createObjectURL(e.target.files[0]),
					});
					break;
				default:
					break;
			}
		}
	};

	const [formData, setFormData] = useState({
		name: "",
		logo: defaultLogo,
		favicon: defaultFavicon,
		"2fa": "0",
		app_domain: "",
		api_domain: ""
	});

	const [smtpFormData, setSmtpFormData] = useState({
		from: "",
		host: "",
		username: "",
		password: "",
		port: "",
		encryption: "",
		auto_tls: "",
	});
	const [autotls, setAutotls] = useState(false);
	const [encryption, setEncryption] = useState(false);

	const [modal, setModal] = useState(false);
	const [smtpModal, setSmtpModal] = useState(false);

	const loadData = async () => {
		try {
			const responseProject = await projectServices.getPrivateProject();
			setFormData({
				name: responseProject.data.payload.title,
				logo: responseProject.data.payload.logo ?? formData.logo,
				favicon: responseProject.data.payload.favicon ?? formData.favicon,
				"2fa": responseProject.data.payload["2fa"] ?? "",
				youtube_api_key: responseProject.data.payload.youtube_api_key ?? "",
				re_api_token: responseProject.data.payload.re_api_token ?? "515613215616",
				app_domain: responseProject.data.payload.app_domain ?? "",
				api_domain: responseProject.data.payload.api_domain ?? ""
			});
			setProjectData({
				...responseProject.data.payload,
			});

			const responseSmtp = await projectServices.getSmtp();
			setSmtpFormData({
				from: responseSmtp?.payload?.from,
				host: responseSmtp?.payload?.host,
				username: responseSmtp?.payload?.username,
				password: responseSmtp?.payload?.password,
				port: responseSmtp?.payload?.port,
				encryption: responseSmtp?.payload?.encryption,
				auto_tls: responseSmtp?.payload?.auto_tls,
			});
			setAutotls(responseSmtp?.payload?.auto_tls == "1");
			setEncryption(responseSmtp?.payload?.encryption == "tls");
		} catch (error) {
			console.debug(error);
		}
	};

	useEffect(() => {
		loadData();
	}, [smtpModal, modal]);

	useEffect(() => {
		setSmtpFormData({ ...smtpFormData, auto_tls: autotls ? "1" : null });
	}, [autotls]);

	useEffect(() => {
		setSmtpFormData({ ...smtpFormData, encryption: encryption ? "tls" : null });
	}, [encryption]);

	const onInputChange = (e) => {
		if (e.target.name == "auto_tls") {
			setAutotls(!autotls);
		} else if (e.target.name == "encryption") {
			setEncryption(!encryption);
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const submitForm = () => {
		let submitData = {
			...formData,
		};
		setModal(false);
	};

	const onFormSubmit = async (filledFormData) => {
		const uploadFormData = new FormData();
		uploadFormData.append("title", filledFormData.name);
		if (logo.logoAsFile) uploadFormData.append("logo", logo.logoAsFile);
		if (favicon.faviconAsFile) uploadFormData.append("favicon", favicon.faviconAsFile);
		uploadFormData.append("api_domain", formData.api_domain);
		uploadFormData.append("app_domain", formData.app_domain);
		uploadFormData.append("2fa", formData["2fa"]);
		uploadFormData.append("youtube_api_key", formData.youtube_api_key);
		projectServices.postProject(uploadFormData).then((response) => {
			setModal(false);
			if (response?.status === 200) {
				setFormData({
					name: response.data.payload.title,
					logo: response.data.payload.logo,
					favicon: response.data.payload.favicon,
				});
				loadData();
			} else {
				setTimeout(() => {
					//alert('error', response);
					console.log("error", response);
				}, 2000);
			}
		});

		resetForm();
	};

	const resetForm = () => {
		setFormData({
			name: "",
			logo: defaultLogo,
			favicon: defaultFavicon,
		});
		setSmtpFormData({
			from: "",
			host: "",
			username: "",
			password: "",
			port: "",
			encryption: "",
			auto_tls: "",
		});
	};

	// submit function to add a new item
	const onSmtpFormSubmit = async (submitData) => {
		const { from, host, password, port, username } = submitData;
		submitData["auto_tls"] = autotls;
		submitData["encryption"] = smtpFormData.encryption;
		projectServices.postSmtp(submitData).then((response) => {
			if (response?.status === 200) {
				setSmtpModal(false);
				loadData();
			}
		});
		//resetForm();
	};

	// function to close the form modal
	const onFormCancel = () => {
		setModal(false);
		setSmtpModal(false);
		resetForm();
	};

	const generateReApiToken = async () => {
		try {
			/* const res = await projectServices.generateReApiToken(); */
			console.log("generate REAPI Token")
		} catch (err) {
			console.error(err);
		}
	};

	const { errors, register, handleSubmit } = useForm();

	return (
		<React.Fragment>
			<Head title={t("project.settings-title")}></Head>

			<BlockHead size="lg">
				<BlockBetween>
					<BlockHeadContent>
						<BlockTitle tag="h4">{t("project.settings-title")}</BlockTitle>
						<BlockDes>
							<p>{t("project.settings-desc")}</p>
						</BlockDes>
					</BlockHeadContent>
					<BlockHeadContent className="align-self-start d-lg-none">
						<Button className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
							<Icon name="menu-alt-r"></Icon>
						</Button>
					</BlockHeadContent>
				</BlockBetween>
			</BlockHead>

			<Block>
				<div className="nk-data data-list">
					<div className="data-head">
						<h6 className="overline-title">{t("project.basics")}</h6>
					</div>
					<div className="data-item" onClick={() => setModal(true)}>
						<div className="data-col">
							<span className="data-label">{t("project.name")}</span>
							<span className="data-value">{formData.name}</span>
						</div>
						<div className="data-col data-col-end">
							<span className="data-more">
								<Icon name="forward-ios"></Icon>
							</span>
						</div>
					</div>
					<div className="data-item" onClick={() => setModal(true)}>
						<div className="data-col">
							<span className="data-label">{t('project.yt_api_key')}</span>
							<span className="data-value">
								*********
							</span>
						</div>
						<div className="data-col data-col-end">
							<span className="data-more">
								<Icon name="forward-ios"></Icon>
							</span>
						</div>
					</div>
					<div className="data-item" style={{cursor: 'default'}}>
						<div className="data-col">
							<span className="data-label">{t('project.reapi_token')}</span>
							<input style={{width: 'max-content'}} defaultValue={formData.re_api_token} disabled type="text" className="form-control mr-1" />
							<CopyToClipboard text={formData.re_api_token}>
							<Button className={`btn-icon btn-trigger`}>
								<Icon name="copy"></Icon> 
							</Button>
							</CopyToClipboard>
							<Button className={`btn-icon btn-trigger`} onClick={() => generateReApiToken()}>
								<Icon name="reload-alt"></Icon> 
							</Button>
						</div>
						<div className="data-col data-col-end">
						</div>
					</div>
					<div className="data-item" onClick={() => setModal(true)}>
						<div className="data-col">
							<span className="data-label">{t("project.logo")}</span>
							<span className="data-value">
								<img width="120" height="30" src={formData.logo} alt="logo" className="img-fluid" />{" "}
							</span>
						</div>
						<div className="data-col data-col-end">
							<span className="data-more">
								<Icon name="forward-ios"></Icon>
							</span>
						</div>
					</div>
					<div className="data-item" onClick={() => setModal(true)}>
						<div className="data-col">
							<span className="data-label">{t("project.favicon")}</span>
							<span className="data-value">
								<img width="30" height="30" src={formData.favicon} alt="logo" className="img-fluid" />{" "}
							</span>
						</div>
						<div className="data-col data-col-end">
							<span className="data-more">
								<Icon name="forward-ios"></Icon>
							</span>
						</div>
					</div>
					<div className="data-item" onClick={() => setSmtpModal(true)}>
						<div className="data-col">
							<span className="data-label">{t("project.smtp-settings-title")}</span>
						</div>
						<div className="data-col data-col-end">
							<span className="data-more">
								<Icon name="forward-ios"></Icon>
							</span>
						</div>
					</div>
				</div>
			</Block>

			<Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
				<ModalBody>
					<a
						href="#dropdownitem"
						onClick={(ev) => {
							ev.preventDefault();
							setModal(false);
						}}
						className="close"
					>
						<Icon name="cross-sm"></Icon>
					</a>
					<div className="p-2">
						<h5 className="title">{t("project.update")}</h5>

						<Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
							<Row className="gy-4">
								<Col md="8" className="mt-2">
									<FormGroup>
										<label className="form-label" htmlFor="full-name">
											{t("project.name")}
										</label>
										<input
											ref={register({
												required: t("operations.required"),
											})}
											type="text"
											id="full-name"
											className="form-control"
											name="name"
											onChange={(e) => onInputChange(e)}
											defaultValue={formData.name}
										/>
									</FormGroup>
								</Col>
								<Col key={formData} md="8" className="mt-2">
									<FormGroup style={{display: 'flex', justifyContent: 'space-between'}}>
										<label className="form-label" htmlFor="2fa">
											{t("project.2fa")}
										</label>
										<div className="custom-control custom-switch">
											<input defaultChecked={formData["2fa"] === "0" ? false : true} onChange={() => setFormData(current => ({ ...current, "2fa": current["2fa"] === "0" ? "1" : "0" }))} type="checkbox" className="custom-control-input" id="2fa" />
											<label className="custom-control-label" htmlFor="2fa"></label>
										</div>
									</FormGroup>
								</Col>
								<Col md="8" className="mt-2">
									<FormGroup>
										<label className="form-label" htmlFor="app_domain">
											{t("project.app_domain")}
										</label>
										<input
											ref={register({
												required: t("operations.required"),
											})}
											type="text"
											id="app_domain"
											className="form-control"
											name="app_domain"
											onChange={(e) => onInputChange(e)}
											defaultValue={formData.app_domain}
										/>
									</FormGroup>
								</Col>
								<Col md="8" className="mt-2">
									<FormGroup>
										<label className="form-label" htmlFor="api_domain">
											{t("project.api_domain")}
										</label>
										<input
											ref={register({
												required: t("operations.required"),
											})}
											type="text"
											id="api_domain"
											className="form-control"
											name="api_domain"
											onChange={(e) => onInputChange(e)}
											defaultValue={formData.api_domain}
										/>
									</FormGroup>
								</Col>
								<Col md="8" className="mt-2">
									<FormGroup>
										<label className="form-label" htmlFor="youtube_api_key">
											{t("project.yt_api_key")}
										</label>
										<input
											ref={register({
												required: t("operations.required"),
											})}
											type="text"
											id="youtube_api_key"
											className="form-control"
											name="youtube_api_key"
											onChange={(e) => onInputChange(e)}
											defaultValue={formData.youtube_api_key}
										/>
									</FormGroup>
								</Col>
								<Col md="12">
									<FormGroup className={"row"}>
										<Col md="12">
											<label className="form-label" htmlFor="logo">
												{t("project.logo")}
											</label>
										</Col>
										<Col md="8">
											<input type="file" id="logo" className="form-control" name="logo" onChange={(e) => fileSelectedHandler(e)} />
										</Col>
										<Col md="4">
											<img width="120" height="30" src={logo.url ?? formData.logo} alt="logo" className="img-fluid" />
										</Col>
									</FormGroup>
								</Col>
								<Col md="12">
									<FormGroup className={"row"}>
										<Col md="12">
											<label className="form-label" htmlFor="favicon">
												{t("project.favicon")}
											</label>
										</Col>
										<Col md="8">
											<input type="file" id="favicon" className="form-control" name="favicon" onChange={(e) => fileSelectedHandler(e)} />
										</Col>
										<Col md="4">
											<img width="30" height="30" src={favicon.url ?? formData.favicon} alt="favicon" className="img-fluid" />
										</Col>
									</FormGroup>
								</Col>
								<Col size="12">
									<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
										<li>
											<Button color="primary" type="submit" size="lg">
												{t("operations.update")}
											</Button>
										</li>
										<li>
											<a
												href="#dropdownitem"
												onClick={(ev) => {
													ev.preventDefault();
													setModal(false);
												}}
												className="link link-light"
											>
												{t("operations.cancel")}
											</a>
										</li>
									</ul>
								</Col>
							</Row>
						</Form>
					</div>
				</ModalBody>
			</Modal>
			<Modal isOpen={smtpModal} toggle={() => setSmtpModal(false)} className="modal-dialog-centered" size="lg">
				<ModalBody>
					<a
						href="#cancel"
						onClick={(ev) => {
							ev.preventDefault();
							setSmtpModal(false);
							onFormCancel();
						}}
						className="close"
					>
						<Icon name="cross-sm"></Icon>
					</a>
					<div className="p-2">
						<h5 className="title">{t("project.smtp-settings-title")}</h5>
						<div className="mt-4">
							<Form className="row gy-4" onSubmit={handleSubmit(onSmtpFormSubmit)}>
								<Col md="6">
									<FormGroup>
										<label className="form-label">{t("project.smtp-from")}</label>
										<input
											className="form-control"
											type="text"
											name="from"
											defaultValue={smtpFormData.from}
											ref={register({
												required: t("general.requiredfield"),
												pattern: {
													value: /^[a-zA-Z0-9._%+-]+@merzigo+\.[a-zA-Z]{3,}$/i,
													message: t("users.required-email"),
												},
											})}
										/>
										{errors.from && <span className="invalid">{errors.from.message}</span>}
									</FormGroup>
									{/*<ErrorMessage*/}
									{/*    errors={errors}*/}
									{/*    name="nameInputErros"*/}
									{/*    render={({message}) => <p>{message}</p>}*/}
									{/*/>*/}
								</Col>
								<Col md="6">
									<FormGroup>
										<label className="form-label">{t("project.smtp-host")}</label>
										<input
											className="form-control"
											type="text"
											name="host"
											defaultValue={smtpFormData.host}
											placeholder="Host"
											ref={register({
												required: t("general.requiredfield"),
											})}
										/>
										{errors.host && <span className="invalid">{errors.host.message}</span>}
									</FormGroup>
								</Col>
								<Col md="6">
									<FormGroup>
										<label className="form-label">{t("project.smtp-username")}</label>
										<input
											className="form-control"
											type="text"
											name="username"
											defaultValue={smtpFormData.username}
											placeholder="Username"
											ref={register({
												required: t("general.requiredfield"),
											})}
										/>
										{errors.username && <span className="invalid">{errors.username.message}</span>}
									</FormGroup>
								</Col>
								<Col md="6">
									<FormGroup>
										<label className="form-label">{t("project.smtp-password")}</label>
										<input className="form-control" type="password" name="password" defaultValue={smtpFormData.password} placeholder="Şifre" ref={register({ required: t("general.requiredfield") })} />
										{errors.password && <span className="invalid">{errors.password.message}</span>}
									</FormGroup>
								</Col>
								<Col md="12">
									<FormGroup>
										<label className="form-label">{t("project.smtp-port")}</label>
										<input className="form-control" type="text" name="port" defaultValue={smtpFormData.port} placeholder="Port" ref={register({ required: t("general.requiredfield") })} />
										{errors.port && <span className="invalid">{errors.port.message}</span>}
									</FormGroup>
								</Col>

								<Col md="6">
									<FormGroup>
										<div className="custom-control custom-control-sm custom-checkbox">
											<input type="checkbox" className="custom-control-input form-control" value="tls" name="encryption" id="authenticationcb" onChange={onInputChange} checked={encryption} />
											<label className="custom-control-label" htmlFor="authenticationcb">
												{t("project.smtp-authentication")}
											</label>
										</div>
									</FormGroup>
								</Col>
								<Col md="6">
									<FormGroup>
										<div className="custom-control custom-control-sm custom-checkbox">
											<input type="checkbox" className="custom-control-input form-control" value="1" name="auto_tls" id="autotlscb" onChange={onInputChange} checked={autotls} />
											<label className="custom-control-label" htmlFor="autotlscb">
												{t("project.smtp-autotls")}
											</label>
										</div>
									</FormGroup>
								</Col>
								<Col size="12">
									<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
										<li>
											<Button color="primary" size="md" type="submit">
												{t("operations.save")}
											</Button>
										</li>
										<li>
											<a
												href="#cancel"
												onClick={(ev) => {
													ev.preventDefault();
													setSmtpModal(false);
													onFormCancel();
												}}
												className="link link-light"
											>
												{t("operations.cancel")}
											</a>
										</li>
									</ul>
								</Col>
							</Form>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};
export default ProjectSettings;
