import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { RSelect } from "../../Component";
import { Chart } from "./Chart";

const ViewsAndRevenue = ({ range, data, labels, columns }) => {
	
	const { t, i18n } = useTranslation("common");

	const [column,setColumn] = useState();

	const changeColumn = (result) => {
		setColumn(result.value);
	};

	useEffect(() => {
		if(columns?.length > 0){
			setColumn(columns[0].value);
		}
	},[])

	return (
		<Card>
			<div className="nk-ecwg nk-ecwg8 h-100">
				<div className="card-inner">
					<div className="nk-ecwg8-ck">
					{data ? 
						 <Chart labels={labels} data={data} column={column} />
						 : <h4 className={"text-center"}>{t("general.no_data")}</h4>}
					</div>
					<div className="chart-label-group pl-5">
						<div className="chart-label">{range && range[0]}</div>
						<div className="chart-label">{range && range[range.length - 1]}</div>
					</div>
				</div>
			</div>
			{columns &&
			<form>
			<label className="form-label" htmlFor="">{t('statistic.column_to_show')}</label>
			<RSelect name="columns" defaultValue={columns.length === 1 && columns[0]} options={columns} required onChange={changeColumn} />
			<br />
			</form>
			}
		</Card>
	);
};
export default ViewsAndRevenue;
