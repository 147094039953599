import React, {useState, useEffect} from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import {
	Block,
	BlockHead,
	BlockTitle,
	BlockBetween,
	BlockHeadContent,
	BlockDes,
	Icon,
	Button,
	DataTable,
	DataTableHead,
	DataTableBody,
	DataTableRow,
	DataTableItem,
	FacebookPagination,
	TooltipComponent,
	RSelect,
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import {Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge} from "reactstrap";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {UncontrolledAlert} from "reactstrap";
import {UserAvatar} from "../../components/Component";
import {findUpper, sliceString} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import {facebookServices, parametersServices, companyService, channelServices} from "../../services";
import moment from 'moment';
import {VideoDetailsModal} from "../../components/videos/VideoDetailsModal";
import {AddToPlaylistModal} from "../../components/videos/AddToPlaylistModal";
import {FilterComponent} from "../../components/FilterComponent";
import "react-datetime/css/react-datetime.css";
import DateTime from "react-datetime";
import debounce from "lodash.debounce";

const FacebookVideosList = () => {
	const {t} = useTranslation("common");
	const history = useHistory();
	const location = useLocation();
	let {search} = useLocation();
	const alertRawData = {
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	};

	const [videosData, setVideosData] = useState([]);
	const [videosMeta, setVideosMeta] = useState({total: null});
	const [alertData, setAlertData] = useState(alertRawData);
	const [formData, setFormData] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);

	const [selectChannelId, setSelectChannelId] = useState(null);
	const [channelRList, setChannelRList] = useState([]);

	const [beforePage, setBeforePage] = useState("");
	const [afterPage, setAfterPage] = useState("");
	const [limitPage, setLimitPage] = useState(25);

	const [publishTime, setPublishTime] = useState({});
	const [publishStatus, setPublishStatus] = useState({});

	const loadData = async () => {
		try {
			if(selectChannelId){
				const videos = await facebookServices.getVideosByPageAndLimit(selectChannelId, {before: beforePage, after: afterPage});
				setVideosData([...videos.payload]);
				setVideosMeta({...videos.meta});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getChannelList = async () => {
		const channelList = await channelServices.getAllChannelsByPlatform({platform: 'facebook'});
		if (channelList?.payload?.length > 0) {
			const createRList = channelList.payload.map((channel, key) => {
				return {value: channel.id, label: channel.title};
			});
			setChannelRList(createRList)
		}
	};

	const channelHandleChange = (result, e) => {
		setSelectChannelId(result.value);
	};

	useState(async () => {
		await loadData();
		await getChannelList();
	}, [])

	const changeInput = (videoId, event) => {
		let oldFormData = formData[videoId];
		let newFormData = {
			...oldFormData,
			[event.target.name]: event.target.value,
		}
		setFormData({
			...formData,
			[videoId]: newFormData,
		})
	}

	// function to delete a video
	const deleteVideo = async (id) => {
		try {
			await videoServices.deleteVideo(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	// Get current list, pagination
	const currentItems = videosData;

	// Change Page
	const paginate = async (tokenType, pageToken = "") => {
		if(tokenType == "prev") {
			await setAfterPage("")
			await setBeforePage(pageToken)
		}

		if(tokenType == "next") {
			await setBeforePage("")
			await setAfterPage(pageToken)
		}
	}

	useEffect(() => {
		if(beforePage == "" || afterPage == ""){
			loadData()
		}
	}, [selectChannelId, beforePage, afterPage])

	const animatedComponents = makeAnimated();

	const {errors, register, handleSubmit} = useForm();

	const [detailsModal, setDetailsModal] = useState({
		status: false,
		item: {}
	})

	const [addToPlaylistModal, setAddToPlaylistModal] = useState({
		status: false,
		videoId: ""
	})

	const toggleDetailsModal = (item) => {
		setDetailsModal(current => ({
			status: !current.status,
			item: item ? item : {}
		}));
	};

	const toggleAddToPlaylistModal = (item) => {
		//console.log(item)
		setAddToPlaylistModal(current => ({
			status: !current.status,
			id: item?.id,
			channelId: item?.channelId
		}));
	};

	const now = new Date().getTime();

	const onVideoSubmit = async (videoId, item) => {
		if(selectChannelId) {
			const duration = Number(item['length']);
			const submitData = {
					title: formData[videoId]?.title,
					description: formData[videoId]?.description,
					ad_breaks: createAdBreaks(duration),
				};
			if(publishStatus[videoId] == "scheduled" && publishTime[videoId]) {
				submitData.scheduled_publish_time = moment(publishTime[videoId]).format('YYYY-MM-DD hh:mm')
			}else if(publishStatus[videoId]) {
				submitData.published = true
			}
			try {
				const response = await facebookServices.updateVideo(selectChannelId, videoId, submitData);
				response.message = response?.message === "" ? t("error.success") : response?.message;
				setAlertData({
					status: true, type: "success", icon: "check-circle", message: response?.message,
				});
			} catch (error) {
				const {response} = error;
				setAlertData({
					status: true, type: "danger", icon: "cross-circle", message: response?.data?.message ?? error.message,
				});
			}
		}
	};


	const createAdBreaks = (duration) =>  {
		const adBreaks = [];
		const msDuration = duration*1000 - 30000;
		const breakLength = 120000;
		if(msDuration >= 60000){
			for (let breakIndex = 60000; breakIndex < msDuration; breakIndex += breakLength) {
				adBreaks.push(breakIndex);
			}
		}

		return adBreaks;
	}

	return (
		<React.Fragment>
			{detailsModal.item &&
				<VideoDetailsModal status={detailsModal?.status ?? false} toggle={toggleDetailsModal}
				                   item={detailsModal.item} t={t}/>
			}
			{
				<AddToPlaylistModal status={addToPlaylistModal?.status ?? false} toggle={toggleAddToPlaylistModal}
				                    ids={{id: addToPlaylistModal.id, channelId: addToPlaylistModal.channelId}}/>
			}
			<Head title="Video Upload"></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle>{t('videos.publish.page-title')}</BlockTitle>
							<BlockDes></BlockDes>
						</BlockHeadContent>
                        <BlockHeadContent className={"w-30"}>
							<label className="form-label text-right">{t("modules.channel")}</label>
							<RSelect
								name="channel"
								components={animatedComponents}
								options={channelRList}
								onChange={channelHandleChange}
								noOptionsMessage={() => t("videos.upload.company&platform-unselected")}
								required={true}
							/>
                        </BlockHeadContent>
					</BlockBetween>
				</BlockHead>
				<Block>
					{alertData.status /* && !modal.create && !modal.edit  */ ? (
						<Block>
							<BlockHeadContent>
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"}/>
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							</BlockHeadContent>
						</Block>
					) : null}
				</Block>
				<Block>
					<Card className="card-bordered">
						<div className="card-inner-group">
							<div className="card-inner p-0">
								<DataTable>
									<DataTableBody>
										<DataTableHead className="nk-tb-stiky-65">
											<DataTableRow className="nk-tb-col-check" size="sm">
												<div
													className="custom-control custom-control-sm custom-checkbox notext">
													<input type="checkbox" className="custom-control-input form-control"
													       id="uid_1"/>
													<label className="custom-control-label" htmlFor="uid_1"></label>
												</div>
											</DataTableRow>
											<DataTableRow>
												<span>{t("videos.publish.video")}</span>
											</DataTableRow>
											<DataTableRow>
												<span>{t("videos.detail.title")}</span>
											</DataTableRow>
											<DataTableRow>
												<span>{t("videos.detail.description")}</span>
											</DataTableRow>
											<DataTableRow size="sm">
												<span>{t("content.channel.playlists.privacy_status")}</span>
											</DataTableRow>
											<DataTableRow>
												<span>{t("videos.publish.options")}</span>
											</DataTableRow>
											<DataTableRow className={"text-center"}>
												<span>{t("general.action")}</span>
											</DataTableRow>
										</DataTableHead>
										{currentItems.length > 0 ? currentItems.map((item) => {
											return (
												<DataTableItem key={item.id}>
													<DataTableRow className="nk-tb-col-check" size="sm">
														<div
															className="custom-control custom-control-sm custom-checkbox notext">
															<input type="checkbox"
															       className="custom-control-input form-control"
															       defaultChecked={item.check} id={item.id + "uid1"}
															       key={Math.random()}/>
															<label className="custom-control-label"
															       htmlFor={item.id + "uid1"}></label>
														</div>
													</DataTableRow>
													<DataTableRow>
														<div className="tb-video">
															<div className="thumb-group left-section">
																<img
																	src={item.picture ?? "https://img.youtube.com/vi/default.jpg"}
																	alt="product" className={"thumb"}/>
																<Badge
																	className="badge-thumb"> {moment.utc(Number(item['length'] ?? "0") * 1000).format('HH:mm:ss').replace(/^(?:00:)?0?/, '')}</Badge>
															</div>
															{/*
															<div className="right-section">
																<h6 className="title" title={item.title}>
																	{sliceString(item?.title, 40)}
																</h6>
																<div className="metadata">
																	<div>
																		<span className="desc"
																		      title={item?.description}>
																			{sliceString(item.description, 50)}
																		</span>
																	</div>
																	<Badge className="badge-fill mr-1" color="primary">
																		{item.video_kind}
																	</Badge>
																	<Badge className="badge-dim"
																	       style={{color: "#4267B2"}}>
																		<Icon name="facebook-f"></Icon> Facebook
																	</Badge>
																</div>
																<div className="video-action">
																	<Link to={`/facebook/videos/${item.id}/edit`}>
																		<Button className="btn-icon btn-trigger">
																			<Icon name="pen"/>
																		</Button>
																	</Link>
																</div>
															</div>
															*/}
														</div>
													</DataTableRow>
													<DataTableRow>
														<textarea
															className="form-control form-control-lg"
															type="text"
															name={`title`}
															onChange={(event) => {
																changeInput(item.id, event)
															}}
															defaultValue={item.title}
															maxLength="5000"
															ref={register({
																required: t("operations.required"),
															})}
															style={{height:"80px", maxHeight: "80px", minHeight: "50px"}}
														/>
													</DataTableRow>
													<DataTableRow>
														<textarea
															className="form-control form-control-lg"
															type="text"
															name={`description`}
															onChange={(event) => {
																changeInput(item.id, event)
															}}
															defaultValue={item.description}
															maxLength="5000"
															ref={register({
																required: t("operations.required"),
															})}
															style={{width:"400px", minWidth: "300px", maxWidth: "500px", height:"80px", maxHeight: "80px", minHeight: "50px"}}
														/>
													</DataTableRow>
													<DataTableRow size="lg">
														<div>
															{item.status.video_status}
														</div> -
														{item.status.publishing_phase.publish_status == "scheduled" ? (
															<>
																<span
																	className="mr-1">{t(`youtube:status.scheduled`)}</span>
																<TooltipComponent key={item.id} icon="help-fill"
																                  iconClass="card-hint" direction="left"
																                  id={"tooltip" + item.id}
																                  text={moment(item?.status?.publishing_phase?.publish_time).format("YYYY-MM-DD hh:mm:ss") !== 'Invalid date' ? moment.utc(item?.status?.publishing_phase?.publish_time).local().format('DD.MM.YYYY HH:mm ') : "-"}/>
															</>)
														: <div>{item.privacy.description}</div>}
													</DataTableRow>
													<DataTableRow key={publishStatus}>
														<div>
															<select className={"form-control"}
															        name={`publish_status`}
																	onChange={(event) => {
																		setPublishStatus({
																			...publishStatus,
																			[item.id]: event.target.value
																		})
																	}}
															        defaultValue={false}>
																<option value={false} disabled={true}>{t("videos.publish.options")}</option>
																<option value={true}>Şimdi Yayınla</option>
																<option value={"scheduled"}>{t("youtube:status.scheduled")}</option>
															</select>
														</div>
														{ publishStatus[item.id] == "scheduled" ?
															<div id="date-picker" className="mt-1">
																<DateTime
																	key={publishStatus[item.id]}
																	id="date-picker-input"
																	name={`publish_time`}
																	initialValue={new Date()}
																	dateFormat="YYYY-MM-DD"
																	timeFormat="HH:mm"
																	onChange={(date) => {
																		setPublishTime({...publishTime, [item.id]: moment(date).local().toISOString()});
																	}}
																/>
															</div>
														: null}
													</DataTableRow>
													<DataTableRow className={"text-center"}>
														<Button color={"primary"} onClick={() => {onVideoSubmit(item.id, item)}}>Kaydet</Button>
													</DataTableRow>
												</DataTableItem>
											);
										}) : null}
									</DataTableBody>
									<div className="card-inner">
										{currentItems.length > 0 ? (
											<FacebookPagination nextToken={videosMeta.next_page} prevToken={videosMeta.prev_page} paginate={paginate}/>
										) : (
											<div className="text-center">
												<span className="text-silent">No videos found</span>
											</div>
										)}
									</div>

								</DataTable>
							</div>
						</div>
					</Card>
				</Block>
			</Content>
		</React.Fragment>
	);
};

export default FacebookVideosList;
