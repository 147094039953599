import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, UncontrolledDropdown, UncontrolledAlert, Badge } from "reactstrap";
import {
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Button,
	Row,
	Col,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableItem,
	DataTableRow,
	Icon,
	PaginationComponent,
	RSelect,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { platformcmsServices, companyService, parametersServices } from "../../services";

const PlatformCms = () => {
	const { t } = useTranslation("common");

	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});

	const [platformCmsData, setPlatformCmsData] = useState([]);
	const [platformCmsMeta, setPlatformCmsMeta] = useState({
		total: null,
	});

	const [companyRList, setCompanyRList] = useState([]);
	const [platformRList, setPlatformRList] = useState([]);

	const [sm, updateSm] = useState(false);
	const [modal, setModal] = useState({
		edit: false,
		add: false,
	});
	const [editId, setEditedId] = useState();
	const [formData, setFormData] = useState({
		name: "",
		company: "",
		platform: "",
		platform_id: "",
		status: "",
	});
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);

	const getPlatformCmsAuthUrl = async (channelId) => {
		try {
			const cmsAuthUrl = await platformcmsServices.getPlatformCmsAuthUrl(channelId, window.location.href);
			if (cmsAuthUrl) {
				window.open(cmsAuthUrl?.payload?.url);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const loadData = async () => {
		try {
			const platformCms = await platformcmsServices.getPlatformCmsByPageAndLimit({ page: currentPage, limit: itemPerPage });
			setPlatformCmsMeta({ total: platformCms.meta.total });
			setPlatformCmsData([...platformCms.payload]);
		} catch (error) {
			console.debug(error);
		}
	};

	useEffect(() => {
		if (modal.add || modal.edit) {
			getCompanyList();
			getPlatformList();
		}
	}, [modal.add, modal.edit]);

	// unselects the data on mount
	useEffect(() => {
		loadData();
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	const getCompanyList = async () => {
		const companyList = await companyService.getCompanies();
		if (companyList?.data?.payload?.length > 0) {
			const createRList = companyList.data.payload.map((company, key) => {
				return { value: company.id, label: company.name };
			});
			setCompanyRList(createRList);
		}
	};

	const getPlatformList = async () => {
		const platformList = await parametersServices.getPlatforms();
		if (platformList?.payload?.length > 0) {
			const createRList = platformList.payload.map((platform, key) => {
				return { value: platform.key, label: platform.value };
			});
			setPlatformRList(createRList);
		}
	};

	// function to reset the form
	const resetForm = () => {
		setFormData({
			name: "",
			company: "",
			platform: "",
			platform_id: "",
			status: "",
		});
		setAlertData({
			status: false,
			type: "info",
			icon: "alert-circle",
			message: t("error.unknown"),
		});
	};

	const handleChange = (result, e) => {
		setFormData({ ...formData, [e.name]: result.value });
	};

	// function to close the form modal
	const onFormCancel = () => {
		setModal({ edit: false, add: false });
		resetForm();
	};

	// submit function to add a new item
	const onFormSubmit = async (submitData) => {
		const { name, platform_id } = submitData;
		let submittedData = {
			name: name,
			company_id: formData.company,
			platform: formData.platform,
			platform_id: platform_id,
		};
		try {
			const response = await platformcmsServices.addPlatformCms({
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// submit function to update a new item
	const onEditSubmit = async (submitData) => {
		const { name, platform_id } = submitData;
		let submittedData = {
			name: name,
			company_id: formData.company,
			platform: formData.platform,
			platform_id: platform_id,
		};
		try {
			const response = await platformcmsServices.updatePlatformCms(editId, {
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// function that loads the want to editted data
	const onEditClick = (id) => {
		resetForm();
		platformCmsData.forEach((item) => {
			if (item.id === id) {
				setFormData({
					name: item.name,
					company: item.company_id,
					companyValue: { value: item.company?.id, label: item.company?.name },
					platform: item.platform,
					platformValue: { value: item.platform, label: item.platform },
					platform_id: item.platform_id,
					status: item.status,
				});
				setEditedId(id);
				setModal({ edit: true }, { add: false });
			}
		});
	};

	// function to change to suspend property for an item
	const deletePlatformCms = async (id) => {
		try {
			await platformcmsServices.deletePlatformCms(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	const { errors, register, handleSubmit } = useForm();

	// Get current list, pagination
	// const indexOfLastItem = currentPage * itemPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemPerPage;
	// const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
	const currentItems = platformCmsData;

	// Change Page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const handleSync = async (cmsId) => {
		try {
			const response = await platformcmsServices.syncCmsChannels(cmsId);
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	}

	return (
		<React.Fragment>
			<Head title={t("content.platformcms.list")}></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle tag="h3" page>
								{t("content.platformcms.list")}
							</BlockTitle>
							<BlockDes className="text-soft">
								<p>
									{t("general.total")} {platformCmsMeta.total} {t("general.platformcms")}.
								</p>
							</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
									<Icon name="menu-alt-r"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3">
										<li className="nk-block-tools-opt">
											<Button
												color="primary"
												className="btn-icon"
												onClick={() => {
													resetForm();
													setModal({ add: true });
												}}
											>
												<Icon name="plus"></Icon>
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>

				{alertData.status && !modal.add && !modal.edit ? (
					<Block>
						<BlockHeadContent>
							<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
								<Icon name={alertData.icon ?? "cross-circle"} />
								{alertData.message ?? t("error.unknown")}
							</UncontrolledAlert>
						</BlockHeadContent>
					</Block>
				) : null}

				<Block>
					<DataTable className="card-stretch">
						<DataTableBody compact>
							<DataTableHead>
								<DataTableRow className="nk-tb-col-check">
									<span className="sub-text">#</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.platformcms.name")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("content.platformcms.company")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.platformcms.platform")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("content.platformcms.is_token")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("content.platformcms.status")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text float-right">{t("content.platformcms.action")}</span>
								</DataTableRow>
							</DataTableHead>
							{/*Head*/}
							{currentItems.length > 0
								? currentItems.map((item, key) => {
										return (
											<DataTableItem key={key}>
												<DataTableRow className="nk-tb-col-check">{item.id}</DataTableRow>
												<DataTableRow>
													<span className="tb-lead">{item?.name}</span>
												</DataTableRow>
												<DataTableRow>
													<span className="tb-lead">{item?.company?.name}</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<Icon name={`${item?.platform.toLowerCase()}-fill`}></Icon>
													<span>{item?.platform}</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>
														{item.token_status ? (
															<Badge className="badge-dot" color="success">
																Success
															</Badge>
														) : (
															<Badge className="badge-dot" color="danger">
																Not Found
															</Badge>
														)}
													</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>
														<Badge className="badge-dot" color={item.status.toLowerCase() === "active" ? "success" : "danger"}>
															{item?.status}
														</Badge>
													</span>
												</DataTableRow>
												<DataTableRow className="nk-tb-col-tools">
													<ul className="nk-tb-actions gx-1">
														<li>
															<UncontrolledDropdown>
																<DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
																	<Icon name="more-h"></Icon>
																</DropdownToggle>
																<DropdownMenu right>
																	<ul className="link-list-opt no-bdr">
																		<li onClick={() => getPlatformCmsAuthUrl(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#connect"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="link"></Icon>
																				<span>{t("operations.connect")}</span>
																			</DropdownItem>
																		</li>
																		<li onClick={() => onEditClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#edit"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="edit"></Icon>
																				<span>{t("operations.edit")}</span>
																			</DropdownItem>
																		</li>
																		<li onClick={() => handleSync(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#sync"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="reload"></Icon>
																				<span>{t("operations.sync")}</span>
																			</DropdownItem>
																		</li>
																		<li
																			onClick={() => {
																				if (window.confirm(t("content.platformcms.delete-confirm"))) deletePlatformCms(item.id);
																			}}
																		>
																			<DropdownItem
																				tag="a"
																				href="#delete"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="trash-fill"></Icon>
																				<span>{t("operations.delete")}</span>
																			</DropdownItem>
																		</li>
																	</ul>
																</DropdownMenu>
															</UncontrolledDropdown>
														</li>
													</ul>
												</DataTableRow>
											</DataTableItem>
										);
								  })
								: null}
						</DataTableBody>
						<div className="card-inner">
							{currentItems.length > 0 ? (
								<PaginationComponent itemPerPage={itemPerPage} totalItems={platformCmsMeta.total} paginate={paginate} currentPage={currentPage} />
							) : (
								<div className="text-center">
									<span className="text-silent">No data found</span>
								</div>
							)}
						</div>
					</DataTable>
				</Block>
				<Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("content.platformcms.add")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.platformcms.name")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.title && <span className="invalid">{errors.title.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.platformcms.company")}</label>
												<RSelect name="company" options={companyRList} required onChange={handleChange} />
												{errors.company && <span className="invalid">{errors.company.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.platformcms.platform")}</label>
												<RSelect name="platform" options={platformRList} required onChange={handleChange} />
												{errors.platform && <span className="invalid">{errors.platform.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.platformcms.platform_id")}</label>
												<input
													className="form-control"
													type="text"
													name="platform_id"
													defaultValue={formData.platform_id}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.platform_id && <span className="invalid">{errors.platform_id.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("content.platformcms.edit")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onEditSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.platformcms.name")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.title && <span className="invalid">{errors.title.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.platformcms.company")}</label>
												<RSelect name="company" options={companyRList} required onChange={handleChange} defaultValue={formData.companyValue} />
												{errors.company && <span className="invalid">{errors.company.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.platformcms.platform")}</label>
												<RSelect name="platform" options={platformRList} required onChange={handleChange} defaultValue={formData.platformValue} />
												{errors.platform && <span className="invalid">{errors.platform.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("content.platformcms.platform_id")}</label>
												<input
													className="form-control"
													type="text"
													name="platform_id"
													defaultValue={formData.platform_id}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.platform_id && <span className="invalid">{errors.platform_id.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
			</Content>
		</React.Fragment>
	);
};
export default PlatformCms;
