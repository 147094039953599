import React from "react";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";
import Icon from "../icon/Icon";
import { useTranslation } from "react-i18next";

const PaginationComponent = ({ itemPerPage, totalItems, paginate, currentPage }) => {
	const { t, i18n } = useTranslation("common");
	const pageNumbers = [];

	for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
		pageNumbers.push(i);
	}
	const lastPage = Math.max(...pageNumbers);

	const nextPage = () => {
		if(currentPage < lastPage)
		paginate(currentPage + 1);
	};

	const prevPage = () => {
		if(currentPage > 1)
		paginate(currentPage - 1);
	};

	return (
		<Pagination aria-label="Page navigation example">
			{ totalItems > 10 &&
			<>
			<PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
				<PaginationLink
					className="page-link-prev"
					onClick={(ev) => {
						ev.preventDefault();
						prevPage();
					}}
					href="#prev"
				>
					<Icon name="chevrons-left" />
					<span>{t("general.prev")}</span>
				</PaginationLink>
			</PaginationItem>
			{pageNumbers.map((item) => {
				if (!(item > currentPage + 3) && !(item < currentPage - 3) || item === Math.ceil(totalItems / itemPerPage) || item === 1)
					return (
						<PaginationItem className={currentPage === item ? "active" : ""} key={Math.random().toString(16).slice(2)}>
							<PaginationLink
								tag="a"
								href="#pageitem"
								onClick={(ev) => {
									ev.preventDefault();
									paginate(item);
								}}
							>
								{item}
							</PaginationLink>
						</PaginationItem>
					);
				else if ((item === currentPage + 4) || (item === currentPage - 4)) {
					return (
						<PaginationItem key={Math.random().toString(16).slice(2)}>
							<PaginationLink
								className="page-link-next"
								onClick={(ev) => {
									ev.preventDefault();
									item === currentPage + 4 && item !== Math.ceil(totalItems / itemPerPage) ? nextPage() : item === currentPage - 4 && item !== 1 ? prevPage() : void(0)
								}}
								href="#next"
							>
								...
							</PaginationLink>
						</PaginationItem>
					);
				}
			})}

			<PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === currentPage}>
				<PaginationLink
					className="page-link-next"
					onClick={(ev) => {
						ev.preventDefault();
						nextPage();
					}}
					href="#next"
				>
					<span>{t("general.next")}</span>
					<Icon name="chevrons-right" />
				</PaginationLink>
			</PaginationItem>
			</>}
		</Pagination>
	);
};
export default PaginationComponent;
