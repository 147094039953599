import React, {  useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Progress, Nav, NavItem } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Row, Col, PreviewAltCard, PaginationComponent} from "../../../components/Component";
import { useTranslation } from "react-i18next";
import DynamicGraph from "../../../components/partials/reports/DynamicGraph";
import DynamicList from "../../../components/partials/reports/DynamicList";
import { profileServices, reportServices, usersService } from "../../../services";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FilterComponent } from "../../../components/FilterComponent";
import { DateSelect } from "../../../components/DateSelect";

const VideoKind = () => {

	const { t } = useTranslation("common");
	const [dates, setDates] = useState();
	const [dimension,setDimension] = useState('day');
	const [graphData,setGraphData] = useState();
	const [graphLabels,setGraphLabels] = useState();
	const [listData,setListData] = useState();
	const [listTotal,setListTotal] = useState();
	const [loading,setLoading] = useState(25);
	const [range,setRange] = useState();
	const [revenuePermission,setRevenuePermission] = useState(false);
	const [reportListData,setReportListData] = useState();
	const [reportsMeta,setReportsMeta] = useState();
	const [selectedItems,setSelectedItems] = useState([]);
	const [allFilterData, setAllFilterData] = useState({});
	const [graphColumns,setGraphColumns] = useState();
	const [selectedGraphData,setSelectedGraphData] = useState([]);
	const [selectedListData,setSelectedListData] = useState([]);
	const [allGraphData,setAllGraphData] = useState();

	const parameters = ['id'];
	const history = useHistory();
	const location = useLocation();
 	let { search } = useLocation();
 	const [searchParams, setSearchParams] = useState(location.search);
	 const query = new URLSearchParams(searchParams);

	 const [currentPage, setCurrentPage] = useState(1);
	 const [itemPerPage, setItemPerPage] = useState(10);

	 useEffect(() => {
		const query = new URLSearchParams(searchParams);
 		const hasPage = query.has('page');
		const hasLimit = query.has('limit'); 
		const hasDimension = query.has('dimension');
		if(hasPage)
		setCurrentPage(Number(query.get('page')));
		else 
		query.append("page", currentPage);
		if(hasLimit)
		setItemPerPage(Number(query.get('limit')));
		else 
		query.append("limit", itemPerPage);
		if(!hasDimension)
		query.append('dimension', dimension);
		else
		setDimension(query.get('dimension'));

		setSearchParams(decodeURI(query.toString()));
		history.replace({ pathname: location.pathname, search: decodeURI(query.toString())});

		getUserList();
	},[])

	useEffect(() => {
		setLoading(99);
		const query = new URLSearchParams(searchParams);
		const loadData = async () => {
			location.search = searchParams;
			history.replace({ pathname: location.pathname, search: location.search});
			query.set('type', "timeseries");
			if(!query.has('dimension'))
			query.set('dimension', dimension);
			const reportData = await reportServices.getUserReportsData(decodeURIComponent(query.toString()));
			setReportListData(reportData);
			setGraphStatistics(reportData);
			query.set('type', "totallist");
			const total = await reportServices.getUserReportsData(decodeURIComponent(query.toString()));
			setReportsMeta(total.meta);
			setListStatistics(total);
			setLoading(0)
		};
		if(query.has('from'))
		loadData();
	},[searchParams,dates]);

	const getUserList = async () => {
		const userList = await usersService.getUsers();
		if (userList?.payload?.length > 0) {
			const createRList = userList.payload.map((user) => {
				return { value: user.id, label: user.fullname };
			});
			setAllFilterData(current => ({...current, 'id' : createRList }));
		}
	};

	const setGraphStatistics = async (reportData) => {
		let items = [];
		if(selectedGraphData.length < 1 || reportData?.payload?.users.includes(selectedGraphData)){
			items = reportData?.payload?.users;
		}else {
			items = [...reportData?.payload?.users, ...selectedGraphData]
		}
		if(selectedItems.length > 0){
			let data = [];
			for(var selected of selectedItems){
				var selectedItem = items.find((us) => us.id === selected);
				data.push(selectedItem);
			};
			var longestLabels = [...data].sort((a,b) => (b.data?.labels?.length ? b.data?.labels?.length : 0) - (a.data?.labels?.length ? a.data?.labels?.length : 0));
			setGraphData(data);
			setGraphLabels(longestLabels[0].data?.labels);
		}else {
			setGraphData([]);
			var longestLabels = [reportData?.payload?.users].sort((a,b) => (b.data?.labels?.length ? b.data?.labels?.length : 0) - (a.data?.labels?.length ? a.data?.labels?.length : 0));
			setGraphLabels(reportData?.payload?.users[0]?.data?.labels)
		};
		setRange(reportData?.payload?.total_timeseries);
		setAllGraphData(items);
	}

	const setListStatistics = async (total) => {
		setListData(total.payload.users);
		setListTotal(total.payload.total);
	}

	useEffect(() =>{
		setGraphStatistics(reportListData);
	},[selectedItems])

	useEffect(() => {
		const getMeData = async () => {
			const me = await profileServices.getMe();
			var perm = me.payload.permissions.find((permission) => permission.name === "reports-revenue-list");
			perm ? setRevenuePermission(true) : setRevenuePermission(false);
		}
		getMeData();
	}, [dimension]);

	const [sortDirection,setSortDirection] = useState(true);
	const [sortBy,setSortBy] = useState();

	const sortData = (sort) => {
		const query = new URLSearchParams(searchParams);
		setSortBy(sortDirection ? "+" + sort : "-" + sort);
		if(sort === "name" || sort === "title" || sort === "fullname"){
			if(query.has('order'))
			query.delete('order');
			query.set('sort', sortDirection ? "" + sort : "-" + sort);
		}else{
			if(query.has('sort'))
			query.delete('sort');
			query.set('order', sortDirection ? "" + sort : "-" + sort)
		}
		setSearchParams(decodeURIComponent(query.toString()));
		setSortDirection(prevSortDirection => !prevSortDirection);
	};

	const setdimension = (dimension) => {
		setDimension(dimension);
		const query = new URLSearchParams(searchParams);
		query.set('dimension', dimension);
		setSearchParams(decodeURIComponent(query.toString()))
	};

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		const query = new URLSearchParams(search);
		query.set("page", pageNumber);
		query.set('limit', itemPerPage);
		setSearchParams(decodeURI(query.toString()));
	}

	return (
		<React.Fragment>
			<Head title={t("mainMenu.reports-user")} />
			<Content>
				<BlockHead size="sm">
					<div className="nk-block-between">
						<BlockHeadContent>
						<Nav tabs className="nav nav-tabs nav-tabs-mb-icon mb-3">
								<NavItem className="nav-item">
									<Link
										tag="a"
										className={"nav-link active"}
										to="/reports/users/video-kind"
									>
										<span>{t('modules.video-kind')}</span>
									</Link>
								</NavItem>
								<NavItem className="nav-item">
									<Link
										tag="a"
                                        className={"nav-link"}
										to={`/reports/users/video-details?${query.get('filter[id]') ? 'filter[user_id]=' + query.get('filter[id]') : ""}`}
									>
										<span>{t('videos.detail.page-title')}</span>
									</Link>
								</NavItem>
							</Nav>
							<BlockTitle page tag="h3">
								{t("mainMenu.reports-user")}
							</BlockTitle>
						</BlockHeadContent>
						<DateSelect setSelectedItems={setSelectedItems} setSelectedListData={setSelectedListData} searchParams={searchParams} setSearchParams={setSearchParams} dates={dates} setDates={setDates}/>
					</div>
				</BlockHead>

				<Block>
					<Row className="g-gs">
						<Col lg="6" xxl="12">
		 					<PreviewAltCard>
							 <div className="card-inner">
					<div className="card-title-group mb-3">
						<div className="card-title">
							{/* <h6 className="title"></h6> */}
						</div>
						<div className="card-tools">
							<UncontrolledDropdown>
								<DropdownToggle tag="a" href="#toggle" onClick={(ev) => ev.preventDefault()} className="link link-light link-sm dropdown-indicator">
									{dimension === "day" ? t("statistic.daily") : dimension === "month" ? t("statistic.monthly") : t("statistic.yearly")}
								</DropdownToggle>
								<DropdownMenu right className="dropdown-menu-sm">
									<ul className="link-list-opt no-bdr">
										<li>
											<DropdownItem
												tag="a"
												href="#dropdown"
												onClick={(ev) => {
													ev.preventDefault();
													setdimension("day");
												}}
												className={dimension === "day" ? "active" : ""}>
												<span>{t("statistic.daily")}</span>
											</DropdownItem>
										</li>
										<li>
											<DropdownItem
												tag="a"
												href="#dropdown"
												onClick={(ev) => {
													ev.preventDefault();
													setdimension("month");
												}}
												className={dimension === "month" ? "active" : ""}>
												<span>{t("statistic.monthly")}</span>
											</DropdownItem>
										</li>
										<li>
											<DropdownItem
												tag="a"
												href="#dropdown"
												onClick={(ev) => {
													ev.preventDefault();
													setdimension("year");
												}}
												className={dimension === "year" ? "active" : ""}>
												<span>{t("statistic.yearly")}</span>
											</DropdownItem>
										</li>
									</ul>
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</div>
				</div>
								<DynamicGraph columns={[{value : "amount", label : t(`statistic.amount`), id : 0 }]} selectedItems={selectedItems} range={range} data={graphData} labels={graphLabels} />
								{<Progress style={loading === 100 || loading === 0 ? {visibility: 'hidden'} : {visibility : 'visible'}} color="success" className="progress-md" animated value={loading} />	}
								<DynamicList 
								type="fullname"
								graphData={allGraphData}
								selectedListData={selectedListData}
								setSelectedListData={setSelectedListData}
								selectedData={selectedGraphData}
								setSelectedData={setSelectedGraphData}
								setGraphColumns={setGraphColumns}
								setSelectedItems={setSelectedItems}
								selectedItems={selectedItems}
								sortBy={sortBy}
								sortData={sortData}
								revenuePermission={revenuePermission}
								total={listTotal}
								data={listData}
								setData={setListData}
								rowTotal={1}>
								<FilterComponent
								 type="search" 
								 setSelectedItems={setSelectedItems} 
								 setSelectedData={setSelectedListData} 
								 history={history} 
								 data={allFilterData} 
								 parameters={parameters} 
								 search={search} 
								 setSearch={setSearchParams} 
								 currentPage={currentPage} 
								 setCurrentPage={setCurrentPage}
								 itemPerPage={itemPerPage}
								 setItemPerPage={setItemPerPage} />
								</DynamicList>
								<div className="mt-2">
									<PaginationComponent currentPage={currentPage} itemPerPage={itemPerPage} totalItems={reportsMeta?.total} paginate={paginate} />
								</div>
							</PreviewAltCard>
						</Col>
					</Row>
				</Block>
			</Content>
		</React.Fragment>
	);
};

export default VideoKind;