import React, { useState } from 'react'
import { useEffect } from 'react';
import { Icon } from './Component'

export const MultiSelect = ({ items, selectedItems, onChange, messages }) => {

    const [selected, setSelected] = useState(selectedItems);
    const [searchText, setSearchText] = useState("");

    const onCheckboxChange = (e, item) => {
        if(e.target.checked)
        setSelected(current => ([...current, item]));
        else
        setSelected(current => ([...current.filter((cr) => cr.id !== item.id)]));
    };

    const removeSelected = (item) => {
        setSelected(current => ([...current.filter((cr) => cr.id !== item.id)]));
    };

    const selectAll = () => {
        if(selected?.length < items?.length)
        setSelected([...items]);
        else
        setSelected([]);
    };

    const removeAll = () => {
        setSelected([]);
    };

    useEffect(() => {
        onChange(selected);
    },[selected]);

    return (
        <div className='multi-select-wrapper d-flex'>
            <div className="section-left border w-100">
                <div className="p-0-12 border-bottom">
                <input onChange={(e) => setSearchText(e.target.value)} placeholder={messages.searchPlaceholder ?? "Search..."} type="text" className='w-95' style={{border: 'none', outline: 'none', height : '45px'}}/>
                <Icon name="search" className="h5" />
                </div>
                <div className="border-bottom">
                    <div className='multi-select-text p-0-12 dark-on-hover'>
                        <input onChange={() => selectAll()} className='mr-2' id="select-all" type="checkbox" />
                        <label className='w-100 d-flex' htmlFor="select-all" style={{margin: '0', height: '45px', alignItems: 'center'}}>
                           <span>{messages.selectAllMessage ?? "Select All"}</span> 
                        </label>
                    </div>
                </div>
                <div className="items" style={{overflow: 'auto', height : '306px'}}>
                    {(items?.length > 0 && searchText === "") && items.map((item) => 
                        <div key={Math.random() * 100000} className='item multi-select-text dark-on-hover p-0-12'>
                            <input className='mr-2' id={`item${item.id}`} type="checkbox" checked={selectedItems?.find((selected) => selected.id === item.id) ? true : false} onChange={(e) => onCheckboxChange(e, item)} />
                            <label className='w-100 d-flex' htmlFor={`item${item.id}`} style={{margin: '0', height: '45px', alignItems: 'center'}}>
                                 <span>{item.label}</span> 
                            </label>
                        </div>
                    )}
                    {(searchText !== "" && items.filter((itm) => itm.label.toLowerCase().includes(searchText.toLowerCase())).length > 0) ? items?.filter((itm) => itm.label.toLowerCase().includes(searchText.toLowerCase())).map((item) => 
                        <div key={Math.random() * 100000} className='item multi-select-text dark-on-hover p-0-12'>
                            <input className='mr-2' id={`item${item.id}`} type="checkbox" checked={selectedItems?.find((selected) => selected.id === item.id) ? true : false} onChange={(e) => onCheckboxChange(e, item)} />
                            <label className='w-100 d-flex' htmlFor={`item${item.id}`} style={{margin: '0', height: '45px', alignItems: 'center'}}>
                                 <span>{item.label}</span> 
                            </label>
                        </div>
                    ) :  <div className='centered-message'>{messages.noItemsMessage ?? "No Items..."}</div>}
                    {(!items.length > 0 && searchText === "") && <div className='centered-message'>{messages.noItemsMessage ?? "No Items..."}</div>}
                </div>
            </div>
            <div className="section-right w-100">
            <div className="p-0-12 border-bottom">
                <div className='multi-select-text d-flex' style={{justifyContent: 'space-between'}}>
                    <span className='text-dark'>{selectedItems?.length > 0 ? messages.selectedMessage ? `${selectedItems?.length} ${messages.selectedMessage}` : `${selectedItems?.length} selected` : messages.noneSelectedMessage ? messages.noneSelectedMessage : "None selected"}</span>
                    {selectedItems?.length > 0 && <div className='text-danger' style={{cursor: 'pointer'}} onClick={() => removeAll()}>{messages.clearAllMessage ?? "Clear All"}</div>}
                </div>
                </div>
                <div className="items" style={{overflow: 'auto', height : '353px'}}>
                    {selectedItems?.length > 0 ? selectedItems.map((item) => 
                        <div key={Math.random() * 100000} className='item multi-select-text dark-on-hover p-0-12'>
                        <div className='w-100 d-flex' style={{margin: '0', height: '45px', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
                            <span>{item.label}</span> 
                            <Icon onClick={() => removeSelected(item)} className="h5" name={"cross"} />
                        </div>
                    </div>
                    ) : <div className='centered-message'>{messages.noItemsMessage ?? "No Items..."}</div>}
                </div>
            </div>
        </div>
    )
}