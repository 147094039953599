import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, Icon, Button, Row, Col, PreviewAltCard } from "../components/Component";
import { useTranslation } from "react-i18next";
import TrafficChannel from "../components/partials/analytics/traffic-channel/Traffic";
import TopProducts from "../components/partials/default/top-products/TopProducts";
import ViewsAndRevenue from "../components/partials/dashboard/ViewsAndRevenue";
import AmountOfWork from "../components/partials/dashboard/CompletedJobs";
import moment from "moment";

const DashboardWithoutRevenues = ({ ...props }) => {
	const { t } = useTranslation("common");

	const [sm, updateSm] = useState(false);
	const [timerange, setTimerange] = useState("last-28");
	const [dates, setDates] = useState();
	const [dimension,setDimension] = useState();
	useEffect(() => {
		let dateObject = {
			from : "",
			to : "",
		};
		var dateTo = moment().subtract(1, 'days');
		var dateFrom;
		const getDates = () => {
			switch (timerange) {
				case "last-7":
					dateFrom = moment(dateTo).subtract(7, 'days');
					break;
				case "last-28":
					dateFrom = moment(dateTo).subtract(28, 'days');
					break;
				case "last-90":
					dateFrom = moment(dateTo).subtract(90, 'days');
					break;
				case "last-365":
					dateFrom = moment(dateTo).subtract(365, 'days');
					break;
				default:
					break;
			}
			dateObject.from = dateFrom.format('YYYY-MM-DD');
			dateObject.to = dateTo.format('YYYY-MM-DD');
		}
		getDates();
		setDates(dateObject);
	}, [timerange])

	return (
		<React.Fragment>
			<Head title={t("general.my-dashboard")} />
			<Content>
				<BlockHead size="sm">
					<div className="nk-block-between">
						<BlockHeadContent>
							<BlockTitle page tag="h3">
								{t("general.my-dashboard")}
							</BlockTitle>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
									<Icon name="more-v"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3">
										<li>
											<UncontrolledDropdown>
												<DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
													<Icon className="d-none d-sm-inline" name="calender-date"></Icon>
													<span>
														<span className="d-none d-md-inline">{t("statistic.last")}</span> {timerange.split("-")[1]} {t("statistic.days")}
													</span>
													<Icon className="dd-indc" name="chevron-right"></Icon>
												</DropdownToggle>
												<DropdownMenu>
													<ul className="link-list-opt no-bdr">
														<li>
															<DropdownItem
																href="#dropdownitem"
																onClick={(ev) => {
																	ev.preventDefault();
																	setTimerange("last-7");
																	setDimension("day");
																}} >
																{t("statistic.last")} 7 {t("statistic.days")}
															</DropdownItem>
														</li>
														<li>
															<DropdownItem
																href="#dropdownitem"
																onClick={(ev) => {
																	ev.preventDefault();
																	setTimerange("last-28");
																	setDimension("day");
																}} >
																{t("statistic.last")} 28 {t("statistic.days")}
															</DropdownItem>
														</li>
														<li>
															<DropdownItem
																href="#dropdownitem"
																onClick={(ev) => {
																	ev.preventDefault();
																	setTimerange("last-90")
																	setDimension("day");
																}} >
																{t("statistic.last")} 90 {t("statistic.days")}
															</DropdownItem>
														</li>
														<li>
															<DropdownItem
																href="#dropdownitem"
																onClick={(ev) => {
																	ev.preventDefault();
																	setTimerange("last-365");
																	setDimension("month");
																}} >
																{t("statistic.last")} 365 {t("statistic.days")}
															</DropdownItem>
														</li>
													</ul>
												</DropdownMenu>
											</UncontrolledDropdown>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</div>
				</BlockHead>

				<Block>
					<Row className="g-gs">
						<Col md="12" lg="9" xl="9" xxl="9">
							<PreviewAltCard>
								{dates && <ViewsAndRevenue revenue={false} dates={dates} data={timerange} dim={dimension ?? 'day'} />}
							</PreviewAltCard>
						</Col>
						<Col md="12" lg="3" xl="3" xxl="3">
							<PreviewAltCard className="h-100">
								{dates && <AmountOfWork dates={dates} data={timerange} />}
							</PreviewAltCard>
						</Col>
					</Row>
				</Block>

				<BlockHead size="sm">
					<div className="nk-block-between mt-5">
						<BlockHeadContent className={"mt-5"}>
							<BlockTitle page tag="h3">
							{t("statistic.best_channels")}
							</BlockTitle>
						</BlockHeadContent>
					</div>
				</BlockHead>

				<Block>
					<Row className="g-gs">
						<Col lg="7" xxl="6">
							<Card className="h-100">
								{dates && <TrafficChannel dates={dates} />}
							</Card>
						</Col>
						<Col lg="7" xxl="6">
							<Card className="h-100">
								{dates && <TopProducts revenue={false} dates={dates} />}
							</Card>
						</Col>
					</Row>
				</Block>
			</Content>
		</React.Fragment>
	);
};

export default DashboardWithoutRevenues;

