import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import { reportServices } from "../../../../services";
import { useTranslation } from "react-i18next";


const TopProducts = ({dates, revenue}) => {
	const { t, i18n } = useTranslation("common");

	const [totalList,setTotalList] = useState();
		useEffect(() => {
			const getStatistics = async (date) => {
				const body = {
					"from": date.from,
					"to": date.to,
					"type": "totallist",
				};
				const res = await reportServices.getChannelData(body);
				setTotalList(res.payload.channels.sort((a,b) => Number(b.data.revenue_sum) - Number(a.data.revenue_sum)));
			}
			if(dates) getStatistics(dates);
	}, [dates]);
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0, 
		minimumFractionDigits: 0, 
	  });
	return (
		<Card className="h-100">
			<div className="card-inner">
				<div className="card-title-group mb-2">
					<div className="card-title">
						<h6 className="title">{t("statistic.top_earning_channels")}</h6>
						<p>{t("statistic.top_earning_channels_subtext")}</p> 
					</div>
				</div>
				<ul className="nk-top-products">
					{totalList ? totalList.map((item, idx) => (
						<li className="item" key={idx}>
							<div className="thumb">
								<img src={item.cover} alt="" />
							</div>
							<div className="info">
								<div className="title">{item.title}</div>
								{revenue &&	<div className="price">{t("statistic.rpm")}: {!isNaN(item.data.revenue_sum / (item.data.view_sum / 1000)) ? formatter.format(item.data.revenue_sum / (item.data.view_sum / 1000)) : "--"} </div>}
							</div>
							<div className="total">
								{revenue && <div className="amount">{!isNaN(item.data.revenue_sum / (item.data.view_sum / 1000)) ? formatter.format(item.data.revenue_sum) : "--"}</div>}
								 <div className="count">{!isNaN((item.data?.view_sum / 1000).toFixed(1)) ? (item.data?.view_sum / 1000).toFixed(1) +  t("statistic.k") + " " + t("statistic.views") : "--"}</div>
							</div>
						</li>
					)) : <h4 className="text-center">No Data</h4>}
				</ul>
			</div>
		</Card>
	);
};

export default TopProducts;
