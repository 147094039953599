
import React, { useEffect, useState } from 'react'
import {  Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

export const UserDoughnut = ({className,data}) => {
	const { t, i18n } = useTranslation("common");
    const colors = ["#798bff", "#b8acff", "#ffa9ce", "#9FE2BF", "#DE3163", "#FF7F50", "#FFBF00", "#CCCCFF", "#6495ED", "#40E0D0" ]

	const [totalList,setTotalList] = useState();
	useEffect(() => {
			setTotalList({
				labels: Object.keys(data).map((item) => t(`statistic.${item}`)),
				dataUnit: "People",
				legend: false,
				datasets: [
					{
						borderColor: "#fff",
						backgroundColor: colors,
						data: Object.values(data),
					},
				],
			})
	},[data])

  return (
	<React.Fragment>
	{totalList && <Doughnut
		className={className}
		data={totalList}
		options={{
			legend: {
				display: false,
			},
			rotation: -1.5,
			cutoutPercentage: 70,
			maintainAspectRatio: false,
			tooltips: {
				enabled: true,
				backgroundColor: "#fff",
				borderColor: "#eff6ff",
				borderWidth: 2,
				titleFontSize: 13,
				titleFontColor: "#6783b8",
				titleMarginBottom: 6,
				bodyFontColor: "#9eaecf",
				bodyFontSize: 12,
				bodySpacing: 4,
				yPadding: 10,
				xPadding: 10,
				footerMarginTop: 0,
				displayColors: false,
			},
		}}
	></Doughnut> }
	</React.Fragment>
	
  )
}
