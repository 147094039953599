import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/roles'
 */
export async function getRoles() {
	const endpoint = generateApiEndpoint("roles?limit=all");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/roles'
 * Params: { page, limit }
 */
export async function getRolesByPageAndLimit({ page, limit }) {
	const endpoint = generateApiEndpoint(`roles?limit=${limit}&page=${page}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/roles/{roleId}'
 */
export async function getRoleById(roleId) {
	const endpoint = generateApiEndpoint(`roles/${roleId}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/roles'
 * Params: { data: {
 *     name, guard_name
 * }}
 */
export async function addRole(data) {
	const endpoint = generateApiEndpoint("roles");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	return response.data;
}

/**
 * POST: '/api/roles/assign'
 * Params: { data: {
 *     user_id, role_name
 * }}
 */
export async function userAssign({ userIds, role }) {
	const endpoint = generateApiEndpoint("roles/assign");
	const response = await restApiClient.post(endpoint, {
		role_name: role,
		user_id: [...userIds],
	});

	return response.data;
}

/**
 * PUT: '/api/roles/{roleId}'
 * Params: { data: {
 *     name, guard_name
 * 	}}
 */
export async function updateRole(roleId, data = null) {
	const endpoint = generateApiEndpoint(`roles/${roleId}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * DELETE: '/api/roles/{roleId}'
 */
export async function deleteRole(roleId) {
	const endpoint = generateApiEndpoint(`roles/${roleId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}

/**
 * GET: '/api/permissions'
 */
export async function getPermissions() {
	const endpoint = generateApiEndpoint("permissions");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/permissions'
 * Params: { limit: all }
 */
export async function getAllPermissions() {
	const endpoint = generateApiEndpoint("permissions?limit=all");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/permissions/sync'
 * Params: { role_name, permission_name }
 */
export async function syncPermissions(data) {
	const endpoint = generateApiEndpoint("permissions/sync");
	const response = await restApiClient.post(endpoint, data);

	return response.data;
}
