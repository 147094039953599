import React, { useState, useEffect, useContext } from "react";
import Content from "../../layout/content/Content";
import { Route, Switch, Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component";
import { findUpper } from "../../utils/Utils";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../layout/context/LanguageContext";

import UserProfileRegularPage from "./UserProfileRegular";
import UserProfileSettingPage from "./UserProfileSetting";

const UserProfileLayout = () => {
	const { t, i18n } = useTranslation("common");

	const [sm, updateSm] = useState(false);
	const [mobileView, setMobileView] = useState(false);

	const authUserObj = JSON.parse(localStorage.getItem("authUser"));
	const [authUser, setAuthUser] = useState(authUserObj);

	// function to change the design view under 990 px
	const viewChange = () => {
		if (window.innerWidth < 990) {
			setMobileView(true);
		} else {
			setMobileView(false);
			updateSm(false);
		}
	};

	useEffect(() => {
		viewChange();
		window.addEventListener("load", viewChange);
		window.addEventListener("resize", viewChange);
		document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
			updateSm(false);
		});
		return () => {
			window.removeEventListener("resize", viewChange);
			window.removeEventListener("load", viewChange);
		};
	}, []);

	return (
		<React.Fragment>
			<Content>
				<Card className="card-bordered">
					<div className="card-aside-wrap">
						<div className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""}`}>
							<div className="card-inner-group">
								<div className="card-inner">
									<div className="user-card">
										<UserAvatar text={findUpper(authUser?.fullname)} theme="primary" />
										<div className="user-info">
											<span className="lead-text">{authUser?.fullname}</span>
											<span className="sub-text">{authUser?.email}</span>
										</div>
										<div className="user-action">
											<UncontrolledDropdown>
												<DropdownToggle tag="a" className="btn btn-icon btn-trigger mr-n2">
													<Icon name="more-v"></Icon>
												</DropdownToggle>
												<DropdownMenu right>
													<ul className="link-list-opt no-bdr">
														<li>
															<DropdownItem
																tag="a"
																href="#dropdownitem"
																onClick={(ev) => {
																	ev.preventDefault();
																}}
															>
																<Icon name="camera-fill"></Icon>
																<span>{t("profile.change-picture")}</span>
															</DropdownItem>
														</li>
													</ul>
												</DropdownMenu>
											</UncontrolledDropdown>
										</div>
									</div>
								</div>
								<div className="card-inner p-0">
									<ul className="link-list-menu">
										<li onClick={() => updateSm(false)}>
											<Link to={`${process.env.PUBLIC_URL}/profile`} className={window.location.pathname === `${process.env.PUBLIC_URL}/profile` ? "active" : ""}>
												<Icon name="user-fill-c"></Icon>
												<span>{t("profile.personal-infos")}</span>
											</Link>
										</li>
										<li onClick={() => updateSm(false)}>
											<Link to={`${process.env.PUBLIC_URL}/profile/settings`} className={window.location.pathname === `${process.env.PUBLIC_URL}/profile/settings` ? "active" : ""}>
												<Icon name="lock-alt-fill"></Icon>
												<span>{t("profile.security-settings")}</span>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="card-inner card-inner-lg">
							{sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
							<Switch>
								<Route exact path={`${process.env.PUBLIC_URL}/profile`} render={() => <UserProfileRegularPage updateSm={updateSm} sm={sm} authUser={authUser} setAuthUser={setAuthUser} />}></Route>
								<Route exact path={`${process.env.PUBLIC_URL}/profile/settings`} render={() => <UserProfileSettingPage updateSm={updateSm} sm={sm} />}></Route>
							</Switch>
						</div>
					</div>
				</Card>
			</Content>
		</React.Fragment>
	);
};

export default UserProfileLayout;
