import React, {useState, useEffect} from "react"
import {
	Container, Card, CardBody, CardFooter, Row, Col, Form, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Alert,
} from "reactstrap"
import classnames from "classnames";
import {Link, useLocation} from "react-router-dom"
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Button, Icon, RSelect} from "../../../components/Component"
import {parametersServices, videoServices} from "../../../services";
import {Button as Btn, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import "react-datetime/css/react-datetime.css";
import DateTime from "react-datetime";
import moment from 'moment';
import {Monetization} from "../../../components/videos/Monetization";
import MultiStepProgressBar from "../../../components/MultiStepProgressBar/MultiStepProgressBar";

const YoutubeVideoCategory = [{
	"kind": "youtube#videoCategory", "etag": "JherwDYjWkAPxocPXFkk3hG7k0U", "id": "1", "snippet": {
		"title": "Film ve Animasyon", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "QT2Q8C9K8F_O2nUNHnGzYfuClik", "id": "2", "snippet": {
		"title": "Otomobiller ve Araçlar", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "ZAeahNHKgMd4akwJx2REUkCHXgo", "id": "10", "snippet": {
		"title": "Müzik", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "F3b-IfpBKoIzIyNeYUiBJGTfRQ0", "id": "15", "snippet": {
		"title": "Ev Hayvanları ve Hayvanlar", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "ZB1qChwb--bHtsmS6DNuBFxFepk", "id": "17", "snippet": {
		"title": "Spor", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "E9VW6A7LetXyxlf52OKga70a1D8", "id": "18", "snippet": {
		"title": "Kısa Filmler", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "7CLK_ZOaaZpfiWyTwURnDUVL_DA", "id": "19", "snippet": {
		"title": "Seyahat ve Etkinlikler", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "cOxkhf1co0JvicmvSzwqabOAtdk", "id": "20", "snippet": {
		"title": "Oyun", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "0cPuQJsgaDPDzxpU6MGzMbKk08o", "id": "21", "snippet": {
		"title": "Video blogu", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "OHzvpzN-KKHln8wOJ7kFy-rpRJE", "id": "22", "snippet": {
		"title": "Kişiler ve Bloglar", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "eZGq5tzdruhszBJTtCBXizhxywo", "id": "23", "snippet": {
		"title": "Komedi", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "oOUyKFRfeoXSvSJJK8JSeY_axhs", "id": "24", "snippet": {
		"title": "Eğlence", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "iKACeXRXBbmesbNgyA77vqA38aQ", "id": "25", "snippet": {
		"title": "Haberler ve Politika", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "kDEAteIjxLqTFaYO-QOR6hDYYn4", "id": "26", "snippet": {
		"title": "Nasıl Yapılır ve Stil", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "fokLGKkYWrdu1zvLQKc35JvG9sw", "id": "27", "snippet": {
		"title": "Eğitim", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "TLOzzcr78c9x7vUgVmbBSZ2CJjY", "id": "28", "snippet": {
		"title": "Bilim ve Teknoloji", "assignable": true, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "bR3V3VHj86pOC1GBr6VLX-LuOIA", "id": "30", "snippet": {
		"title": "Filmler", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "cJ5lfaGGwHXd8Nd_dyFQ8_LhC9A", "id": "31", "snippet": {
		"title": "Anime/Animasyon", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "0JdA7gPqqpy0L7VumJinRVqxSV0", "id": "32", "snippet": {
		"title": "Aksiyon/Macera", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "Q30wjTdvKh5rdtByytnUuQFq5FE", "id": "33", "snippet": {
		"title": "Klasikler", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "dBgzufADy9LwONL0fcIJYoCoESs", "id": "34", "snippet": {
		"title": "Komedi", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "_skEnUWZAQ4ppPDRF6AAEPGCVog", "id": "35", "snippet": {
		"title": "Belgesel", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "eAl2b-uqIGRDgnlMa0EsGZjXmWg", "id": "36", "snippet": {
		"title": "Drama", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "xgy9vL-dINCzwqq4qyT2c8hyCu0", "id": "37", "snippet": {
		"title": "Aile", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "rf4qOBFsx-zZsqqbHpOvEshjnmc", "id": "38", "snippet": {
		"title": "Yabancı", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "bQoddpwYRDuM2Uum96IvbzvdqQs", "id": "39", "snippet": {
		"title": "Korku", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "iRG_Z9bBwkS_ro8EYd0PSl-NKg8", "id": "40", "snippet": {
		"title": "Bilim Kurgu/Fantastik", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "r6ILLWqJQc1V1cnEHlqfuCKCfSo", "id": "41", "snippet": {
		"title": "Gerilim", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "tO_xcxe6bE9H7Cnq877ro9xCVXE", "id": "42", "snippet": {
		"title": "Kısa Filmler", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "PmNQDKNVbvVsDgc6ED796oSeee8", "id": "43", "snippet": {
		"title": "Gösterimler", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}, {
	"kind": "youtube#videoCategory", "etag": "nnPE3CbxdYkc0JpIj0b1PhMNVTM", "id": "44", "snippet": {
		"title": "Fragmanlar", "assignable": false, "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
	}
}];

const YoutubeVideoEdit = (props) => {
	const {t} = useTranslation("common");
	const [platformVideo, setPlatformVideo] = useState({});
	const [activeTab, setActivetab] = useState("1");
	const [tags, setTags] = useState([]);
	const [unsentTagLength, setUnsentTagLength] = useState();
	const [thumbnail, setThumbnail] = useState();
	const toggleTab = (tabId) => {
		setActivetab(tabId)
	};

	const formRawData = {
		title: "", videoKind: "", privacyStatus: "", tags: []
	};

	const [formData, setFormData] = useState(formRawData);
	const [serviceStatus, setServiceStatus] = useState(false);
	const [languageRList, setLanguageRList] = useState([]);


	useEffect(() => {
		getLanguageList();
		getVideoPlatformData();
	}, []);

	const getLanguageList = async () => {
		const languageList = await parametersServices.getLanguages();
		if (languageList?.payload?.length > 0) {
			const createRList = languageList.payload.map((language, key) => {
				return {value: language.iso, label: language.name,};
			});
			setLanguageRList(createRList);
		}
	};

	const getVideoPlatformData = async () => {
		try {
			let datePickerElement = document.querySelector('#date-picker');
			datePickerElement.classList.add('invisible');
			const getPlatformVideo = await videoServices.getVideoByPlatform(props.video.id, props.video.platform);
			setPlatformVideo(getPlatformVideo.payload);
			let publishedAtTimestamp = new Date(getPlatformVideo.payload.status.publishAt).getTime();
			let now = new Date().getTime();
			let privacyStatus = getPlatformVideo.payload.status.privacyStatus;
			if (now < publishedAtTimestamp) {
				privacyStatus = "scheduled";
				datePickerElement.classList.replace('invisible', "visible");
			}
			setTags(getPlatformVideo.payload.snippet.tags ?? []);
			setFormData({
				embedHtml: getPlatformVideo.payload.player.embedHtml,

				title: getPlatformVideo.payload.snippet.title,
				description: getPlatformVideo.payload.snippet.description,
				categoryId: getPlatformVideo.payload.snippet.categoryId,
				defaultAudioLanguage: getPlatformVideo.payload.snippet.defaultAudioLanguage,
				publishAt: getPlatformVideo.payload.status.publishAt ?? "",
				thumbnails: getPlatformVideo.payload.snippet.thumbnails,

				embeddable: getPlatformVideo.payload.status.embeddable,
				license: getPlatformVideo.payload.status.license,
				madeForKids: getPlatformVideo.payload.status.madeForKids ?? false,
				privacyStatus: privacyStatus,
				publicStatsViewable: getPlatformVideo.payload.status.publicStatsViewable,
				uploadStatus: getPlatformVideo.payload.status.uploadStatus,
				tokenStatus: props.video.channel.token_status,
				commentCount: getPlatformVideo.payload.statistics.commentCount,
				favoriteCount: getPlatformVideo.payload.statistics.favoriteCount,
				likeCount: getPlatformVideo.payload.statistics.likeCount,
				viewCount: getPlatformVideo.payload.statistics.viewCount,
				defaultLanguage: getPlatformVideo.payload.snippet.defaultLanguage,
			});
			setServiceStatus(true);
		} catch (error) {
			console.debug(error);
		}
	}

	useEffect(() => {
		//console.log("platformVideo:", platformVideo);
	}, [platformVideo]);

	const onVideoSubmit = async (submitData) => {
		const {title, description} = submitData;
		let submittedData = {
			snippet_category_id: formData.categoryId,//(int)
			snippet_default_language: formData.defaultLanguage,//(string)
			snippet_default_audio_language: formData.defaultAudioLanguage,//(string)
			snippet_description: description,//(string)
			snippet_tags: tags,//(array)
			snippet_title: title,//(string)
			//localization_title:  formData.categoryId,//(string)
			//localization_description:  formData.categoryId,//(string)
			status_embeddable: formData.embeddable,//(boolean)
			status_license: formData.license,//(string)
			status_made_for_kids: formData.madeForKids,//(boolean)
			status_self_declared_made_for_kids: formData.madeForKids,//(boolean)
			status_privacy_status: formData.privacyStatus,//(boolean)
			status_publish_at: formData.publishAt,//(datetime) is privacy_status: private
			status_public_stats_viewable: formData.publicStatsViewable,//(boolean)
		};
		try {
			//console.log("submittedData: ", submittedData);
			const response = await videoServices.updateVideoByPlatform(props.video.id, props.video.platform, {
				...submittedData,
			});
			await videoServices.updateVideo(props.video.id, {
				title: title,
				description: description,
				privacy_status: formData.privacyStatus,
				published_at: formData.publishAt
			});
			if (thumbnail) {
				const formdata = new FormData();
				formdata.append("image", thumbnail);
				await videoServices.uploadVideoThumbnail(props.video.id, formdata);
			}
			getVideoPlatformData();
			response.message = response?.message === "" ? t("error.success") : response?.message;
			props.alertCallback({
				status: true, type: "success", icon: "check-circle", message: response?.message,
			});
		} catch (error) {
			const {response} = error;
			props.alertCallback({
				status: true, type: "danger", icon: "cross-circle", message: response?.data?.message ?? error.message,
			});
		}
	};

	const onMadeForKidsChange = (result) => {
		setFormData({...formData, "madeForKids": result.target.value === "madeForKidsTrue" ? true : false});
	}

	const handleChangeStatus = (e) => {
		setFormData({...formData, "privacyStatus": e.target.value});
		let datePickerElement = document.querySelector('#date-picker');
		if (e.target.value === "scheduled") {
			datePickerElement.classList.replace('invisible', "visible");
			document.querySelector('#date-picker > div > input').focus();
		} else {
			datePickerElement.classList.add('invisible');
			setFormData({...formData, "privacyStatus": e.target.value, publishedAt: null});
		}
		;
	};

	const handleChangeCategory = (result) => {
		setFormData({...formData, "categoryId": result.target.value});
	};

	const handleChangeCheckBox = (result) => {
		setFormData({...formData, [result.target.name]: result.target.checked});
	};

	const changeTags = (tags) => {
		setFormData({...formData, tags: tags});
	};

	const {errors, register, handleSubmit} = useForm();

	const [modalStatus, setModalStatus] = useState({
		confirmation: false, secondConfirmation: false
	})

	const toggleModal = () => {
		setModalStatus(current => ({...current, confirmation: !current.confirmation}));
	}

	const toggleSecondModal = (e) => {
		e?.preventDefault();
		setModalStatus(current => ({confirmation: false, secondConfirmation: !current.secondConfirmation}));
	}

	const [redirectedFrom, setRedirectedFrom] = useState();
	const {search} = useLocation();
	const [extensionStatus, setExtensionStatus] = useState(false);

	useState(() => {
		let query = new URLSearchParams(search);
		if (query.get("endscreen") === `true`) setRedirectedFrom("endscreen"); else if (query.get("vcard") === `true`) setRedirectedFrom("vcard"); else setRedirectedFrom("false")
		const checkExtension = () => {
			const extStatus = document.querySelector('body').getAttribute('data-extension');
			if (extStatus == 'true') setExtensionStatus(true); else setExtensionStatus(false);
		};
		checkExtension();
	}, [])

	useEffect(() => {
		const updateVideoData = async () => {
			switch (redirectedFrom) {
				case "endscreen":
					await videoServices.updateVideo(props.video.id, {endscreen: true});
					window.location.href = window.location.href.split('?')[0]
					break;
				case "vcard":
					await videoServices.updateVideo(props.video.id, {vcard: true});
					window.location.href = window.location.href.split('?')[0]
					break;
				default:
					void (0);
					break;
			}
		}
		updateVideoData();
	}, [redirectedFrom]);

	let yesterday = DateTime.moment().subtract(1, 'day');
	let valid = function (current) {
		return current.isAfter(yesterday);
	};

	const handleOnKeyUp = async (event) => {
		let value = event.target.value;
		if (tags.toString().length + value.length > 500) return;
		let isDuplicate = tags.find((tag) => tag == value) || tags.find((tag) => tag == value.slice(0, -1)) ? true : false;
		const removeDuplicates = (multipleTags) => {
			let result = [];
			let uniqueTags = [];
			multipleTags.forEach((c) => {
				if (!uniqueTags.includes(c)) {
					uniqueTags.push(c);
				}
			});
			for (var tag of uniqueTags) {
				var duplicate = tags.find((t) => t === tag);
				if (!duplicate && tag !== "") result.push(tag);
			}
			return result;
		}
		if (event.key === "Enter" || event.key === "," || event.key === "Tab") {
			if (!isDuplicate && value.length > 0) {
				if (event.key === ",") {
					if (value.slice(0, -1).split(',').length > 1) {
						await setTags(current => [...current, ...removeDuplicates(event.target.value.slice(0, -1).split(','))]);
						event.target.value = "";
					} else {
						await setTags(current => [...current, event.target.value.slice(0, -1)]);
						event.target.value = "";
					}
				} else {
					if (value.split(',').length > 1) {
						await setTags(current => [...current, ...removeDuplicates(event.target.value.split(','))]);
						event.target.value = "";
					} else {
						await setTags(current => [...current, event.target.value]);
						event.target.value = "";
					}
				}
			}
			setUnsentTagLength(0);
		}
	};

	const handleOnKeyDown = async (event) => {
		var value = event.target.value
		if (!value && tags.length) {
			var newTagsArray = tags.slice(0, -1);
			await setTags(newTagsArray);
		}
	}

	const removeTag = (tags, tag) => {
		var newTagsArray = tags.filter((t) => t !== tag)
		setTags(newTagsArray);
	};

	const onFileSelect = (e) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length > 0) {
			setThumbnail(e.target.files[0]);
		}
	};

	return (<Container>
		<Form id="form" onSubmit={handleSubmit(onVideoSubmit)}>
			<Row>
				<Col sm={8}>
					<Card>
						<CardBody>
							<MultiStepProgressBar page={activeTab} onPageNumberClick={setActivetab}/>
							<div className="d-flex justify-content-center">
								<div className="d-flex justify-content-around mb-2 col-lg-7">
									<div onClick={() => setActivetab("1")} className="ml-4 cursor-pointer"
									     style={{marginRight: '5rem'}}>
										{t('operations.settings')}
									</div>
									<div onClick={() => setActivetab("2")} className="cursor-pointer">
										{t('videos.publish.tab-monetization')}
									</div>
								</div>
							</div>
							{!formData.tokenStatus && <div className="alert alert-icon alert-danger" role="alert">
								<em className="icon ni ni-alert-circle"/>
								{t('content.channel.settings.not_connected')}
							</div>}
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									<FormGroup>
										<label className="form-label">{t("videos.detail.title")}</label>
										<input
											className="form-control form-control-xl"
											type="text"
											name="title"
											defaultValue={formData.title}
											maxLength="100"
											ref={register({
												required: t("operations.required"),
											})}
											disabled={!formData.tokenStatus ? true : false}
										/>
										{errors.title && <span className="invalid">{errors.title.message}</span>}
									</FormGroup>

									<FormGroup>
										<label className="form-label">{t("videos.detail.description")}</label>
										<textarea
											className="form-control form-control-lg"
											type="text"
											name="description"
											defaultValue={formData.description}
											maxLength="5000"
											ref={register({
												required: t("operations.required"),
											})}
											style={{maxHeight: "960px", minHeight: "260px"}}
											disabled={!formData.tokenStatus ? true : false}
										/>
										{errors.description &&
											<span className="invalid">{errors.description.message}</span>}
									</FormGroup>

									<FormGroup>
										<Row>
											<Col md="12" key={formData.defaultLanguage}>
												<label
													className="form-label">{t("videos.detail.defaultLanguage")}</label>
												<RSelect placeholder={"-"} name="defaultLanguage"
												         options={languageRList}
												         defaultValue={languageRList.find((item) => item.value === formData.defaultLanguage)}
												         onChange={(item) => setFormData({
													         ...formData,
													         "defaultLanguage": item.value
												         })}/>
											</Col>
										</Row>
									</FormGroup>
									<FormGroup>
										<Row>
											<Col md="12">
												<label className="form-label">{t("videos.detail.thumbnail")}</label>
												<input type="file" id="thumbnail" className="form-control"
												       accept="image/*" name="logo"
												       onChange={(e) => onFileSelect(e)}/>
											</Col>
										</Row>
									</FormGroup>
									<FormGroup key={formData.tags}>
										<label className="form-label">{t("videos.detail.tags")}</label>
										<label id="tags-wrapper" aria-labelledby="tags"
										       className="wrapper form-control form-control-md">
											<span className="tags">
											{tags && tags.map((tag, key) =>
												<span className="tag my-auto mr-1"  key={key}>
													{tag}
													<Icon onClick={() => removeTag(tags, tag)} className="tag-remove" name="cross-sm"></Icon>
												</span>
											)}
											</span>
											<input
												id="input-tags"
												type="text"
												name="tags"
												placeholder={"Video etiketlerini giriniz..."}
												onChange={(e) => setUnsentTagLength(e.target.value.length ?? 0)}
												onKeyDown={(e) => {
													if (e.key === "Tab") {
														e.preventDefault()
													} else if (e.key === "Backspace") {
														handleOnKeyDown(e)
													}
												}}
												onKeyUp={handleOnKeyUp}
												style={{outline: '0', border: '0', width: '50%'}}
												disabled={!formData.tokenStatus ? true : false}
											/>
										</label>
										<div className="text-right"><span
											className={(tags.toString().length + (unsentTagLength ?? 0)) < 500 ? "text-success" : "text-danger"}>{tags.toString().length + (unsentTagLength ?? 0)}</span> /
											500 {}</div>
										{errors.tags && <span className="invalid">{errors.tags.message}</span>}
									</FormGroup>
									<span
										className="preview-title overline-title">{t('videos.detail.is_made_for_kids')}</span>
									<FormGroup>
										<div className={"custom-control custom-radio"}>
											<input disabled={!formData.tokenStatus ? true : false}
											       id={"madeForKidsRadioYes"} type="radio"
											       className={"custom-control-input form-control"}
											       name={"madeForKids"} value={"madeForKidsTrue"}
											       onChange={onMadeForKidsChange}
											       checked={formData.madeForKids === true}/>
											<label className="custom-control-label"
											       htmlFor={"madeForKidsRadioYes"}>{t('videos.detail.made_for_kids')}</label>
										</div>
									</FormGroup>
									<FormGroup>
										<div className={"custom-control custom-radio"}>
											<input disabled={!formData.tokenStatus ? true : false}
											       id={"madeForKidsRadioNo"} type="radio"
											       className={"custom-control-input form-control"}
											       name={"madeForKids"} value={"madeForKidsFalse"}
											       onChange={onMadeForKidsChange}
											       checked={formData.madeForKids === false}/>
											<label className="custom-control-label"
											       htmlFor={"madeForKidsRadioNo"}>{t('videos.detail.not_made_for_kids')}</label>
										</div>
									</FormGroup>

									<span className="preview-title overline-title">{t('videos.detail.embed')}</span>
									<FormGroup>
										<div className={"custom-control custom-checkbox"}>
											<input disabled={!formData.tokenStatus ? true : false}
											       name={"embeddable"} id={"embeddable"} type="checkbox"
											       className={"custom-control-input form-control"}
											       defaultChecked={formData.embeddable}
											       onChange={handleChangeCheckBox} value={true}/>
											<label className="custom-control-label"
											       htmlFor={"embeddable"}>{t('videos.detail.allow_embed')}</label>
										</div>
									</FormGroup>

									<span
										className="preview-title overline-title">{t('videos.detail.category')}</span>
									<FormGroup key={formData.categoryId + parseInt(Math.random() * 10000)}>
										<select id="yt-video-category"
										        name={"category"}
										        className="form-control"
										        defaultValue={formData.categoryId}
										        onChange={handleChangeCategory}
										        disabled={!formData.tokenStatus ? true : false}>
											{YoutubeVideoCategory?.sort((a, b) => {
												const nameA = a.snippet.title.toUpperCase(); // ignore upper and lowercase
												const nameB = b.snippet.title.toUpperCase(); // ignore upper and lowercase
												if (nameA < nameB) {
													return -1;
												}
												if (nameA > nameB) {
													return 1;
												}

												// names must be equal
												return 0;
											})?.map((value, key) => {
												if (value.snippet.assignable) {
													return <option key={key}
													               value={value.id}>{value.snippet.title}</option>
												}

												return false;
											})}
										</select>
									</FormGroup>

									<span
										className="preview-title overline-title">{t('videos.detail.comments_and_ratings')}</span>
									<FormGroup>
										<div className={"custom-control custom-checkbox"}>
											<input disabled={!formData.tokenStatus ? true : false}
											       name={"publicStatsViewable"} id={"publicStatsViewable"}
											       type="checkbox" className={"custom-control-input form-control"}
											       defaultChecked={formData.publicStatsViewable}
											       onChange={handleChangeCheckBox}/>
											<label className="custom-control-label"
											       htmlFor={"publicStatsViewable"}>{t('videos.detail.public_video_stats')}</label>
										</div>
									</FormGroup>
								</TabPane>
								<Monetization activeTab={activeTab} tokenStatus={formData.tokenStatus}
								              cmsId={props.video.channel.cms_id} monetize={props.video.monetize}/>
							</TabContent>
							<Modal isOpen={modalStatus.confirmation} toggle={() => toggleModal()}>
								<ModalHeader
									toggle={() => toggleModal()}>{t('video_edit_modal.modal_title')}</ModalHeader>
								<ModalBody>
									{t('video_edit_modal.modal_body')}
								</ModalBody>
								<ModalFooter>
									<Button form="form" type="submit" color="primary" onClick={(e) => {
										props.video.vcard === 0 || props.video.endscreen === 0 ? toggleSecondModal(e) : toggleModal();
									}}>{t('video_edit_modal.yes')}</Button>
									<Button color="danger"
									        onClick={() => toggleModal()}>{t('video_edit_modal.cancel')}</Button>
								</ModalFooter>
							</Modal>
							<Modal isOpen={modalStatus.secondConfirmation} toggle={() => toggleModal()}>
								<ModalHeader
									toggle={() => toggleSecondModal()}>{t('video_edit_modal.modal_title')}</ModalHeader>
								<ModalBody>
									<Alert color="danger"> <Icon
										name="alert-circle-fill"/> {t('video_edit_modal.modal_body_error')}</Alert>
									{t('video_edit_modal.modal_body')}
								</ModalBody>
								<ModalFooter>
									<Button form="form" type="submit" color="primary" onClick={() => {
										toggleSecondModal();
										setActivetab("2");
									}}>{t('video_edit_modal.yes')}</Button>
									<Button color="danger"
									        onClick={() => toggleSecondModal()}>{t('video_edit_modal.cancel')}</Button>
								</ModalFooter>
							</Modal>
							{(activeTab === "1") && (<FormGroup className={"mt-5"}>
								<Btn onClick={() => formData.tokenStatus && toggleModal()} color="primary"
								     size="md">
									<div key={formData.privacyStatus}>
										{t('operations.save_and_continue')}
									</div>
								</Btn>
							</FormGroup>)}
						</CardBody>
					</Card>
				</Col>
				<Col cm={4}>
					<Card>
						<CardBody>
							<iframe width={"100%"} height={"200px"}
							        src={`https://www.youtube.com/embed/${props.video.video_uid}`}
							        title="YouTube video player" frameBorder="0"
							        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							        allowFullScreen></iframe>
						</CardBody>
						<CardFooter>
							<div><strong>Video Link:</strong> <Link
								to={`//youtu.be/${props.video.video_uid}`}
								target="_blank">{`https://youtu.be/${props.video.video_uid}`}</Link>
							</div>
							<div><strong>Studio Link:</strong> <Link
								to={`//studio.youtube.com/video/${props.video.video_uid}/edit`}
								target="_blank">{`https://studio.youtube.com/${props.video.video_uid}`}</Link>
							</div>
						</CardFooter>
					</Card>
					<Card>
						{/* formData.privacyStatus kontrolü */}
						<CardBody>
							{formData.privacyStatus && <select id="privacy-status" name={"privacyStatus"}
							                                   defaultValue={formData.privacyStatus}
							                                   className="form-control"
							                                   onChange={handleChangeStatus}
							                                   disabled={!formData.tokenStatus ? true : false}>
								<option value="public">{t('youtube:status.public')}</option>
								<option value="unlisted">{t('youtube:status.unlisted')}</option>
								<option value="private">{t('youtube:status.private')}</option>
								<option value="scheduled">{t('youtube:status.scheduled')}</option>
							</select>}
							<div id="date-picker" className="mt-1">
								<DateTime
									id="date-picker-input"
									key={formData.privacyStatus}
									initialValue={new Date(formData.publishAt)}
									isValidDate={valid}
									dateFormat="YYYY-MM-DD"
									timeFormat="hh:mm A"
									onChange={(date) => {
										setFormData({...formData, publishAt: moment(date).local().toISOString()});
									}}
								/>
							</div>
						</CardBody>
						{/* {!extensionStatus &&
							<div className="alert alert-icon alert-warning" role="alert">
								<em className="icon ni ni-alert-circle"/>
								{t('extension.extension_offline_alert')}
								<br />
								<Link to="/extension-instructions"><i className="icon ni ni-clip" /> {t('extension.instructions_link')}</Link>
							</div>
						}

						{(extensionStatus && !props.video.channel.cookie) &&
							<div className="alert alert-icon alert-danger" role="alert">
								<em className="icon ni ni-alert-circle"/>
								{t('extension.invalid_cookie')}
							</div>
						}
						*/}

						{/* formData.privacyStatus kontrolü bitişi */}
						{props?.video?.video_uid && <>
							<div tabIndex={-2}
							     className={props.video.endscreen === 0 ? "mb-1 btn btn-secondary" : "mb-1 btn btn-primary"}
							     onClick={(e) => {
								     e.preventDefault();
									 window.open(`https://studio.youtube.com/video/${props.video.video_uid}/edit`, "_blank")
							     }} data-chid={props.video.channel.id} data-videoid={props.video.video_uid}>
								{/*                  {!extensionStatus || !props.video.channel.cookie && <em className="icon ni ni-cross-circle mr-1"/>} */}
								{t('extension.endscreen')}
								<div id="endscreen-loading" style={{display: 'none'}} className="wrapper ml-1">
									<div className="spinner-border spinner-grow-sm" role="status"/>
									<span className="visually-hidden"></span>
								</div>
							</div>
							<div tabIndex={-1}
							     className={props.video.vcard == 0 ? "btn btn-secondary" : "btn btn-primary"}
							     onClick={(e) => {
									 e.preventDefault();
									 window.open(`https://studio.youtube.com/video/${props.video.video_uid}/edit`, "_blank")
								 }} data-chid={props.video.channel.id} data-videoid={props.video.video_uid}
							     id={props.video.channel.cookie ? "cards" : "kartlar"}>
								{/*{!extensionStatus || !props.video.channel.cookie && <em className="icon ni ni-cross-circle mr-1"/>} */}
								{t('extension.cards')}
								<div id="cards-loading" style={{display: 'none'}} className="wrapper ml-1">
									<div className="spinner-border spinner-grow-sm" role="status"/>
									<span className="visually-hidden"></span>
								</div>
							</div>
						</>}
					</Card>
				</Col>
			</Row>
		</Form>
	</Container>);
};

export default YoutubeVideoEdit;