import React from "react";
import { useTranslation } from "react-i18next";

const menuTranslate = (text) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { t } = useTranslation("common");

	return t(text);
};

const menu = [
	{
		heading: "mainMenu.dashboards",
	},
	{
		icon: "dashboard",
		text: "mainMenu.dashboard",
		link: "/",
	},
	{
		heading: "mainMenu.content-management",
		permission: ["product-list", "inventory-list", "platformcms-list", "channel-list", "video-create", "video-edit"],
	},
	{
		icon: "property-add",
		text: "mainMenu.content-add",
		//link: "/content/define",
		permission: ["product-list", "inventory-list", "platformcms-list", "channel-list"],
		subMenu: [
			{
				text: "mainMenu.manage-content-product",
				link: "/content/products",
				permission: "product-list",
			},
			{
				text: "mainMenu.manage-content-inventory",
				link: "/content/inventory",
				permission: "inventory-list",
			},
			{
				text: "mainMenu.manage-content-cms",
				link: "/content/cms",
				permission: "platformcms-list",
			},
			{
				text: "mainMenu.manage-content-channel",
				link: "/content/channel",
				permission: "channel-list",
			},
			{
				text: "mainMenu.manage-content-mass-upload",
				link: "/content/mass-upload",
				permission: ["product-list", "inventory-list"],
			},
		],
	},
	{
		icon: "upload",
		text: "mainMenu.video-upload",
		link: "/content/videos/upload",
		permission: "video-create",
	},
	{
		icon: "share",
		text: "mainMenu.video-publish",
		link: "/content/videos/list",
		permission: "video-edit",
	},
	{
		icon: "share",
		text: "mainMenu.facebook-videos",
		link: "/facebook/videos",
		permission: "video-edit",
	},
	{
		heading: "mainMenu.reports",
		permission: ["reports-user-list", "reports-content-list", "reports-channel-list", "reports-company-list"],
	},
	{
		icon: "user-list",
		text: "mainMenu.reports-user",
		link: "/reports/users/video-kind",
		permission: "reports-user-list",
	},
	{
		icon: "reports-alt",
		text: "mainMenu.reports-content",
		link: "/reports/content/product",
		permission: "reports-content-list",
	},
	{
		icon: "reports",
		text: "mainMenu.reports-channel",
		link: "/reports/channels",
		permission: "reports-channel-list",
	},
	{
		icon: "report-profit",
		text: "mainMenu.reports-company",
		link: "/reports/companies/company",
		permission: "reports-company-list",
	},
	{
		heading: "mainMenu.manage",
		permission: ["user-list", "role-list"],
	},
	{
		icon: "users",
		text: "mainMenu.manage-users",
		// link: "/users",
		permission: ["user-list", "role-list"],
		subMenu: [
			{
				text: "mainMenu.manage-users-list",
				link: "/users/list",
				permission: "user-list",
			},
			{
				text: "mainMenu.manage-roles",
				link: "/roles/list",
				permission: "role-list",
			},
		],
	},
	{
		icon: "list-index",
		text: "mainMenu.manage-parameters",
		//link: "/contents",
		permission: ["parameter-list", "company-list"],
		subMenu: [
			{
				text: "mainMenu.manage-parameters-static",
				link: "/parameter/static",
				permission: "parameter-list",
			},
			{
				text: "mainMenu.manage-parameters-dynamic",
				permission: ["parameter-list", "company-list"],
				subMenu: [
					{
						text: "mainMenu.manage-parameters-company",
						link: "/parameter/companies",
						permission: "company-list",
					},
					{
						text: "mainMenu.manage-parameters-genre",
						link: "/parameter/product-genres",
						permission: "parameter-list",
					},
					{
						text: "mainMenu.manage-parameters-licensors",
						link: "/parameter/licensors",
						permission: "parameter-list",
					},
					{
						text: "mainMenu.manage-parameters-directors",
						link: "/parameter/directors",
						permission: "parameter-list",
					},
				],
			},
		],
	},
];
export default menu;
