import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: 'api/companies'
 */
export async function getCompanies() {
	try {
		const endpoint = generateApiEndpoint("companies?limit=all");
		const response = await restApiClient.get(endpoint);
		return response;
	} catch (error) {
		console.log("error", error?.response?.data);
		return error?.response?.data;
	}
}

/**
 * GET: '/api/companies'
 * Params: { page, limit }
 */
export async function getCompaniesByPageAndLimit({ page, limit }) {
	const endpoint = generateApiEndpoint(`companies?limit=${limit}&page=${page}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: 'api/company/{id}'
 */
export async function getCompany(id) {
	if (id) {
		try {
			const endpoint = generateApiEndpoint(`companies/${id}`);
			const response = await restApiClient.get(endpoint);
			return response;
		} catch (error) {
			console.log("error", error?.response?.data);
			return error?.response?.data;
		}
	} else {
		return "Missing id";
	}
}

/**
 * DELETE: '/api/companies/{id}'
 */
export async function deleteCompany(id) {
	if (id) {
		try {
			const endpoint = generateApiEndpoint(`companies/${id}`);
			const response = await restApiClient.delete(endpoint);
			return response;
		} catch (error) {
			console.log("error", error?.response?.data);
			return error?.response?.data;
		}
	} else {
		return "Missing id";
	}
}

/**
 * POST: 'api/companies'
 */
export async function addCompany(data) {
	console.log("data", data);
	if (data) {
		try {
			const endpoint = generateApiEndpoint("companies");
			const response = await restApiClient.post(endpoint, {
				...data,
			});
			console.log("response", response);
			return response;
		} catch (error) {
			console.log("error", error?.response?.data);
			return error?.response?.data;
		}
	} else {
		return "Please fill missing the fields";
	}
}

/**
 * PUT: '/api/companies/{id}'
 */
export async function updateCompanies(id, data) {
	console.log("data", data);
	if (data) {
		try {
			const endpoint = generateApiEndpoint(`companies/${id}`);
			const response = await restApiClient.put(endpoint, {
				...data,
			});
			console.log("response", response);
			return response;
		} catch (error) {
			console.log("error", error?.response?.data);
			return error?.response?.data;
		}
	} else {
		return "Please fill missing the fields";
	}
}
