import React, { useEffect, useState } from "react";
import { ProjectSettingsContextProvider } from "../../layout/context/ProjectSettingsContext";

const PageContainer = ({ ...props }) => {
	const [themeState] = useState({
		main: "default",
		sidebar: "white",
		header: "white",
		skin: "light",
	});

	useEffect(() => {
		document.body.className = `nk-body bg-lighter npc-default has-sidebar no-touch nk-nio-theme ${themeState.skin === "dark" ? "dark-mode" : ""}`;
	}, [themeState.skin]);

	return (
		<ProjectSettingsContextProvider>
			<React.Fragment>
				<div className="nk-app-root">
					<div className="nk-wrap nk-wrap-nosidebar">
						<div className="nk-content">{props.children}</div>
					</div>
				</div>
			</React.Fragment>
		</ProjectSettingsContextProvider>
	);
};
export default PageContainer;
