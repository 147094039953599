import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { reportServices } from "../../../services";
import { Chart } from "./Chart";
import { NoRevenuesChart } from "./NoRevenuesChart";

const ViewsAndRevenue = ({ dates, dim, revenue }) => {

	const { t, i18n } = useTranslation("common");
	const [timeseries, setTimeseries] = useState();
	const [range, setRange] = useState();
	const [dimension, setDimension] = useState("day");
	const [user, setUser] = useState()

	useEffect(() => {
		const getStatistics = async (dates) => {
			setUser(user);
			const params = {
				"from": dates.from,
				"to": dates.to,
				"type": "timeseries",
				"dimension": dimension,
			};
			const channelReportData = await reportServices.getChannelData(params);
			setTimeseries(channelReportData.payload);
			setRange(channelReportData?.payload.channels[0]?.data?.labels);
		}
		if(dimension){
			getStatistics(dates)
		}
	}, [dimension, dates]);


	useEffect(() => {
		if(dim){
			setDimension(dim);
		};
	},[dim])

	return (
		<Card>
			<div className="nk-ecwg nk-ecwg8 h-100">
				<div className="card-inner">
					<div className="card-title-group mb-3">
						<div className="card-title">
							{revenue ? <h6 className="title">{t("statistic.views_and_revenue_title")}</h6> : 
										 <h6 className="title">{t("statistic.view_reports")}</h6>
							}
						</div>
						<div className="card-tools">
							<UncontrolledDropdown>
								<DropdownToggle tag="a" href="#toggle" onClick={(ev) => ev.preventDefault()} className="link link-light link-sm dropdown-indicator">
									{dimension === "day" ? t("statistic.daily") : dimension === "month" ? t("statistic.monthly") : t("statistic.yearly")}
								</DropdownToggle>
								<DropdownMenu right className="dropdown-menu-sm">
									<ul className="link-list-opt no-bdr">
										<li>
											<DropdownItem
												tag="a"
												href="#dropdown"
												onClick={(ev) => {
													ev.preventDefault();
													setDimension("day");
												}}
												className={dimension === "day" ? "active" : ""}>
												<span>{t("statistic.daily")}</span>
											</DropdownItem>
										</li>
										<li>
											<DropdownItem
												tag="a"
												href="#dropdown"
												onClick={(ev) => {
													ev.preventDefault();
													setDimension("month");
												}}
												className={dimension === "month" ? "active" : ""}>
												<span>{t("statistic.monthly")}</span>
											</DropdownItem>
										</li>
										<li>
											<DropdownItem
												tag="a"
												href="#dropdown"
												onClick={(ev) => {
													ev.preventDefault();
													setDimension("year");
												}}
												className={dimension === "year" ? "active" : ""}>
												<span>{t("statistic.yearly")}</span>
											</DropdownItem>
										</li>
									</ul>
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</div>
					<ul className="nk-ecwg8-legends">
						<li>
							<div className="title">
								<span className="dot dot-lg sq" style={{ background: "#eb6459" }}></span>
								<span>{t("statistic.views")}</span>
							</div>
						</li>
						<li>
						{revenue &&
							<div className="title">
								<span className="dot dot-lg sq" style={{ background: "#9d72ff" }}></span>
								<span>{t("statistic.revenue")}</span>
							</div>}
						</li>
					</ul>
					<div className="nk-ecwg8-ck">
						{timeseries?.timeseries?.labels ? (revenue ?
						 <Chart state={timeseries} />
							:  <NoRevenuesChart state={timeseries} />
						) : <h5 className={"text-center"}>{t('general.no_data')}</h5>}
					</div>
					<div className="chart-label-group pl-5">
						<div className="chart-label">{range && range[0]}</div>
						<div className="chart-label">{range && range[range.length - 1]}</div>
					</div>
				</div>
			</div>
		</Card>
	);
};
export default ViewsAndRevenue;
