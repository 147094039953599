import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/products'
 */
export async function getProducts() {
	const endpoint = generateApiEndpoint("products?limit=all");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/products'
 * Params: { page, limit }
 */
export async function getProductsByPageAndLimit({ search }) {
	const endpoint = generateApiEndpoint(`products?${search ?? ""}&include=company,licensor,genres`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/products'
 * Params: { page, limit }
 */
export async function getProductsWithSearch(query) {
	const endpoint = generateApiEndpoint(`products?filter[search]=${query ?? ""}&limit=20`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/products'
 * Params: { page, limit }
 */
export async function getProductsWithQuery(search) {
	const endpoint = generateApiEndpoint(`products?${search ?? ""}&include=company,licensor,genres&limit=all`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/products'
 * Params: { data: {
 *     name, company_id, director_id, licensor_id, license_date, license_price, publish_date, product_type, product_genre_id
 * }}
 */
export async function addProduct(data) {
	const endpoint = generateApiEndpoint("products");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	return response.data;
}

/**
 * PUT: '/api/products/{productId}'
 * Params: { data: {
 *     name, company_id, director_id, licensor_id, license_date, license_price, publish_date, product_type, product_genre_id
 * 	}}
 */
export async function updateProduct(productId, data = null) {
	const endpoint = generateApiEndpoint(`products/${productId}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * DELETE: '/api/products/{productId}'
 */
export async function deleteProduct(productId) {
	const endpoint = generateApiEndpoint(`products/${productId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}

/**
 * GET: '/api/products'
 * Params: { page, limit, filterQuery }
 */
 export async function getFilteredProducts({ page, limit, filterQuery, searchText }) {
	const endpoint = generateApiEndpoint(`products?limit=${limit}&page=${page}&${filterQuery && filterQuery}&${searchText && searchText}&include=company,licensor,genres`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}
/**
 * POST: '/api/products/genres/sync'
 */
 export async function productGenresSync( data ) {
	const endpoint = generateApiEndpoint(`products/genres/sync`);
	const response = await restApiClient.post(endpoint, data);

	return response.data;
}