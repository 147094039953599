import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/users'
 */
export async function getUsers() {
	const endpoint = generateApiEndpoint("users?limit=all&include=roles");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/users/$id/team'
 */
export async function getUserTeam(id) {
	const endpoint = generateApiEndpoint(`users/${id}/team`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/users'
 * Params: { page, limit }
 */
export async function getUsersByPageAndLimit({ query }) {
	const endpoint = generateApiEndpoint(`users?${query ? query : ""}&include=roles`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/users'
 */
export async function addUser(data) {
	const endpoint = generateApiEndpoint("users");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	return response.data;
}

/**
 * GET: '/api/roles/{roleId}'
 */
export async function getUserById(userId) {
	const endpoint = generateApiEndpoint(`users/${userId}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/roles/{roleId}?include=roles'
 */
export async function getUserByIdWithRoles(userId) {
	const endpoint = generateApiEndpoint(`users/${userId}?include=roles`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * PUT: '/api/users/{userId}'
 * Params: { data: {
 *		name, surname, email, password, validity_start_date, validity_end_date
 * 	}}
 */
export async function updateUser(userId, data = null) {
	const endpoint = generateApiEndpoint(`users/${userId}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * DELETE: '/api/users/{userId}'
 */
export async function deleteUser(userId) {
	const endpoint = generateApiEndpoint(`users/${userId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}

/**
 * POST: '/api/users/assign'
 */
export async function roleAssign({ userId, roles }) {
	const endpoint = generateApiEndpoint(`users/assign`);
	const response = await restApiClient.post(endpoint, {
		user_id: userId,
		role_name: [...roles],
	});

	return response.data;
}

/**
 *  POST: 'api/profiles/forgetPassword'
 * @param {any} request - included email object
 */
export async function sendPasswordResetEmail(request) {
	try {
		if (request) {
			const endpoint = generateApiEndpoint("me/forgotPassword");
			var response = await restApiClient.post(endpoint, {
				email: request,
			});

			if(response.status !== 200){
			    throw response.data.message;
			}

			return {
				isSuccess: true,
				message: response.data.message,
			};
		}
	} catch (error) {
		console.error("error", error.message);
		return {
			isSuccess: false,
			message: error.response.data.message,
		};
	}
}
/**
 *  POST: 'api/profiles/resetPassword'
 * - included email object
 */
export async function sendNewPasswordReset(data) {
	try {
		if (data) {
			const endpoint = generateApiEndpoint("me/resetPassword");
			var response = await restApiClient.post(endpoint, {
				email: data.email,
				token: data.token,
				password: data.password,
			});

			if(response.status !== 200){
			    throw response.data.message;
			}

			return {
				isSuccess: true,
				message: response.data.message
			};
		}
	} catch (error) {
		console.error("error", error.message);
		return {
			isSuccess : false,
			message : error.response.data.message
		};
	}
}

/**
 * GET: '/api/users/{userId}/managers'
 */
 export async function getUserManagers(userId) {
	const endpoint = generateApiEndpoint(`users/${userId}/managers`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/users/{userId}/managers'
 */
 export async function postUserManagers(userId,body) {
	const endpoint = generateApiEndpoint(`users/${userId}/managers`);
	const response = await restApiClient.post(endpoint,body);

	return response.data;
}

/**
 * GET: '/api/users/{userId}/channels'
 */
 export async function getUserChannels(userId) {
	const endpoint = generateApiEndpoint(`users/${userId}/channels`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/users/{userId}/channels'
 */
 export async function attachUserChannels(userId, channels) {
	const endpoint = generateApiEndpoint(`users/${userId}/channels`);
	const response = await restApiClient.post(endpoint, channels);

	return response.data;
}

/**
 * DELETE: '/api/users/{userId}/channels/${channelId}'
 */
 export async function detachUserChannel(userId, channelId) {
	const endpoint = generateApiEndpoint(`users/${userId}/channels/${channelId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}
/**
 * GET: '/api/channels'
 * Params: { page, limit, filter }
 */
 export async function getFilteredUsers({ page, filterQuery, searchText }) {
	const endpoint = generateApiEndpoint(`users?page=${page}&include=roles&${searchText ? searchText : ""}&${filterQuery && filterQuery}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/users/{userId}/companies'
 */
 export async function getUserCompanies(userId) {
	const endpoint = generateApiEndpoint(`users/${userId}/companies`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/users/{userId}/companies'
 */
 export async function attachUserCompanies(userId, companies) {
	const endpoint = generateApiEndpoint(`users/${userId}/companies`);
	const response = await restApiClient.post(endpoint,companies);

	return response.data;
}

/**
 * DELETE: '/api/users/{userId}/companies/${companyId}'
 */
 export async function detachUserCompany(userId, companyId) {
	const endpoint = generateApiEndpoint(`users/${userId}/companies/${companyId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}