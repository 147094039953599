import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/packages'
 */
export async function getPackages({ search }) {
    const endpoint = generateApiEndpoint(`packages?${search ?? ""}`);
    const response = await restApiClient.get(endpoint);

    return response.data;
}

/**
 * GET: '/api/packages/{packageId}/items'
 */
export async function getPackageItems({packageId, search}) {
    const endpoint = generateApiEndpoint(`packages/${packageId}/items?${search ?? ""}`);
    const response = await restApiClient.get(endpoint);

    return response.data;
}

/**
 * POST: '/api/packages/{packageId}/execute'
 */
export async function executePackage(packageId) {
    const endpoint = generateApiEndpoint(`packages/${packageId}/execute`);
    const response = await restApiClient.post(endpoint);

    return response.data;
}

/**
 * POST: '/api/packages'
 */
export async function postPackage(data) {
    const endpoint = generateApiEndpoint(`packages`);
    const response = await restApiClient.post(endpoint, data);

    return response.data;
}

/**
 * DELETE: '/api/packages/{packageId}'
 */
export async function deletePackage(packageId) {
    const endpoint = generateApiEndpoint(`packages/${packageId}`);
    const response = await restApiClient.delete(endpoint);

    return response.data;
}
