import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import { Route, Switch, Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component";
import { findUpper } from "../../utils/Utils";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useTranslation } from "react-i18next";

import ProjectSettingsPage from "./ProjectSettings";

const ProjectLayout = () => {
	const { t } = useTranslation("common");

	const [sm, updateSm] = useState(false);
	const [mobileView, setMobileView] = useState(false);
	const [profileName, setProfileName] = useState("Merzigo Digital");

	// function to change the design view under 990 px
	const viewChange = () => {
		if (window.innerWidth < 990) {
			setMobileView(true);
		} else {
			setMobileView(false);
			updateSm(false);
		}
	};

	useEffect(() => {
		viewChange();
		window.addEventListener("load", viewChange);
		window.addEventListener("resize", viewChange);
		document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
			updateSm(false);
		});
		return () => {
			window.removeEventListener("resize", viewChange);
			window.removeEventListener("load", viewChange);
		};
	}, []);

	return (
		<React.Fragment>
			<Content>
				<Card className="card-bordered">
					<div className="card-aside-wrap">
						<div className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${sm ? "content-active" : ""}`}>
							<div className="card-inner-group">
								<div className="card-inner p-0">
									<ul className="link-list-menu">
										<li onClick={() => updateSm(false)}>
											<Link to={`${process.env.PUBLIC_URL}/project/settings`} className={window.location.pathname === `${process.env.PUBLIC_URL}/project/settings` ? "active" : ""}>
												<Icon name="setting-alt-fill"></Icon>
												<span>{t("project.settings-title")}</span>
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="card-inner card-inner-lg">
							{sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
							<Switch>
								<Route exact path={`${process.env.PUBLIC_URL}/project`} render={() => <ProjectSettingsPage updateSm={updateSm} sm={sm} setProfileName={setProfileName} />}></Route>
								<Route exact path={`${process.env.PUBLIC_URL}/project/settings`} render={() => <ProjectSettingsPage updateSm={updateSm} sm={sm} />}></Route>
							</Switch>
						</div>
					</div>
				</Card>
			</Content>
		</React.Fragment>
	);
};

export default ProjectLayout;
