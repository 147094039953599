import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/inventories'
 */
export async function getInventories() {
	const endpoint = generateApiEndpoint("inventories?limit=all");
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/inventories'
 * Params: { page, limit }
 */
export async function getInventoriesByPageAndLimit({ search }) {
	const endpoint = generateApiEndpoint(`inventories?${search ?? ''}&include=company,product,language`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * POST: '/api/inventories'
 * Params: { data: {
 *     name, comapny_id, platform, platform_id, token
 * }}
 */
export async function addInventory(data) {
	const endpoint = generateApiEndpoint("inventories");
	const response = await restApiClient.post(endpoint, {
		...data,
	});

	return response.data;
}

/**
 * PUT: '/api/inventories/{inventoryId}'
 * Params: { data: {
 *     name, comapny_id, platform, platform_id, token
 * 	}}
 */
export async function updateInventory(inventoryId, data = null) {
	const endpoint = generateApiEndpoint(`inventories/${inventoryId}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}

/**
 * DELETE: '/api/inventories/{inventoryId}'
 */
export async function deleteInventory(inventoryId) {
	const endpoint = generateApiEndpoint(`inventories/${inventoryId}`);
	const response = await restApiClient.delete(endpoint);

	return response.data;
}

/**
 * GET: '/api/inventories'
 * Params: { page, limit, sortBy }
 */
 export async function getFilteredInventories({ page, limit, filterQuery, searchText}) {
	const endpoint = generateApiEndpoint(`inventories?limit=${limit}&page=${page}&${filterQuery && filterQuery}&${searchText && searchText}&include=company,product,language`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/inventories/labels'
 */
 export async function getInventoryLabels() {
	const endpoint = generateApiEndpoint("inventories/labels");
	const response = await restApiClient.get(endpoint);

	return response.data;
}