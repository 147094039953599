import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import React from "react";
import { useTranslation } from "react-i18next";

export const Chart = ({ state }) => {

    const [data,setData] = useState({});
    const { t, i18n } = useTranslation("common");

    
    useEffect(() => {
        setData({});

        /**
         * desc:cc:
         * While creating the data set, the number format function
         * of the relevant field should be writtenin the tooltips
         * callbacks section by adding the number format value.
         */
        if(state){
            setData({
                labels : state?.timeseries?.labels,
                dataUnit: "Views",
                datasets: [
                    {
                        label: t("statistic.revenue"),
                        //desc:cc:Next line is number format type
                        numberFormat: "currency",
                        color: "#eb6459",
                        borderWidth: 2,
                        borderColor: "#9d72ff",
                        fill: true,
                        dash: 0,
                        pointRadius: 0,
                        lineTension: 0,
                        backgroundColor: "rgba(157, 114, 255, 0.15)",
                        pointBorderColor: "transparent",
                        pointBackgroundColor: "transparent",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#9d72ff",
                        pointBorderWidth: 2,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 2,
                        pointHitRadius: 4,
                        yAxisID : 'y2',
                        data: state?.timeseries?.series?.revenue_sum?.length > 0 ? state?.timeseries?.series?.revenue_sum :  [], 
                    },
                    {
                        label: t("statistic.views"),
                        //desc:cc:Next line is number format type
                        numberFormat: "integer",
                        color: "#9d72ff",
                        lineTension: 0,
                        borderWidth: 2,
                        borderColor: "#eb6459",
                        borderDash: [5],
                        pointBorderColor: "transparent",
                        pointBackgroundColor: "transparent",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#eb6459",
                        pointBorderWidth: 2,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 2,
                        pointHitRadius: 4,
                        backgroundColor: "transparent",
                        yAxisID : 'y1',
                        data: state?.timeseries?.series?.view_sum?.length > 0 ? state?.timeseries?.series?.view_sum : [],
                    },
                ],
            })
        }

    },[state])

    //desc:cc:Next line is number format function
    var currencyNumberFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    var viewNumberFormat = new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 2
    });

    return (
        <React.Fragment>
            {data && <Line
            className="ecommerce-line-s4"
            data={data}
            options={{
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data["labels"][tooltipItem[0]["index"]];
                        },
                        label: function (tooltipItem, data) {
                            //desc:cc:Next line is number format callback function for tooltips
                            if(data.datasets[tooltipItem.datasetIndex].numberFormat == "currency"){
                                return `${data.datasets[tooltipItem.datasetIndex].label}: ${currencyNumberFormat.format(tooltipItem.yLabel)}`;
                            }
                            return `${data.datasets[tooltipItem.datasetIndex].label}: ${viewNumberFormat.format(tooltipItem.yLabel)}`;
                        },
                    },
                    backgroundColor: "#1c2b46",
                    titleFontSize: 13,
                    titleFontColor: "#fff",
                    titleMarginBottom: 6,
                    bodyFontColor: "#fff",
                    bodyFontSize: 12,
                    bodySpacing: 4,
                    yPadding: 10,
                    xPadding: 10,
                    footerMarginTop: 0,
                    displayColors: false,
                },
                scales: {
                    yAxes: [
                        {
                            id : "y2",
                            display: true,
                            position: "left" ,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11,
                                fontColor: "#9d72ff",
                                padding: 10,
                                callback: function (value) {
                                    //desc:cc:Next line is number format callback function for axes value
                                    return currencyNumberFormat.format(value);
                                },
                                min: 0,
                                stepSize: 9000,
                            },
                            gridLines: {
                                color: "rgba(82, 100, 132, 0.2)",
                                tickMarkLength: 0,
                                zeroLineColor: "rgba(82, 100, 132, 0.2)",
                            },
                        },
                        {
                            id : "y1",
                            display: true,
                            position: "right" ,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11,
                                fontColor: "#eb6459",
                                padding: 10,
                                callback: function (value) {
                                    //desc:cc:Next line is number format callback function for axes value
                                    return viewNumberFormat.format(value) ;
                                },
                                min: 0,
                                stepSize: 9000,
                            },
                            gridLines: {
                                color: "rgba(82, 100, 132, 0.2)",
                                tickMarkLength: 0,
                                zeroLineColor: "rgba(82, 100, 132, 0.2)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            display: true,
                            stacked: false,
                            ticks: {
                                fontSize: 9,
                                fontColor: "#9eaecf",
                                source: "auto",
                                padding: 10,
                            },
                            gridLines: {
                                color: "transparent",
                                tickMarkLength: 0,
                                zeroLineColor: "transparent",
                            },
                        },
                    ],
                },
            }}
        />}
        </React.Fragment>
    
    );
};