import restApiClient from "./client";
import {generateApiEndpoint, generateReApiEndpoint} from "../utils";

/**
 * GET: '/api/facebook/channels/{channelId}/videos'
 */
export async function getVideos($channelId) {
	const endpoint = generateApiEndpoint(`facebook/channels/${$channelId}/videos`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/facebook/channels/{channelId}/videos?{params}'
 * Params: { before, after, limit }
 */
export async function getVideosByPageAndLimit(channelId, params = {}) {
	var uri_params = "";
	if(params?.before){
		uri_params += "&before="+params?.before;
	}

	if(params?.after){
		uri_params += "&after="+params?.after;
	}

	if(params?.limit){
		uri_params += "&limit="+params?.limit;
	}

	const endpoint = generateApiEndpoint(`facebook/channels/${channelId}/videos?${uri_params}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/facebook/channels/{channelId}/videos?platform_id={videoIds}&{params}'
 * Params: {  }
 */
export async function getVideosByVideoIds(channelId, videoIds, params = {}) {
	var uri_params = "";

	videoIds = videoIds.join(',');

	const endpoint = generateApiEndpoint(`facebook/channels/${channelId}/videos?platform_id=${videoIds}&${uri_params}`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/facebook/channels/{channelId}/videos/{videoId}'
 */
export async function getVideo(channelId, videoId) {
	const endpoint = generateApiEndpoint(`facebook/channels/${channelId}/videos/${videoId}`);
	const response = await restApiClient.get(endpoint);
	return response.data;
}

/**
 * PUT: 'api/facebook/channels/{channelId}/videos/{videoId}'
 * Params: { data: {
 *     title, description, ad_breaks, scheduled_publish_time, published
 * 	}}
 */
export async function updateVideo(channelId, videoId, data = null) {
	const endpoint = generateApiEndpoint(`facebook/channels/${channelId}/videos/${videoId}`);
	const response = await restApiClient.put(endpoint, {
		...data,
	});
	return response.data;
}