import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { findUpper } from "../../utils/Utils";
import { DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, UncontrolledDropdown, UncontrolledAlert, Badge } from "reactstrap";
import {
	Block,
	BlockBetween,
	BlockDes,
	BlockHead,
	BlockHeadContent,
	BlockTitle,
	Button,
	Row,
	Col,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableItem,
	DataTableRow,
	Icon,
	PaginationComponent,
	UserAvatar,
	RSelect,
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { usersService, rolepermissionServices, channelServices, companyService } from "../../services";
import DatePicker from "react-datepicker";
import { FilterComponent } from "../../components/FilterComponent";
import { useHistory, useLocation } from "react-router";

const UserList = () => {
	const { t } = useTranslation("common");
	const location = useLocation();
	const history = useHistory();
	let { search } = useLocation();

	const [sendEmail, setSendEmail] = useState(false);
	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});

	const [userData, setUserData] = useState([]);

	const [usersMeta, setUsersMeta] = useState({
		total: null,
	});

	const [sm, updateSm] = useState(false);
	const [modal, setModal] = useState({
		edit: false,
		add: false,
		managers: false,
		users : false,
	});
	const [editId, setEditedId] = useState();
	const [formData, setFormData] = useState({
		name: "",
		surname: "",
		email: "",
		password: "",
		validityStartDate: null,
		validityEndDate: null,
		notification: false,
	});
	const [rolesRList, setRolesRList] = useState([]);
	const [roleNamesList, setRoleNamesList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [searchParams,setSearchParams] = useState(search);
	const [allFilterData, setAllFilterData] = useState({});

	const parameters = ['roles.id'];

	const loadData = async () => {
		try {
			const users = await usersService.getUsersByPageAndLimit({ query : decodeURIComponent(searchParams) });
			setUsersMeta({ total: users.meta.total });
			setUserData([...users.payload]);
		} catch (error) {
			console.debug(error);
		}
	};

	useEffect(() => {
		history.replace({ pathname: location.pathname, search: decodeURIComponent(searchParams)});
		loadData();
	},[searchParams]);

	// function to reset the form
	const resetForm = () => {
		setFormData({
			name: "",
			surname: "",
			email: "",
			password: "",
			validityStartDate: null,
			validityEndDate: null,
			notification: false,
		});
		setAlertData({
			status: false,
			type: "info",
			icon: "alert-circle",
			message: t("error.unknown"),
		});
	};

	// function to close the form modal
	const onFormCancel = () => {
		setModal({ edit: false, add: false });
		resetForm();
	};

	// submit function to add a new item
	const onFormSubmit = async (submitData) => {
		const { name, surname, email, password, sendEmail } = submitData;
		let submittedData = {
			// id: data.length + 1,
			name: name,
			surname: surname,
			email: email,
			password: password,
			validity_start_date: formData.validityStartDate,
			validity_end_date: formData.validityEndDate,
			notification: sendEmail,
		};
		try {
			Object.keys(submittedData).forEach((key) => {
				if (!submittedData[key]) delete submittedData[key];
			});
			const response = await usersService.addUser({
				...submittedData,
				notification: sendEmail,
			});
			loadData();
			setModal({ edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// submit function to update a new item
	const onEditSubmit = async (submitData) => {
		const { name, surname, email, password } = submitData;
		let submittedData = {
			// id: data.length + 1,
			name: name,
			surname: surname,
			email: email,
			password: password,
			validity_start_date: formData.validityStartDate,
			validity_end_date: formData.validityEndDate,
		};
		try {
			Object.keys(submittedData).forEach((key) => {
				if (!submittedData[key]) delete submittedData[key];
			});
			const response = await usersService.updateUser(editId, {
				...submittedData,
			});
			loadData();
			setModal({ edit: false }, { add: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	// function that loads the want to editted data
	const onEditClick = (id) => {
		resetForm();
		userData.forEach((item) => {
			if (item.id === id) {
				setFormData({
					name: item.name,
					surname: item.surname,
					email: item.email,
					password: item.password,
					validityStartDate: item.validity_start_date ? new Date(item.validity_start_date) : null,
					validityEndDate: item.validity_end_date ? new Date(item.validity_end_date) : null,
				});
				setModal({ edit: true }, { add: false });
				setEditedId(id);
			}
		});
	};

	// function that loads the want to editted data
	const onUserAssignClick = async (id) => {
		resetForm();
		const user = await usersService.getUserByIdWithRoles(id);
		userData.forEach((item) => {
			if (item.id === id) {
				setFormData({
					...item,
					roles: user.payload.roles,
				});
				getRolesForRList();
				setRolesRList(user.payload?.roles?.map((role) => role.name));
				setModal({ users: true }, { edit: false }, { add: false }, { permission: false });
				setEditedId(id);
			}
		});
	};

	const handleChangeUser = (result, e) => {
		const roleNames = result.map((role) => role.value);
		setRoleNamesList(roleNames);
	};

	const onUserAssignSubmit = async () => {
		try {
			const response = await usersService.roleAssign({
				userId: formData.id,
				roles: roleNamesList,
			});
			loadData();
			setModal({ edit: false }, { add: false }, { permission: false }, { users: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	const onManagerAssignSubmit = async () => {
		try {
			const response = await usersService.postUserManagers(formData.id,managerList);
			loadData();
			setModal({ edit: false }, { add: false }, { permission: false }, { users: false });
			resetForm();
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};	

	const getRolesForRList = async () => {
		try {
			const rolesResponse = await rolepermissionServices.getRoles();
			const rolesOption = rolesResponse.payload.map((role) => {
				return { value: role.name, label: role.name, id: role.id };
			});
			setRolesRList(rolesOption);
			setAllFilterData(current => ({...current, "roles.id" : rolesOption.map((role) => ({...role, value: role.id}))}));
		} catch (error) {
			console.debug(error);
		}
	};

	// function to change to suspend property for an item
	const suspendUser = async (id) => {
		try {
			await usersService.updateUser(id, {
				status: "Suspended",
			});
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	// function to change to suspend property for an item
	const activeUser = async (id) => {
		try {
			await usersService.updateUser(id, {
				status: "Active",
			});
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	// function to change to suspend property for an item
	const deleteUser = async (id) => {
		try {
			await usersService.deleteUser(id);
			loadData();
		} catch (error) {
			console.debug(error);
		}
	};

	const animatedComponents = makeAnimated();

	const { errors, register, handleSubmit } = useForm();

	// Get current list, pagination
	// const indexOfLastItem = currentPage * itemPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemPerPage;
	// const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
	const currentItems = userData;

	// Change Page
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		const query = new URLSearchParams(search);
		query.set("page", pageNumber);
		query.set('limit', itemPerPage);
		setSearchParams(decodeURI(query.toString()));
	};

	const [currentUserId,setCurrentUserId] = useState();
	const [allUsers,setAllUsers] = useState();

	const [userManagers,setUserManagers] = useState();
	const [managerList,setManagerList] = useState([]);

	const [attachChannels, setAttachChannels] = useState({
		channels : [],
		userChannels : [{}],
		attachableChannels : [],
		newUserChannels : {channel_ids : [], type : ""}
	});

	const [channelModals, setChannelModals] = useState({
		channelModal : false,
		channelAddModal : false
	})

	const [attachCompany, setAttachCompany] = useState({
		companies : [],
		userCompanies : [],
		attachableCompanies : [],
		newUserCompanies : {company_ids : []}
	});

	const [companyModals, setCompanyModals] = useState({
		companyModal : false,
		addCompanyModal : false
	})

	useEffect(() => {
		const getAllUsers = async () => {
			try {
				const users = await usersService.getUsers();
				setAllUsers(users.payload?.map((user) => ({label : user.fullname, value : user.id})));
			} catch (error) {
				console.debug(error);
			}
		};
		const getChannels = async () => {
			try {
				const channels = await channelServices.getChannels();
				setAttachChannels(current => ({...current, channels : channels?.payload?.map((channel) => ({label : channel.title, id : channel.id}))}));
			} catch (error) {
				console.debug(error);
			}
		};
		const getCompanies = async () => {
			try {
				const companies = await companyService.getCompanies();
				setAttachCompany(current => ({...current, companies : companies.data.payload?.map((cm) => ({label : cm.name, id : cm.id}))}));
			} catch (error) {
				console.debug(error);
			}
		};

	   const query = new URLSearchParams(searchParams);
	   const hasPage = query.has('page');
	   const hasLimit = query.has('limit'); 
	   if(hasPage)
	   setCurrentPage(Number(query.get('page')));
	   else 
	   query.append("page", currentPage);
	   if(hasLimit)
	   setItemPerPage(Number(query.get('limit')));
	   else 
	   query.append("limit", itemPerPage);

	   setSearchParams(decodeURI(query.toString()));
	   history.replace({ pathname: location.pathname, search: decodeURI(query.toString())});

		getAllUsers();
		getRolesForRList();
		getChannels();
		getCompanies();
	},[]);

	const handleChangeManager = (result, e) => {;
		var newManagers = result.map((manager) => manager.value);
		setManagerList({user_ids : newManagers});
	};

		// function that loads the want to editted data
		const onManagerAssignClick = async (userId) => {
			const res = await usersService.getUserManagers(userId);
			var managers = res.payload?.map((manager) =>( {label : manager.fullname, value : manager.id}));
			setUserManagers(managers ? managers : []);
			userData.forEach((item) => {
				if (item.id === userId) {
					setFormData({
						...item,
						managers : managers
					});
					setModal({managers: true})
				}
			}); 
	};

	const onChannelAssignClick = async (userId) => { 
		setCurrentUserId(userId);
		const res = await usersService.getUserChannels(userId);
		setAttachChannels(current => ({...current, userChannels : res.payload}));
		var channelList = []; var usersChannels = []; var Attachables = [];
		attachChannels.channels.forEach((mem) =>  channelList.push(mem.id));
		res.payload.forEach((mem) => usersChannels.push(mem.id));
		const ids = channelList.filter(val => !usersChannels.includes(val));
 		for (var i = 0; i < ids.length; i++) {
			Attachables.push(attachChannels.channels.find((ch) => ch.id === ids[i]));
		};
		setAttachChannels(current => ({...current, attachableChannels : Attachables}));
		setFormData((userData.find((user) => user.id === userId)));
		setChannelModals(current => ({...current, channelModal : true}));
	}; 

	const onCompanyAssignClick = async (userId) => { 
		setCurrentUserId(userId);
		const res = await usersService.getUserCompanies(userId);
		setAttachCompany(current => ({...current, userCompanies : res.payload}));
		var companyList = []; var userCompanies = []; var Attachables = [];
		attachCompany?.companies?.forEach((mem) =>  companyList.push(mem.id));
		res.payload.forEach((mem) => userCompanies.push(mem.id));
		const ids = companyList.filter(val => !userCompanies.includes(val));
 		for (var i = 0; i < ids.length; i++) {
			Attachables.push(attachCompany.companies.find((cm) => cm.id === ids[i]));
		} 
		setAttachCompany(current => ({...current, attachableCompanies : Attachables}));
		setFormData((userData.find((user) => user.id === userId)));
		setCompanyModals(current => ({...current, companyModal : true}));
	};

	const typesRaw = [{id: 1,label : 'editor'},{id : 2, label : 'manager'}, {id: 3, label : 'owner'}]

	const attachChannelToUser = async () => {
		try{
			await usersService.attachUserChannels(currentUserId, attachChannels.newUserChannels);
			setChannelModals(current => ({...current, channelAddModal : false }))
			onChannelAssignClick(currentUserId);
		} catch (error) {
			console.debug(error);
		}
	}

	const detachChannelFromUser = (userId, channelId) => {
		try {
			usersService.detachUserChannel(userId, channelId);
			onChannelAssignClick(userId); 
		}catch(error) {
			console.debug(error);
		}
	}

	const attachCompanyToUser = async () => {
		try{
			await usersService.attachUserCompanies(currentUserId, attachCompany.newUserCompanies);
			setCompanyModals(current => ({...current, addCompanyModal : false }))
			onCompanyAssignClick(currentUserId);
		} catch (error) {
			console.debug(error);
		}
	}

	const detachCompanyFromUser = (userId,companyId) => {
		try {
			usersService.detachUserCompany(userId, companyId);
			onCompanyAssignClick(currentUserId);
		}catch(error) {
			console.debug(error);
		}
	}

	return (
		<React.Fragment>
			<Head title={t("users.list")}></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle tag="h3" page>
								{t("users.list")}
							</BlockTitle>
							<BlockDes className="text-soft">
								<p>
									{t("general.total")} {usersMeta.total} {t("general.user")}.
								</p>
							</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
									<Icon name="menu-alt-r"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3">
										{/*
										todo:cc: export button
										<li>
											<a
												href="#export"
												onClick={(ev) => {
													ev.preventDefault();
												}}
												className="btn btn-white btn-outline-light"
											>
												<Icon name="download-cloud"></Icon>
												<span>Export</span>
											</a>
										</li>
										 */}
										<li className="nk-block-tools-opt">
											<Button
												color="primary"
												className="btn-icon"
												onClick={() => {
													resetForm();
													setModal({ add: true });
												}}>
												<Icon name="plus"></Icon>
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>

				{alertData.status && !modal.add && !modal.edit ? (
					<Block>
						<BlockHeadContent>
							<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
								<Icon name={alertData.icon ?? "cross-circle"} />
								{alertData.message ?? t("error.unknown")}
							</UncontrolledAlert>
						</BlockHeadContent>
					</Block>
				) : null}

				<Block>
					<DataTable className="card-stretch">

						<FilterComponent
							type="search"
							data={allFilterData}
							search={searchParams}
							setSearch={setSearchParams}
							parameters={parameters}
							currentPage={currentPage}
							itemPerPage={itemPerPage}
							setItemPerPage={setItemPerPage}
						/>

						<DataTableBody compact>
							<DataTableHead>
								<DataTableRow className="nk-tb-col-check">
									<span className="sub-text">#</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("users.fullname")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("users.email")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("general.role")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("users.validity-start-date")}</span>
								</DataTableRow>
								<DataTableRow size="sm">
									<span className="sub-text">{t("users.validity-end-date")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("users.status")}</span>
								</DataTableRow>
								<DataTableRow>
									<span className="sub-text">{t("users.action")}</span>
								</DataTableRow>
							</DataTableHead>
							{/*Head*/}
							{userData.length > 0
								? userData.map((item, key) => {
										let status = item?.status?.toLowerCase();
										return (
											<DataTableItem key={key}>
												<DataTableRow className="nk-tb-col-check">{item.id}</DataTableRow>
												<DataTableRow>
														<div className="user-card">
															<UserAvatar theme={item.avatarBg} className="xs" text={findUpper(item.name)} image={item.image}></UserAvatar>
															<div className="user-name">
																<span className="tb-lead">{item.fullname}</span>
															</div>
														</div>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>{item.email}</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span>{item.roles && item.roles.map((role,key) => <Badge key={key}>{role.name}</Badge>)}</span>
												</DataTableRow>
												<DataTableRow size="lg">
													<span>{item.validity_start_date}</span>
												</DataTableRow>
												<DataTableRow size="lg">
													<span>{item.validity_end_date}</span>
												</DataTableRow>
												<DataTableRow>
													<span className={`tb-status text-${status === "Active".toLowerCase() ? "success" : status === "Pending".toLowerCase() ? "warning" : "danger"}`}>{t("users.statuses." + status)}</span>
												</DataTableRow>
												<DataTableRow className="nk-tb-col-tools">
													<ul className="nk-tb-actions gx-1">
														<li>
															<UncontrolledDropdown>
																<DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
																	<Icon name="more-h"></Icon>
																</DropdownToggle>
																<DropdownMenu right>
																	<ul className="link-list-opt no-bdr">
																		<li onClick={() => onEditClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#edit"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="edit"></Icon>
																				<span>{t("operations.edit")}</span>
																			</DropdownItem>
																		</li>
																		<li onClick={() => onUserAssignClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#role-assign"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="account-setting-alt"></Icon>
																				<span>{t("operations.roles-assign")}</span>
																			</DropdownItem>
																		</li>
																		<li onClick={() => onManagerAssignClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#manager-assign"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="user-check"></Icon>
																				<span>{t("user_assignment.assign_manager")}</span>
																			</DropdownItem>
																		</li>
																		<li onClick={() => onChannelAssignClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#channel-assign"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="video"></Icon>
																				<span>{t("user_assignment.assign_channel")}</span>
																			</DropdownItem>
																		</li>
																		<li onClick={() => onCompanyAssignClick(item.id)}>
																			<DropdownItem
																				tag="a"
																				href="#company-assign"
																				onClick={(ev) => {
																					ev.preventDefault();
																				}}
																			>
																				<Icon name="building-fill"></Icon>
																				<span>{t("user_assignment.assign_company")}</span>
																			</DropdownItem>
																		</li>
																		{status !== "Suspended".toLowerCase() && (
																			<React.Fragment>
																				<li className="divider"></li>
																				<li onClick={() => suspendUser(item.id)}>
																					<DropdownItem
																						tag="a"
																						href="#suspend"
																						onClick={(ev) => {
																							ev.preventDefault();
																						}}
																					>
																						<Icon name="na"></Icon>
																						<span>{t("operations.suspend")}</span>
																					</DropdownItem>
																				</li>
																			</React.Fragment>
																		)}
																		{status !== "Active".toLowerCase() && (
																			<>
																				<React.Fragment>
																					<li className="divider"></li>
																					<li onClick={() => activeUser(item.id)}>
																						<DropdownItem
																							tag="a"
																							href="#active"
																							onClick={(ev) => {
																								ev.preventDefault();
																							}}
																						>
																							<Icon name="undo"></Icon>
																							<span>{t("operations.active")}</span>
																						</DropdownItem>
																					</li>
																					<li
																						onClick={() => {
																							if (window.confirm(t("users.delete-confirm"))) deleteUser(item.id);
																						}}
																					>
																						<DropdownItem
																							tag="a"
																							href="#delete"
																							onClick={(ev) => {
																								ev.preventDefault();
																							}}
																						>
																							<Icon name="user-remove"></Icon>
																							<span>{t("operations.delete")}</span>
																						</DropdownItem>
																					</li>
																				</React.Fragment>
																			</>
																		)}
																	</ul>
																</DropdownMenu>
															</UncontrolledDropdown>
														</li>
													</ul>
												</DataTableRow>
											</DataTableItem>
										);
								  })
								: null}
						</DataTableBody>
						<div className="card-inner">
							{currentItems.length > 0 ? (
								<PaginationComponent itemPerPage={itemPerPage} totalItems={usersMeta.total} paginate={paginate} currentPage={currentPage} />
							) : (
								<div className="text-center">
									<span className="text-silent">No data found</span>
								</div>
							)}
						</div>
					</DataTable>
				</Block>
				<Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("users.add")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onFormSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.firstname")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-zA-ZöÖçÇşŞıİğĞüÜ\s]*$/i,
															message: t("users.required-alfa"),
														},
													})}
												/>
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
											{/*<ErrorMessage*/}
											{/*    errors={errors}*/}
											{/*    name="nameInputErros"*/}
											{/*    render={({message}) => <p>{message}</p>}*/}
											{/*/>*/}
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.lastname")}</label>
												<input
													className="form-control"
													type="text"
													name="surname"
													defaultValue={formData.surname}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-zA-ZöÖçÇşŞıİğĞüÜ\s]*$/i,
															message: t("users.required-alfa"),
														},
													})}
												/>
												{errors.surname && <span className="invalid">{errors.surname.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.email")}</label>
												<input
													className="form-control"
													type="text"
													name="email"
													defaultValue={formData.email}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-zA-Z0-9._%+-]+@merzigo+\.[a-zA-Z]{3,}$/i,
															message: t("users.required-alfa"),
														},
													})}
												/>
												{errors.email && <span className="invalid">{errors.email.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												{/*// TODO Change with password*/}
												<label className="form-label">{t("users.password")}</label>
												<input
													className="form-control"
													type="password"
													name="password"
													defaultValue={formData.password}
													ref={register({
														required: t("operations.required"),
													})}
												/>
												{errors.password && <span className="invalid">{errors.password.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.validity-start-date")}</label>
												<DatePicker
													selected={formData.validityStartDate}
													className="form-control"
													onChange={(date) => {
														setFormData({ ...formData, validityStartDate: date });
													}}
													minDate={new Date()}
												/>
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.validity-end-date")}</label>
												<DatePicker
													selected={formData.validityEndDate}
													className="form-control"
													placeholder="dd/mm/yyyy"
													onChange={(date) => {
														setFormData({ ...formData, validityEndDate: date });
													}}
													minDate={formData.validityStartDate != null ? formData.validityStartDate : new Date()}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<div className="custom-control custom-control-sm custom-checkbox">
													<input
														name="notification"
														type="checkbox"
														onChange={() => {
															setSendEmail(true);
														}}
														className="custom-control-input form-control"
														id="customCheck1"
													/>
													<label className="custom-control-label" htmlFor="customCheck1">
														{t("users.sendMail")}
													</label>
												</div>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("users.edit")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onEditSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.firstname")}</label>
												<input
													className="form-control"
													type="text"
													name="name"
													defaultValue={formData.name}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-zA-ZöÖçÇşŞıİğĞüÜ\s]*$/i,
															message: t("users.required-alfa"),
														},
													})}
												/>
												{errors.name && <span className="invalid">{errors.name.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.lastname")}</label>
												<input
													className="form-control"
													type="text"
													name="surname"
													defaultValue={formData.surname}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-zA-ZöÖçÇşŞıİğĞüÜ\s]*$/i,
															message: t("users.required-alfa"),
														},
													})}
												/>
												{errors.surname && <span className="invalid">{errors.surname.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.email")}</label>
												<input
													className="form-control"
													type="text"
													name="email"
													defaultValue={formData.email}
													ref={register({
														required: t("operations.required"),
														pattern: {
															value: /^[a-zA-Z0-9._%+-]+@merzigo+\.[a-zA-Z]{3,}$/i,
															message: t("users.required-alfa"),
														},
													})}
												/>
												{errors.email && <span className="invalid">{errors.email.message}</span>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												{/*// TODO Change with password*/}
												<label className="form-label">{t("users.password")}</label>
												<input className="form-control" type="password" name="password" ref={register({})} />
												{errors.password && <span className="invalid">{errors.password.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.validity-start-date")}</label>
												<DatePicker
													selected={formData.validityStartDate}
													className="form-control"
													defaultValue={formData.validityStartDate}
													onChange={(date) => {
														setFormData({ ...formData, validityStartDate: date });
													}}
													minDate={new Date()}
												/>
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.validity-end-date")}</label>
												<DatePicker
													selected={formData.validityEndDate}
													className="form-control"
													placeholder="dd/mm/yyyy"
													defaultValue={formData.validityEndDate}
													onChange={(date) => {
														setFormData({ ...formData, validityEndDate: date });
													}}
													minDate={formData.validityStartDate != null ? formData.validityStartDate : new Date()}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.users} toggle={() => setModal({ users: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("users.role-assign")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onUserAssignSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.fullname")}</label>
												<input className="form-control" type="text" defaultValue={formData.fullname} disabled={true} />
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.email")}</label>
												<input className="form-control" type="text" defaultValue={formData.email} disabled={true} />
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">{t("users.roles")}</label>
												<RSelect
													name="users"
													isMulti
													components={animatedComponents}
													options={rolesRList}
													onChange={handleChangeUser}
													defaultValue={formData?.roles?.map((role) => {
														return { value: role.name, label: role.name };
													})}
												/>
												{errors.users && <span className="invalid">{errors.users.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={modal.managers} toggle={() => setModal({ managers: false })} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								onFormCancel();
							}}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("user_assignment.assign_manager")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(onManagerAssignSubmit)}>
									<Row>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.fullname")}</label>
												<input className="form-control" type="text" defaultValue={formData.fullname} disabled={true} />
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<label className="form-label">{t("users.email")}</label>
												<input className="form-control" type="text" defaultValue={formData.email} disabled={true} />
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<label className="form-label">Kullanıcılar</label>
												<RSelect
													name="users"
													isMulti
													components={animatedComponents}
													options={allUsers}
													onChange={handleChangeManager}
													defaultValue={userManagers}
												/>
												{errors.users && <span className="invalid">{errors.users.message}</span>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															onFormCancel();
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal isOpen={channelModals?.channelModal} toggle={() => setChannelModals(current => ({...current, channelModal : !current.channelModal}))} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								setChannelModals(current => ({...current, channelModal : false}))
							}}
							className="close">
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("user_assignment.assign_channel")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
									<Row>
										<Col md="6">
												<label className="form-label">{t("users.fullname")}</label>
												<input className="form-control" type="text" defaultValue={formData.fullname} disabled={true} />
										</Col>
										<Col md="6">
												<label className="form-label">{t("users.email")}</label>
												<input className="form-control" type="text" defaultValue={formData.email} disabled={true} />
										</Col>
									</Row> <br/>
									<Row>
										<Col md="12">
											<Row>
										<Col md="11">
											<label className="form-label">{t('user_assignment.channels')}</label>
										</Col>
										{attachChannels.attachableChannels.length > 0 && <Col md="1">
											<Button onClick={() => setChannelModals(current => ({...current, channelAddModal : true}))} className="btn-round btn-icon" color="primary" size="sm">
												<Icon className="text-light" name="plus" />
												</Button>
										</Col>}
									</Row>
						
									<DataTable className="card-stretch">
										<DataTableBody compact>
											<DataTableHead>
												<DataTableRow className="sm">
													<span className="tb-lead">{t('statistic.channel_name')}</span>
												</DataTableRow>
												<DataTableRow size="sm">
													<span className="tb-lead">{t('user_assignment.channel_access')}</span>
												</DataTableRow>
											</DataTableHead>
											{/*Head*/}
											{attachChannels?.userChannels ? attachChannels?.userChannels?.map((channel, key) => 
												<DataTableItem key={key}>
												<DataTableRow>
													<span>{channel.title}</span>
												</DataTableRow>
												<DataTableRow size="lg">
													<span>{channel.pivot?.type}</span>
												</DataTableRow>
												<DataTableRow size="lg">
													<Icon onClick={() => detachChannelFromUser(formData.id, channel.id)} style={{cursor : 'pointer'}} className="text-danger" name="cross" />
												</DataTableRow>
											</DataTableItem>
											) : <span>{t('general.no_data')}</span>}
										</DataTableBody>
									</DataTable>
												{errors.users && <span className="invalid">{errors.users.message}</span>}
										</Col>
									</Row>
									<Row>
									</Row>
							</div>
						</div>
					</ModalBody>
					<Modal isOpen={channelModals.channelAddModal} toggle={() => setChannelModals(current => ({...current, channelAddModal : !current.channelAddModal}))} className="modal-dialog-centered" size="sm">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								setChannelModals(current => ({...current, channelAddModal : false}))
							}}
							className="close">
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("user_assignment.assign_channel")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(attachChannelToUser)}>
								<Col>
											<FormGroup>
											<label className="form-label">{t('statistic.channel_name')}</label>
												<RSelect options={attachChannels.attachableChannels} onChange={(e) => setAttachChannels(current => ({...current, newUserChannels : ({...attachChannels.newUserChannels, channel_ids : [e.id]})}))} required/>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
											<label className="form-label">{t('user_assignment.channel_access')}</label>
												<RSelect options={typesRaw} onChange={(e) => setAttachChannels(current => ({...current, newUserChannels : ({...attachChannels.newUserChannels, type : e.label})}))}  required />
											</FormGroup>
										</Col>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															setChannelModals(current => ({...current, channelAddModal : false}))
														}}
														className="link link-light">
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				</Modal>
				<Modal key={attachCompany?.userCompanies} isOpen={companyModals?.companyModal} toggle={() => setCompanyModals(current => ({...current, companyModal : !current.companyModal}))} className="modal-dialog-centered" size="lg">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								setCompanyModals(current => ({...current, companyModal : false}))}
							}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("user_assignment.assign_company")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
									<Row>
										<Col md="6">
												<label className="form-label">{t("users.fullname")}</label>
												<input className="form-control" type="text" defaultValue={formData.fullname} disabled={true} />
										</Col>
										<Col md="6">
												<label className="form-label">{t("users.email")}</label>
												<input className="form-control" type="text" defaultValue={formData.email} disabled={true} />
										</Col>
									</Row> <br/>
									<Row>
										<Col md="12">
											<Row>
										<Col md="11">
											<label className="form-label">{t('user_assignment.companies')}</label>
										</Col>
										{attachCompany?.attachableCompanies?.length > 0 && <Col md="1">
											<Button onClick={() => setCompanyModals(current => ({...current, addCompanyModal : !current.addCompanyModal }))} className="btn-round btn-icon" color="primary" size="sm">
												<Icon className="text-light" name="plus" />
												</Button>
										</Col>}
									</Row>
						
									<DataTable className="card-stretch">
										<DataTableBody compact>
											<DataTableHead>
												<DataTableRow className="sm">
													<span className="tb-lead">{t('company.name')}</span>
												</DataTableRow>
											</DataTableHead>
											{/*Head*/}
											{attachCompany?.userCompanies ? attachCompany?.userCompanies?.map((company, key) => 
												<DataTableItem key={key}>
												<DataTableRow>
													<span>{company.name}</span>
												</DataTableRow>
												<DataTableRow size="lg">
													<Icon onClick={() => detachCompanyFromUser(formData.id,company.id)} style={{cursor : 'pointer'}} className="text-danger" name="cross" />
												</DataTableRow>
											</DataTableItem>
											) : <span>Veri yok</span>}
										</DataTableBody>
									</DataTable>
												{errors.users && <span className="invalid">{errors.users.message}</span>}
										</Col>
									</Row>
									<Row>
									</Row>
							</div>
						</div>
					</ModalBody>
					<Modal isOpen={companyModals?.addCompanyModal} toggle={() => setCompanyModals(current => ({...current, addCompanyModal : !current.addCompanyModal }))} className="modal-dialog-centered" size="sm">
					<ModalBody>
						<a
							href="#cancel"
							onClick={(ev) => {
								ev.preventDefault();
								setCompanyModals(current => ({...current, addCompanyModal : false }))}
							}
							className="close"
						>
							<Icon name="cross-sm"></Icon>
						</a>
						<div className="p-2">
							<h5 className="title">{t("user_assignment.assign_company")}</h5>
							{alertData.status ? (
								<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
									<Icon name={alertData.icon ?? "cross-circle"} />
									{alertData.message ?? t("error.unknown")}
								</UncontrolledAlert>
							) : null}
							<div className="mt-4">
								<Form className="gy-4" onSubmit={handleSubmit(attachCompanyToUser)}>
								<Col>
											<FormGroup>
											<label className="form-label">{t('company.name')}</label>
												<RSelect options={attachCompany.attachableCompanies} onChange={(e) => setAttachCompany(current => ( {...current, newUserCompanies : {company_ids : [e.id]} } ))} required/>
											</FormGroup>
										</Col>
										<Col>
										</Col>
									<Row>
										<Col size="12">
											<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
												<li>
													<Button color="primary" size="md" type="submit">
														{t("operations.save")}
													</Button>
												</li>
												<li>
													<a
														href="#cancel"
														onClick={(ev) => {
															ev.preventDefault();
															setCompanyModals(current => ({...current, addCompanyModal : false }))
														}}
														className="link link-light"
													>
														{t("operations.cancel")}
													</a>
												</li>
											</ul>
										</Col>
									</Row>
								</Form>
							</div>
						</div>
					</ModalBody>
				</Modal>
				</Modal>
			</Content>
		</React.Fragment>
	);
};
export default UserList;