import React, {  useState } from "react";
import { Block, BlockContent, BlockHead, BlockTitle, Button, PreviewCard, Icon } from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { Form, FormGroup, Alert, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Link, useHistory, useLocation } from "react-router-dom";
import { sendNewPasswordReset } from "../../services/usersService";
import { useTranslation } from "react-i18next";
import { ProjectSettingsContextProvider } from "../../layout/context/ProjectSettingsContext";
import { LanguageContextProvider } from "../../layout/context/LanguageContext";

const ResetPassword = () => {
	let params = useLocation().search;
    const token = new URLSearchParams(params).get('token');
    const email = new URLSearchParams(params).get('email');
    const history = useHistory();

    const { t } = useTranslation("common");
    const [pass,setPass] = useState("");
    const [passControl,setPassControl] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");
    const [passwordState, setpasswordState] = useState(false);
	const [loading, setLoading] = useState(false);


	const sendResetPassword = async () => {
        setLoading(true);
		setErrorMessage();
		setSuccessMessage();
        if(pass === passControl){
            var response = await sendNewPasswordReset({"password" : pass, token, email});
			if(response?.isSuccess){
				setPass("");
				setPassControl("");
				setSuccessMessage(response.message)
				setTimeout(function () {
					history.push(`${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/auth-login"}`);
				}, 2000);
			}else{
				setErrorMessage(response.message)
			}
        }else {
            setErrorMessage(t("resetPassword.password-not-match"));
        }
        setLoading(false);
	
	};


	const { errors, register, handleSubmit } = useForm();

	return (
		<ProjectSettingsContextProvider>
			<React.Fragment>
				<Head title="Reset-Password" />
				<PageContainer>
					<Block className="nk-block-middle nk-auth-body  wide-xs">
						<div className="brand-logo pb-4 text-center">
							<Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
								<img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
								<img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
							</Link>
						</div>
						<PreviewCard className="card-bordered" bodyClass="card-inner-lg">
							<BlockHead>
								<BlockContent>
									<BlockTitle tag="h5">{t("resetPassword.title")}</BlockTitle>

								</BlockContent>
							</BlockHead>
							{errorMessage && (
								<div className="mb-3">
									<Alert color="danger" className="alert-icon">
										<Icon name="alert-circle" /> {errorMessage}
									</Alert>
								</div>
							)}{" "}
							{successMessage && (
								<div className="mb-3">
									<Alert color="success" className="alert-icon">
										<Icon name="alert-circle" /> {successMessage}
									</Alert>
								</div>
							)}
							<Form className="is-alter" onSubmit={handleSubmit(sendResetPassword)}>
								<FormGroup>
									<div className="form-label-group">
										<label className="form-label" htmlFor="password">
											{t("resetPassword.email")}
										</label>
									</div>
									<div className="form-control-wrap">
										<input
											type="email"
											id="email"
											name="email"
											placeholder={email}
											className={`form-control-lg form-control`}
											defaultValue={email}
											tabIndex="99"
											disabled={true}
										/>
										<input name="token" defaultValue={token} hidden={true} />
									</div>
								</FormGroup>
								<FormGroup>
									<div className="form-label-group">
										<label className="form-label" htmlFor="password">
											{t("resetPassword.password")}
										</label>

									</div>
									<div className="form-control-wrap">
										<a
											href="#password"
											onClick={(ev) => {
												ev.preventDefault();
												setpasswordState(!passwordState);
											}}
											className={`form-icon lg form-icon-right password-switch ${passwordState ? "is-hidden" : "is-shown"}`}
										>
											{!passwordState ? <Icon name="eye" className="password-icon icon-show"></Icon> : <Icon name="eye-off" className="password-icon icon-hide"></Icon>}
										</a>
										<input
											type={passwordState ? "text" : "password"}
											id="password"
	                                        onChange={(e) => setPass(e.target.value)}
											name="passcode"
											placeholder={t("resetPassword.passwordPh")}
											className={`form-control-lg form-control ${passwordState ? "is-hidden" : "is-shown"}`}
											value={pass}
											tabIndex="2"
										/>

									</div>
								</FormGroup>
	                            <FormGroup>
										<div className="form-label-group">
											<label className="form-label" htmlFor="passwordControl">
												{t("resetPassword.passwordControl")}
											</label>
										</div>
										<div className="form-control-wrap">
											<a
												href="#password"
												onClick={(ev) => {
													ev.preventDefault();
													setpasswordState(!passwordState);
												}}
												className={`form-icon lg form-icon-right password-switch ${passwordState ? "is-hidden" : "is-shown"}`}
											>
												{!passwordState ? <Icon name="eye" className="password-icon icon-show"></Icon> : <Icon name="eye-off" className="password-icon icon-hide"></Icon>}
											</a>
											<input
												type={passwordState ? "text" : "password"}
												id="passwordControl"
												name="passControl"
												placeholder={t("resetPassword.passwordControlPh")}
												className={`form-control-lg form-control ${passwordState ? "is-hidden" : "is-shown"}`}
												tabIndex="3"
												value={passControl}
	                                            onChange={(e) => setPassControl(e.target.value)}
											/>

										</div>
									</FormGroup>
								<FormGroup>
									<Button type={"submit"} color="primary" size="lg" className="btn-block">
										{loading ? <Spinner size="sm" color="light" /> :  t("resetPassword.submit")}
									</Button>
								</FormGroup>
							</Form>
                            <div className="form-note-s2 text-center pt-4">
								{" "}
								{t("resetPassword.hasAcc")} {" "}
								<Link to={`${process.env.PUBLIC_URL}/auth-login`}>
									<strong>{t("resetPassword.login")}</strong>
								</Link>
							</div>
							{/* oAuth Social Media Login */}
							{/*
							<div className="text-center pt-4 pb-3">
								<h6 className="overline-title overline-title-sap">
									<span>{t("resetPassword.or") }</span>
								</h6>
							</div>
							<ul className="nav justify-center gx-8">
								<li className="nav-item">
									<a
										className="nav-link"
										href="#socials"
										onClick={(ev) => {
											ev.preventDefault();
										}}
									>
										Facebook
									</a>
								</li>
								<li className="nav-item">
									<a
										className="nav-link"
										href="#socials"
										onClick={(ev) => {
											ev.preventDefault();
										}}
									>
										Google
									</a>
								</li>
							</ul>
							*/}
						</PreviewCard>
					</Block>
					<LanguageContextProvider>
						<AuthFooter />
					</LanguageContextProvider>
				</PageContainer>
			</React.Fragment>
		</ProjectSettingsContextProvider>
	);
};
export default ResetPassword;
