import restApiClient from "./client";
import { generateApiEndpoint } from "../utils";

/**
 * GET: '/api/reports/channels'
 */
export async function getChannelData(body) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/channels?${queryString}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/reports/users'
 */
export async function getUserData(body,id) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/users?${queryString}&filter[id]=${id}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/reports/revenues'
 */
export async function getDashboardRevenuesData() {
	const endpoint = generateApiEndpoint(`reports/revenues`);
	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/reports/videos'
 */
 export async function getVideoReportsData(body) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/videos?${queryString}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/reports/users'
 */
 export async function getUserReportsData(body) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/users?${queryString}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/reports/companies'
 */
 export async function getCompanyReportsData(body) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/companies?${decodeURIComponent(queryString)}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/reports/cmses'
 */
 export async function getCmsReportsData(body) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/cmses?${decodeURIComponent(queryString)}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/reports/licensors'
 */
 export async function getLicensorReportsData(body) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/licensors?${decodeURIComponent(queryString)}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}

/**
 * GET: '/api/reports/inventories'
 */
 export async function getInventoryReportsData(body) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/inventories?${decodeURIComponent(queryString)}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}
/**
 * GET: '/api/reports/inventories'
 */
 export async function getProductReportsData(body) {
	const queryString = new URLSearchParams(body).toString();
	const endpoint = generateApiEndpoint(`reports/products?${decodeURIComponent(queryString)}`);

	const response = await restApiClient.get(endpoint);

	return response.data;
}