import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Block, BlockHead, BlockTitle, BlockBetween, BlockHeadContent, BlockDes, Icon, Row, Col, Button, DataTableHead, DataTableBody, DataTableRow, DataTableItem, PaginationComponent } from "../../../components/Component";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Badge } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useForm } from "react-hook-form";
import ProductH from "../../../images/product/h.png";
import Dropzone from "react-dropzone";
import { Modal, ModalBody } from "reactstrap";
import { RSelect } from "../../../components/Component";
import VideoList from "./VideoList";
import VideoCreateModal from "./VideoCreateModal";
import ProcessListModal from "./ProcessListModal";
import { useTranslation } from "react-i18next";

const VideoUploadMaster = () => {
	const { t, i18n } = useTranslation("common");
	const [sm, updateSm] = useState(false);
	const [view, setView] = useState({
		create: false,
		edit: false,
		processlist: false,
	});

	// toggle function to view product details
	const toggle = (type) => {
		setView({
			create: type === "create" ? true : false,
			edit: type === "edit" ? true : false,
			processlist: type === "processlist" ? true : false,
		});
	};

	return (
		<React.Fragment>
			<Head title="Video Upload"></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle>{ t('videos.upload.page-title') }</BlockTitle>
							<BlockDes></BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<a
									href="#more"
									className="btn btn-icon btn-trigger toggle-expand mr-n1"
									onClick={(ev) => {
										ev.preventDefault();
										updateSm(!sm);
									}}
								>
									<Icon name="more-v"></Icon>
								</a>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3">
										<li className="nk-block-tools-opt">
											<Button
												className="toggle btn-icon d-md-none"
												color="primary"
												onClick={() => {
													toggle("create");
												}}
											>
												<Icon name="plus"></Icon>
											</Button>
											<Button
												className="toggle d-none d-md-inline-flex"
												color="primary"
												onClick={() => {
													toggle("create");
												}}
											>
												<Icon name="plus"></Icon>
												<span>{t('videos.new-upload')}</span>
											</Button>
										</li>
										<li className="nk-block-tools-opt">
											<Button
												className="toggle btn-icon d-md-none"
												color="primary"
												onClick={() => {
													toggle("processlist");
												}}
											>
												<Icon name="plus"></Icon>
											</Button>
											<Button
												className="toggle d-none d-md-inline-flex"
												color="primary"
												onClick={() => {
													toggle("processlist");
												}}
											>
												<Icon name="list"></Icon>
												<span>{t('videos.upload.process-list-title')}</span>
											</Button>
										</li>
									</ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>
				<Block>
					<VideoList modal={view} setModal={setView} />
				</Block>

				<SimpleBar className={`nk-add-product toggle-slide toggle-slide-right toggle-screen-any ${view.processlist ? "content-active" : ""}`}>
					<ProcessListModal modal={view} setModal={setView} />
				</SimpleBar>
				{view.processlist && <div className="toggle-overlay" onClick={toggle}></div>}

				<Modal isOpen={view.create} toggle={() => setView({ create: false })} className="modal-dialog-centered" size="lg">
					<VideoCreateModal modal={view} setModal={setView} />
				</Modal>
			</Content>
		</React.Fragment>
	);
};

export default VideoUploadMaster;
