import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import { TCDoughnut } from "../charts/analytics/AnalyticsCharts";
import { reportServices } from "../../../services";
import { Doughnut, UserDoughnut } from "./UserDoughnut";

const CompletedJobs = ({ dates }) => {
    const { t } = useTranslation("common");
    const [totalList, setTotalList] = useState({});

    const colors = ["#798bff", "#b8acff", "#ffa9ce", "#9FE2BF", "#DE3163", "#FF7F50", "#FFBF00", "#CCCCFF", "#6495ED", "#40E0D0" ]

    const calculate = (a, b) => {
        const result = ((a * 100) / (a + b));
        return result;
    }
    useEffect(() => {
        const getGraphs = () => {
            const user = JSON.parse(localStorage.getItem("authUser"));
            if (dates) {
                const getStatistics = async (date) => {
                    const body = {
                        "from": date.from,
                        "to": date.to,
                        "type": "totallist",
                    };
                    const res = await reportServices.getUserData(body, user.id);
                    setTotalList(res.payload?.total);
                }
                getStatistics(dates);
            }
        }
        getGraphs(dates)
    }, [dates])
    return (
        <React.Fragment>
            {" "}
            <div className="card-title-group">
                <div className="card-title card-title-sm">
                    <h6 className="title">{t("statistic.completed_jobs_title")}</h6>
                </div>
            </div>
            <div className="traffic-channel">
                <div className="traffic-channel-doughnut-ck">
                    {totalList && Object.keys(totalList).length > 0 ? <UserDoughnut data={totalList} className="analytics-doughnut" /> : <h5 className="text-center">{t('general.no_data')}</h5>}
                </div>
                <div className="traffic-channel-group g-2">
                    {totalList && Object.entries(totalList).map((item, index) =>
                        <div key={index + Math.random() + 1000} className="traffic-channel-data">
                            <div className="title">
                                <span className="dot dot-lg sq" style={{ background: colors[index] }}></span>
                                <span>{t(`statistic.${item[0]}`)}</span>
                            </div>
                            <div className="amount">
                                {totalList && totalList?.[item?.[0]]} <small>{totalList?.[item?.[0]] !== 0 ?
                                    calculate(item[1], (Object.entries(totalList)
                                        .filter(([key]) => key !== item["key"])
                                        .reduce((acc, [, value]) => acc + value, 0))).toFixed(2) + '%' : '0%'} </small>
                            </div>
                        </div>
                    )}

                    {/*                     <div className="traffic-channel-data">
                        <div className="title">
                            <span className="dot dot-lg sq" style={{ background: "#ffa9ce" }}></span>
                            <span>{t("statistic.collage")}</span>
                        </div>
                        <div className="amount">
                            {totalList && totalList.collage} <small>{totalList?.collage !== 0 ? calculate(totalList?.collage,(totalList?.stars + totalList?.episode)).toFixed(2) + '%' : '0%'} </small>
                        </div>
                    </div> */}

                </div>
            </div>
        </React.Fragment>
    );
};
export default CompletedJobs;