import React, { createContext, useState, useEffect } from "react";
import { profileServices } from "../../services";

import EnglishFlag from "../../images/flags/english.png";
import SpanishFlag from "../../images/flags/spanish.png";
import FrenchFlag from "../../images/flags/french.png";
import TurkeyFlag from "../../images/flags/turkey.png";

export const LanguageContext = createContext();

export const langBulkData = [
	{
		id: "tr",
		desc: "Türkçe",
		selected: true,
		flag: TurkeyFlag,
	},
	{
		id: "en",
		desc: "English",
		selected: false,
		flag: EnglishFlag,
	},
];

export const LanguageContextProvider = (props) => {
	const [langData, setLangData] = useState({ selectedLangId: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en" });

	useEffect(async () => {
		try {
			if (localStorage.getItem("lang") !== langData.selectedLangId) {
				if (localStorage.getItem("accessToken")) await profileServices.updateLanguage(langData.selectedLangId);
			}
		} catch (error) {
			console.error(error);
		}
	}, [langData]);

	return <LanguageContext.Provider value={{ contextData: [langData, setLangData] }}>{props.children}</LanguageContext.Provider>;
};
