import React, { useRef, useState } from 'react'
import { TabPane, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledAlert, Button, Form, Badge, Modal, ModalBody, Row, Col, FormGroup } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { DataTable, DataTableBody, DataTableHead, DataTableRow, DataTableItem, Icon, Block, BlockHeadContent, PaginationComponent } from '../Component';
import { useEffect } from 'react';
import { channelServices } from '../../services';
import { useHistory, useLocation, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import YoutubePagination from '../pagination/YoutubePagination';

export const PlaylistTab = (props) => {

  const { t } = useTranslation("common");
  const history = useHistory();
  const { channelId } = useParams();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const dataListRef = useRef(null);
  const { handleSubmit } = useForm();

  const [data, setData] = useState();
  const [meta, setMeta] = useState();
  const [itemPerPage, setItemPerPage] = useState(5);
  const [alertData, setAlertData] = useState({
    status: false,
    type: "info",
    icon: "alert-circle",
    message: t("error.unknown"),
  });
  const [modalAlerts, setModalAlerts] = useState({
    createAlert: "",
    editAlert: ""
  })

  const [modal, setModal] = useState({
    create: false,
    edit: false
  });

  const [editForm, setEditForm] = useState({
    title: "",
    description: "",
    privacy_status: "public"
  });

  const [createForm, setCreateForm] = useState({
    title: "",
    description: "",
    privacy_status: "public"
  })


  const loadData = async () => {
    const query = new URLSearchParams(search);
    if(props.tokenStatus){
      try {
        const res = await channelServices.getChannelPlaylists(channelId, decodeURIComponent(query));
        setData(res.payload);
        setMeta(res.meta);
      } catch (err) {
        const { response } = err;
        setAlertData({
          status: true,
          type: "danger",
          icon: "cross-circle",
          message: response?.data?.message ?? err.message,
        });
      }
    }
  }

  useEffect(() => {
    const hasLimit = query.has('limit');
    const hasSearch = query.has('filter[search]');
    if (hasLimit){
      setItemPerPage(Number(query.get('limit')));
    }
    else
      query.append("limit", itemPerPage);
    if (hasSearch){
      setonSearch(query.get('filter[search]'));
    }
    loadData();
    history.replace({ pathname: search.pathname, search: decodeURI(query.toString()) });
  }, [])

  useEffect(() => {
    loadData();
    dataListRef?.current?.scrollIntoView({ block: 'start' });
  }, [search]);

  const paginate = (pageToken) => {
    query.set("page", pageToken);
    history.replace({ pathname: search.pathname, search: decodeURIComponent(query) });
  };

  // SEARCH SECTION
  const toggle = () => { setonSearch(current => !current); document.getElementById("search-input").focus(); };

  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");

  const onFilterSearch = (e) => {
    setSearchText(e.target.value);
  };
  // onChange function for searching name
  const submitSearch = () => {
    const query = new URLSearchParams(search);
    query.set('filter[search]', onSearchText);
    query.delete("page");
    if (onSearchText !== "") {
      toggle();
    }
    history.replace({ pathname: search.pathname, search: decodeURIComponent(query) });
  };

  const clearSearchFilter = () => {
    const query = new URLSearchParams(search);
    setSearchText("");
    query.delete('filter[search]');
    history.replace({ pathname: search.pathname, search: decodeURIComponent(query) });
  };

  const resetForms = () => {
    setEditForm({
      title: "",
      description: "",
      privacy_status: "public"
    });
    setCreateForm({
      title: "",
      description: "",
      privacy_status: "public"
    });
  };

  const onFormCancel = () => {
    setModal({ create: false, edit: false });
    resetForms();
  }

  const onEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const {id, ...others} = editForm;
      await channelServices.updatePlaylist(channelId, id, {...others}) 
      setModal(current => ({ ...current, edit: false }));
      resetForms();
      setTimeout(() => {
        setAlertData({
          status: true,
          type: "success",
          icon: "check",
          message: t('error.success'),
        });
        loadData();
      }, 1000);
    } catch (err) {
      const { response } = err;
      setModalAlerts(current => ({
        ...current, createAlert: {
          status: true,
          type: "danger",
          icon: "cross-circle",
          message: response?.data?.message ?? err.message,
        }
      }));
    }
  }

  const onCreateSubmit = async (e) => {
    e.preventDefault();
    try {
        await channelServices.insertPlaylist(channelId,createForm);
        setModal(current => ({ ...current, create: false }));
        resetForms();
        setTimeout(() => {
          setAlertData({
            status: true,
            type: "success",
            icon: "check",
            message: t('error.success'),
          });
          loadData();
        }, 1000);
    } catch (err) {
      const { response } = err;
      setModalAlerts(current => ({
        ...current, createAlert: {
          status: true,
          type: "danger",
          icon: "cross-circle",
          message: response?.data?.message ?? err.message,
        }
      }));
    }
}
  const onDeleteSubmit = async (playlistId) => {
    try {
        await channelServices.deletePlaylist(channelId,playlistId);
        setTimeout(() => {
          setAlertData({
            status: true,
            type: "success",
            icon: "check",
            message: t('error.success'),
          });
          loadData();
        }, 1000);
    } catch (err) {
      const { response } = err;
      setAlertData({
        status: true,
        type: "danger",
        icon: "cross-circle",
        message: response?.data?.message ?? err.message,
    });
    }
}

  return (
    <TabPane tabId="4">
      {alertData.status &&
        <Block className="mb-2">
          <BlockHeadContent>
            <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
              <Icon name={alertData.icon ?? "cross-circle"} />
              {alertData.message ?? t("error.unknown")}
            </UncontrolledAlert>
          </BlockHeadContent>
        </Block>
      }
      <div className="card-inner position-relative card-tools-toggle">
        <Button
          color="primary"
          className="btn-icon mb-1"
          onClick={() => setModal(current => ({ ...current, create: true }))}>
          <Icon name="plus"></Icon>
        </Button>
        <div className="card-title-group">
          <div className="card-tools">
            <div>
              {onSearchText?.length > 0 &&
                <Badge className="badge-sm" pill color="primary">
                  <a
                    href="#reset"
                    style={{ color: '#fff' }}
                    onClick={(ev) => {
                      ev.preventDefault();
                      clearSearchFilter();
                    }}
                    className="clickable">
                    {t('settings:list-filter.reset')}
                  </a>
                </Badge>}
              {onSearchText &&
                <Badge className="badge-sm ml-2" pill color="gray" style={{ cursor: 'pointer' }} onClick={() => { clearSearchFilter() }}>
                  <Icon className="mr-1" name="cross-circle"></Icon>
                  {t("filter.search")}{onSearchText}
                </Badge>
              }
            </div>
          </div>
          <div className="card-tools mr-n1">
            <ul className="btn-toolbar gx-1">
              {/** Search Button **/}
              <li>
                <a
                  href="#search"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggle();
                  }}
                  className="btn btn-icon search-toggle toggle-search">
                  <Icon name="search"></Icon>
                </a>
              </li>
              {/** Filter and Sorting Button **/}
              <li>
                <div className="toggle-wrap">
                  <div
                    className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                    <ul className="btn-toolbar gx-1">
                      <li className="toggle-close">
                        <Button className="btn-icon btn-trigger toggle"
                          onClick={() => updateTableSm(false)}>
                          <Icon name="arrow-left"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={`card-search search-wrap ${!onSearch && "active"}`}>
          <div className="card-body">
            <div className="search-content">
              <Button
                className="search-back btn-icon toggle-search active"
                onClick={() => {
                  setSearchText("");
                  toggle();
                }}
              >
                <Icon name="arrow-left"></Icon>
              </Button>
              <Form onSubmit={handleSubmit(submitSearch)}>
                <input
                  id="search-input"
                  type="text"
                  className="border-transparent form-focus-none form-control"
                  placeholder={t("settings:list-filter.search-placeholder")}
                  value={onSearchText}
                  onChange={(e) => onFilterSearch(e)}
                />
                <Button type="submit" className="search-submit btn-icon">
                  <Icon name="search"></Icon>
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <DataTable className="card-stretch">
        <DataTableBody compact>
          <DataTableHead>
            <DataTableRow className="nk-tb-col-check">
              <span className="sub-text">#</span>
            </DataTableRow>
            <DataTableRow size="lg">
              <span className="sub-text">{t('content.channel.playlists.playlist')}</span>
            </DataTableRow>
            <DataTableRow size="sm">
              <span className="sub-text">{t('content.channel.playlists.privacy_status')}</span>
            </DataTableRow>
            <DataTableRow size="sm">
              <span className="sub-text">{t('content.channel.playlists.published_at')}</span>
            </DataTableRow>
            <DataTableRow size="sm">
              <span ref={dataListRef} className="sub-text">{t("users.action")}</span>
            </DataTableRow>
          </DataTableHead>
          {data && data.map((item, index) =>
            <DataTableItem key={parseInt(Math.random() * 10000)} className="nk-tb-item">
              <DataTableRow className="nk-tb-col-check">{index + 1}</DataTableRow>
              <div className='nk-tb-col cursor-pointer' onClick={() => history.push(`/content/channel/${channelId}/playlist/${item.id}`)}>
                <div className="tb-video">
                  <div className='thumb-group left section'>
                    <img
                      src={item.snippet?.thumbnails?.["default"]?.url ?? "https://img.youtube.com/vi/default.jpg"}
                      alt="product" className={"thumb"} />
                    <div className="count-thumb">
                      {item.contentDetails?.itemCount}
                      <Icon name="view-list-wd" />
                    </div>
                  </div>
                  <div className='thumb-group right section'>
                    <div className="tb-lead">{item.snippet?.title}</div>
                    <div>{item.snippet?.description?.length > 150 ? item.snippet?.description.substring(0, 150) + "..." : item.snippet?.description}</div>
                  </div>
                </div>
              </div>
              <DataTableRow size="sm">
                <span>{t(`youtube:status.${item.status?.privacyStatus}`)}</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span>{moment(item?.uploaded_at).format("YYYY-MM-DD hh:mm:ss") !== 'Invalid date' ? moment(item?.uploaded_at).format("YYYY-MM-DD hh:mm:ss") : "-"}</span>
              </DataTableRow>
              <DataTableRow className="nk-tb-col-tools">
                <ul className="gx-1">
                  <li>
                    <UncontrolledDropdown>
                      <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                        <Icon name="more-h"></Icon>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#edit"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setEditForm({
                                  id: item.id,
                                  title: item.snippet?.title,
                                  description: item.snippet?.description,
                                  privacy_status: item.status?.privacyStatus
                                });
                                setModal(current => ({ ...current, edit: true }));
                              }}>
                              <Icon name="edit"></Icon>
                              <span>{t("operations.edit")}</span>
                            </DropdownItem>
                          </li>
                          <li>
                            <DropdownItem
                              tag="a"
                              href="#delete"
                              onClick={(ev) => {
                                ev.preventDefault();
                                onDeleteSubmit(item.id);
                              }}>
                              <Icon name="delete"></Icon>
                              <span>{t("operations.delete")}</span>
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </ul>
              </DataTableRow>
            </DataTableItem>
          )}
        </DataTableBody>
        <div className="card-inner">
          {data?.length > 0 ? (
            <YoutubePagination nextToken={meta?.next_page} prevToken={meta?.prev_page} paginate={paginate} />
          ) : (
            <div className="text-center">
              <span className="text-silent">{t('general.nodatafound')}</span>
            </div>
          )}
        </div>
      </DataTable>
      <Modal isOpen={modal.edit} toggle={() => setModal(current => ({ ...current, edit: false }))} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{t('content.channel.playlists.modals.edit_title')}</h5>
            {modalAlerts.editAlert.status ? (
              <UncontrolledAlert className="alert-icon" color={modalAlerts.editAlert.type ?? "danger"}>
                <Icon name={modalAlerts.editAlert.icon ?? "cross-circle"} />
                {modalAlerts.editAlert.message ?? t("error.unknown")}
              </UncontrolledAlert>
            ) : null}
            <div className="mt-4">
              <Form className="gy-4" onSubmit={onEditSubmit}>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t('content.channel.playlists.modals.title')}</label>
                      <input onChange={(e) => setEditForm(current => ({ ...current, title: e.target.value }))} className="form-control" type="text" defaultValue={editForm?.title} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t('content.channel.playlists.modals.description')}</label>
                      <input onChange={(e) => setEditForm(current => ({ ...current, description: e.target.value }))} className="form-control" type="text" defaultValue={editForm?.description} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t('content.channel.playlists.modals.privacy_status')}</label>
                      <select onChange={(e) => setEditForm(current => ({ ...current, privacy_status: e.target.value }))} className='form-control' defaultValue={editForm?.privacy_status} name="privacy_status">
                        <option selected={editForm?.privacy_status === "public"} value="public">{t('youtube:status.public')}</option>
                        <option selected={editForm?.privacy_status === "unlisted"} value="unlisted">{t('youtube:status.unlisted')}</option>
                        <option selected={editForm?.privacy_status === "private"} value="private">{t('youtube:status.private')}</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          {t("operations.save")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("operations.cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal.create} toggle={() => setModal(current => ({ ...current, create: false }))} className="modal-dialog-centered" size="lg">
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              onFormCancel();
            }}
            className="close">
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{t('content.channel.playlists.modals.create_title')}</h5>
            {modalAlerts.createAlert.status ? (
              <UncontrolledAlert className="alert-icon" color={modalAlerts.createAlert.type ?? "danger"}>
                <Icon name={modalAlerts.createAlert.icon ?? "cross-circle"} />
                {modalAlerts.createAlert.message ?? t("error.unknown")}
              </UncontrolledAlert>
            ) : null}
            <div className="mt-4">
              <Form className="gy-4" onSubmit={onCreateSubmit}>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t('content.channel.playlists.modals.title')}</label>
                      <input required onChange={(e) => setCreateForm(current => ({ ...current, title: e.target.value }))} className="form-control" type="text" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t('content.channel.playlists.modals.description')}</label>
                      <input onChange={(e) => setCreateForm(current => ({ ...current, description: e.target.value }))} className="form-control" type="text" />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t('content.channel.playlists.modals.privacy_status')}</label>
                      <select onChange={(e) => setCreateForm(current => ({ ...current, privacy_status: e.target.value }))} className='form-control' >
                        <option value="public">{t('youtube:status.public')}</option>
                        <option value="unlisted">{t('youtube:status.unlisted')}</option>
                        <option value="private">{t('youtube:status.private')}</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          {t("operations.save")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light">
                          {t("operations.cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </TabPane>
  )
}
