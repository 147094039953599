import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { UncontrolledAlert } from "reactstrap"
import { Button, Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, Row, Col, UserAvatar } from "../../../components/Component";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import YoutubeVideoEdit from "./YoutubeVideoEdit"

import { videoServices } from "../../../services";


const VideoPublishEdit = (props) => {
	const { t, i18n } = useTranslation("common");

	const alertRawData = {
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	};

	const [alertData, setAlertData] = useState(alertRawData);
    const [videoId, setVideoId] = useState(props.match.params.videoId);
    const [video, setVideo] = useState(null);

    useEffect(() => {
        setVideoId(props.match.params.videoId);
    }, [props.match.params.videoId]);

    useEffect(async () => {
        const video = await videoServices.getVideo(videoId, 'channel,company,inventory');
        if(video?.payload){
            setVideo(video.payload);
            console.log("Video Publish Edit video: ",video.payload);
        }
    }, [videoId]);

    return (
		<React.Fragment>
			<Head title="Video Upload"></Head>
            {video && <Content>
                <Block>
                    <BlockHead size="sm">
                        <BlockBetween className="g-3">
                            <BlockHeadContent>
                                <BlockTitle page>
                                    {t('videos.detail.page-title')} / <strong className="text-primary small">{video.title}</strong>
                                </BlockTitle>
                                <BlockDes className="text-soft">
                                    <ul className="list-inline">
                                        <li>
                                            {t('modules.platform')}: <span className="text-base"><Icon name={`${video.platform.toLowerCase()}-fill`}></Icon> {video.platform.capitalize()}</span>
                                        </li>
                                        <li>
                                            {t('modules.company')}: <span className="text-base">{video.company.name}</span>
                                        </li>
                                        <li>
                                            {t('modules.channel')}: <span className="text-base">{video.channel.title}</span>
                                        </li>
                                        <li>
                                            {t('modules.content')}: <span className="text-base">{video.inventory.name}</span>
                                        </li>
                                    </ul>
                                </BlockDes>
                            </BlockHeadContent>
                            <BlockHeadContent>
                                <Button color="light" outline className="bg-white d-none d-sm-inline-flex" onClick={() => {props.history.goBack()}}>
                                    <Icon name="arrow-left"></Icon>
                                    <span>Back</span>
                                </Button>
                                <Button color="light" outline className="btn-icon bg-white d-inline-flex d-sm-none">
                                    <Icon name="arrow-left"></Icon>
                                </Button>
                            </BlockHeadContent>
                        </BlockBetween>
                    </BlockHead>
                </Block>

                {alertData.status && (
                    <Block>
                        <BlockHeadContent>
                            <UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
                                <Icon name={alertData.icon ?? "cross-circle"} />
                                {alertData.message ?? t("error.unknown")}
                            </UncontrolledAlert>
                        </BlockHeadContent>
                    </Block>
                )}

                <Block>
                     {
                        video.platform == "youtube" && <YoutubeVideoEdit video={video} alertCallback={setAlertData} />
                    }
                    {
                        video.platform == "facebook" && <YoutubeVideoEdit video={video}  />
                    } 
                </Block>
            </Content>}
        </React.Fragment>
    );
};

export default VideoPublishEdit;