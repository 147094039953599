import React, { Suspense, useLayoutEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";

import Homepage from "../pages/Homepage";
import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Regularv1 from "../pages/others/Regular-1";
import Regularv2 from "../pages/others/Regular-2";
import Terms from "../pages/others/Terms";
import { UserListPage } from "../pages/users";
import { default as RoleListPage } from "../pages/roles&permissions/RoleList";
import { default as Channel } from "../pages/content/Channel";
import ChannelSettings from "../pages/content/ChannelSettings";
import { default as Inventory } from "../pages/content/Inventory";
import { default as PlatformCms } from "../pages/content/PlatformCms";
import { default as Product } from "../pages/content/Product";
import StaticParameters from "../pages/parameters/StaticParameters";
import { ProductGenreRegular } from "../pages/product-genre";
import UserProfileLayout from "../pages/profile/UserProfileLayout";
import ProjectLayout from "../pages/project/ProjectLayout";
import { DirectorRegular } from "../pages/director";
import { LicensorRegular } from "../pages/licensor";
import Company from "../pages/company/Company";
import VideoUploadMaster from "../pages/videos/upload/VideoUploadMaster";
import VideoPublishMaster from "../pages/videos/publish/VideoPublishMaster";
import VideoPublishEdit from "../pages/videos/publish/VideoPublishEdit";
import ExtensionInstructions from "../pages/content/ExtensionInstructions";
import { YoutubeUpload as Youtube } from "../module/Module";
import ChannelReports from '../pages/reports/ChannelReports';
import CompanyReports from "../pages/reports/company-reports/CompanyReports";
import CmsReports from "../pages/reports/company-reports/CmsReports";
import LicensorReports from "../pages/reports/company-reports/LicensorReports";
import VideoKind from "../pages/reports/user-reports/VideoKind";
import VideoDetails from "../pages/reports/user-reports/VideoDetails";
import InventoryReports from "../pages/reports/content-reports/InventoryReports";
import ProductReports from "../pages/reports/content-reports/ProductReports";
import VideoReports from "../pages/reports/content-reports/VideoReports";
import { Playlist } from "../pages/content/Playlist";
import MassUpload from "../pages/content/MassUpload";
import PackageDetails from "../pages/content/PackageDetails";
import Notifications from "../pages/others/Notifications";
import FacebookVideosList from "../pages/facebook/FacebokVideosList";
import FacebokVideosListWithID from "../pages/facebook/FacebokVideosListWithID";

	/*Import Pages in a Group*/

const Pages = () => {
	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	});

	return (
		<Suspense fallback={<div />}>
			<Switch>
				{/*Blank Pages*/}
				<Route exact path={`${process.env.PUBLIC_URL}/_blank`} component={Blank}></Route>

				{/*Content Management*/}
				<Route exact path={`${process.env.PUBLIC_URL}/content/products`} component={Product}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/inventory`} component={Inventory}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/cms`} component={PlatformCms}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/mass-upload`} component={MassUpload}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/mass-upload/package-items/:packageId`} component={PackageDetails}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/define`} component={Blank}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/channel`} component={Channel}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/channel/:channelId/settings`} component={ChannelSettings}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/channel/:channelId/playlist/:playlistId`} component={Playlist}></Route>

				<Route exact path={`${process.env.PUBLIC_URL}/content/videos/:videoId/edit`} component={VideoPublishEdit}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/videos/upload`} component={VideoUploadMaster}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/content/videos/list`} component={VideoPublishMaster}></Route>

				<Route exact path={`${process.env.PUBLIC_URL}/extension-instructions`} component={ExtensionInstructions}></Route>

				{/*Report Management*/}
				<Route exact path={`${process.env.PUBLIC_URL}/reports/users/video-kind`} component={VideoKind}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/reports/users/video-details`} component={VideoDetails}></Route>

				<Route exact path={`${process.env.PUBLIC_URL}/reports/content/product`} component={ProductReports}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/reports/content/inventory`} component={InventoryReports}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/reports/content/video`} component={VideoReports}></Route>

				<Route exact path={`${process.env.PUBLIC_URL}/reports/channels`} component={ChannelReports}></Route>

				<Route exact path={`${process.env.PUBLIC_URL}/reports/companies/company`} component={CompanyReports}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/reports/companies/cms`} component={CmsReports}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/reports/companies/licensor`} component={LicensorReports}></Route>

				{/*User Management*/}
				<Route exact path={`${process.env.PUBLIC_URL}/users/list`} component={UserListPage}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/roles/list`} component={RoleListPage}></Route>

				{/*Parameter Management*/}
				<Route exact path={`${process.env.PUBLIC_URL}/parameter/static`} component={StaticParameters}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/parameter/companies`} component={Company}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/parameter/product-genres`} component={ProductGenreRegular}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/parameter/licensors`} component={LicensorRegular}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/parameter/directors`} component={DirectorRegular}></Route>

				{/*Demo Pages*/}
				<Route exact path={`${process.env.PUBLIC_URL}/pages/terms-policy`} component={Terms}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/pages/faq`} component={Faq}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/pages/regular-v1`} component={Regularv1}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/pages/regular-v2`} component={Regularv2}></Route>

				<Route exact path={`${process.env.PUBLIC_URL}/profile`} component={UserProfileLayout}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/profile/activities`} component={UserProfileLayout}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/profile/settings`} component={UserProfileLayout}></Route>

				<Route exact path={`${process.env.PUBLIC_URL}/notifications`} component={Notifications}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/project/settings`} component={ProjectLayout}></Route>

				<Route exact path={`${process.env.PUBLIC_URL}/facebook/videos`} component={FacebookVideosList}></Route>
				<Route exact path={`${process.env.PUBLIC_URL}/facebook/videos/list`} component={FacebokVideosListWithID}></Route>

				{/*Routes cannot be written under this line.*/}
				<Route exact path={`${process.env.PUBLIC_URL}/`} component={Homepage}></Route>
				<Route component={RedirectAs404}></Route>
			</Switch>
		</Suspense>
	);
};
export default Pages;
