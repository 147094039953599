import React, { useContext, useState } from "react";
import { DropdownItem, Dropdown, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { langBulkData, LanguageContext } from "../../layout/context/LanguageContext";
import _ from "lodash";

export const LanguageSelector = (props, { dropdownOpen }) => {
	const { contextData } = useContext(LanguageContext);
	const [langData, setLangData] = contextData;

	const [open, setOpen] = useState(false);
	const toggle = () => setOpen((prevState) => !prevState);

	const changeLanguage = (langId) => {
		setLangData({ selectedLangId: langId });
		localStorage.setItem("lang", langId);
	};

	return (
		<Dropdown isOpen={open} toggle={toggle}>
			<DropdownToggle color="transparent" style={{ color: "black", border: "none" }} className="dropdown-toggle dropdown-indicator has-indicator nav-link">
				{props.children ?? <div className="user-name">{_.upperCase(langData.selectedLangId)}</div>}
			</DropdownToggle>
			<DropdownMenu right className="dropdown-menu-sm">
				<ul className="language-list link-list-opt">
					{langBulkData.length > 0
						? langBulkData.map((i) => {
								return (
									<li key={i.id} className={i.id === langData.selectedLangId ? "active" : null}>
										<DropdownItem tag="a" href="" onClick={() => changeLanguage(i.id)} className="language-item">
											<img src={i.flag} alt="" className="language-flag" />
											<span className="language-name">{i.desc}</span>
										</DropdownItem>
									</li>
								);
						  })
						: null}
				</ul>
			</DropdownMenu>
		</Dropdown>
	);
};
