import React, { useState, useContext } from "react";
import Head from "../../layout/head/Head";
import { Row, Col, Card, Modal, ModalBody, Form, FormGroup, UncontrolledAlert } from "reactstrap";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Icon, InputSwitch, Button } from "../../components/Component";
import { useForm } from "react-hook-form";

import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../layout/context/LanguageContext";

import { profileServices } from "../../services";

const UserProfileSettingPage = ({ sm, updateSm }) => {
	const { t, i18n } = useTranslation("common");
	const { contextData } = useContext(LanguageContext);
	const [langData, setLangData] = contextData;

	const [alertData, setAlertData] = useState({
		status: false,
		type: "info",
		icon: "alert-circle",
		message: t("error.unknown"),
	});

	const [modal, setModal] = useState({ changePassword: false });

	const { errors, register, handleSubmit } = useForm();

	const onFormSubmit = async (submitData) => {
		const { current_password, password, password_confirmation } = submitData;
		try {
			const response = await profileServices.updatePassword({
				current_password: current_password,
				password: password,
				password_confirmation: password_confirmation,
			});

			console.log(response);
			setTimeout(function () {
				setModal({ changePassword: false });
			}, 1000);
			setAlertData({
				status: true,
				type: "success",
				icon: "check-circle",
				status: true,
				message: response?.message,
			});
		} catch (error) {
			const { response } = error;
			console.error(error);
			setAlertData({
				status: true,
				type: "danger",
				icon: "cross-circle",
				message: response?.data?.message ?? error.message,
			});
		}
	};

	return (
		<React.Fragment>
			<Head title={t("profile.security-settings")}></Head>

			<BlockHead size="lg">
				<BlockBetween>
					<BlockHeadContent>
						<BlockTitle tag="h4">{t("profile.security-settings")}</BlockTitle>
						<BlockDes>
							<p>{t("profile.security-settings-desc")}</p>
						</BlockDes>
					</BlockHeadContent>
					<BlockHeadContent className="align-self-start d-lg-none">
						<Button className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
							<Icon name="menu-alt-r"></Icon>
						</Button>
					</BlockHeadContent>
				</BlockBetween>
			</BlockHead>

			<Block>
				<Card className="card-bordered">
					<div className="card-inner-group">
						<div className="card-inner">
							<div className="between-center flex-wrap g-3">
								<div className="nk-block-text">
									<h6>{t("profile.change-password")}</h6>
									<p>{t("profile.change-password-desc")}</p>
								</div>
								<div className="nk-block-actions flex-shrink-sm-0">
									<ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
										<li className="order-md-last">
											<Button color="primary" onClick={() => setModal({ changePassword: true })}>
												{t("profile.change-password")}
											</Button>
										</li>
										{/**
										 * todo:cc: last change password date hidden
										<li>

											<em className="text-soft text-date fs-12px">
												Last changed: <span>Oct 2, 2019</span>
											</em>
										</li>
										*/}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</Card>
			</Block>

			<Modal isOpen={modal.changePassword} className="modal-dialog-centered" size="md" toggle={() => setModal({ changePassword: false })}>
				<ModalBody>
					<a
						href="#dropdownitem"
						onClick={(ev) => {
							ev.preventDefault();
							setModal({ changePassword: false });
						}}
						className="close"
					>
						<Icon name="cross-sm"></Icon>
					</a>
					<div className="p-2">
						<h5 className="title">{t("profile.change-password")}</h5>
						{alertData.status && !modal.add && !modal.edit ? (
							<Row className="gy-4">
								<Col>
									<UncontrolledAlert className="alert-icon" color={alertData.type ?? "danger"}>
										<Icon name={alertData.icon ?? "cross-circle"} />
										{alertData.message ?? t("error.unknown")}
									</UncontrolledAlert>
								</Col>
							</Row>
						) : null}
						<div className="tab-content">
							<Form onSubmit={handleSubmit(onFormSubmit)}>
								<Row className="gy-4">
									<Col md="12">
										<FormGroup>
											<label className="form-label" htmlFor="current_password">
												{t("profile.current-password")}
											</label>
											<input
												type="password"
												id="current_password"
												name="current_password"
												className="form-control"
												ref={register({
													required: t("operations.required"),
												})}
											/>
										</FormGroup>
									</Col>
									<Col md="12">
										<FormGroup>
											<label className="form-label" htmlFor="new_password">
												{t("profile.new-password")}
											</label>
											<input
												type="password"
												id="new_password"
												name="password"
												className="form-control"
												ref={register({
													required: t("operations.required"),
												})}
											/>
										</FormGroup>
									</Col>
									<Col md="12">
										<FormGroup>
											<label className="form-label" htmlFor="confirm_new_password">
												{t("profile.new-password-confirmation")}
											</label>
											<input
												type="password"
												id="confirm_new_password"
												name="password_confirmation"
												className="form-control"
												ref={register({
													required: t("operations.required"),
												})}
											/>
										</FormGroup>
									</Col>
									<Col size="12">
										<ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
											<li>
												<Button color="primary" size="lg">
													{t("profile.change-password")}
												</Button>
											</li>
											<li>
												<a
													href="#dropdownitem"
													onClick={(ev) => {
														ev.preventDefault();
														setModal({ changePassword: false });
													}}
													className="link link-light"
												>
													{t("operations.cancel")}
												</a>
											</li>
										</ul>
									</Col>
								</Row>
							</Form>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};
export default UserProfileSettingPage;
