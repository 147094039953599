import React, { useState } from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard, Icon } from "../../components/Component";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { FormGroup, Alert, Spinner } from "reactstrap";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { LanguageContextProvider } from "../../layout/context/LanguageContext";
import { Link } from "react-router-dom";
import { checkEmail } from "../../utils/helper";
import { sendPasswordResetEmail } from "../../services/usersService";
import { ProjectSettingsContextProvider } from "../../layout/context/ProjectSettingsContext";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
	const { t } = useTranslation("common");
	const [email, setEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [sucessMessage, setSuccessMessage] = useState("");
	const [loading, setLoading] = useState(false);


	const sendResetLink = async () => {
		setLoading(true);
		setErrorMessage();
		setSuccessMessage();
		if (checkEmail(email)) {
			var response = await sendPasswordResetEmail(email);
			if (response.isSuccess) {
				setSuccessMessage(response.message);
			} else {
				setErrorMessage(response.message);
			}
		} else {
			setErrorMessage("Your e-mail address is not in the correct format.");
		}
		setLoading(false);
	};

	return (
		
		<ProjectSettingsContextProvider>
			<React.Fragment>
				<Head title="Forgot-Password" />
				<PageContainer>
					<Block className="nk-block-middle nk-auth-body  wide-xs">
						<div className="brand-logo pb-4 text-center">
							<Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
								<img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
								<img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
							</Link>
						</div>
						<PreviewCard className="card-bordered" bodyClass="card-inner-lg">
							<BlockHead>
								<BlockContent>
									<BlockTitle tag="h5">{t("forgotPassword.title")}</BlockTitle>
									<BlockDes>
										<p>{t("forgotPassword.desc")}</p>
									</BlockDes>
								</BlockContent>
							</BlockHead>
							{errorMessage && (
								<div className="mb-3">
									<Alert color="danger" className="alert-icon">
										<Icon name="alert-circle" /> {errorMessage}
									</Alert>
								</div>
							)}{" "}
							{sucessMessage && (
								<div className="mb-3">
									<Alert color="primary" className="alert-icon">
										<Icon name="alert-circle" /> {sucessMessage}
									</Alert>
								</div>
							)}
							<FormGroup>
								<div className="form-label-group">
									<label className="form-label" htmlFor="default-01">
										Email
									</label>
								</div>
								<input
									type="text"
									className="form-control form-control-lg"
									id="default-01"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									placeholder={t("forgotPassword.placeholder")}
								/>
							</FormGroup>
							<FormGroup>
								<Button color="primary" size="lg" className="btn-block" onClick={() => sendResetLink()}>
									{loading ? <Spinner size="sm" color="light" /> : t("forgotPassword.submit")}
								</Button>
							</FormGroup>
							<div className="form-note-s2 text-center pt-4">
								<Link to={`${process.env.PUBLIC_URL}/auth-login`}>
									<strong>{t("forgotPassword.redirectButton")}</strong>
								</Link>
							</div>
						</PreviewCard>
					</Block>
					<LanguageContextProvider>
					<AuthFooter />
					</LanguageContextProvider>
				</PageContainer>
			</React.Fragment>
		</ProjectSettingsContextProvider>
	);
};
export default ForgotPassword;
