import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { findUpper } from "../../utils/Utils";
import { DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Modal, ModalBody, UncontrolledDropdown, UncontrolledAlert, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Block, BlockBetween, BlockDes, BlockHead, BlockHeadContent, BlockTitle, Button, Row, Col, DataTable, DataTableBody, DataTableHead, DataTableItem, DataTableRow, Icon, PaginationComponent, UserAvatar } from "../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { LanguageContext } from "../../layout/context/LanguageContext";
import { usersService } from "../../services";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import ParameterTable from "./ParameterTable";

import { CompanyTypes, Platforms, Languages, LanguageTypes, Countries, ChannelTypes, VideoKinds, ProductTypes } from "../parameters/static";

const StaticParameters = () => {
	const { t, i18n } = useTranslation("common");

	const [sm, updateSm] = useState(false);

	const [verticalTab, setVerticalTab] = useState("1");

	return (
		<React.Fragment>
			<Head title={t("static-parameters.name")}></Head>
			<Content>
				<BlockHead size="sm">
					<BlockBetween>
						<BlockHeadContent>
							<BlockTitle tag="h3" page>
								{t("static-parameters.name")}
							</BlockTitle>
							<BlockDes className="text-soft">{/*<p>{t("general.total")} {"%Total%"} {t("general.user")}.</p>*/}</BlockDes>
						</BlockHeadContent>
						<BlockHeadContent>
							<div className="toggle-wrap nk-block-tools-toggle">
								<Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`} onClick={() => updateSm(!sm)}>
									<Icon name="menu-alt-r"></Icon>
								</Button>
								<div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
									<ul className="nk-block-tools g-3"></ul>
								</div>
							</div>
						</BlockHeadContent>
					</BlockBetween>
				</BlockHead>

				<Block>
					<Card>
						<CardBody>
							<Row className="g-gs">
								<Col md={3}>
									<ul id={"static-parameters-menu"} className="nav link-list-menu border border-light round m-0">
										<li>
											<a
												href="#tab"
												className={classnames({ active: verticalTab === "1" })}
												onClick={(ev) => {
													ev.preventDefault();
													setVerticalTab("1");
												}}
											>
												Şirket Tipi
											</a>
										</li>
										<li>
											<a
												href="#tab"
												className={classnames({ active: verticalTab === "2" })}
												onClick={(ev) => {
													ev.preventDefault();
													setVerticalTab("2");
												}}
											>
												Platform
											</a>
										</li>
										<li>
											<a
												href="#tab"
												className={classnames({ active: verticalTab === "3" })}
												onClick={(ev) => {
													ev.preventDefault();
													setVerticalTab("3");
												}}
											>
												Dil
											</a>
										</li>
										<li>
											<a
												href="#tab"
												className={classnames({ active: verticalTab === "4" })}
												onClick={(ev) => {
													ev.preventDefault();
													setVerticalTab("4");
												}}
											>
												Dil Tipi
											</a>
										</li>
										<li>
											<a
												href="#tab"
												className={classnames({ active: verticalTab === "5" })}
												onClick={(ev) => {
													ev.preventDefault();
													setVerticalTab("5");
												}}
											>
												Coğrafi Bölge
											</a>
										</li>
										<li>
											<a
												href="#tab"
												className={classnames({ active: verticalTab === "6" })}
												onClick={(ev) => {
													ev.preventDefault();
													setVerticalTab("6");
												}}
											>
												Kanal Tipi
											</a>
										</li>
										<li>
											<a
												href="#tab"
												className={classnames({ active: verticalTab === "7" })}
												onClick={(ev) => {
													ev.preventDefault();
													setVerticalTab("7");
												}}
											>
												Video Türü
											</a>
										</li>
										<li>
											<a
												href="#tab"
												className={classnames({ active: verticalTab === "8" })}
												onClick={(ev) => {
													ev.preventDefault();
													setVerticalTab("8");
												}}
											>
												Ürün Tipi
											</a>
										</li>
									</ul>
								</Col>
								<Col md={9}>
									<TabContent activeTab={verticalTab}>
										<TabPane tabId="1">
											<ParameterTable action={false} isCompact data={CompanyTypes} />
										</TabPane>
										<TabPane tabId="2">
											<ParameterTable action={false} isCompact data={Platforms} />
										</TabPane>
										<TabPane tabId="3">
											<ParameterTable action={false} isCompact data={Languages} />
										</TabPane>
										<TabPane tabId="4">
											<ParameterTable action={false} isCompact data={LanguageTypes} />
										</TabPane>
										<TabPane tabId="5">
											<ParameterTable action={false} isCompact data={Countries} />
										</TabPane>
										<TabPane tabId="6">
											<ParameterTable action={false} isCompact data={ChannelTypes} />
										</TabPane>
										<TabPane tabId="7">
											<ParameterTable action={false} isCompact data={VideoKinds} />
										</TabPane>
										<TabPane tabId="8">
											<ParameterTable action={false} isCompact data={ProductTypes} />
										</TabPane>
									</TabContent>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Block>
			</Content>
		</React.Fragment>
	);
};
export default StaticParameters;
