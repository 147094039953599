import axios from "axios";

axios.interceptors.request.use(
	async (config) => {
		const accessToken = localStorage.getItem("accessToken");
		const applicationId = localStorage.getItem("applicationId");

		// Set necessary headers.
		// TODO Not decided yet
		if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
		if (applicationId) config.headers["Application"] = `${applicationId}`;
		// config.headers['Client-Id'] = appConfig.clientId;
		// config.headers['content-language'] = appConfig.localStorageKeys.language;
		config.headers["x-xsrf-token"] =
			"eyJpdiI6IkZRN3JHcUpMNkZTYlRHM2FRSkxQOGc9PSIsInZhbHVlIjoiNElDbWVlRkxwd1hTbEpTWVhacGZnWFluMW5xc09KUUtUUHpFQngzN2pJeHcrK1hxNjE2ZDBHakdLZ0JSUWtXWlFZZGxJNU1QOVhDTXN2Ynp0QzU4Ykk4WEZDemNoS1J0ZnJXVHdySGZKUjNZWlF0L0hTdXBneWlYL0FJSWRaOUoiLCJtYWMiOiJjOTg5ZWM5ZjhkYmI2MWQ5YTJjNTM5NTk0ODE0NTE3MzFmYzFlYWQ5YjZhYTc1ZjcwZGRkZmUyOTU1ZjEzNTkyIiwidGFnIjoiIn0%3D";
		config.headers["Content-Language"] = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
		return config;
	},
	(error) => {
		if (error.response.status === 401) {
			return (window.location.href = "/auth-login");
		}
		return Promise.reject(error);
	}
);

export default axios;
