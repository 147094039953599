import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

export const LineChartMini = ({ data, className, id }) => {


    const [timeseries, setTimeseries] = useState();

    useEffect(() => {
        if (data) {
            var timeSeriesData = data;
            setTimeseries({
                labels: timeSeriesData?.labels,
                dataUnit: "Views",
                datasets: [
                    {
                        label: "Total Revenue",
                        color: "#9d72ff",
                        borderWidth: 2,
                        borderColor: "#9d72ff",
                        fill: true,
                        lineTension: 0,
                        dash: 0,
                        pointRadius: 0,
                        lineTension: 0.4,
                        backgroundColor: "rgba(157, 114, 255, 0.15)",
                        pointBorderColor: "transparent",
                        pointBackgroundColor: "transparent",
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#9d72ff",
                        pointBorderWidth: 2,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 2,
                        pointHitRadius: 4,
                        data: timeSeriesData?.series?.revenue_sum ? timeSeriesData?.series?.revenue_sum : [],
                    },
                ],
            })

        }

    }, [data])

    return (
        <React.Fragment>
            {timeseries &&
                <Line
                    className={className}
                    data={timeseries}
                    options={{
                        legend: {
                            display: false,
                            labels: {
                                boxWidth: 12,
                                padding: 20,
                                fontColor: "#6783b8",
                            },
                        },
                        maintainAspectRatio: false,
                        tooltips: {
                            enabled: true,
                            callbacks: {
                                title: function (tooltipItem, data) {
                                    return false; //data['labels'][tooltipItem[0]['index']];
                                },
                                label: function (tooltipItem, data) {
                                    return data.datasets[tooltipItem.datasetIndex]["data"][tooltipItem["index"]];
                                },
                            },
                            backgroundColor: "#eff6ff",
                            titleFontSize: 9,
                            titleFontColor: "#6783b8",
                            titleMarginBottom: 6,
                            bodyFontColor: "#9eaecf",
                            bodyFontSize: 9,
                            bodySpacing: 4,
                            yPadding: 6,
                            xPadding: 6,
                            footerMarginTop: 0,
                            displayColors: false,
                        },
                        scales: {
                            yAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        beginAtZero: false,
                                        fontSize: 12,
                                        fontColor: "#9eaecf",
                                        padding: 0,
                                        stepSize: 300,
                                    },
                                    gridLines: {
                                        color: "rgba(82, 100, 132, 0.2)",
                                        tickMarkLength: 0,
                                        zeroLineColor: "rgba(82, 100, 132, 0.2)",
                                    },
                                },
                            ],
                            xAxes: [
                                {
                                    display: false,
                                    ticks: {
                                        fontSize: 12,
                                        fontColor: "#9eaecf",
                                        source: "auto",
                                        padding: 0,
                                    },
                                    gridLines: {
                                        color: "transparent",
                                        tickMarkLength: 0,
                                        zeroLineColor: "rgba(82, 100, 132,0.2)",
                                        offsetGridLines: true,
                                    },
                                },
                            ],
                        },
                    }}
                ></Line>
            }
        </React.Fragment>
    );
};
